import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHSNData, updateProduct } from "../../../reducers/Slices/productSlice";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import toast from "react-hot-toast";
import DurationInput from "./DurationInput";
import BarcodeInput from "./BarcodeInput";
import { debounce } from "lodash";
import { Autocomplete, TextField } from "@mui/material";

function EditProduct({ product }) {
  // Modal toggler
  const [showModal, setShowModal] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [totalSeconds, setTotalSeconds] = useState(null);

  // State to hold form data for edit form
  const [editProductFormData, setEditProductFormData] = useState({
    id: product?.id || "",
    product_name: product?.product_name || "",
    brand: product?.brand || "",
    hsn_number: product?.hsn_number || "",
    package: product?.package || "",
    is_service: product?.is_service || "N",
    service: {
      rate: product?.service?.rate || 0,
      cost: product?.service?.cost || 0,
      duration_time: product?.service?.duration_time || 0,
      tax: product?.service?.tax || 0,
      bar_code_number: product?.service?.bar_code_number || 0,
      mrp: product?.service?.mrp || 0,
    },
    batches: product?.batches || [],
  });

  // Update Product when parent product changes
  useEffect(() => {
    if (product) {
      const { id, product_name, brand, hsn_number, is_service, service, batches } = product;
      const { rate = 0, cost = 0, duration_time = 0, tax = 0, mrp = 0 } = service || {};

      setEditProductFormData({
        id,
        product_name,
        brand,
        hsn_number,
        package: product?.package,
        is_service: is_service ?? "N",
        service: {
          rate: rate,
          cost: cost,
          duration_time: duration_time,
          tax: tax,
          mrp: mrp,
        },
        batches,
      });
    }
  }, [product]);

  useEffect(() => {
    setEditProductFormData((prevState) => {
      return { ...prevState, service: { ...prevState.service, duration_time: totalSeconds } };
    });
  }, [totalSeconds]);

  //Barcode functionality
  const [barcodeNumber, setBarcodeNumber] = useState(null);

  //side effect to set batch if available in batches
  useEffect(() => {
    if (product?.service?.bar_code_number) {
      setBarcodeNumber(product?.service?.bar_code_number);
    }
  }, [product]);

  //side effect for barcode.
  useEffect(() => {
    setEditProductFormData((prevState) => {
      return { ...prevState, service: { ...prevState.service, bar_code_number: barcodeNumber } };
    });
  }, [barcodeNumber]);

  function handleEditClick() {
    setShowModal(true);

    //Only autofill totalSeconds if product is service based product
    if (product?.is_service === "Y") {
      setTotalSeconds(parseInt(product?.service?.duration_time));
    }
  }

  const dispatch = useDispatch();

  const handleClose = () => {
    if (product) {
      const { id, product_name, brand, hsn_number, is_service, service, batches } = product;
      const { rate = 0, cost = 0, duration_time = 0, tax = 0, mrp = 0 } = service || {};

      setEditProductFormData({
        id,
        product_name,
        brand,
        hsn_number,
        package: product?.package,
        is_service: is_service ?? "N",
        service: {
          rate: rate,
          cost: cost,
          duration_time: duration_time,
          tax: tax,
          mrp: mrp,
        },
        batches,
      });
    }
    setShowModal(false);
  };

  const handleSubmit = () => {
    setDisableSubmitButton(true);
    console.log(editProductFormData, "this is the edit product form data");
    // Guard - check required fields
    if (
      !editProductFormData?.product_name ||
      !editProductFormData?.brand ||
      // !editProductFormData?.package ||
      !editProductFormData?.hsn_number ||
      (editProductFormData.is_service === "Y" &&
        (!editProductFormData?.service?.rate || !editProductFormData?.service?.mrp))
    ) {
      toast.error("please fill all field values in form!");
      setDisableSubmitButton(false);
      return;
    }

    setDisableSubmitButton(false);
    dispatch(updateProduct(editProductFormData))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          setShowModal(false);
        } else {
          toast.error("Sorry, product cannot be Edited");
          setDisableSubmitButton(false);
        }
      })
      .catch(() => {
        toast.error("Error Occured");
        setDisableSubmitButton(false);
      });
  };

  // //Dummy HSN tax rates
  // const hsnTaxRates = {
  //   1205: [5],
  //   1902: [12, 18],
  //   2106: [18],
  //   2402: [28],
  //   3304: [18, 28],
  //   3824: [5],
  //   4202: [12],
  //   4901: [5, 12],
  //   6802: [28],
  //   7306: [18, 28],
  // };

  // const [hSNTaxRates, sethSNTaxRates] = useState([]);

  // useEffect(() => {
  //   if (!product?.id) {
  //     return;
  //   }

  //   const hsn_number = product?.hsn_number;
  //   const hsnTaxRateArr = hsnTaxRates[hsn_number];
  //   sethSNTaxRates(hsnTaxRateArr);
  // }, [product]);

  const [hSNTaxRates, sethSNTaxRates] = useState([]);

  //HSN codes
  const HSN_data = useSelector((state) => state.product.HSNData.data);

  const getHSNDataByHSNCodeAsync = async (hsn_number) => {
    let data = HSN_data.filter((HSNData) => HSNData?.hsn_code === hsn_number);

    try {
      if (data?.length === 0) {
        const response = await dispatch(fetchHSNData(`?hsn_code=${hsn_number}`));
        data = response.payload.data.filter((HSNData) => HSNData?.hsn_code === hsn_number);
      }
      return data;
    } catch (err) {
      toast.error("some error occurred while fetching HSN data");
      return null;
    }
  };

  useEffect(() => {
    const getHSNTaxRates = async () => {
      if (product?.hsn_number) {
        const hsnData = await getHSNDataByHSNCodeAsync(product?.hsn_number);

        if (!hsnData) {
          return null;
        }

        const duplicatePercentages =
          hsnData?.length > 1
            ? hsnData.map((data) => parseFloat(data?.cgst_rate) + parseFloat(data?.sgst_rate))
            : [parseFloat(hsnData[0]?.cgst_rate) + parseFloat(hsnData[0]?.sgst_rate)];

        const taxPercent = [...new Set(duplicatePercentages)];

        // const taxPercent = parseFloat(data?.cgst_rate) + parseFloat(data?.sgst_rate);
        const nextHSNTaxRateArr = taxPercent || [];
        sethSNTaxRates(nextHSNTaxRateArr);
      }
    };
    getHSNTaxRates();
  }, [product?.hsn_number]);

  const [isGstUser, setIsGstUser] = useState(false);

  const businessDetails = useSelector((state) => state.user);

  useLayoutEffect(() => {
    setIsGstUser(() => businessDetails?.gst_number && businessDetails?.gst_number?.length > 0);
  }, [businessDetails]);

  const handleHSNNumberChange = (value) => {
    const nextAddProductFormData = { ...editProductFormData, hsn_number: value };
    setEditProductFormData(nextAddProductFormData);

    if (!isGstUser) return;

    //Early Return if product is not a service
    if (editProductFormData.is_service === "N") {
      return;
    }

    const hsnData = getHSNDataByHSNCode(value);

    const duplicatePercentages =
      hsnData?.length > 1
        ? hsnData.map((data) => parseFloat(data?.cgst_rate) + parseFloat(data?.sgst_rate))
        : [parseFloat(hsnData[0]?.cgst_rate) + parseFloat(hsnData[0]?.sgst_rate)];

    const taxPercent = [...new Set(duplicatePercentages)];

    // const taxPercent = parseFloat(hsnData?.cgst_rate) + parseFloat(hsnData?.sgst_rate);

    const nextHSNTaxRateArr = taxPercent || [];

    //if nextHSNTaxRate is a single item then add that item into product details or select first item anyway
    setEditProductFormData((prevState) => {
      return { ...prevState, service: { ...editProductFormData.service, tax: nextHSNTaxRateArr[0] } };
    });

    sethSNTaxRates(nextHSNTaxRateArr);
  };

  const [hSNSearchTerm, sethSNSearchTerm] = useState("");
  const [hSNAutoCompleteLoading, setHSNAutoCompleteLoading] = useState(false);

  useEffect(() => {
    if (hSNSearchTerm.length > 0) {
      setHSNAutoCompleteLoading(true);
      dispatch(fetchHSNData(`?hsn_code=${hSNSearchTerm}`))
        .then((action) => {
          setHSNAutoCompleteLoading(false);
        })
        .catch((err) => {
          setHSNAutoCompleteLoading(false);
        });
    }
  }, [hSNSearchTerm]);

  const setDebouncedHSNSearchTerm = debounce((value) => sethSNSearchTerm(value), 1000);

  const getHSNDataByHSNCode = (hsn_number) => {
    const data = HSN_data?.filter((HSNData) => HSNData?.hsn_code === hsn_number);
    return data;
  };

  const selectedHSNValue = useMemo(() => {
    if (!hSNTaxRates) return null; // Ensure it handles loading state
    return getHSNDataByHSNCode(editProductFormData?.hsn_number)[0] || null;
  }, [hSNTaxRates, editProductFormData?.hsn_number]);

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center text-xl font-semibold">
            {product?.is_service === "N" ? "Product" : "Service"} description
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="Product-name" className="text-neutral-500">
              {product?.is_service === "N" ? "Product" : "Service"} Name<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="Product-name"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px] bg-gray-100"
              value={editProductFormData.product_name}
              onChange={(e) => setEditProductFormData({ ...editProductFormData, product_name: e.target.value })}
              required
              disabled
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="brand-name" className="text-neutral-500">
              {product?.is_service === "N" ? "Brand" : "Company"} Name<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="brand-name"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px] bg-gray-100"
              value={editProductFormData.brand}
              onChange={(e) => setEditProductFormData({ ...editProductFormData, brand: e.target.value })}
              required
              disabled
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="hsn-number" className="text-neutral-500">
              {product?.is_service === "N" ? "HSN Number" : "SAC code"}
              <span className="pl-1 text-red-500">*</span>
            </label>
            {/* <input
              type="text"
              id="hsn-number"
              className="border-[1px] border-neutral-500 px-[10px] py-[10px] bg-white"
              value={editProductFormData.hsn_number}
              onChange={(e) => setEditProductFormData({ ...editProductFormData, hsn_number: e.target.value })}
              required
            /> */}
            <Autocomplete
              disablePortal
              id="hsn-number"
              options={HSN_data || null}
              noOptionsText={hSNAutoCompleteLoading ? "Loading..." : "No data found!"}
              style={{ borderColor: "black" }}
              value={selectedHSNValue}
              getOptionLabel={(option) => option?.hsn_code}
              onChange={(event, value) => {
                handleHSNNumberChange(value?.hsn_code);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    sx: {
                      border: "0.5px solid #737373", // Black border
                      borderRadius: "0px", // No border radius
                    },
                  }}
                  onChange={(e) => setDebouncedHSNSearchTerm(e.target.value)}
                />
              )}
              disabled
            />
          </div>
          {product?.is_service === "N" && (
            <div className="flex flex-col gap-[10px]">
              <label htmlFor="product-package" className="text-neutral-500">
                {product?.is_service === "N" ? "Product Packaging" : "Service Type"}{" "}
                <span className="pl-1 text-red-500">*</span>
              </label>
              <input
                type="text"
                id="product-package"
                className="border-[1px] border-neutral-500 px-[10px] py-[10px] bg-gray-100"
                value={editProductFormData.package}
                onChange={(e) => setEditProductFormData({ ...editProductFormData, package: e.target.value })}
                required
              />
            </div>
          )}

          {/* is_service field */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="is-service" className="text-neutral-500">
              Is Service<span className="pl-1 text-red-500">*</span>
            </label>
            <select
              id="is-service"
              className="border-[1px] border-neutral-500 bg-white px-[10px] py-[10px]"
              value={editProductFormData.is_service}
              onChange={(e) => setEditProductFormData({ ...editProductFormData, is_service: e.target.value })}
              required
            >
              <option value="N">No</option>
              <option value="Y">Yes</option>
            </select>
          </div> */}

          {/* Conditionally render extra fields if is_service is "Y" */}
          {editProductFormData.is_service === "Y" && (
            <>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="mrp-price" className="text-neutral-500">
                  MRP Price<span className="pl-1 text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="mrp-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={editProductFormData?.service?.mrp}
                  onChange={(e) =>
                    setEditProductFormData({
                      ...editProductFormData,
                      service: { ...editProductFormData.service, mrp: parseFloat(e.target.value) },
                    })
                  }
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="sales-price" className="text-neutral-500">
                  Rate<span className="pl-1 text-red-500">*</span>
                </label>
                <input
                  type="number"
                  id="sales-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={editProductFormData?.service?.rate}
                  onChange={(e) =>
                    setEditProductFormData({
                      ...editProductFormData,
                      service: { ...editProductFormData.service, rate: parseFloat(e.target.value) },
                    })
                  }
                  required
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="purchase-price" className="text-neutral-500">
                  Service Cost to Business / Expense
                </label>
                <input
                  type="number"
                  id="purchase-price"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={editProductFormData?.service?.cost}
                  onChange={(e) =>
                    setEditProductFormData({
                      ...editProductFormData,
                      service: { ...editProductFormData.service, cost: parseFloat(e.target.value) },
                    })
                  }
                />
              </div>
              <div className="flex flex-col gap-[10px]">
                <label htmlFor="tax-percentage" className="text-neutral-500">
                  Tax %<span className="pl-1 text-red-500">*</span>
                </label>

                {hSNTaxRates?.length === 1 && (
                  <input
                    type="number"
                    id="tax-percentage"
                    className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                    value={editProductFormData?.service?.tax}
                    onChange={(e) =>
                      setEditProductFormData({
                        ...editProductFormData,
                        service: { ...editProductFormData.service, tax: parseFloat(e.target.value) },
                      })
                    }
                    disabled
                    required
                  />
                )}
                {hSNTaxRates?.length > 1 && (
                  <select
                    id="tax-percentage"
                    className="border-[1px] border-neutral-500 px-[10px] py-[10px] bg-white"
                    value={parseFloat(editProductFormData?.service?.tax)}
                    onChange={(e) =>
                      setEditProductFormData({
                        ...editProductFormData,
                        service: { ...editProductFormData.service, tax: parseFloat(e.target.value) },
                      })
                    }
                    required
                  >
                    <option value="" disabled>
                      Select Tax Percentage
                    </option>
                    {hSNTaxRates.map((rate) => (
                      <option key={rate} value={rate}>
                        {rate}%
                      </option>
                    ))}
                  </select>
                )}
              </div>
              {/* Barcode Input */}
              <div className="relative">
                <BarcodeInput barcodeNumber={barcodeNumber} setBarcodeNumber={setBarcodeNumber} />
              </div>
              {/* <DurationInput totalSeconds={totalSeconds} setTotalSeconds={setTotalSeconds} /> */}
            </>
          )}

          <div className="flex justify-center gap-[20px]">
            <button
              className="save bg-black px-[20px] py-[10px] text-white rounded-full"
              onClick={handleSubmit}
              disabled={disableSubmitButton}
            >
              Save
            </button>
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>

      {/* Edit Product Button */}
      <ButtonWrapper type="button" eventCallback={handleEditClick}>
        Edit Product
      </ButtonWrapper>
    </>
  );
}

export default EditProduct;
