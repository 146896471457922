import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";

//API to create vendor
export const createVendor = createAsyncThunk("vendor/createVendor", async (data, extra) => {
  const res = await http.post(`/user_profile/backend/vendor/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  } else if (res.data.status_code === 409) {
    return { message: res.data.message };
  }
});

// API to fetch vendors
export const fetchVendors = createAsyncThunk("vendor/fetchVendors", async (data, extra) => {
  const res = await http.get(`/user_profile/backend/vendor${data ? data : ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

// API to fetch more vendors - react infinity scroll
export const fetchMoreVendors = createAsyncThunk("vendors/fetchMoreVendors", async (url, extra) => {
  const res = await http.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const setFavouriteVendor = createAsyncThunk("vendor/setFavouriteVendor", async (data, extra) => {
  const res = await http.patch("/user_profile/backend/vendor/favourite/add", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

// API to update vendor - todo - remove updateVendor from vendorSlice if not needed anymore other then ledgerbook
export const updateVendor = createAsyncThunk("vendor/updateVendor", async (data, extra) => {
  const res = await http.put("/user_profile/backend/vendor/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

// pHaniver - todo - remove if not needed anywhere other then ledgerbook
// API to delete vendor
export const deleteVendor = createAsyncThunk("vendor/deleteVendor", async (data, extra) => {
  const res = await http.delete("/user_profile/backend/vendor/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  });
  if (res.data.status === "success") {
    return data;
  }
});

export const vendorSlice = createSlice({
  name: "vendor",
  initialState: {
    vendors: { data: [], next: "" },
  },
  reducers: {
    addVendor: (state, action) => {
      return { ...state, vendors: { ...state.vendors, data: [action.payload, ...state.vendors.data] } };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVendors.fulfilled, (state, action) => {
      return { ...state, vendors: action.payload };
    });
    builder.addCase(fetchMoreVendors.fulfilled, (state, action) => {
      return {
        ...state,
        vendors: { data: [...state.vendors.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(setFavouriteVendor.fulfilled, (state, action) => {
      return {
        ...state,
        vendors: {
          ...state.vendors,
          data: state.vendors.data.map((item) =>
            item.id === action.payload.id ? { ...item, ...action.payload } : item
          ),
        },
      };
    });
    builder.addCase(updateVendor.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          vendors: {
            ...state.vendors,
            data: state.vendors.data.map((item) =>
              item.id === action.payload.id ? { ...item, ...action.payload } : item
            ),
          },
        };
      }
    });
    builder.addCase(deleteVendor.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          vendors: {
            ...state.vendors,
            data: state.vendors.data.filter((item) => item.id !== action.payload.vendor_id),
          },
        };
      }
    });
  },
});

export default vendorSlice.reducer;
export const { addVendor } = vendorSlice.actions;
