import { Avatar } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchZipcode, updateUserProfile, uploadUserProfileImage, userDelete } from "../../reducers/Slices/userSlice";
import profileDefault from "../../assets/images/profile-default.jpg";
import toast from "react-hot-toast";
import { unsubscribeFirebaseNotification } from "../../reducers/Slices/appSlice";
import { debounce } from "lodash";
import { http } from "../../service/http";

const UserProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [profileImg, setProfileImg] = useState(null);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const data = useSelector((state) => state.user);

  useEffect(() => {
    if (!data) {
      return;
    }

    const requiredData = {
      address1: data?.address1,
      address2: data?.address2,
      business_name: data?.business_name,
      business_number: data?.business_number,
      license_number: data?.license_number,
      business_type: data?.business_type,
      city: data?.city,
      client_id: data?.client_id,
      email: data?.email,
      gst_number: data?.gst_number,
      industry: data?.industry,
      phone: data?.phone,
      profile_picture: data?.profile_picture,
      sales_target: data?.sales_target,
      state: data?.state,
      state_code: data?.state_code,
      zipcode: data?.zipcode,
      upi: data?.upi,
      bank: data?.bank,
      account_holder: data?.account_holder,
      account_number: data?.account_number,
      ifsc: data?.ifsc,
      alternate_phone: data?.alternate_phone,
    };

    setFormData(requiredData);
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitButton(true);
    dispatch(updateUserProfile(formData))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          navigate(-1);
        } else {
          toast.error("Sorry, user profile cannot be updated");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const stateCodeMapper = (state) => {
    const stateCodes = {
      "ANDAMAN AND NICOBAR": "35",
      "ANDHRA PRADESH": "37",
      "ARUNACHAL PRADESH": "12",
      ASSAM: "18",
      BIHAR: "10",
      CHANDIGARH: "04",
      CHHATTISGARH: "22",
      "DADRA AND NAGAR HAVELI & DAMAN AND DIU": "26",
      DELHI: "07",
      GOA: "30",
      GUJARAT: "24",
      HARYANA: "06",
      "HIMACHAL PRADESH": "02",
      "JAMMU AND KASHMIR": "01",
      JHARKHAND: "20",
      KARNATAKA: "29",
      KERALA: "32",
      LADAKH: "38",
      LAKSHYADEEP: "31",
      "MADHYA PRADESH": "23",
      MAHARASHTRA: "27",
      MANIPUR: "14",
      MEGHALAYA: "17",
      MIZORAM: "15",
      NAGALAND: "13",
      ODISHA: "21",
      "OTHER COUNTRIES": "99",
      "Other Territory": "97",
      PUDUCHERRY: "34",
      PUNJAB: "03",
      RAJASTHAN: "08",
      SIKKIM: "11",
      "TAMIL NADU": "33",
      TELANGANA: "36",
      TRIPURA: "16",
      "UTTAR PRADESH": "09",
      UTTARAKHAND: "05",
      "WEST BENGAL": "19",
    };

    return parseInt(stateCodes[state]) || null;
  };

  const handleSearchZipcode = (e) => {
    // if (data?.gst_number?.length > 0) {
    //   toast.error("Can't update zipcode on businesses with GST");
    //   return;
    // }

    dispatch(searchZipcode(formData.zipcode))
      .then((action) => {
        if (action.payload) {
          setFormData({
            ...formData,
            state: action.payload.state,
            city: action.payload.city,
            address: action.payload.address,
            state_code:
              `${action.payload.state_code}`.length > 1 ? action.payload.state_code : `0${action.payload.state_code}`,
            country_code: action.payload.country_code,
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (formData?.gst_number?.length > 0) {
      if (formData?.stateCode && formData?.stateCode?.length > 1) return;

      setFormData((prevState) => {
        return {
          ...prevState,
          state_code: formData?.gst_number?.slice(0, 2) || stateCodeMapper(formData?.state?.toUpperCase() || null),
        };
      });
    }
  }, [formData?.gst_number]);

  const handleChangeProfileImage = (e) => {
    e.preventDefault();
    if (!profileImg) {
      toast.error("first select an image!");
      return;
    }
    dispatch(uploadUserProfileImage(profileImg))
      .then((action) => {
        if (action.payload) {
        } else {
          toast.error("Sorry, Profile Image cannot be changed");
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  //Unsubscribe from notifications if notification permission is disabled.
  const unsubscribeTokenGeneratedByFirebase = (token) => {
    dispatch(unsubscribeFirebaseNotification({ device_token: token }))
      .then((action) => {
        if (action?.payload?.status === "success") {
          localStorage.removeItem("device_token", token); // Save the token in localStorage
          dispatch(userDelete());
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleDeleteAccount = () => {
    const device_token = localStorage.getItem("device_token");
    if (window.confirm("Are you sure you want to delete your account?")) {
      if (device_token) {
        unsubscribeTokenGeneratedByFirebase(device_token);
      } else {
        dispatch(userDelete());
      }
    }
  };

  const changeDetailsBasedOnNewGSTAsync = async (gst_number) => {
    if (gst_number.length > 15) {
      return;
    }

    try {
      const response = await http.post(
        "user_profile/backend/gst-api/",
        { gst_number },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      setFormData((prevState) => {
        return {
          ...prevState,
          business_name: response?.data?.business_name,
          city: response?.data?.city,
          state: response?.data?.state,
          address1: response?.data?.address1,
          address2: response?.data?.address2,
          zipcode: response?.data?.zipcode,
        };
      });
    } catch (err) {
      toast.error("Having problems fetching data for this GST number.");
    }
  };

  const updateGSTnumber = () => {
    if (formData?.gst_number) {
      changeDetailsBasedOnNewGSTAsync(formData?.gst_number);
    }
  };

  return (
    <div className="p-4 mx-auto md:w-[76.5vw] min-[1124px]:min-w-[84vw] w-screen overflow-y-scroll">
      <div className="flex justify-center flex-1">
        <form className="md:w-4/6 w-full mx-auto" onSubmit={handleSubmit}>
          <div>
            <div className="grid justify-items-center">
              <Avatar
                alt={formData?.business_name}
                src={data?.profile_picture ? data?.profile_picture : profileDefault}
                sx={{ width: 150, height: 150 }}
              />
            </div>
            <div className="mt-5 mb-5 flex items-center flex-col">
              <label htmlFor="profile_image" className="block mb-2 text-sm font-medium text-gray-900">
                Profile Image
              </label>
              <input
                type="file"
                id="profile_image"
                accept="image/*"
                onChange={(e) => setProfileImg(e.target.files[0])}
                className="block w-fit text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none"
              />
              <button
                className="mt-5 mb-5 px-[10px] py-[5px] bg-black text-white rounded-full text-sm"
                onClick={handleChangeProfileImage}
              >
                Change Profile Image
              </button>
            </div>
            <div className="mb-5">
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">
                Email id*
              </label>
              <input
                type="email"
                id="email"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                value={formData?.email}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="name@profitai.com"
                required
              />
            </div>
            <div className="mb-5">
              <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">
                Phone Number
              </label>
              <input
                type="text"
                id="phone"
                value={formData?.phone}
                disabled
                className="opacity-75 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter phone number"
                required
              />
            </div>
            <div className="mb-5">
              <label htmlFor="phone" className="block mb-2 text-sm font-medium text-gray-900">
                Alternate Phone Number
              </label>
              <input
                type="text"
                id="phone"
                value={formData?.alternate_phone}
                onChange={(e) => setFormData({ ...formData, alternate_phone: e.target.value })}
                className="opacity-75 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter phone number i.e +91 XXXXX XXXXX, +91 XXXXX XXXXX"
                required
              />
            </div>
            <div className="mb-5">
              <label htmlFor="gst_number" className="block mb-2 text-sm font-medium text-gray-900">
                GST Number
              </label>
              <div className="flex gap-[10px]">
                <input
                  type="text"
                  id="gst_number"
                  value={formData?.gst_number ? formData?.gst_number?.toUpperCase() : ""}
                  onChange={(e) => setFormData({ ...formData, gst_number: e.target.value })}
                  className="opacity-75 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter GST number"
                  required
                />
                <button
                  className="px-[20px] py-[10px] rounded-md text-white bg-black"
                  type="button "
                  onClick={(e) => {
                    e.preventDefault();
                    updateGSTnumber();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
            <div className="mb-5">
              <label htmlFor="license_number" className="block mb-2 text-sm font-medium text-gray-900">
                License Number
              </label>
              <div className="flex gap-[10px]">
                <input
                  type="text"
                  id="gst_number"
                  value={formData?.license_number}
                  onChange={(e) => setFormData({ ...formData, license_number: e.target.value })}
                  className="opacity-75 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter License number"
                />
              </div>
            </div>
            <div className="mb-5">
              <label htmlFor="business_name" className="block mb-2 text-sm font-medium text-gray-900">
                Business Name*
              </label>
              <input
                type="text"
                id="business_name"
                value={formData?.business_name}
                onChange={(e) => setFormData({ ...formData, business_name: e.target.value })}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter your business name"
                required
                disabled={data?.gst_number?.length > 0}
              />
            </div>
            <div className="flex flex-col">
              <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900">
                Zipcode*
              </label>
              <div className="flex mb-5 gap-[10px]">
                <div className="relative z-0 w-full group">
                  <input
                    type="zipcode"
                    onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                    id="zipcode"
                    value={formData?.zipcode}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter zipcode"
                    required
                    // disabled={data?.gst_number?.length > 0}
                  />
                </div>
                <div className="relative z-0 group">
                  <button
                    type="button"
                    onClick={handleSearchZipcode}
                    className="text-white rounded-[5px] bg-black h-full px-[20px] py-[8px] text-center"
                  >
                    Find
                  </button>
                </div>
              </div>
            </div>
            <div className="flex space-x-[10px] mb-5">
              <div className="w-1/3">
                <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900">
                  State
                </label>
                <input
                  type="state"
                  id="state"
                  value={formData?.state}
                  onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter state"
                  required
                  // disabled={data?.gst_number?.length > 0}
                />
              </div>
              <div className="w-1/3">
                <label htmlFor="city" className="ml-2 block mb-2 text-sm font-medium text-gray-900">
                  City
                </label>
                <input
                  type="city"
                  id="city"
                  value={formData?.city}
                  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Enter city"
                  required
                  // disabled={data?.gst_number?.length > 0}
                />
              </div>
              <div className="w-1/3">
                <label htmlFor="state_code" className="ml-2 block mb-2 text-sm font-medium text-gray-900 ">
                  State Code<span className="pl-1 text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="state_code"
                  value={formData.state_code}
                  onChange={(e) => setFormData({ ...formData, state_code: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="i.e 17"
                  required
                />
              </div>
            </div>
            <div className="mb-5">
              <label htmlFor="address1" className="block mb-2 text-sm font-medium text-gray-900">
                Address 1* (Max 250 Characters)
              </label>
              <textarea
                id="address1"
                rows="2"
                onChange={(e) => setFormData({ ...formData, address1: e.target.value })}
                className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                value={formData?.address1}
                maxLength={250}
                placeholder="Address 1..."
              ></textarea>
            </div>
            <div className="mb-5">
              <label htmlFor="address2" className="block mb-2 text-sm font-medium text-gray-900">
                Address 2 (Max 250 Characters)
              </label>
              <textarea
                id="address2"
                rows="2"
                onChange={(e) => setFormData({ ...formData, address2: e.target.value })}
                className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                value={formData?.address2}
                maxLength={250}
                placeholder="Address 2..."
              ></textarea>
            </div>
            <div className="mb-5">
              <label htmlFor="upi" className="block mb-2 text-sm font-medium text-gray-900">
                UPI ID
              </label>
              <input
                type="text"
                id="upi"
                value={formData?.upi}
                onChange={(e) => setFormData({ ...formData, upi: e.target.value })}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter UPI ID"
                maxLength={100}
              />
            </div>
            <div className="mb-5">
              <label htmlFor="bank_name" className="block mb-2 text-sm font-medium text-gray-900">
                Bank Name
              </label>
              <input
                type="text"
                id="bank_name"
                value={formData?.bank}
                onChange={(e) => setFormData({ ...formData, bank: e.target.value })}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Enter Bank Name"
                maxLength={100}
              />
            </div>
            <div className="mb-5">
              <label htmlFor="account_holder_name" className="block mb-2 text-sm font-medium text-gray-900">
                Account Holder Name
              </label>
              <input
                type="text"
                id="account_holder_name"
                value={formData?.account_holder}
                onChange={(e) => setFormData({ ...formData, account_holder: e.target.value })}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Account Holder Name"
                maxLength={100}
              />
            </div>
            <div className="mb-5">
              <label htmlFor="account_number" className="block mb-2 text-sm font-medium text-gray-900">
                Account Number
              </label>
              <input
                type="text"
                id="account_number"
                value={formData?.account_number}
                onChange={(e) => setFormData({ ...formData, account_number: e.target.value })}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Account Number"
                maxLength={18}
              />
            </div>
            <div className="mb-5">
              <label htmlFor="ifsc_code" className="block mb-2 text-sm font-medium text-gray-900">
                IFSC Code
              </label>
              <input
                type="text"
                id="ifsc_code"
                value={formData?.ifsc}
                onChange={(e) => setFormData({ ...formData, ifsc: e.target.value })}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="IFSC Code"
                maxLength={11}
              />
            </div>
            <div className="flex justify-center py-[40px] gap-[20px]">
              <button
                // onClick={handleSubmit}
                type="submit"
                disabled={disableSubmitButton}
                className="text-white bg-black px-[20px] py-[10px] rounded-md"
              >
                Update Profile
              </button>
              <button
                onClick={() => handleDeleteAccount()}
                className="text-white bg-red-500 px-[20px] py-[10px] rounded-md"
              >
                Delete Account
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfile;
