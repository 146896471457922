export const navigationItems = [
  {
    title: "Home",
    imgUrl: "/image/home-icon.svg",
    path: "/",
    cName: "nav-text",
  },
  {
    title: "Sales Book",
    imgUrl: "/image/inventory-icon.svg",
    path: "/sales-book",
    cName: "nav-text",
  },
  {
    title: "Purchase Book",
    imgUrl: "/image/inventory-icon.svg",
    path: "/purchase-book",
    cName: "nav-text",
  },
  {
    title: "Ledger Book",
    imgUrl: "/image/employee-icon.svg",
    path: "/ledger-book",
    cName: "nav-text",
  },
  {
    title: "Inventory",
    imgUrl: "/app/image/inventory-icon.svg",
    path: "/inventory",
    cName: "nav-text",
  },
  {
    title: "Warehouse",
    imgUrl: "",
    path: "/warehouse",
    cName: "nav-text",
  },
  {
    title: "Expiry / Return",
    imgUrl: "",
    path: "/expiry_return",
    cName: "nav-text",
  },
  {
    title: "Cash Register",
    imgUrl: "/app/image/inventory-icon.svg",
    path: "/cash-register",
    cName: "nav-text",
  },
  // {
  //     title: 'Transactions',
  //     imgUrl: '/image/transaction-icon.svg',
  //     path: '/transactions',
  //     cName: 'nav-text'
  // },
  {
    title: "Reports",
    imgUrl: "/image/reports-icon.svg",
    path: "/reports",
    cName: "nav-text",
  },
  // {
  //   title: "GST Filing",
  //   imgUrl: "/image/employee-icon.svg",
  //   path: "/gst-filing",
  //   cName: "nav-text",
  // },
  {
    title: "P&L",
    imgUrl: "/image/employee-icon.svg",
    path: "/pnl",
    cName: "nav-text",
  },
  {
    title: "Online Store",
    imgUrl: "/image/employee-icon.svg",
    path: "/online-store",
    cName: "nav-text",
  },
  {
    title: "Expenses",
    imgUrl: "/image/employee-icon.svg",
    path: "/expenses",
    cName: "nav-text",
  },
  {
    title: "Employees",
    imgUrl: "/image/employee-icon.svg",
    path: "/employees",
    cName: "nav-text",
  },
  // {
  //   title: "Import / Export",
  //   imgUrl: "",
  //   path: "/import_export",
  //   cName: "nav-text",
  // },
];
