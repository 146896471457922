import React, { useEffect } from "react";
import TheProfitAILogo from "../../assets/images/TheProfitAi_logo.png";

const MainLoadingScreen = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-white overflow-hidden w-full">
      <div>
        <img src={TheProfitAILogo} alt="Main Logo" className="-mb-[130px] h-[300px]" />
      </div>
      <svg className="w-full h-[50vh] -mt-[130px]" xmlns="http://www.w3.org/2000/svg">
        <g className="g-group">
          {[30, 40, 50, 60, 70].map((cx, index) => (
            <circle
              key={index}
              className={`dot`}
              style={{
                animationDelay: `${index * 0.15}s`,
              }}
              cx={`${cx}vw`}
              cy="50%"
              r="11"
              fill="#017CF7"
            />
          ))}
        </g>
      </svg>
      <style jsx>{`
        .dot {
          stroke: white;
          stroke-width: 0.5px;
          opacity: 0;
          transform-origin: center;
          animation: loader 3s ease-in-out infinite;
        }
        @keyframes loader {
          0%,
          100% {
            opacity: 0;
            transform: scale(1);
          }
          45%,
          65% {
            opacity: 1;
            transform: scale(0.7);
          }
        }
      `}</style>
    </div>
  );
};

export default MainLoadingScreen;
