import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";

//API to create customers
export const createCustomer = createAsyncThunk("customer/createCustomer", async (data, extra) => {
  const res = await http.post(`/user_profile/backend/customer/`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  } else if (res.data.status_code === 409) {
    return { message: res.data.message };
  }
});

//API to fetch customers
export const fetchCustomers = createAsyncThunk("customer/fetchCustomers", async (data, extra) => {
  const res = await http.get(`/user_profile/backend/customer/${data ? data : ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//API to fetch more customers - react infinity scroll
export const fetchMoreCustomers = createAsyncThunk("customer/fetchMoreCustomers", async (url, data) => {
  const res = await http.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

export const setFavouriteCustomer = createAsyncThunk("customer/setFavouriteCustomer", async (data, extra) => {
  const res = await http.patch("/user_profile/backend/customer/favourite/add", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

//API to update customer - todo - delete if not needed in other then ledgerbook
export const updateCustomer = createAsyncThunk("customer/updateCustomer", async (data, extra) => {
  const res = await http.put("/user_profile/backend/customer/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

// pHaniver - todo - remove if not needed anywhere other then ledgerbook
//API to delete customer
export const deleteCustomer = createAsyncThunk("customer/deleteCustomer", async (data, extra) => {
  const res = await http.delete("/user_profile/backend/customer/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: data,
  });
  if (res.data.status === "success") {
    return data;
  }
});

export const fetchTopCustomers = createAsyncThunk("customer/fetchTopCustomers", async (data, extra) => {
  // Helper function to get the current month number
  const getCurrentMonth = () => {
    const date = new Date();
    return date.getMonth() + 1;
  };

  const res = await http.get(`/user_profile/backend/customer/?most_profitable=1&month=${getCurrentMonth()}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

export const fetchTopDebtors = createAsyncThunk("customer/fetchTopDebtors", async (data, extra) => {
  const res = await http.get("/user_profile/backend/top-debtors/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.data;
  }
});

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    top_customers: [],
    customers: { data: [], next: "" },
    top_debtors: [],
  },
  reducers: {
    addCustomer: (state, action) => {
      return { ...state, customers: { ...state.customers, data: [action.payload, ...state.customers.data] } };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCustomers.fulfilled, (state, action) => {
      return { ...state, customers: { data: action.payload.data, next: action.payload.next } };
    });
    builder.addCase(fetchMoreCustomers.fulfilled, (state, action) => {
      return {
        ...state,
        customers: { data: [...state.customers.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(setFavouriteCustomer.fulfilled, (state, action) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          data: state.customers.data.map((item) =>
            item.id === action.payload.id ? { ...item, ...action.payload } : item
          ),
        },
      };
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          customers: {
            ...state.customers,
            data: state.customers.data.map((item) =>
              item.id === action.payload.id ? { ...item, ...action.payload } : item
            ),
          },
        };
      }
    });
    builder.addCase(deleteCustomer.fulfilled, (state, action) => {
      if (action.payload) {
        return {
          ...state,
          customers: {
            ...state.customers,
            data: state.customers.data.filter((item) => {
              return item.id !== action.payload.customer_id;
            }),
          },
        };
      }
    });
    builder.addCase(fetchTopCustomers.fulfilled, (state, action) => {
      if (action.payload[0]) {
        return {
          ...state,
          top_customers: action.payload.map((item) => ({
            name: item.customer_name,
            amount: item.all_grand_total || 0,
          })),
        };
      }
    });
    builder.addCase(fetchTopDebtors.fulfilled, (state, action) => {
      if (action.payload[0]) {
        return {
          ...state,
          top_debtors: action.payload.map((item) => ({
            name: item.customer_name,
            amount: item.total_remaining || 0,
          })),
        };
      }
    });
  },
});

export default customerSlice.reducer;
export const { addCustomer } = customerSlice.actions;
