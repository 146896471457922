import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

//Redux Reducer functions
import { fetchInvoicesCustomer, fetchMoreInvoicesCustomer } from "../../reducers/Slices/invoiceSlice";

//UI components
import IconWrapper from "../MicroComponents/IconWrapper";
import InfiniteScroll from "react-infinite-scroll-component";

//Icon Library - React Feather
import { Filter } from "react-feather";
import Joyride from "react-joyride";
import CustomModal from "../SharedComponents/CustomModals/CustomModal";
import axios from "axios";
import {
  authenticateEWayBill,
  cancelEWayBill,
  extendEWayBillValidity,
  fetchAllEWayBills,
  fetchMoreEWayBills,
  getEWayBillByID,
  updateTransporterID,
} from "../../reducers/Slices/eWayBillSlice";
import toast from "react-hot-toast";
import { setShowWaitLoader } from "../../reducers/Slices/appSlice";
import { http } from "../../service/http";

function EwayBill() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //Redux states
  const customer_invoices = useSelector((state) => state.invoice.customer_invoices.data);
  const customer_invoicesNext = useSelector((state) => state.invoice.customer_invoices.next);

  const eBill_invoices = useSelector((state) => state.ewaybill.bills.data);
  const eBill_invoicesNext = useSelector((state) => state.ewaybill.bills.next);

  //Search functionality
  const [search, setSearch] = useState("");

  const setDebouncedSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 1000);

  useEffect(() => {
    dispatch(setShowWaitLoader(true));
    dispatch(fetchInvoicesCustomer(`?search=${search}&is_cancelled=0`)).then(() => dispatch(setShowWaitLoader(false)));
    //eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    dispatch(fetchAllEWayBills());
    //eslint-disable-next-line
  }, []);

  //Filter Functionality
  const [showMenu, setShowMenu] = useState(null);

  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  //React Infinity Scroll functionality to fetch Invoices
  const [hasMoreInvoices, setHasMoreInvoices] = useState(() => (customer_invoicesNext ? true : false));
  const [hasMoreEBillInvoices, setHasMoreEBillInvoices] = useState(() => (eBill_invoicesNext ? true : false));

  const fetchNextInvoices = () => {
    if (customer_invoicesNext) {
      dispatch(fetchMoreInvoicesCustomer(customer_invoicesNext));
    }
  };

  const fetchNextEWayBills = () => {
    if (customer_invoicesNext) {
      dispatch(fetchMoreEWayBills(eBill_invoicesNext));
    }
  };

  useEffect(() => {
    if (customer_invoicesNext) {
      setHasMoreInvoices(true);
    } else {
      setHasMoreInvoices(false);
    }
  }, [customer_invoicesNext]);

  useEffect(() => {
    if (eBill_invoicesNext) {
      setHasMoreEBillInvoices(true);
    } else {
      setHasMoreEBillInvoices(false);
    }
  }, [eBill_invoicesNext]);

  //Handle E-way Bill Creation with Invoice ID and customer ID as param
  const handleCreateEwayBill = (invoiceId, customer) => {
    if (!invoiceId || !customer?.id) {
      return;
    }

    navigate(`/ewaybill/create/${customer?.id}/${invoiceId}`);
  };

  //React Joyride - Guide user on how to use app
  const steps = [
    {
      target: "#joyride-step-1",
      content: "You can search invoices by customer name or phone number from here.",
    },
    {
      target: "#joyride-step-2",
      content: "You can filter invoices - filter 1, filter 2, filter 3",
    },
    {
      target: "#joyride-step-3",
      content: "This is invoices list double click to create EWayBill",
    },
    {
      target: "#joyride-step-4",
      content: "These is list of recently create EWayBills",
    },
    {
      target: "#joyride-step-5",
      content: "You can cancel a EWayBill using this Cancel E-Way Bill button",
    },
  ];

  const [localStorageEWayBillStatus, setLocalStorageEWayBillStatus] = useState(
    localStorage.getItem("showed_EWayBill_guide") || false
  );

  //handle till final steps
  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished" || status === "skipped") {
      localStorage.setItem("showed_EWayBill_guide", true);
      setLocalStorageEWayBillStatus(false);
    }
  };

  const [showAuthModal, setShowAuthModal] = useState(false);

  //Close E-WAy Auth Modal
  const handleCloseAuthModal = (successfulClose = false) => {
    setShowAuthModal(false);

    if (!successfulClose) {
      navigate("/");
    }
  };

  const [authFormData, setAuthFormData] = useState({
    username: "",
    password: "",
    ip_address: "",
  });

  //user ip address:
  const [ipAddress, setIpAdderess] = useState(null);

  ///side effect to set IP address in required forms;
  useEffect(() => {
    if (ipAddress) {
      //set ip address in auth from data.
      setAuthFormData((prevState) => {
        return { ...prevState, ip_address: ipAddress };
      });

      setTransporterUpdateFormData((prevState) => {
        return { ...prevState, ip_address: ipAddress };
      });
    }
  }, [ipAddress]);

  // Fetch user's IP address
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        if (response.data.ip) {
          setIpAdderess(response.data.ip);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIPAddress();
  }, []);

  // Handle form input change
  const handleAuthInputChange = (e) => {
    const { name, value } = e.target;
    setAuthFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setExpiryTimestamp = () => {
    const currentTime = Date.now(); // Get the current time in milliseconds
    const expiryTime = currentTime + 5 * 60 * 60 * 1000; // 5 hours from now

    // Store the expiry time in localStorage
    localStorage.setItem("ewayauth_token", expiryTime);
  };

  const checkExpiryTimestamp = () => {
    const expiryTime = localStorage.getItem("ewayauth_token");

    if (expiryTime) {
      const currentTime = Date.now();

      if (currentTime < expiryTime) {
        return true;
      } else {
        localStorage.removeItem("ewayauth_token"); // Clear expired timestamp
        return false;
      }
    }

    return false;
  };

  useEffect(() => {
    if (!checkExpiryTimestamp()) {
      setShowAuthModal(true);
    }
  }, []);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(authenticateEWayBill(authFormData))
      .then((action) => {
        if (action.payload) {
          setExpiryTimestamp();
          handleCloseAuthModal(true);
        } else {
          toast.error("some error occurred while authenticating you. try again!");
        }
      })
      .catch((err) => {
        toast.error("some error occurred while authenticating you try again!");
      });
  };

  //cancel e-bill operations
  const handleCancelEWayBill = async (id) => {
    const payload = {
      ip_Address: ipAddress,
      ewbNo: id,
      cancelRsnCode: 2,
      cancelRmrk: "Order cancelled by the customer",
    };

    dispatch(cancelEWayBill(payload))
      .then((action) => {
        if (action.payload) {
          toast.success("Invoice Cancelled successfully!");
        } else {
          toast.error("some error occurred while cancelling, try again!");
        }
      })
      .catch((err) => {
        toast.error("some error occurred while cancelling, try again!");
      });
  };

  const [showTransporterUpdateModal, setShowTransporterUpdateModal] = useState(false);
  const [showExtendEBillUpdateModal, setShowExtendEBillUpdateModal] = useState(false);
  const [selectedEBillForExtend, setSelectedEBillForExtend] = useState(null);

  const handleCloseTransporterUpdateModal = () => {
    setShowTransporterUpdateModal(false);
  };

  const handleCloseExtendEBillUpdateModal = () => {
    setShowExtendEBillUpdateModal(false);
  };

  const [extendEBillUpdateFormData, setExtendEBillUpdateFormData] = useState({
    remainingDistance: "",
    fromState: null,
    fromPlace: "",
    fromPincode: null,
  });

  const [transporterUpdateFormData, setTransporterUpdateFormData] = useState({
    ewbNo: "",
    transporterId: "",
    ip_address: "",
  });

  // Handle form input change
  const handleTransporterUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setTransporterUpdateFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleExtendEBillUpdateInputChange = (e) => {
    const { name, value } = e.target;
    setExtendEBillUpdateFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // {
  //   "id": 4,
  //   "supply_type": "O",
  //   "sub_supply_type": "1",
  //   "doc_type": "INV",
  //   "doc_no": "241205093825",
  //   "doc_date": "2024-12-05",
  //   "from_gstin": "05AAACH6188F1ZM",
  //   "from_trd_name": "M/S THE HINDUSTAN TRUST PVT LTD",
  //   "from_addr1": "RAIPUR, 46B 49B, BHAGWANPUR, ROORKEE, Haridwar, Uttarakhand, 247667",
  //   "from_addr2": "Commissionerate - Uttarakhand,Zone - Haridwar,Sector - Roorkee - Sector 3 (Jurisdictional Office)",
  //   "from_place": "Kota Muradnagar",
  //   "from_pincode": 247667,
  //   "act_from_state_code": 5,
  //   "from_state_code": 5,
  //   "to_gstin": "24AACCA3020F1ZI",
  //   "to_trd_name": "AMUL INDUSTRIES PVT LTD",
  //   "to_addr1": "Anandpar(Morbi), Gujarat",
  //   "to_addr2": "",
  //   "to_place": "Anandpar(Morbi)",
  //   "to_pincode": 360003,
  //   "act_to_state_code": 24,
  //   "to_state_code": 24,
  //   "transaction_type": 1,
  //   "other_value": "0.00",
  //   "total_value": "400.00",
  //   "cgst_value": "0.00",
  //   "sgst_value": "0.00",
  //   "igst_value": "48.00",
  //   "cess_value": "0.00",
  //   "cess_nonadvol_value": "0.00",
  //   "tot_inv_value": "448.00",
  //   "transporter_id": "05AAACG0904A1ZL",
  //   "transporter_name": "",
  //   "trans_doc_no": "",
  //   "trans_mode": "1",
  //   "trans_distance": 1385,
  //   "trans_doc_date": null,
  //   "vehicle_no": "RJ14AB1234",
  //   "vehicle_type": "R",
  //   "ewaybill_number": "311009859787",
  //   "status": "cancelled",
  //   "ewaybill_date": "2024-12-05T14:44:00",
  //   "valid_upto": "2024-12-12T23:59:00",
  //   "created_at": "2024-12-05T09:14:04.184874",
  //   "updated_at": "2024-12-05T09:14:04.184886",
  //   "business_profile": 4
  // },

  const handleUpdateTransporterID = (id, transporterID) => {
    setTransporterUpdateFormData((prevState) => {
      return {
        ...prevState,
        ewbNo: id,
        transporterId: transporterID,
      };
    });
    setShowTransporterUpdateModal(true);
  };

  const handleTransporterUpdateFormSubmit = (e) => {
    e.preventDefault();
    console.log("transporter update Form Data Submitted:", transporterUpdateFormData);
    dispatch(updateTransporterID(transporterUpdateFormData))
      .then((action) => {
        if (action.payload) {
          console.log(action.payload, "transporter payload data");
          setExpiryTimestamp();
          setShowAuthModal(false);
        } else {
          toast.error("some error occurred while updating transporter ID. try again!");
        }
      })
      .catch((err) => {
        toast.error("some error occurred while updating transporter ID. try again!");
      });
  };

  //Extending E-Way Bill Validity
  const handleExtendEWayBillValidity = (id) => {
    if (!selectedEBillForExtend) {
      return;
    }

    console.log(selectedEBillForExtend, "this is the selected E-bill");

    const payload = {
      ip_Address: ipAddress,
      payload: {
        ewbNo: parseInt(selectedEBillForExtend?.ewaybill_number),
        vehicleNo: selectedEBillForExtend?.vehicle_no ?? "",
        fromPlace: extendEBillUpdateFormData?.fromPlace ?? "",
        fromState: parseInt(extendEBillUpdateFormData?.fromState) ?? "",
        remainingDistance: parseFloat(extendEBillUpdateFormData.remainingDistance),
        transDocNo: selectedEBillForExtend?.trans_doc_no,
        // transDocDate: selectedEBillForExtend?.trans_doc_date,
        transMode: selectedEBillForExtend?.trans_mode,
        extnRsnCode: 1,
        extnRemarks: "Nature Calamity",
        fromPincode: parseInt(extendEBillUpdateFormData?.fromPincode),
        consignmentStatus: "M",
        // transitType: selectedEBillForExtend?.vehicle_type,
        // addressLine1: selectedEBillForExtend?.to_addr1,
        // addressLine2: selectedEBillForExtend?.to_addr2,
        // addressLine3: "",
      },
    };

    // const payload = {
    //   ip_Address: ipAddress,
    //   payload: {
    //     ewbNo: 311009859787,
    //     vehicleNo: "RJ14AB1234",
    //     fromPlace: "Kota Muradnagar",
    //     fromState: 8,
    //     remainingDistance: 473,
    //     transDocNo: "",
    //     // transDocDate: "",
    //     transMode: "1",
    //     extnRsnCode: 1,
    //     extnRemarks: "Nature Calamity",
    //     fromPincode: 313001,
    //     consignmentStatus: "M",
    //     // transitType: "R",
    //     // addressLine1: "Anandpar(Morbi), Gujarat",
    //     // addressLine2: "",
    //     // addressLine3: "",
    //   },
    // };

    dispatch(extendEWayBillValidity(payload))
      .then((action) => {
        if (action.payload) {
          console.log(action.payload, "extension data");
          setExpiryTimestamp();
          setShowAuthModal(false);
        } else {
          toast.error("some error occurred while updating transporter ID. try again!");
        }
      })
      .catch((err) => {
        toast.error("some error occurred while updating transporter ID. try again!");
      });
  };

  //handleDownloadPDF
  const handleDownloadPDF = async (endpoint) => {
    if (!endpoint) {
      toast.error("No PDF available for this invoice!");
      return;
    }

    if (endpoint) {
      try {
        const response = await http.get(`/storage/${endpoint}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        });
        // Create a URL for the PNG blob
        const imageURL = URL.createObjectURL(response.data);

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = `${endpoint.split("/")[1]}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        toast.error("PDF not found!");
      }
    }
  };

  return (
    <>
      <CustomModal isOpen={showAuthModal} handleClose={handleCloseAuthModal}>
        <form onSubmit={handleSubmit} className="flex flex-col items-center">
          <div className="mb-4 w-full">
            <label className="block text-gray-700 pb-[5px]">
              GST Portal Username<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="username"
              value={authFormData.username}
              onChange={handleAuthInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4 w-full pb-[5px]">
            <label className="block text-gray-700 pb-[5px]">
              GST Portal Password<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="password"
              name="password"
              value={authFormData.password}
              onChange={handleAuthInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <button type="submit" className="px-[20px] py-[10px] bg-black text-white rounded-full max-w-fit">
            Submit
          </button>
          <span className="text-center pt-[10px] text-sm font-semibold text-neutral-500">
            Note: Please login on{" "}
            <a
              className="text-blue-500"
              href="https://docs.ewaybillgst.gov.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GST Portal
            </a>{" "}
            and in Registration {">"} For GSP {">"} Select Tera Software Limited as GSP. Checkout{" "}
            <a className="text-blue-500" href="/documents/gsp_guide.pdf" target="_blank">
              Visual Guide.
            </a>
          </span>
          <span className="text-center pt-[10px] text-sm font-semibold text-neutral-500">
            Note: The GST number and email in the business profile must match the credentials in GST Portal.
          </span>
        </form>
      </CustomModal>
      <CustomModal isOpen={showTransporterUpdateModal} handleClose={handleCloseTransporterUpdateModal}>
        <form onSubmit={handleTransporterUpdateFormSubmit} className="flex flex-col items-center">
          <div className="mb-4 w-full pb-[5px]">
            <label className="block text-gray-700 pb-[5px]">
              Transporter Id<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="transporterId"
              maxLength={15}
              minLength={13}
              value={transporterUpdateFormData?.transporterId}
              onChange={handleTransporterUpdateInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <button type="submit" className="px-[20px] py-[10px] bg-black text-white rounded-full max-w-fit">
            Submit
          </button>
        </form>
      </CustomModal>
      <CustomModal isOpen={showExtendEBillUpdateModal} handleClose={handleCloseExtendEBillUpdateModal}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleExtendEWayBillValidity();
          }}
          className="flex flex-col items-center"
        >
          <div className="mb-4 w-full pb-[5px]">
            <label className="block text-gray-700 pb-[5px]">
              From State Code: <span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="number"
              name="fromState"
              value={extendEBillUpdateFormData.fromState}
              onChange={handleExtendEBillUpdateInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4 w-full pb-[5px]">
            <label className="block text-gray-700 pb-[5px]">
              From Place<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="fromPlace"
              value={extendEBillUpdateFormData.fromPlace}
              onChange={handleExtendEBillUpdateInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4 w-full pb-[5px]">
            <label className="block text-gray-700 pb-[5px]">
              From Pincode<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="fromPincode"
              value={extendEBillUpdateFormData.fromPincode}
              onChange={handleExtendEBillUpdateInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <div className="mb-4 w-full pb-[5px]">
            <label className="block text-gray-700 pb-[5px]">
              Remaining Distance<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              name="remainingDistance"
              value={extendEBillUpdateFormData.remainingDistance}
              onChange={handleExtendEBillUpdateInputChange}
              className="w-full p-2 border rounded"
              required
            />
          </div>
          <button type="submit" className="px-[20px] py-[10px] bg-black text-white rounded-full max-w-fit">
            Submit
          </button>
        </form>
      </CustomModal>
      {!localStorageEWayBillStatus && (
        <Joyride
          steps={steps}
          continuous={true}
          showSkipButton={true}
          disableBeacon={true}
          callback={handleJoyrideCallback}
          styles={{ options: { primaryColor: "#6366f1" } }}
        />
      )}
      <div className="flex flex-col md:flex-row content-center h-[91.2vh]">
        {/* customer container  */}
        <div className="w-full border-b-2 md:border-b-0 py-[20px] md:border-r-2 border-neutral-200">
          <div className="flex items-center gap-[10px] px-[20px] border-b-2 border-neutral-200 pb-[20px]">
            {/* search Bar */}
            <div className="w-full max-w-[40vw]" id="joyride-step-1">
              <label for="default-search" className="mb-2 xl:text-sm text-xs font-medium text-gray-900 sr-only">
                Search
              </label>
              <div className="relative">
                <input
                  type="search"
                  onChange={(e) => setDebouncedSearch(e.target.value)}
                  id="default-search"
                  className="block w-full py-2 px-3 pe-10 xl:text-sm text-xs text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Mobile numbers or Username"
                  required
                />
                <div className="absolute cursor-pointer z-5 inset-y-0 end-0 flex items-center px-3 ">
                  <svg
                    className="w-4 h-4 text-gray-500"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            {/* Filter Button */}
            <div className="relative">
              <button
                id="joyride-step-2"
                onClick={() => {
                  handleShowMenu("settings");
                }}
              >
                <IconWrapper>
                  <Filter className="z-10 text-white" />
                </IconWrapper>
              </button>
              <div
                id="dropdownInformation"
                onMouseLeave={() => handleShowMenu("settings")}
                className={`${
                  showMenu !== "settings" ? "hidden" : ""
                } translate-x-[-50%] left-[50%] mt-[10px] absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
              >
                <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                  <li>
                    <button
                      onClick={() => alert("filter 1 was clicked")}
                      className="text-lg py-2 w-full border-b-2 border-neutral-200"
                    >
                      filter 1
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => alert("filter 2 was clicked")}
                      className="text-lg py-2 w-full border-b-2 border-neutral-200"
                    >
                      filter 2
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => alert("filter 3 was clicked")}
                      className="text-lg pt-2 w-full border-neutral-200"
                    >
                      filter 3
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex">
            <div
              className="px-[20px] max-h-[85vh] overflow-y-scroll flex-1 border-r border-neutral-200 bg-white"
              id="joyride-step-3"
            >
              <h3 className="py-[20px] text-center text-2xl font-semibold">Invoices List</h3>
              <span className="block text-sm text-neutral-500 py-[10px] text-center">
                Double click to Create E-Way Bill
              </span>
              <InfiniteScroll
                dataLength={customer_invoices?.length}
                next={fetchNextInvoices}
                hasMore={hasMoreInvoices}
                height={"64vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>Invoice List End</b>
                  </p>
                }
              >
                {customer_invoices?.map((invoice, index) => {
                  return (
                    <div
                      key={index}
                      onDoubleClick={() => handleCreateEwayBill(invoice?.id, invoice?.customer)}
                      className="flex  justify-between items-center p-[20px] mb-[20px] border-[1px] hover:border-neutral-500 focus:border-neutral-500 shadow bg-white rounded-[20px]"
                    >
                      <div className="flex flex-col gap-[10px]">
                        <span className="text-xl text-black font-semibold">
                          Invoice Counter: <span className="text-neutral-500">{invoice?.invoice_counter}</span>
                        </span>
                        <span className="flex flex-col text-neutral-500 text-sm font-semibold">
                          <span className="text-lg"> {invoice?.customer?.customer_name}</span>
                          <span>
                            GST number:{" "}
                            {invoice?.customer?.gst_number
                              ? invoice?.customer?.gst_number?.toUpperCase()
                              : "Not Provided"}
                          </span>
                        </span>
                      </div>
                      <div className="flex flex-col items-end">
                        <span>₹{invoice?.grand_total}</span>
                        <span>{invoice?.payment_option}</span>
                        <span>{invoice?.payment_type}</span>
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
            <div className="px-[20px] max-h-[85vh] overflow-y-scroll flex-1 bg-white" id="joyride-step-4">
              <h3 className="py-[20px] text-center text-2xl font-semibold">E-Way Bill List</h3>
              <span className="block text-sm text-neutral-500 py-[10px] text-center">Previous E-Way Bills </span>
              <InfiniteScroll
                dataLength={eBill_invoices?.length}
                next={fetchNextEWayBills}
                hasMore={hasMoreEBillInvoices}
                height={"64vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>E-way Bill List End</b>
                  </p>
                }
              >
                {eBill_invoices?.map((invoice, index) => {
                  return (
                    <div
                      key={index}
                      className="flex  justify-between  px-[20px] mb-[20px] border-[1px] hover:border-neutral-500 focus:border-neutral-500 shadow bg-white rounded-[20px]"
                    >
                      <div className="flex flex-col gap-[10px]">
                        <span className="text-xl text-black font-semibold mt-[20px]">
                          Doc number: <span className="text-neutral-500">{invoice?.doc_no}</span>
                        </span>
                        <span className="flex flex-col text-neutral-500 text-sm font-semibold">
                          <span className="text-lg"> {invoice?.to_trd_name}</span>
                          <span>GST number: {invoice?.to_gstin ? invoice?.to_gstin : "URP"}</span>
                        </span>
                        <div className="flex py-[10px] gap-[10px]">
                          <button
                            className="py-[10px] text-red-500 px-[10px] border-2 mb-[10px] border-red-500 rounded-full bg-white"
                            id="joyride-step-5"
                            onClick={() => handleCancelEWayBill(parseInt(invoice?.ewaybill_number))}
                          >
                            Cancel E-Way Bill
                          </button>
                          <button
                            className="py-[10px] text-black px-[10px] border-2 mb-[10px] border-black rounded-full bg-white"
                            id="joyride-step-5"
                            onClick={() =>
                              handleUpdateTransporterID(
                                parseInt(invoice?.ewaybill_number),
                                invoice?.transporter_id,
                                invoice
                              )
                            }
                          >
                            Update Transporter ID
                          </button>
                          <button
                            className="py-[10px] text-black px-[10px] border-2 mb-[10px] border-black rounded-full bg-white"
                            onClick={() => handleDownloadPDF(invoice?.ewaybill_pdf)}
                          >
                            Download PDF
                          </button>
                          {/* <button
                            className="py-[10px] text-black px-[10px] border-2 mb-[10px] border-black rounded-full bg-white"
                            id="joyride-step-5"
                            onClick={() => {
                              setSelectedEBillForExtend(invoice);
                              setShowExtendEBillUpdateModal(true);
                            }}
                          >
                            Extend Bill Validity
                          </button> */}
                          {/* <div className="flex gap-[10px]">
                          <span>₹{invoice?.grand_total}</span>
                          <span>{invoice?.payment_option}</span>
                          <span>{invoice?.payment_type}</span>
                        </div> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EwayBill;
