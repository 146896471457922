import React, { useEffect, useLayoutEffect, useState } from "react";

import Expiry from "./ExpiryAndReturnComponents/Expiry";
import Breakage from "./ExpiryAndReturnComponents/Breakage";
import CustomModal from "../SharedComponents/CustomModals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchVendors } from "../../reducers/Slices/vendorSlice";
import { Autocomplete, debounce, TextField } from "@mui/material";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PreviousVouchers from "./ExpiryAndReturnComponents/BreakageComponents/PreviousVouchers";
import WaitLoader from "../SharedComponents/WaitLoader";

function ExpiryAndReturn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isGstUser, setIsGstUser] = useState(false);

  const [selectedSection, setSelectedSection] = useState("Expiry");
  const [showModalVendorSelection, setShowModalVendorSelection] = useState(false);
  const [voucherType, setVoucherType] = useState("expiry_return");

  const vendors = useSelector((state) => state.vendor.vendors.data) || [];

  const renderExiryReturnSection = () => {
    switch (selectedSection) {
      case "Expiry":
        return <Expiry />;
      case "Breakage":
        return <Breakage />;
      case "PreviousVouchers":
        return <PreviousVouchers />;
      default:
        return <Expiry />;
    }
  };

  const handleCloseProductSelection = () => {
    setSelectedVendor(null);
    setShowModalVendorSelection(false);
  };

  const createReturnVoucher = (e) => {
    e.preventDefault();
    if (!selectedVendor?.id) {
      toast.error("Something went wrong please try again");
    }

    if (voucherType === "normal_voucher") {
      if (isGstUser) {
        navigate(`/returnVoucher/create/estimate/vendor/${selectedVendor?.id}`);
      } else {
        navigate(`/returnVoucher/nongst/create/estimate/vendor/${selectedVendor?.id}`);
      }
    }
    if (voucherType === "expiry_return") {
      if (isGstUser) {
        navigate(`/expiryReturnVoucher/create/estimate/vendor/${selectedVendor?.id}`);
      } else {
        navigate(`/expiryReturnVoucher/nongst/create/estimate/vendor/${selectedVendor?.id}`);
      }
    }
    if (voucherType === "breakage_return") {
      if (isGstUser) {
        navigate(`/breakageReturnVoucher/create/estimate/vendor/${selectedVendor?.id}`);
      } else {
        navigate(`/breakageReturnVoucher/nongst/create/estimate/vendor/${selectedVendor?.id}`);
      }
    }
  };

  const handleCreateReturnVoucher = (type) => {
    setVoucherType(type);
    setShowModalVendorSelection(true);
  };

  //Vendor Selection Operations
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [vendorSearchTerm, setVendorSearchTerm] = useState("");

  const setDebouncedVendorSearchTerm = debounce((value) => setVendorSearchTerm(value), 1000);

  useEffect(() => {
    if (vendorSearchTerm.length > 0) {
      dispatch(fetchVendors(`?search=${vendorSearchTerm}`));
    } else {
      dispatch(fetchVendors());
    }
    //eslint-disable-next-line
  }, [vendorSearchTerm]);

  const businessDetails = useSelector((state) => state.user);

  useLayoutEffect(() => {
    setIsGstUser(() => businessDetails?.gst_number && businessDetails?.gst_number?.length > 0);
  }, [businessDetails]);

  return (
    <>
      <CustomModal isOpen={showModalVendorSelection} handleClose={handleCloseProductSelection}>
        <form onSubmit={createReturnVoucher} className="flex flex-col items-center">
          <h3 className="w-full text-center text-lg font-semibold">Select Vendor to Create Voucher</h3>
          <div className="pt-[20px] pb-[20px] w-full">
            <Autocomplete
              disablePortal
              options={vendors || []}
              value={selectedVendor}
              getOptionLabel={(option) => option.vendor_name}
              onChange={(event, value) => {
                setSelectedVendor(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Vendor Name"
                  onChange={(e) => setDebouncedVendorSearchTerm(e.target.value)}
                />
              )}
            />
          </div>
          <button type="submit" className="px-[20px] py-[10px] bg-black text-white max-w-fit rounded-full">
            Submit
          </button>
        </form>
      </CustomModal>
      <div>
        {/* Context Switcher Section - Expiry, Return, Breakage */}
        <div className="py-[10px] px-[20px] flex justify-between border-b-2 border-neutral-200">
          <div className="flex gap-[15px]">
            <button
              className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
                selectedSection === "Expiry" ? "text-white bg-black" : "text-black"
              } rounded-full hover:bg-black hover:text-white`}
              onClick={() => setSelectedSection("Expiry")}
            >
              Expiry
            </button>
            <button
              className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
                selectedSection === "Breakage" ? "text-white bg-black" : "text-black"
              } rounded-full hover:bg-black hover:text-white`}
              onClick={() => setSelectedSection("Breakage")}
            >
              Breakage
            </button>
            <button
              className={`text-semibold px-[20px] py-[10px] border-2 border-black ${
                selectedSection === "PreviousVouchers" ? "text-white bg-black" : "text-black"
              } rounded-full hover:bg-black hover:text-white`}
              onClick={() => setSelectedSection("PreviousVouchers")}
            >
              Previous Vouchers
            </button>
          </div>
          <div className="flex gap-[15px]">
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full font-semibold"
              onClick={() => handleCreateReturnVoucher("expiry_return")}
            >
              Return Expiry Voucher
            </button>
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full font-semibold"
              onClick={() => handleCreateReturnVoucher("breakage_return")}
            >
              Return Breakage Voucher
            </button>
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full font-semibold"
              onClick={() => handleCreateReturnVoucher("normal_voucher")}
            >
              Return Voucher
            </button>
          </div>
        </div>
        <div className="h-[82vh]">{renderExiryReturnSection()}</div>
      </div>
    </>
  );
}

export default ExpiryAndReturn;
