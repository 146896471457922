import React, { useEffect, useState } from "react";
import { http } from "../../service/http";
import { useDispatch } from "react-redux";
import { addVendor, updateVendor } from "../../reducers/Slices/vendorSlice";
import { searchZipcode } from "../../reducers/Slices/userSlice";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function Vendor(props) {
  const { formData, setFormData, setData, isNew, handleCloseModal } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const stateCodeMapper = (state) => {
    const stateCodes = {
      "ANDAMAN AND NICOBAR": "35",
      "ANDHRA PRADESH": "37",
      "ARUNACHAL PRADESH": "12",
      ASSAM: "18",
      BIHAR: "10",
      CHANDIGARH: "04",
      CHHATTISGARH: "22",
      "DADRA AND NAGAR HAVELI & DAMAN AND DIU": "26",
      DELHI: "07",
      GOA: "30",
      GUJARAT: "24",
      HARYANA: "06",
      "HIMACHAL PRADESH": "02",
      "JAMMU AND KASHMIR": "01",
      JHARKHAND: "20",
      KARNATAKA: "29",
      KERALA: "32",
      LADAKH: "38",
      LAKSHYADEEP: "31",
      "MADHYA PRADESH": "23",
      MAHARASHTRA: "27",
      MANIPUR: "14",
      MEGHALAYA: "17",
      MIZORAM: "15",
      NAGALAND: "13",
      ODISHA: "21",
      "OTHER COUNTRIES": "99",
      "Other Territory": "97",
      PUDUCHERRY: "34",
      PUNJAB: "03",
      RAJASTHAN: "08",
      SIKKIM: "11",
      "TAMIL NADU": "33",
      TELANGANA: "36",
      TRIPURA: "16",
      "UTTAR PRADESH": "09",
      UTTARAKHAND: "05",
      "WEST BENGAL": "19",
    };

    return parseInt(stateCodes[state]) || null;
  };

  const handleSearchZipcode = (e) => {
    dispatch(searchZipcode(formData.zipcode))
      .then((action) => {
        if (action.payload) {
          setFormData({
            ...formData,
            state: action.payload.state,
            city: action.payload.city,
            address: action.payload.address,
            state_code:
              `${action.payload.state_code}`.length > 1 ? action.payload.state_code : `0${action.payload.state_code}`,
            country_code: action.payload.country_code,
          });
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (formData?.gst_number?.length > 0) {
      if (formData?.stateCode && formData?.stateCode?.length > 1) return;

      setFormData((prevState) => {
        return {
          ...prevState,
          state_code: formData?.gst_number?.slice(0, 2) || stateCodeMapper(formData?.state?.toUpperCase() || null),
        };
      });
    }
  }, [formData?.gst_number]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmitButton(true);
    if (isNew) {
      http
        .post(`/user_profile/backend/vendor/`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            setData(res.data.data);
            dispatch(addVendor(res.data.data));
            setDisableSubmitButton(false);
            // navigate(`/invoice/create/customer/${res.data.data.id}/1`);
            handleCloseModal();
          } else {
            toast.error(res.data.message);
          }
        })
        .catch((err) => {
          toast.error("Vendor with this phone number already exists!");
          setDisableSubmitButton(false);
        });
    } else {
      dispatch(updateVendor(formData))
        .then((action) => {
          if (action.payload) {
            setDisableSubmitButton(false);
            handleCloseModal();
          } else {
            toast.error("Sorry, Details updated cannot be added!");
            setDisableSubmitButton(false);
          }
        })
        .catch((err) => {
          toast.error("Sorry, Details updated cannot be added!");
          setDisableSubmitButton(false);
        });
    }
  };

  return (
    <div className="p-4  w-full">
      <div className="flex justify-center">
        <form className="w-full mx-auto" onSubmit={handleSubmit}>
          <div className="w-full">
            <div className="mb-5">
              <label htmlFor="Vendor_name" className="block mb-2 text-sm font-medium text-gray-900 ">
                Full Vendor Name<span className="pl-1 text-red-500">*</span>
              </label>
              <input
                type="text"
                id="vendor_name"
                onChange={(e) => setFormData({ ...formData, vendor_name: e.target.value })}
                value={formData.vendor_name}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                placeholder="Vendor Name"
                required
              />
            </div>
            <div className="mb-5">
              <label htmlFor="phone_number" className="block mb-2 text-sm font-medium text-gray-900 ">
                Mobile Number<span className="pl-1 text-red-500">*</span>
              </label>
              <input
                type="number"
                id="phone_number"
                value={formData.phone_number}
                onChange={(e) => setFormData({ ...formData, phone_number: e.target.value.slice(0, 10) })}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                min={0}
                max={9999999999}
                placeholder="Enter mobile number"
                required
              />
            </div>
            <div>
              <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900 ">
                Zipcode<span className="pl-1 text-red-500">*</span>
              </label>
              <div className="relative z-0 flex gap-[10px] mb-5 group">
                <input
                  type="zipcode"
                  onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                  id="zipcode"
                  value={formData.zipcode}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Enter zipcode"
                  required
                />
                <button
                  type="button"
                  onClick={handleSearchZipcode}
                  className="bg-black text-white px-[20px] py-[10px] rounded-lg"
                >
                  Find
                </button>
              </div>
            </div>
            <div className="flex mb-5">
              <div className="w-1/3 p-1">
                {" "}
                <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 ">
                  State<span className="pl-1 text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="state"
                  value={formData.state}
                  onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Enter state"
                  required
                />
              </div>
              <div className="w-1/3 p-1">
                {" "}
                <label htmlFor="city" className="ml-2 block mb-2 text-sm font-medium text-gray-900 ">
                  City<span className="pl-1 text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="city"
                  value={formData.city}
                  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="Enter city"
                  required
                />
              </div>
              <div className="w-1/3 p-1">
                {" "}
                <label htmlFor="state_code" className="ml-2 block mb-2 text-sm font-medium text-gray-900 ">
                  State Code<span className="pl-1 text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="state_code"
                  value={formData.state_code}
                  onChange={(e) => setFormData({ ...formData, state_code: e.target.value })}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                  placeholder="i.e 17"
                  required
                />
              </div>
            </div>
            <div className="mb-5">
              <label htmlFor="address" className="block mb-2 text-sm font-medium">
                Address<span className="pl-1 text-red-500">*</span>
              </label>
              <textarea
                id="address"
                rows="2"
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                className="block p-2.5 w-full text-sm  rounded-lg border border-black  focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={formData.address}
                placeholder="Enter address"
                required
              ></textarea>
            </div>
            <div className="mb-5">
              <label htmlFor="license_number" className="block mb-2 text-sm font-medium text-gray-900 ">
                License Number
              </label>
              <input
                type="text"
                id="license_number"
                onChange={(e) => setFormData({ ...formData, license_number: e.target.value })}
                value={formData.license_number}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                placeholder=""
              />
            </div>
            <div className="mb-5">
              <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                Email
              </label>
              <input
                type="email"
                id="email"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                value={formData.email}
                className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                placeholder="name@profitai.com"
              />
            </div>
            <div className="w-full flex items-center justify-center py-[20px]">
              <button
                type="submit"
                disabled={disableSubmitButton}
                // onClick={handleSubmit}
                className="bg-black text-white px-[20px] py-[10px] rounded-full"
              >
                Click to Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Vendor;
