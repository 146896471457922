import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import AddWarehouse from "./warehouseComponents/AddWarehouse";
import IconWrapper from "../MicroComponents/IconWrapper";
import { Search } from "react-feather";
import EditWarehouse from "./warehouseComponents/EditWarehouse";
import RemoveWarehouse from "./warehouseComponents/RemoveWarehouse";
import {
  fetchMoreWarehouses,
  fetchProductsByWarehouseId,
  fetchTopProductsByWarehouseId,
  fetchWarehouses,
} from "../../reducers/Slices/warehouseSlice";
import Joyride from "react-joyride";
import { prettifyDigits } from "../../utils/prettifyDigits";
import { formatIndianNumber } from "../../utils/fomatIndianNumber";
import WaitLoader from "../SharedComponents/WaitLoader";
import { setShowWaitLoader } from "../../reducers/Slices/appSlice";

// const warehouses = [
//   {
//     id: 1,
//     name: "Warehouse A",
//     address1: "123 Industrial St",
//     address2: "Suite 100",
//     zipcode: "12345",
//     state: "California",
//     city: "Los Angeles",
//   },
//   {
//     id: 2,
//     name: "Warehouse B",
//     address1: "456 Commerce Ave",
//     address2: "",
//     zipcode: "67890",
//     state: "Texas",
//     city: "Houston",
//   },
//   {
//     id: 3,
//     name: "Warehouse C",
//     address1: "789 Logistics Rd",
//     address2: "Building 5",
//     zipcode: "54321",
//     state: "New York",
//     city: "New York City",
//   },
//   {
//     id: 4,
//     name: "Warehouse D",
//     address1: "321 Distribution Ln",
//     address2: "",
//     zipcode: "98765",
//     state: "Florida",
//     city: "Miami",
//   },
// ];

// const warehouseProductsData = [
//   {
//     id: 1,
//     products: [
//       { product_id: 1, product_name: "Product A1", batch_number: "Batch1", quantity: 100 },
//       { product_id: 2, product_name: "Product A2", batch_number: "Batch2", quantity: 50 },
//     ],
//   },
//   {
//     id: 2,
//     products: [
//       { product_id: 3, product_name: "Product B1", batch_number: "Batch1", quantity: 200 },
//       { product_id: 4, product_name: "Product B2", batch_number: "Batch2", quantity: 75 },
//     ],
//   },
//   // Add more warehouses and products as needed
// ];

function Warehouse() {
  const dispatch = useDispatch();

  const warehouses = useSelector((state) => state.warehouse.warehouses.data);
  const warehousesNext = useSelector((state) => state.warehouse.warehouses.next);
  const warehouseProducts = useSelector((state) => state.warehouse.warehouseProducts.data);
  const warehouseProductsNext = useSelector((state) => state.warehouse.warehouseProducts.next);
  const warehouseTopProducts = useSelector((state) => state.warehouse.warehouseTopProducts.data);

  // const [selectedProductsArr, setSelectedProductsArr] = useState([]);
  const [hasMoreProducts, setHasMoreProducts] = useState(warehouseProductsNext ? true : false);

  const [hasMoreWarehousesEntries, setHasMoreWarehousesEntries] = useState(() => (warehousesNext ? true : false));

  //fetch more warehouses on scroll if available
  const fetchNextWarehousesEntries = () => {
    if (warehousesNext) {
      dispatch(fetchMoreWarehouses(warehousesNext));
    }
  };

  //fetch more Products on scroll if available
  const fetchNextProductEntries = () => {
    if (warehouseProductsNext) {
      //   dispatch(fetchMoreWarehousesEntries(warehousesNext));
      // currently API doesn't support infinity scroll.
    }
  };

  //Use effect to fetch more warehouses based on value of warehousesNext
  useEffect(() => {
    if (warehousesNext) {
      setHasMoreWarehousesEntries(true);
    } else {
      setHasMoreWarehousesEntries(false);
    }
  }, [warehousesNext]);

  //Use effect to fetch more products based on value of warehouseProductsNext
  useEffect(() => {
    if (warehouseProductsNext) {
      setHasMoreProducts(true);
    } else {
      setHasMoreProducts(false);
    }
  }, [warehouseProductsNext]);

  const [selectedWarehouse, setSelectedWarehouseEntry] = useState({});
  const [searchWarehouses, setSearchWarehouses] = useState("");
  const [searchProducts, setSearchProducts] = useState("");

  // useEffect(() => {
  //   dispatch(fetchHSNData());
  // }, []);

  //Side effect to get products based on selectedWarehouse

  useEffect(() => {
    if (!selectedWarehouse?.id) {
      return;
    }

    // const productsArr = warehouseProductsData?.find((warehouse) => warehouse.id === selectedWarehouse.id)?.products;
    // if (productsArr?.length > 0) {
    //   setSelectedProductsArr(productsArr);
    // } else {
    //   setSelectedProductsArr([]);
    // }
    dispatch(fetchProductsByWarehouseId({ id: selectedWarehouse?.id }));
    dispatch(fetchTopProductsByWarehouseId({ id: selectedWarehouse?.id }));
  }, [selectedWarehouse]);

  //side effect to set default warehouse when availble
  useEffect(() => {
    if (warehouses.length > 0) {
      setSelectedWarehouseEntry(warehouses[0]);
    }
  }, [warehouses]);

  const debouncedWarehousesSearch = debounce((value) => setSearchWarehouses(value), 1000);
  const debouncedProductSearch = debounce((value) => setSearchProducts(value), 1000);

  useEffect(() => {
    if (searchWarehouses?.length > 0) {
      dispatch(setShowWaitLoader(true));
      dispatch(fetchWarehouses(`?search=${searchWarehouses}`)).then(() => dispatch(setShowWaitLoader(false)));
    }
    //eslint-disable-next-line
  }, [searchWarehouses]);

  useEffect(() => {
    dispatch(setShowWaitLoader(true));
    dispatch(fetchWarehouses()).then(() => dispatch(setShowWaitLoader(false)));
  }, []);

  useEffect(() => {
    if (searchProducts?.length > 0) {
      dispatch(fetchProductsByWarehouseId({ id: selectedWarehouse?.id, search: `?search=${searchProducts}` }));
    }
    //eslint-disable-next-line
  }, [searchProducts]);

  //React Joyride - Guide user on how to use app
  const steps = [
    {
      target: "#joyride-step-1",
      content: "Search for warehouses here.",
    },
    {
      target: "#joyride-step-2",
      content: "Click 'Add Warehouse' to create new warehouse entries.",
    },
    {
      target: "#joyride-step-3",
      content: "This is your list of warehouses.",
    },
    {
      target: "#joyride-step-4",
      content: "View detailed information for the selected warehouse here.",
    },
    {
      target: "#joyride-step-5",
      content: "Use the 'Edit' or 'Remove' button to modify or delete selected warehouse.",
    },
    {
      target: "#joyride-step-6",
      content: "Search for products in the selected warehouse from here.",
    },
    {
      target: "#joyride-step-7",
      content: "This is your list of products in the selected warehouse.",
    },
    {
      target: "#joyride-step-8",
      content: "This list shows the top-selling products in the selected warehouse.",
    },
  ];

  const [localStorageWarehouseStatus, setLocalStorageWarehouseStatus] = useState(
    localStorage.getItem("showed_warehouse_guide") || false
  );

  //handle till final steps
  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished" || status === "skipped") {
      localStorage.setItem("showed_warehouse_guide", true);
      setLocalStorageWarehouseStatus(false);
    }
  };

  function getBatchNames(batches) {
    const batchCount = batches.length;

    if (batchCount === 1) {
      return batches[0]?.batch_number;
    } else {
      return batches.map((batch, index) => batch?.batch_number).join(", ");
    }
  }

  return (
    <>
      {!localStorageWarehouseStatus && (
        <Joyride
          steps={steps}
          continuous={true}
          showSkipButton={true}
          disableBeacon={true}
          callback={handleJoyrideCallback}
          styles={{ options: { primaryColor: "#6366f1" } }}
        />
      )}
      <div className="flex">
        {/* Warehouse list */}
        <div className="flex-1 max-w-[30%] border-r-2 border-neutral-200 h-[91.3vh]">
          <div className="flex gap-[10px] border-b-2 border-neutral-200 p-[15px]">
            {/* //Search Bar */}
            <div className="flex w-full" id="joyride-step-1">
              <input
                type="text"
                className="h-[44px] w-full rounded-full border-2 border-neutral-200 text-neutral-500 px-[15px]"
                placeholder="Search Warehouses"
                onChange={(e) => debouncedWarehousesSearch(e.target.value)}
              />
              <button className="-ml-[40px]">
                <IconWrapper>
                  <Search className="z-10 text-white"></Search>
                </IconWrapper>
              </button>
            </div>

            {/* add cash registery button */}
            <span id="joyride-step-2">
              <AddWarehouse />
            </span>
          </div>
          {/* registery Container */}
          {warehouses?.length === 0 && (
            <div className="text-center py-10 font-semibold bg-white" id="joyride-step-3">
              No Entries found!
            </div>
          )}
          {warehouses?.length > 0 && (
            <div className="overflow-y-scroll bg-white" id="joyride-step-3">
              <InfiniteScroll
                dataLength={warehouses.length}
                next={fetchNextWarehousesEntries}
                hasMore={hasMoreWarehousesEntries}
                height={"66vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>Warehouses List End</b>
                  </p>
                }
              >
                <ul className="flex flex-col p-[15px] gap-[10px]">
                  {warehouses.map((warehouseEntry, index) => (
                    <li
                      key={index}
                      className={`w-full flex justify-between p-[12px] border-2  ${
                        selectedWarehouse?.id === warehouseEntry?.id ? "border-black" : "border-neutral-200"
                      } rounded-full cursor-pointer`}
                      onClick={() => setSelectedWarehouseEntry(warehouseEntry)}
                    >
                      <span>
                        {/* <span>{index + 1}.</span> */}
                        <span className="ml-[5px]">{warehouseEntry?.name}</span>
                      </span>
                    </li>
                  ))}
                </ul>
              </InfiniteScroll>
            </div>
          )}
        </div>
        {/* Warehouse details */}
        <div className="flex-1 h-[calc(100vh-80px)] max-w-[50%] border-r-2 border-neutral-200">
          <div className="p-[20px] border-b-2 border-neutral-200 bg-white" id="joyride-step-4">
            <div className="flex justify-between">
              <h2 className="text-3xl pb-[20px]">{selectedWarehouse?.name}</h2>
              <div className="flex gap-[10px] bg-white" id="joyride-step-5">
                <EditWarehouse warehouse={selectedWarehouse} />
                <RemoveWarehouse warehouse={selectedWarehouse} />
              </div>
            </div>
            <div className="flex gap-[10px] pb-[10px]">
              <h3 className="font-semibold text-lg">Warehouse Address:</h3>
              <span className="text-neutral-500 text-lg">
                {(selectedWarehouse?.address1 || "") +
                  (selectedWarehouse?.address2 ? `, ${selectedWarehouse?.address2}` : "")}
              </span>
            </div>
            <div className="flex gap-[10px] pb-[10px]">
              <h3 className="font-semibold text-lg">Manager Contact:</h3>
              <span className="text-neutral-500 text-lg">{selectedWarehouse?.contact}</span>
            </div>
            <div className="flex gap-[10px] pb-[10px]">
              <h3 className="font-semibold text-lg">Sales Value of Inventory:</h3>
              <span className="text-neutral-500 text-lg">
                ₹{formatIndianNumber(selectedWarehouse?.total_sales_price || 0)}
              </span>
            </div>
            <div className="flex gap-[10px] pb-[10px]">
              <h3 className="font-semibold text-lg">Purchase Value of Inventory:</h3>
              <span className="text-neutral-500 text-lg">
                ₹{formatIndianNumber(selectedWarehouse?.total_purchase_price || 0)}
              </span>
            </div>
            <div className="flex gap-[10px] pb-[10px]">
              <h3 className="font-semibold text-lg">Total Products:</h3>
              <span className="text-neutral-500 text-lg">{warehouseProducts?.length}</span>
            </div>
          </div>
          {/* <div className="h-[76px] border-b-2 flex items-center justify-center px-[15px]">
          <h2 className="text-xl">Warehouse Details</h2>
          </div>
          <div className="p-[15px] flex gap-[15px]"> */}
          {/* Name, Location, Capacity, and other details */}
          {/* <div className="w-full flex flex-col gap-[10px] justify-center">
            <DetailContainer title={"Name"} value={selectedWarehouse.name || "N/A"} />
            <DetailContainer
            title={"Address"}
            value={
                selectedWarehouse.address1 + (selectedWarehouse.address2 ? `, ${selectedWarehouse.address2}` : "") ||
                "N/A"
                }
                />
                <DetailContainer title={"City"} value={selectedWarehouse.city || "N/A"} />
                <DetailContainer title={"State"} value={selectedWarehouse.state || "N/A"} />
                <DetailContainer title={"Zipcode"} value={selectedWarehouse.zipcode || "N/A"} />
                <DetailContainer title={"Product Batches Available"} value={100 || "N/A"} />
                {warehouses?.length > 0 && (
                  <div className="flex gap-[10px] mt-[10px]">
                  <EditWarehouse warehouse={selectedWarehouse} />
                  <RemoveWarehouse warehouse={selectedWarehouse} />
                  </div>
                  )}
                  </div>
                  </div> */}
          {/* warehouse product list */}
          <div className="flex-1">
            <h3 className="text-lg text-center text-neutral-500 my-[20px]">Products Present in Warehouse</h3>
            {warehouseProducts?.length > 0 && (
              <div className="flex w-full px-[10px] pb-[20px] bg-white" id="joyride-step-6">
                <input
                  type="text"
                  className="h-[44px] w-full rounded-full border-2 border-neutral-200 text-neutral-500 px-[15px]"
                  placeholder="Search Products in Warehouse"
                  onChange={(e) => debouncedProductSearch(e.target.value)}
                />
                <button className="-ml-[40px]">
                  <IconWrapper>
                    <Search className="z-10 text-white"></Search>
                  </IconWrapper>
                </button>
              </div>
            )}
            {warehouseProducts?.length > 0 && (
              <div className="bg-white" id="joyride-step-7">
                <InfiniteScroll
                  dataLength={warehouseProducts.length}
                  next={fetchNextProductEntries}
                  hasMore={hasMoreProducts}
                  height={"44vh"}
                  loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                  endMessage={
                    <p style={{ textAlign: "center", paddingTop: "10px" }}>
                      <b>Products List End</b>
                    </p>
                  }
                >
                  {warehouseProducts.map((item, index) => (
                    <div
                      key={index}
                      className="border-neutral-200 border-2 hover:border-black focus:border-black p-2 rounded-lg bg-white cursor-pointer shadow mb-2 mx-[10px]"
                    >
                      <div className="relative flex text-sm items-center justify-between gap-2 pb-2 pl-2 ">
                        <div>
                          <h3 className="text-base font-semibold text-black">{item.product_name}</h3>
                          <h3 className="text-black">
                            Batch: <span className="text-gray-500">{getBatchNames(item.batches)}</span>
                          </h3>
                          <h3 className="text-black">
                            Total Quantity: <span className="text-gray-500">{item.total_remaining_quantity || 0}</span>
                          </h3>
                        </div>
                      </div>
                    </div>
                  ))}
                </InfiniteScroll>
              </div>
            )}
            {warehouseProducts?.length === 0 && (
              <div className="text-lg text-center py-[20px] text-neutral-500 bg-white" id="joyride-step-7">
                Warehouse doesn't contain any products.
              </div>
            )}
          </div>
        </div>
        {/* Top Selling Products */}
        <div className="max-w-[20%] flex-1 p-[20px]">
          <div className="border-2 rounded-[20px] max-h-[40vh] overflow-y-scroll bg-white" id="joyride-step-8">
            <div className="text-center text-neutral-500 text-sm py-[20px]">
              Top Selling Products in {selectedWarehouse?.name}
            </div>
            <div>
              <div className="px-[20px] flex w-full justify-between border-neutral-200 border-b-2">
                <span className="text-neutral-500 text-sm">Product Name</span>
                <span className="text-neutral-500 text-sm">Quantity Sold</span>
              </div>
              {warehouseTopProducts?.length === 0 && (
                <div className="text-sm text-center py-[10px]">No data found!</div>
              )}
              {warehouseTopProducts?.map((item, index) => {
                return (
                  <div key={index} className="flex justify-between text-sm mx-[20px] border-b-2 last:border-b-0 py-2">
                    <div className="">
                      {index + 1}.{" "}
                      {item.product__product_name.length > 15
                        ? item.product__product_name.slice(0, 15) + "..."
                        : item.product__product_name}
                    </div>
                    <div className="">{item.total_sales_quantity}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Warehouse;
