// import React, { useState, useEffect } from "react";
import React, { useEffect, useState } from "react";
// import { useNavigate, useLocation, Link } from "react-router-dom"; TODO - Delete if not needed
import { useLocation, Link } from "react-router-dom";
// import "./DesktopSideBar.scss"; TODO - Deletion if nothing breaks
import { useSelector } from "react-redux";

import TheProfitAILogo from "../../../assets/images/TheProfitAi_logo.png";
import TheProfitAILogoMini from "../../../assets/images/TheProfitAi_logo_mini.png";

// Data import
import { navigationItems } from "./SideBarData";

// UI libarary and components
import IconWrapper from "../../MicroComponents/IconWrapper";
import { ArrowRight } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faHome,
  faBook,
  faBox,
  faCashRegister,
  faFileInvoice,
  faChartPie,
  faStore,
  faMoneyBillWave,
  faUsers,
  faArrowTrendUp,
  faWarehouse,
  faHardDrive,
  faTruckRampBox,
} from "@fortawesome/free-solid-svg-icons";

export const iconMapping = {
  "/": faHome,
  "/sales-book": faBook,
  "/purchase-book": faBook,
  "/inventory": faBox,
  "/cash-register": faCashRegister,
  "/ledger-book": faBook,
  "/warehouse": faWarehouse,
  "/reports": faChartPie,
  "/gst-filing": faFileInvoice,
  "/pnl": faArrowTrendUp,
  "/online-store": faStore,
  "/expenses": faMoneyBillWave,
  "/employees": faUsers,
  "/import_export": faHardDrive,
  "/expiry_return": faTruckRampBox,
};

const DesktopSideBar = () => {
  const location = useLocation();

  const [showSidebar, setShowSidebar] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleHoverState = (hoveredState) => {
    setIsHovered(hoveredState);
  };

  return (
    <>
      {/* Placeholder to stop UI from breaking on showMenu reason aside(block) -> aside(absolute) */}
      <div className="pl-[82px]"></div>

      <aside
        id="default-sidebar"
        className={`bg-[#F6F6F6] border-r-2 border-stone-200 z-40 hidden md:block
    ${isHovered && "xl:w-72"} h-[100vh] fixed top-0 left-0 
    ${showSidebar && " w-[30vw]"} transition-all duration-500`}
        aria-label="Sidebar"
        onMouseEnter={() => {
          handleHoverState(true);
        }}
        onMouseLeave={() => {
          handleHoverState(false);
        }}
      >
        <div className="sticky h-full overflow-y-scroll 2xl:overflow-y-hidden">
          <div className="h-[80px] mx-[20px] flex items-center justify-between transition-all duration-300 ease-in-out">
            <Link to={"/"}>
              <img
                src={TheProfitAILogo}
                alt="The Profit AI logo"
                className={`w-[180px] ${isHovered ? "" : "hidden"}`}
              />
            </Link>

            <span
              className={`${isHovered && "hidden"} transition-all duration-300 ease-in-out`}
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <img src={TheProfitAILogoMini} alt="The Profit AI logo mini" className={`w-[40px]`} />
            </span>
          </div>
          <ul className="flex flex-col  xl:mt-[10px] px-[10px] h-[100%] transition-all duration-300 ease-in-out">
            {navigationItems.map((item, index) => (
              <li
                key={index}
                className={`my-[0.5vh] py-[10px] ${isHovered && "xl:pl-[10px]"} 
          ${isHovered ? "pl-[10px]" : ""} rounded-lg 
          ${item.path === location.pathname ? "bg-stone-200" : ""} transition-all duration-300 ease-in-out`}
              >
                <Link
                  to={item.path}
                  className={`${
                    item.path === location.pathname ? "text-neutral-600" : "text-neutral-500"
                  } flex items-center font-semibold 
            ${
              isHovered
                ? "justify-normal"
                : `justify-center ${isHovered && "xl:justify-normal"} transition-all duration-300 ease-in-out`
            }`}
                >
                  <FontAwesomeIcon
                    icon={iconMapping[item.path]}
                    className={`h-[20px] w-[20px] 
                ${
                  item.path === location.pathname ? "text-[#277494]" : "text-neutral-500"
                } transition-all duration-300 ease-in-out`}
                  />
                  <span
                    className={`ms-3 hidden md:${isHovered ? "block" : ""} ${
                      isHovered && "xl:block"
                    } transition-all duration-300 ease-in-out`}
                  >
                    {item.title}
                  </span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default DesktopSideBar;
