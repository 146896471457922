import React, { useState, useEffect, useLayoutEffect } from "react";
import { humanDateFormat } from "../../utils/dateFormat";
import {
  cancelEInvoice,
  cancelInvoice,
  cancelNonGstInvoice,
  fetchMoreInvoicesCustomer,
} from "../../reducers/Slices/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";
// import { fetchMoreInvoices } from "../../reducers/Slices/invoiceSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import toast from "react-hot-toast";
import { formatIndianNumber } from "../../utils/fomatIndianNumber";

function ShowInvoices({
  selectedUser,
  invoices,
  isCancelled,
  showAuthModal,
  setShowAuthModal,
  authFormData,
  setAuthFormData,
}) {
  const dispatch = useDispatch();

  const customer_invoicesNext = useSelector((state) => state.invoice.customer_invoices.next);
  const [hasMoreInvoices, setHasMoreInvoices] = useState(() => (customer_invoicesNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (customer_invoicesNext) {
      setHasMoreInvoices(true);
    } else {
      setHasMoreInvoices(false);
    }
  }, [customer_invoicesNext]);

  //fetch more expenses on scroll if available
  const fetchNextInvoices = () => {
    if (customer_invoicesNext) {
      dispatch(fetchMoreInvoicesCustomer(customer_invoicesNext));
    }
  };

  const businessDetails = useSelector((state) => state.user);

  const [isGstUser, setIsGstUser] = useState(false);

  useLayoutEffect(() => {
    setIsGstUser(() => businessDetails?.gst_number && businessDetails?.gst_number?.length > 0);
  }, [businessDetails]);

  const deleteInvoice = (invoice) => {
    if (window.confirm("are you sure you want to cancel this invoice?")) {
      if (invoice?.irn) {
        setAuthFormData((prevData) => {
          return { ...prevData, id: invoice?.id };
        });
        setShowAuthModal(true);
      } else {
        if (isGstUser) {
          dispatch(cancelInvoice({ id: invoice?.id }))
            .then((action) => {
              if (action.payload) {
              } else {
                toast.error("Invoice cancellation failed!");
              }
            })
            .catch((err) => {
              toast.error(err.message);
            });
        } else {
          dispatch(cancelNonGstInvoice({ id: invoice?.id }))
            .then((action) => {
              if (action.payload) {
              } else {
                toast.error("Invoice cancellation failed!");
              }
            })
            .catch((err) => {
              toast.error(err.message);
            });
        }
      }
    }
  };

  return (
    <div className="w-full px-[20px] overflow-y-scroll">
      <h3 className="text-center text-2xl pb-[20px]">Invoice List</h3>
      <InfiniteScroll
        dataLength={invoices?.length}
        next={fetchNextInvoices}
        hasMore={hasMoreInvoices}
        height={"70vh"}
        loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
        endMessage={
          <p style={{ textAlign: "center", paddingTop: "10px" }}>
            <b>Invoices List End</b>
          </p>
        }
      >
        {invoices?.length > 0 &&
          invoices?.map((invoice, index) => {
            return (
              <div
                key={index}
                className="flex border-2 border-neutral-200 rounded-[20px] p-[20px] mb-[20px] w-full justify-between items-start"
              >
                <div className="flex flex-col gap-[2px]">
                  <span className="text-lg">
                    Invoice Number: <span className="text-neutral-500">{invoice?.invoice_counter}</span>
                  </span>
                  <span className="text-lg">
                    Order Date: <span className="text-neutral-500">{humanDateFormat(invoice?.order_date_time)}</span>
                  </span>
                  <span className="text-lg">
                    Payment Type: <span className="text-neutral-500">{invoice?.payment_type}</span>
                  </span>
                  <span className="text-lg">
                    Payment Option: <span className="text-neutral-500">{invoice?.payment_option}</span>
                  </span>

                  <span className="text-lg">
                    Amount: <span className="text-neutral-500">{formatIndianNumber(invoice?.grand_total || 0)}</span>
                  </span>
                </div>

                {!isCancelled && (
                  <button
                    className="px-[20px] border-2 border-red-500 text-red-500 py-[10px] rounded-full"
                    onClick={() => deleteInvoice(invoice)}
                  >
                    Cancel Invoice
                  </button>
                )}

                {isCancelled && <span className="text-lg text-red-500">Cancelled</span>}
              </div>
            );
          })}
      </InfiniteScroll>
    </div>
  );
}

export default ShowInvoices;
