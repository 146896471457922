import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

//Library Components
import toast from "react-hot-toast";

//Reducer functions
import { searchZipcode } from "../../reducers/Slices/userSlice";
import { getCompleteInvoiceDetailsById } from "../../reducers/Slices/invoiceSlice";
import { fetchBatchesByProductID } from "../../reducers/Slices/productSlice";
import { fetchCustomers } from "../../reducers/Slices/customerSlice";

//UI Library
import { Plus, Trash2 } from "react-feather";
import axios from "axios";
import { generateEWayBill } from "../../reducers/Slices/eWayBillSlice";

//util function for dot - red or black
const TextWithDot = ({ text, textClasses, dotColor }) => {
  // Determine dot color class based on the input
  const dotClass = dotColor === "red" ? "bg-red-500" : "bg-black";

  return (
    <div className="relative inline-block">
      <span className={`${textClasses} mr-[10px]`}>{text}</span>
      <span
        className={`absolute top-[5px] right-[3px] w-2 h-2 rounded-full ${dotClass}`}
        style={{ transform: "translate(50%, -50%)" }}
      ></span>
    </div>
  );
};

function CreateEwayBill() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { customerId, invoiceId } = params;

  // React state for managing radio button selections - Transaction Details
  const [supplyType, setSupplyType] = useState("Outward");
  const [subType, setSubType] = useState("Supply");
  const [subTypeMenu, setSubTypeMenu] = useState([
    "Supply",
    "Export",
    "Job Work",
    "SKD/CKD/Lots",
    "Recipient Not Known",
    "For Own Use",
    "Exhibition or Fairs",
    "Line Sales",
    "Others",
  ]);

  //Mapper function to generate payload equilent value
  const supplyTypeMapper = (supplyType) => {
    const supplyTypes = {
      Outward: "O",
      Inward: "I",
    };

    // Return the corresponding payload value or a default if not found
    return supplyTypes[supplyType] || null;
  };

  //Mapper function to generate payload equilent value
  const subSupplyTypeMapper = (subType) => {
    const subSupplyTypes = [
      "Supply",
      "Import",
      "Export",
      "Job Work",
      "For Own Use",
      "Job work Returns",
      "Sales Return",
      "Others",
      "SKD/CKD/Lots",
      "Line Sales",
      "Recipient Not Known",
      "Exhibition or Fairs",
    ];

    const index = subSupplyTypes.indexOf(subType);

    return index !== -1 ? index + 1 + "" : null;
  };

  //Condition when other SubType is selected
  const [otherSubType, setOtherSubType] = useState("");

  const [documentType, setDocumentType] = useState("Tax Invoice");
  const [documentTypeMenu, setDocumentTypeMenu] = useState(["Tax Invoice", "Bill of Supply"]);
  const [documentNo, setDocumentNo] = useState("");

  //Mapper function to generate payload equilent value
  const documentTypeMapper = (docType) => {
    const documentTypes = {
      "Tax Invoice": "INV",
      "Bill of Supply": "BIL",
      "Bill of Entry": "BOE",
      "Delivery Challan": "CHL",
      Others: "OTH",
    };

    // Return the corresponding payload value or a default if not found
    return documentTypes[docType] || null;
  };

  // Limiting document date to today's date - Document Date Field - Transaction
  const todayDate = new Date().toISOString().split("T")[0];

  const [documentDate, setDocumentDate] = useState(todayDate);
  const [transactionType, setTransactionType] = useState("Regular");

  //Mapper function to generate payload equilent value
  const transactionTypeMapper = (subType) => {
    const transactionTypes = ["Regular", "Bill To - Ship To", "Bill From - Dispatch From", "Combination of 2 and 3"];

    const index = transactionTypes.indexOf(subType);

    return index !== -1 ? index + 1 : null;
  };

  // Handlers for changing the selected radio buttons - Transaction Details
  const handleSupplyTypeChange = (e) => {
    setSupplyType(e.target.value);

    //Change SubType menu based on Supply Type i.e inward or outward
    if (e.target.value === "Outward") {
      const updatedSubTypeMenu = [
        "Supply",
        "Export",
        "Job Work",
        "SKD/CKD/Lots",
        "Recipient Not Known",
        "For Own Use",
        "Exhibition or Fairs",
        "Line Sales",
        "Others",
      ];

      setSubTypeMenu(updatedSubTypeMenu);
    } else if (e.target.value === "Inward") {
      const updatedSubTypeMenu = [
        "Supply",
        "Import",
        "SKD/CKD/Lots",
        "Job work Returns",
        "Sales Return",
        "Exhibition or Fairs",
        "For Own Use",
        "Others",
      ];

      setSubTypeMenu(updatedSubTypeMenu);
    }
  };

  const handleSubTypeChange = (e) => {
    setSubType(e.target.value);

    //Based on sub type change perform operations
    let updatedDocumentTypeMenu = [];

    switch (e.target.value) {
      case "Supply":
        //For Outward and Inward Supply Type
        updatedDocumentTypeMenu = ["Tax Invoice", "Bill of Supply"];
        setDocumentType("Tax Invoice");
        break;
      case "Export":
        //For Outward Supply Type
        updatedDocumentTypeMenu = ["Bill of Supply", "Tax Invoice"];
        setDocumentType("Bill of Supply");
        break;
      case "Import":
        //For Inward Supply Type
        updatedDocumentTypeMenu = ["Bill of Entry"];
        setDocumentType("Bill of Entry");
        break;
      case "Job Work":
        //For Outward Supply Type
        updatedDocumentTypeMenu = ["Delivery Challan"];
        setDocumentType("Delivery Challan");
        break;
      case "Job work Returns":
        //For Inward Supply Type
        updatedDocumentTypeMenu = ["Delivery Challan"];
        setDocumentType("Delivery Challan");
        break;
      case "Sales Return":
        //For Inward Supply Type
        updatedDocumentTypeMenu = ["Delivery Challan"];
        setDocumentType("Delivery Challan");
        break;
      case "SKD/CKD/Lots":
        //For Outward and Inward Supply Type
        if (supplyType === "Outward") {
          updatedDocumentTypeMenu = ["Delivery Challan", "Tax Invoice", "Bill of Supply"];
          setDocumentType("Delivery Challan");
        } else {
          updatedDocumentTypeMenu = ["Bill of Entry", "Delivery Challan", "Tax Invoice", "Bill of Supply"];
          setDocumentType("Bill of Entry");
        }
        break;
      case "Recipient Not Known":
        //For Outward Supply Type
        updatedDocumentTypeMenu = ["Delivery Challan", "Others"];
        setDocumentType("Delivery Challan");
        break;
      case "For Own Use":
        //For Outward and Inward Supply Type
        updatedDocumentTypeMenu = ["Delivery Challan"];
        setDocumentType("Delivery Challan");
        break;
      case "Exhibition or Fairs":
        //For Outward and Inward Supply Type
        updatedDocumentTypeMenu = ["Delivery Challan"];
        setDocumentType("Delivery Challan");
        break;
      case "Line Sales":
        //For Outward Supply Type
        updatedDocumentTypeMenu = ["Delivery Challan"];
        setDocumentType("Delivery Challan");
        break;
      case "Others":
        //For Outward and Inward Supply Type
        updatedDocumentTypeMenu = ["Delivery Challan", "Others"];
        setDocumentType("Delivery Challan");
        break;
      default:
        updatedDocumentTypeMenu = ["Tax Invoice", "Bill of Supply"];
        setDocumentType("Tax Invoice");
    }

    setDocumentTypeMenu(updatedDocumentTypeMenu);
  };

  const handleOtherSubType = (e) => {
    setOtherSubType(e.target.value);
  };

  const handleDocumentTypeChange = (e) => {
    setDocumentType(e.target.value);
  };

  const handleDocumentNoChange = (e) => {
    setDocumentNo(e.target.value);
  };

  const handleDocumentDateChange = (e) => {
    setDocumentDate(e.target.value);
  };

  const handleTransactionTypeChange = (e) => {
    setTransactionType(e.target.value);
  };

  //Bill from, Dispatch from, Bill to, ship to section operations
  const [billFrom, setBillFrom] = useState({
    name: "",
    gstin: "",
    state: "",
  });

  const [dispatchFrom, setDispatchFrom] = useState({
    address1: "",
    address2: "",
    place: "",
    pincode: "",
    state: "",
  });

  const [billTo, setBillTo] = useState({
    name: "",
    gstin: "",
    state: "",
  });

  const [shipTo, setShipTo] = useState({
    address1: "",
    address2: "",
    place: "",
    pincode: "",
    state: "",
  });

  //Inititally null to detect if they are empty using conditions in further operations
  const [customerObj, setCustomerObj] = useState(null);
  const [businessObj, setBusinessObj] = useState(null);

  //Function to handle raw customer object - extract required fields and clean them according to requirement
  const handleRawCustomerObj = (obj) => {
    const { address, city, gst_number, state, zipcode, customer_name } = obj;

    const customerObjEx = {
      name: customer_name,
      gstin: gst_number || "URP",
      state: state?.toUpperCase(),
      address1: address,
      address2: "",
      place: city,
      pincode: zipcode,
    };

    setCustomerObj(customerObjEx);
  };

  //Function to handle raw business object - extract required fields and clean them according to requirement
  const handleRawBusinessObj = (obj) => {
    const { address1, address2, business_name, city, gst_number, state, zipcode } = obj;

    const businessObjEx = {
      name: business_name,
      gstin: gst_number || "URP",
      state: state.toUpperCase(),
      address1: address1,
      address2: address2,
      place: city,
      pincode: zipcode,
    };

    setBusinessObj(businessObjEx);
  };
  const rawBusinessObj = useSelector((state) => state.user);

  //side effect to fetch customer details and business details and set object customer and object business
  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await dispatch(fetchCustomers(`?customerId=${customerId}`));
        const rawCustomerObj = response.payload.data[0];

        if (rawCustomerObj?.id) {
          handleRawCustomerObj(rawCustomerObj);
        }
      } catch (err) {
        toast.error("some error occurred while fetching customer details");
      }
    };

    fetchCustomerDetails();

    //We are not fetching this because it would be redundant to do so and because while using product information is already
    //stored in a reducer state.
    if (rawBusinessObj?.id || rawBusinessObj?.id === 0) {
      handleRawBusinessObj(rawBusinessObj);
    }
    //eslint-disable-next-line
  }, [customerId, rawBusinessObj]);

  //pre-fill handler to autofill billFrom, dispatchFrom, bill To and ship To based on selected subtype
  const handlePrefillTransactionDetails = () => {
    let updatedBillFrom = {
      name: "",
      gstin: "",
      state: "",
    };

    let updatedDispatchFrom = {
      address1: "",
      address2: "",
      place: "",
      pincode: "",
      state: "",
    };

    let updatedBillTo = {
      name: "",
      gstin: "",
      state: "",
    };

    let updatedShipTo = {
      address1: "",
      address2: "",
      place: "",
      pincode: "",
      state: "",
    };

    function setObjBasedOnCases(cases) {
      // cases - [business obj, customer obj], [business obj, custom co], [business obj, business obj], [customer obj, business obj], [custom co, business obj],
      const business1 = { name: businessObj?.name, gstin: businessObj?.gstin, state: businessObj?.state };
      const business2 = {
        address1: businessObj?.address1,
        address2: businessObj?.address2,
        place: businessObj?.place,
        pincode: businessObj?.pincode,
        state: businessObj?.state,
      };
      const customer1 = { name: customerObj?.name, gstin: customerObj?.gstin, state: customerObj?.state };
      const customer2 = {
        address1: customerObj?.address1,
        address2: customerObj?.address2,
        place: customerObj?.place,
        pincode: customerObj?.pincode,
        state: customerObj?.state,
      };
      const customerCustom = { name: customerObj?.name, gstin: "URP", state: "OTHER COUNTRIES" };

      switch (cases) {
        case 1:
          updatedBillFrom = business1;
          updatedDispatchFrom = business2;
          updatedBillTo = customer1;
          updatedShipTo = customer2;
          break;
        case 2:
          updatedBillFrom = business1;
          updatedDispatchFrom = business2;
          updatedBillTo = customerCustom;
          updatedShipTo = customer2;
          break;
        case 3:
          updatedBillFrom = business1;
          updatedDispatchFrom = business2;
          updatedBillTo = business1;
          updatedShipTo = business2;
          break;
        case 4:
          updatedBillFrom = customer1;
          updatedDispatchFrom = customer2;
          updatedBillTo = business1;
          updatedShipTo = business2;
          break;
        case 5:
          updatedBillFrom = customerCustom;
          updatedDispatchFrom = customer2;
          updatedBillTo = business1;
          updatedShipTo = business2;
          break;
        default:
          updatedBillFrom = business1;
          updatedDispatchFrom = business2;
          updatedBillTo = customer1;
          updatedShipTo = customer2;
      }
    }

    if (supplyType === "Outward") {
      // cases - [business obj, customer obj], [business obj, custom co], [business obj, business obj], [customer obj, business obj], [custom co, business obj],
      switch (subType) {
        case "Supply":
          //outward - supply - business obj, customer obj
          setObjBasedOnCases(1);
          break;
        case "Export":
          //outward - export - business obj, (gstin - urp, state - other countries, customer obj)
          setObjBasedOnCases(2);
          break;
        case "Job Work":
          //outward - job work - business obj, customer obj
          setObjBasedOnCases(1);
          break;
        case "SKD/CKD/Lots":
          //outward - SKDS/CKD/lots - business obj, customer obj
          setObjBasedOnCases(1);
          break;
        case "Recipient Not Known":
          //outward - Recepient not known - business obj, business obj
          setObjBasedOnCases(3);
          break;
        case "For Own Use":
          //outward - for own use - business obj, business obj
          setObjBasedOnCases(3);
          break;
        case "Exhibition or Fairs":
          //outward - exhibition - business obj, business obj
          setObjBasedOnCases(3);
          break;
        case "Line Sales":
          //outward - line sales - business obj, business obj
          setObjBasedOnCases(3);
          break;
        case "Others":
          //outward - others - business obj, customer obj,
          setObjBasedOnCases(1);
          break;
        default:
          setObjBasedOnCases(1);
      }
    } else if (supplyType === "Inward") {
      switch (subType) {
        case "Supply":
          //inward - supply - customer obj, business obj,
          setObjBasedOnCases(4);
          break;
        case "Import":
          //inward - imports - (customer obj, gstin-urp, state - other countries), business obj
          setObjBasedOnCases(5);
          break;
        case "SKD/CKD/Lots":
          //inward - SKDS/CKD/lots - customer obj, business obj
          setObjBasedOnCases(4);
          break;
        case "Job work Returns":
          //inward - job work returns - customer obj, business obj
          setObjBasedOnCases(4);
          break;
        case "Sales Return":
          //inward - sales return - customer obj, business obj
          setObjBasedOnCases(4);
          break;
        case "Exhibition or Fairs":
          //inward - exhibition - business obj, business obj
          setObjBasedOnCases(3);
          break;
        case "For Own Use":
          //inward - for own use - business obj, business obj
          setObjBasedOnCases(3);
          break;
        case "Others":
          //inward - others - customer obj, business obj
          setObjBasedOnCases(4);
          break;
        default:
          setObjBasedOnCases(4);
      }
    }

    setBillFrom(updatedBillFrom);
    setDispatchFrom(updatedDispatchFrom);
    setBillTo(updatedBillTo);
    setShipTo(updatedShipTo);
  };

  //side effect to updated products based on dispatchFrom and shipTo data - delay because on initial render products are empty.
  useEffect(() => {
    const handleSwitchTaxRate = () => {
      // if (products[0]?.productName?.length <= 0) {
      //   return;
      // }

      setProducts((prevState) => {
        const updatedProducts = prevState.map((prevItem) => {
          const taxRate = prevItem.igstRate !== 0 ? prevItem.igstRate : prevItem.cgstSgstRate;

          console.log(taxRate, "this is the tax rate here");
          if (dispatchFrom?.state === shipTo?.state) {
            return { ...prevItem, cgstSgstRate: taxRate, igstRate: 0 };
          } else {
            return { ...prevItem, cgstSgstRate: 0, igstRate: taxRate };
          }
        });
        return updatedProducts;
      });
    };

    setTimeout(() => {
      handleSwitchTaxRate();
    }, 5000);

    //eslint-disable-next-line
  }, [dispatchFrom?.state, shipTo?.state]);

  //Side effect to run based on subType and supply type change
  useEffect(() => {
    //Guard to only run when these values are present
    if (subType && supplyType && customerObj && businessObj) {
      handlePrefillTransactionDetails();
    }
    //eslint-disable-next-line
  }, [subType, supplyType, customerObj, businessObj]);

  const handleInputChange = (e, setState, field) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      [field]: value,
    }));

    //If input change is pincode then search state
    if (field === "pincode" && e.target.value.length > 0) {
      debouncedPincodeSearch(e, setState);
    }
  };

  const indianStates = [
    "-State-",
    "ANDAMAN AND NICOBAR",
    "ANDHRA PRADESH",
    "ARUNACHAL PRADESH",
    "ASSAM",
    "BIHAR",
    "CHANDIGARH",
    "CHHATTISGARH",
    "DADRA AND NAGAR HAVELI & DAMAN AND DIU",
    "DELHI",
    "GOA",
    "GUJARAT",
    "HARYANA",
    "HIMACHAL PRADESH",
    "JAMMU AND KASHMIR",
    "JHARKHAND",
    "KARNATAKA",
    "KERALA",
    "LADAKH",
    "LAKSHYADEEP",
    "MADHYA PRADESH",
    "MAHARASHTRA",
    "MANIPUR",
    "MEGHALAYA",
    "MIZORAM",
    "NAGALAND",
    "ODISHA",
    "OTHER COUNTRIES",
    "Other Territory",
    "PUDUCHERRY",
    "PUNJAB",
    "RAJASTHAN",
    "SIKKIM",
    "TAMIL NADU",
    "TELANGANA",
    "TRIPURA",
    "UTTAR PRADESH",
    "UTTARAKHAND",
    "WEST BENGAL",
  ];

  const stateCodeMapper = (state) => {
    const stateCodes = {
      "ANDAMAN AND NICOBAR": "35",
      "ANDHRA PRADESH": "37",
      "ARUNACHAL PRADESH": "12",
      ASSAM: "18",
      BIHAR: "10",
      CHANDIGARH: "04",
      CHHATTISGARH: "22",
      "DADRA AND NAGAR HAVELI & DAMAN AND DIU": "26",
      DELHI: "07",
      GOA: "30",
      GUJARAT: "24",
      HARYANA: "06",
      "HIMACHAL PRADESH": "02",
      "JAMMU AND KASHMIR": "01",
      JHARKHAND: "20",
      KARNATAKA: "29",
      KERALA: "32",
      LADAKH: "38",
      LAKSHYADEEP: "31",
      "MADHYA PRADESH": "23",
      MAHARASHTRA: "27",
      MANIPUR: "14",
      MEGHALAYA: "17",
      MIZORAM: "15",
      NAGALAND: "13",
      ODISHA: "21",
      "OTHER COUNTRIES": "99",
      "Other Territory": "97",
      PUDUCHERRY: "34",
      PUNJAB: "03",
      RAJASTHAN: "08",
      SIKKIM: "11",
      "TAMIL NADU": "33",
      TELANGANA: "36",
      TRIPURA: "16",
      "UTTAR PRADESH": "09",
      UTTARAKHAND: "05",
      "WEST BENGAL": "19",
    };

    return parseInt(stateCodes[state]) || null;
  };

  //Pincode search (debounced as debouncedPincodeSearch) - being triggered from handleInputChange function
  const pincodeSearch = async (e, setState) => {
    let state = null;

    try {
      const response = await dispatch(searchZipcode(e.target.value));
      state = response.payload.state;
    } catch (err) {
      toast.error("Some err ocurred while searching state based on zipcode!");
    }

    if (state) {
      if (!indianStates.includes(state?.toUpperCase())) {
        toast.error("State not found based on entered pincode! please select state manually.");
      }

      setState((prevState) => {
        return { ...prevState, state: state.toUpperCase() };
      });
    }
  };

  //Custom react hook to debounce a function - use somewhere else
  const useDebounce = (func, delay) => {
    const timeoutRef = useRef(null);

    const debounced = (...args) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        func(...args);
      }, delay);
    };

    return debounced;
  };

  const debouncedPincodeSearch = useDebounce(pincodeSearch, 1000);

  //Product section related operations
  const [products, setProducts] = useState([
    {
      productName: "",
      description: "",
      hsn: "",
      quantity: "",
      unit: "",
      value: "",
      cgstSgstRate: "",
      igstRate: "",
      cessAdvolRate: "",
      cessNonAdvolRate: "",
    },
  ]);

  const addItem = () => {
    setProducts([
      ...products,
      {
        productName: "",
        description: "",
        hsn: "",
        quantity: "",
        unit: "",
        value: "",
        cgstSgstRate: "",
        igstRate: "",
        cessAdvolRate: "",
        cessNonAdvolRate: "",
      },
    ]);
  };

  //Side effect to populate Items based on Invoice Id
  useEffect(() => {
    if (!invoiceId) {
      return;
    }

    const populateDocNoFromInvoiceDetails = (response) => {
      try {
        const invoiceCounter = response.payload.invoice_counter;
        if (invoiceCounter.length > 0) {
          setDocumentNo(invoiceCounter);
        }
      } catch (err) {
        toast.error("Some error occured while fetching data from invoice!");
      }
    };

    const populateProductsFromInvoiceDetailsAsync = async (response) => {
      try {
        const invoiceItems = response.payload.sales_invoice_items;

        //Filter invoiceItems and remove services, keep only goods.
        const filteredInvoiceItems = invoiceItems.filter((item) => item.product.is_service === "N");

        const populatedProducts = await handleInvoiceItemConversionAsync(filteredInvoiceItems);
        setProducts(populatedProducts);
      } catch (err) {
        toast.error("Some error occured while fetching data from invoice!");
      }
    };

    const handleInvoiceItemConversionAsync = async (invoiceItems) => {
      //Map over invoiceItems and create suitable product object.
      const updatedProducts = await Promise.all(
        invoiceItems.map(async (item) => {
          try {
            const response = await dispatch(
              fetchBatchesByProductID({
                productId: item?.product.id,
                filters: `?batch_id=${item?.batch}`,
              })
            );
            //Combination of data extracted from item and api call
            const product_name = item.product.product_name;
            const unit = item.product.package;
            const hsn_number = item.product.hsn_number;
            const quantity = item.quantity;
            const taxableAmount = item.assessable_amount;
            const taxPercent = response?.payload?.batchData?.data[0]?.tax;

            const productObject = {
              productName: product_name,
              unit: unit,
              description: "",
              hsn: hsn_number,
              quantity: quantity,
              value: taxableAmount,
              cgstSgstRate: taxPercent,
              igstRate: 0,
              cessAdvolRate: "",
              cessNonAdvolRate: "",
            };

            return productObject;
          } catch (err) {
            //if some error occurs return empty object
            return {
              productName: "",
              description: "",
              hsn: "",
              quantity: "",
              unit: "",
              value: "",
              cgstSgstRate: "",
              igstRate: "",
              cessAdvolRate: "",
              cessNonAdvolRate: "",
            };
          }
        })
      );

      return updatedProducts;
    };

    const fetchInvoiceDetailsAsync = async () => {
      try {
        const response = await dispatch(getCompleteInvoiceDetailsById(invoiceId));
        populateProductsFromInvoiceDetailsAsync(response);
        populateDocNoFromInvoiceDetails(response);
      } catch (err) {
        toast.error("some error occurred while fetching invoice details");
      }
    };

    fetchInvoiceDetailsAsync();

    //eslint-disable-next-line
  }, [invoiceId]);

  const removeItem = (index) => {
    const newProducts = [...products];
    newProducts.splice(index, 1);
    setProducts(newProducts);
  };

  const handleProductInputChange = (e, index, field) => {
    const newProducts = [...products];
    newProducts[index][field] = e.target.value;
    setProducts(newProducts);
  };

  // Dropdown options for CESS Advol Rate
  const cessAdvolOptions = [
    "-Select-",
    0,
    1,
    3,
    5,
    11,
    12,
    12.5,
    15,
    17,
    20,
    21,
    22,
    36,
    49,
    60,
    61,
    65,
    71,
    72,
    89,
    96,
    142,
    160,
    204,
    290,
    "-Not Appl-",
  ];

  // Dropdown options for CESS Non-Advol Rate
  const cessNonAdvolOptions = ["-Select-", 0, 400, 2076, 2747, 3668, 4006, 4170, "-Not Appl-"];

  //Calculated totals based on products selected
  const [totals, setTotals] = useState({
    taxableAmount: "",
    cgstAmount: "",
    sgstAmount: "",
    igstAmount: "",
    cessAdvolAmount: "",
    cessNonAdvolAmount: "",
    otherAmount: "",
    totalInvoiceAmount: "",
  });

  const calculateTotals = (products, otherAmount) => {
    return products.reduce(
      (acc, product) => {
        const value = parseFloat(product.value) || 0;
        const cgstSgstRate = parseFloat(product.cgstSgstRate) || 0;
        const igstRate = parseFloat(product.igstRate) || 0;
        const cessAdvolRate = parseFloat(product.cessAdvolRate) || 0;
        // const cessNonAdvolRate = // TODO: Calculation logic

        // Calculate totals
        acc.taxableAmount += value;
        acc.cgstAmount += (value * cgstSgstRate) / 200;
        acc.sgstAmount += (value * cgstSgstRate) / 200;
        acc.igstAmount += (value * igstRate) / 100;
        acc.cessAdvolAmount += (value * cessAdvolRate) / 100;

        return acc;
      },
      {
        taxableAmount: 0,
        cgstAmount: 0,
        sgstAmount: 0,
        igstAmount: 0,
        cessAdvolAmount: 0,
        cessNonAdvolAmount: 0, // TODO: Cess Non-Advol Rate calculation logic
        totalInvoiceAmount: 0, // To be calculated later
        otherAmount: otherAmount,
      }
    );
  };

  useEffect(() => {
    const otherAmount = parseFloat(totals.otherAmount) || 0;
    const totalsCalculated = calculateTotals(products, otherAmount);

    // Calculate totalInvoiceAmount considering otherAmount
    totalsCalculated.totalInvoiceAmount =
      totalsCalculated.taxableAmount +
      totalsCalculated.cgstAmount +
      totalsCalculated.sgstAmount +
      totalsCalculated.igstAmount +
      totalsCalculated.cessAdvolAmount +
      otherAmount; // otherAmount can be negative or positive

    setTotals(totalsCalculated);
  }, [products, totals.otherAmount]);

  // Handle input change
  const handleTotalsInputChange = (e, field) => {
    setTotals({
      ...totals,
      [field]: e.target.value,
    });
  };

  //Transport related operations
  const [transporterDetails, setTransporterDetails] = useState({
    transporterID: "",
    transporterName: "",
    approxDistance: "",
  });

  // Handler function to update the transporterDetails object
  const handleTransporterDetailsChange = (e) => {
    const { name, value } = e.target;
    setTransporterDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  //Part B - operations and fields
  const [mode, setMode] = useState("Road"); // Default mode
  const [vehicleNo, setVehicleNo] = useState("");
  const [docNumberPartB, setDocNumberPartB] = useState(""); // Renamed to docNumberPartB
  const [docDatePartB, setDocDatePartB] = useState(todayDate); // Renamed to docDatePartB
  const [vehicleType, setVehicleType] = useState("Regular"); // Default vehicle type for road

  //Mapper function to generate payload equilent value
  const vehicleTypeMapper = (vehicleType) => {
    const vehicleTypes = { Regular: "R", "Over Dimensional Cargo": "O" };

    // Return the corresponding payload value or a default if not found
    return vehicleTypes[vehicleType] || null;
  };

  //Mapper function to generate payload equilent value
  const transportModeMapper = (mode) => {
    const transportModes = ["Road", "Rail", "Air", "Ship or Ship Cum Road/Rail"];

    const index = transportModes.indexOf(mode);
    return index !== -1 ? index + 1 + "" : null;
  };

  const handleModeChange = (e) => {
    setMode(e.target.value);
    // Reset fields when mode changes

    // Reset vehicle type to "Over Dimensional Cargo" if Ship is selected
    if (e.target.value === "Ship or Ship Cum Road/Rail") {
      setVehicleType("Over Dimensional Cargo");
    } else {
      setVehicleType("Regular");
    }

    //Based on mode selected change transporter name similar to government portal
    setTransporterDetails((prev) => {
      if (e.target.value === "Road") {
        return prev;
      } else {
        return { ...prev, transporterName: e.target.value };
      }
    });

    setVehicleNo("");
    setDocNumberPartB("");
    setDocDatePartB(todayDate);
  };

  //user ip address:
  const [ipAddress, setIpAdderess] = useState(null);

  // Fetch user's IP address
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        if (response.data.ip) {
          setIpAdderess(response.data.ip);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIPAddress();
  }, []);

  //Generate payload from micro states
  const payloadGenerator = () => {
    const convertDate = (dateString) => {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
    };

    //Function to handle those value which are supposed to be number but can be an empty string
    const finStringHandler = (value) => {
      if (!value || value.length === 0) {
        return 0;
      }

      if (isNaN(parseFloat(value))) {
        return 0;
      }

      return parseFloat(value);
    };

    //convert products into data structure api is going to accept
    const itemsList = products.map((product) => {
      return {
        productName: product.productName,
        productDesc: product.description,
        hsnCode: finStringHandler(product.hsn),
        quantity: finStringHandler(product.quantity),
        qtyUnit: product.unit,
        cgstRate: finStringHandler(product.cgstSgstRate) / 2,
        sgstRate: finStringHandler(product.cgstSgstRate) / 2,
        igstRate: finStringHandler(product.igstRate),
        cessRate: finStringHandler(product.cessAdvolRate),
        cessNonadvol: finStringHandler(product.cessNonAdvolRate),
        taxableAmount: finStringHandler(product.value),
      };
    });

    try {
      const eBillData = {
        supplyType: supplyTypeMapper(supplyType),
        subSupplyType: subSupplyTypeMapper(subType),
        subSupplyDesc: otherSubType,
        docType: documentTypeMapper(documentType),
        docNo: documentNo,
        docDate: convertDate(documentDate),
        fromGstin: billFrom.gstin,
        fromTrdName: billFrom.name,
        fromAddr1: dispatchFrom.address1,
        fromAddr2: dispatchFrom.address2,
        fromPlace: dispatchFrom.place,
        fromPincode: parseInt(dispatchFrom.pincode),
        actFromStateCode: stateCodeMapper(dispatchFrom.state),
        fromStateCode: stateCodeMapper(billFrom.state),
        toGstin: billTo.gstin,
        toTrdName: billTo.name,
        toAddr1: shipTo.address1,
        toAddr2: shipTo.address2,
        toPlace: shipTo.place,
        toPincode: parseInt(shipTo.pincode),
        actToStateCode: stateCodeMapper(shipTo.state),
        toStateCode: stateCodeMapper(billTo.state),
        transactionType: transactionTypeMapper(transactionType),
        otherValue: finStringHandler(totals.otherAmount),
        totalValue: finStringHandler(totals.taxableAmount),
        cgstValue: finStringHandler(totals.cgstAmount),
        sgstValue: finStringHandler(totals.sgstAmount),
        igstValue: finStringHandler(totals.igstAmount),
        cessValue: finStringHandler(totals.cessAdvolAmount),
        cessNonAdvolValue: finStringHandler(totals.cessNonAdvolAmount),
        totInvValue: finStringHandler(totals.totalInvoiceAmount),
        transporterId: transporterDetails.transporterID.length > 0 ? transporterDetails.transporterID : undefined,
        transporterName: transporterDetails.transporterName,
        transDocNo: docNumberPartB,
        transMode: transportModeMapper(mode),
        transDistance: transporterDetails.approxDistance,
        transDocDate: convertDate(docDatePartB),
        vehicleNo: vehicleNo,
        vehicleType: vehicleTypeMapper(vehicleType),
        itemList: itemsList,
      };

      if (!ipAddress) {
        return null;
      }

      const payload = {
        ip_address: ipAddress,
        ewaybill_data: eBillData,
      };

      return payload;
    } catch (err) {
      return null;
    }
  };

  function validatePayload(payload) {
    // Required fields
    const requiredFields = [
      "supplyType",
      "subSupplyType",
      "docType",
      "docNo",
      "docDate",
      "fromGstin",
      "fromPincode",
      "fromStateCode",
      "toGstin",
      "toPincode",
      "toStateCode",
      "transDistance",
      "itemList",
      "actToStateCode",
      "actFromStateCode",
      "totInvValue",
      "transactionType",
    ];

    // Validations based on the schema
    const validations = {
      supplyType: (val) => ["O", "I"].includes(val),
      subSupplyType: (val) => typeof val === "string", // Check for a number
      docType: (val) => ["INV", "CHL", "BIL", "BOE", "OTH"].includes(val),
      docNo: (val) => typeof val === "string" && val.length <= 16,
      docDate: (val) => /^\d{2}\/\d{2}\/\d{4}$/.test(val),
      fromGstin: (val) => /^[0-9]{2}[0-9A-Z]{13}$/.test(val) || val === "URP", // Allow "URP" or valid GSTIN
      fromPincode: (val) => val >= 100000 && val <= 999999,
      fromStateCode: (val) => Number.isInteger(val) && val <= 99,
      toGstin: (val) => /^[0-9]{2}[0-9A-Z]{13}$/.test(val) || val === "URP", // Allow "URP" or valid GSTIN
      toPincode: (val) => val >= 100000 && val <= 999999,
      toStateCode: (val) => Number.isInteger(val) && val <= 99,
      transDistance: (val) => typeof val === "string" && val.length > 0,
      actToStateCode: (val) => Number.isInteger(val) && val <= 99,
      actFromStateCode: (val) => Number.isInteger(val) && val <= 99,
      totInvValue: (val) => typeof val === "number" && val > 0,
      transactionType: (val) => Number.isInteger(val) && val >= 1 && val <= 4,
      itemList: (val) => Array.isArray(val) && val.length > 0,
    };

    // Check each required field
    for (const field of requiredFields) {
      const value = payload[field];

      // If the field is missing
      if (value === undefined || value === null || value === "") {
        toast.error(`${field} cannot be empty`);
        return false;
      }

      // If the field has specific validation
      if (validations[field] && !validations[field](value)) {
        toast.error(`Invalid value for ${field}`);
        return false;
      }
    }

    // Additional validation for itemList array
    for (const item of payload.itemList) {
      if (!item.hsnCode || typeof item.hsnCode !== "number") {
        toast.error("HSN Code is required for each item and must be a number");
        return false;
      }
      if (!item.taxableAmount || typeof item.taxableAmount !== "number") {
        toast.error("Taxable Amount is required for each item and must be a number");
        return false;
      }
    }

    return true; // All validations passed
  }

  //Submission and exit button operations
  const handleSubmitForm = () => {
    const payload = payloadGenerator();

    if (!payload) {
      toast.error("Something went wrong try again!");
      return;
    }

    if (!validatePayload(payload.ewaybill_data)) {
      return;
    }

    dispatch(generateEWayBill(payload))
      .then((action) => {
        if (action?.payload) {
          toast.success("E-Way Bill Created Successfully!");
          navigate(-1);
        } else {
          toast.error("something went wrong try again!");
        }
      })
      .catch((err) => {
        toast.error("something went wrong try again!");
      });
  };

  const handleExitForm = () => {
    alert("Handle Exit form was clicked");
  };

  //Sections req - Transaction details, shipping details, item details, transportation details, Part-B
  return (
    <>
      <div className=" px-[20px] w-full flex justify-between items-center py-[20px] overflow-y-scroll">
        <h1 className="text-2xl font-bold"> New E-Way Bill</h1>
        <span className="block  text-right">
          [
          <TextWithDot text="" textClasses="" dotColor="red" />
          ...indicates mandatory fields for E-Way Bill and <TextWithDot text="" textClasses="" dotColor="black" />
          ...indicates mandatory fields for GSTR-1]
        </span>
      </div>
      {/* Transaction Details*/}
      <section>
        <h3 className="py-[10px] px-[20px] text-xl bg-stone-900 text-white">Transaction Details</h3>
        {/* Supply Type and SubType */}
        <div className="flex flex-row items-center justify-between border border-b-2 border-neutral-200">
          {/* Supply Type Label and Radio Buttons */}
          <div className="flex items-center">
            <label className="mr-2 px-[20px] py-[10px] bg-neutral-200">
              <TextWithDot text="Supply Type" textClasses="" dotColor="red" />
            </label>
            <div className="flex space-x-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="supplyType"
                  value="Outward"
                  checked={supplyType === "Outward"}
                  onChange={handleSupplyTypeChange}
                  className="mr-1"
                />
                Outward
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  name="supplyType"
                  value="Inward"
                  checked={supplyType === "Inward"}
                  onChange={handleSupplyTypeChange}
                  className="mr-1"
                />
                Inward
              </label>
            </div>
          </div>

          {/* SubType Label and Radio Buttons */}
          <div className="flex items-center mr-[10px]">
            <label className="mr-2 px-[20px] py-[10px] bg-neutral-200">
              <TextWithDot text="SubType" textClasses="" dotColor="red" />
            </label>
            <div className="flex gap-4 pr-[10px]">
              {subTypeMenu.map((type) => (
                <label key={type} className="flex items-center">
                  <input
                    type="radio"
                    name="subType"
                    value={type}
                    checked={subType === type}
                    onChange={handleSubTypeChange}
                    className="mr-1"
                  />
                  {type}
                </label>
              ))}
            </div>
            {/* On subtype other give field to enter the other subtype */}
            {subType === "Others" && (
              <input
                type="text"
                placeholder="please specify..."
                className="border px-[10px] py-[8px] max-w-[8vw]"
                value={otherSubType}
                onChange={(e) => handleOtherSubType(e)}
                maxLength={20}
              />
            )}
          </div>
        </div>
        {/* Document Type, Document No., Document Date, Transaction Type */}
        <div className="flex flex-row items-center justify-between gap-4 px-[20px] py-[5px] border-b-2 border-neutral-200">
          {/* Document Type Dropdown */}
          <div className="flex items-center">
            <label className="mr-2">
              <TextWithDot text="Document Type:" textClasses="" dotColor="red" />
            </label>
            <select
              value={documentType}
              onChange={handleDocumentTypeChange}
              className="border border-gray-300 px-[20px] py-[10px] bg-white"
            >
              {documentTypeMenu.map((type) => (
                <option value={type} key={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          {/* Document No Textfield */}
          <div className="flex items-center">
            <label className="mr-2">
              <TextWithDot text="Document No:" textClasses="" dotColor="red" />
            </label>
            <input
              type="text"
              value={documentNo}
              onChange={handleDocumentNoChange}
              className="border border-gray-300 p-2 rounded"
              placeholder="Enter Document No."
              maxLength={16}
            />
          </div>

          {/* Document Date */}
          <div className="flex items-center">
            <label className="mr-2">
              <TextWithDot text="Document Date:" textClasses="" dotColor="red" />
            </label>
            <input
              type="date"
              value={documentDate}
              onChange={handleDocumentDateChange}
              max={todayDate}
              className="border border-gray-300 px-[20px] py-[10px] bg-white"
            />
          </div>

          {/* Transaction Type Dropdown */}
          <div className="flex items-center">
            <label className="mr-2">
              <TextWithDot text="Transaction Type" textClasses="" dotColor="red" />
            </label>
            <select
              value={transactionType}
              onChange={handleTransactionTypeChange}
              className="border border-gray-300 px-[20px] py-[10px] bg-white"
            >
              <option value="Regular">Regular</option>
              <option value="Bill To - Ship To">Bill To - Ship To</option>
              <option value="Bill From - Dispatch From">Bill From - Dispatch From</option>
              <option value="Combination of 2 and 3">Combination of 2 and 3</option>
            </select>
          </div>
        </div>
      </section>

      {/* Bill From, Dispatch From, Bill To, Ship To Section */}
      <section>
        <div className="flex flex-col space-y-4 p-[20px] border-b-2">
          {/* First Div: Bill From and Dispatch From */}
          <div className="flex flex-row gap-8">
            {/* Bill From Section */}
            <div className="flex flex-col space-y-2 w-1/2">
              <h3 className="font-semibold px-[20px] py-[10px] text-center bg-neutral-200">Bill From</h3>
              <div className="flex gap-[10px] items-center">
                <label className="w-[65px]">Name:</label>
                <input
                  type="text"
                  value={billFrom.name}
                  onChange={(e) => handleInputChange(e, setBillFrom, "name")}
                  className="border border-gray-300 p-2 rounded w-full"
                  maxLength={100}
                />
              </div>
              <div className="flex gap-[10px] items-center">
                <label className="w-[65px]">
                  <TextWithDot text="GSTIN:" textClasses="" dotColor="red" />
                </label>
                <input
                  type="text"
                  value={billFrom.gstin}
                  onChange={(e) => handleInputChange(e, setBillFrom, "gstin")}
                  className="border border-gray-300 p-2 rounded w-full"
                  minLength={15}
                  maxLength={15}
                />
              </div>
              <div className="flex gap-[10px] items-center">
                <label className="w-[65px]">
                  <TextWithDot text="State:" textClasses="" dotColor="red" />
                </label>
                <select
                  value={billFrom.state}
                  onChange={(e) => handleInputChange(e, setBillFrom, "state")}
                  className="border border-gray-300 p-2 rounded w-full bg-white"
                >
                  {indianStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Dispatch From Section */}
            <div className="flex flex-col space-y-2 w-1/2">
              <h3 className="font-semibold px-[20px] py-[10px] text-center bg-neutral-200">Dispatch From</h3>
              <div className="flex space-x-2">
                <div className="w-full flex gap-[10px] items-center">
                  <label className="w-[65px]">Address:</label>
                  <div className="flex gap-[10px] w-full">
                    <input
                      type="text"
                      value={dispatchFrom.address1}
                      onChange={(e) => handleInputChange(e, setDispatchFrom, "address1")}
                      className="border border-gray-300 p-2 rounded w-full"
                      maxLength={120}
                    />
                    <input
                      type="text"
                      value={dispatchFrom.address2}
                      onChange={(e) => handleInputChange(e, setDispatchFrom, "address2")}
                      className="border border-gray-300 p-2 rounded w-full"
                      maxLength={120}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-[10px] items-center">
                <label className="w-[65px]">Place:</label>
                <input
                  type="text"
                  value={dispatchFrom.place}
                  onChange={(e) => handleInputChange(e, setDispatchFrom, "place")}
                  className="border border-gray-300 p-2 rounded w-full"
                  maxLength={50}
                />
              </div>
              <div className="flex space-x-2">
                <div className="w-full flex gap-[10px] items-center">
                  <label className="w-[65px]">
                    <TextWithDot text="Pincode:" textClasses="" dotColor="red" />
                  </label>
                  <div className="flex gap-[10px] w-full">
                    <input
                      type="number"
                      value={dispatchFrom.pincode}
                      onChange={(e) => handleInputChange(e, setDispatchFrom, "pincode")}
                      className="border border-gray-300 p-2 rounded w-full"
                    />
                    <select
                      value={dispatchFrom.state}
                      onChange={(e) => handleInputChange(e, setDispatchFrom, "state")}
                      className="border border-gray-300 p-2 rounded w-full bg-white"
                    >
                      {indianStates.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Second Div: Bill To and Ship To */}
          <div className="flex flex-row gap-8">
            {/* Bill To Section */}
            <div className="flex flex-col space-y-2 w-1/2">
              <h3 className="font-semibold px-[20px] py-[10px] text-center bg-neutral-200">Bill To</h3>
              <div className="flex gap-[10px] items-center">
                <label className="w-[65px]">Name:</label>
                <input
                  type="text"
                  value={billTo.name}
                  onChange={(e) => handleInputChange(e, setBillTo, "name")}
                  className="border border-gray-300 p-2 rounded w-full"
                  maxLength={100}
                />
              </div>
              <div className="flex gap-[10px] items-center">
                <label className="w-[65px]">
                  <TextWithDot text="GSTIN:" textClasses="" dotColor="red" />
                </label>
                <input
                  type="text"
                  value={billTo.gstin}
                  onChange={(e) => handleInputChange(e, setBillTo, "gstin")}
                  className="border border-gray-300 p-2 rounded w-full"
                  minLength={15}
                  maxLength={15}
                />
              </div>
              <div className="flex gap-[10px] items-center">
                <label className="w-[65px]">
                  <TextWithDot text="State:" textClasses="" dotColor="red" />
                </label>
                <select
                  value={billTo.state}
                  onChange={(e) => handleInputChange(e, setBillTo, "state")}
                  className="border border-gray-300 p-2 rounded w-full bg-white"
                >
                  {indianStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* Ship To Section */}
            <div className="flex flex-col space-y-2 w-1/2">
              <h3 className="font-semibold px-[20px] py-[10px] text-center bg-neutral-200">Ship To</h3>
              <div className="flex space-x-2">
                <div className="w-full flex gap-[10px] items-center">
                  <label className="w-[65px]">Address:</label>
                  <div className="flex gap-[10px] w-full">
                    <input
                      type="text"
                      value={shipTo.address1}
                      onChange={(e) => handleInputChange(e, setShipTo, "address1")}
                      className="border border-gray-300 p-2 rounded w-full"
                      maxLength={120}
                    />
                    <input
                      type="text"
                      value={shipTo.address2}
                      onChange={(e) => handleInputChange(e, setShipTo, "address2")}
                      className="border border-gray-300 p-2 rounded w-full"
                      maxLength={120}
                    />
                  </div>
                </div>
              </div>
              <div className="flex gap-[10px] items-center">
                <label className="w-[65px]">Place:</label>
                <input
                  type="text"
                  value={shipTo.place}
                  onChange={(e) => handleInputChange(e, setShipTo, "place")}
                  className="border border-gray-300 p-2 rounded w-full"
                  maxLength={50}
                />
              </div>
              <div className="flex space-x-2">
                <div className="w-full flex gap-[10px] items-center">
                  <label className="w-[65px]">
                    <TextWithDot text="Pincode:" textClasses="" dotColor="red" />
                  </label>
                  <div className="flex gap-[10px] w-full">
                    <input
                      type="number"
                      value={shipTo.pincode}
                      onChange={(e) => handleInputChange(e, setShipTo, "pincode")}
                      className="border border-gray-300 p-2 rounded w-full"
                    />
                    <select
                      value={shipTo.state}
                      onChange={(e) => handleInputChange(e, setShipTo, "state")}
                      className="border border-gray-300 p-2 rounded w-full bg-white"
                    >
                      {indianStates.map((state) => (
                        <option key={state} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Item Details Section  */}
      <section>
        <h3 className="py-[10px] px-[20px] text-xl bg-stone-900 text-white">Item Details</h3>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="border px-4 py-2">Product Name</th>
              <th className="border px-4 py-2">Description</th>
              <th className="border px-4 py-2">
                <TextWithDot text="HSN" textClasses="" dotColor="red" />
              </th>
              <th className="border px-4 py-2">Quantity</th>
              <th className="border px-4 py-2">Unit</th>
              <th className="border px-4 py-2">
                <TextWithDot text="Value/Taxable Value (Rs.)" textClasses="" dotColor="red" />
              </th>
              <th className="border px-4 py-2">
                <TextWithDot text="CGST+SGST Rate (%)" textClasses="" dotColor="black" />
              </th>
              <th className="border px-4 py-2">
                <TextWithDot text="IGST Rate (%)" textClasses="" dotColor="black" />
              </th>
              <th className="border px-4 py-2">
                <TextWithDot text="CESS Advol Rate (%)" textClasses="" dotColor="black" />
              </th>
              <th className="border px-4 py-2">
                <TextWithDot text="CESS non.Advol. Rate" textClasses="" dotColor="black" />
              </th>
              <th className="border px-4 py-2">
                <button className="bg-black text-white px-4 py-1 rounded" onClick={addItem}>
                  <Plus />
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    value={product.productName}
                    onChange={(e) => handleProductInputChange(e, index, "productName")}
                    className="border p-2 w-full"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    value={product.description}
                    onChange={(e) => handleProductInputChange(e, index, "description")}
                    className="border p-2 w-full"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    value={product.hsn}
                    onChange={(e) => handleProductInputChange(e, index, "hsn")}
                    className="border p-2 w-full"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="number"
                    value={product.quantity}
                    onChange={(e) => handleProductInputChange(e, index, "quantity")}
                    className="border p-2 w-full"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="text"
                    value={product.unit}
                    onChange={(e) => handleProductInputChange(e, index, "unit")}
                    className="border p-2 w-full"
                    maxLength={3}
                    minLength={3}
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="number"
                    value={product.value}
                    onChange={(e) => handleProductInputChange(e, index, "value")}
                    className="border p-2 w-full"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="number"
                    value={product.cgstSgstRate}
                    onChange={(e) => handleProductInputChange(e, index, "cgstSgstRate")}
                    className="border p-2 w-full"
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    type="number"
                    value={product.igstRate}
                    onChange={(e) => handleProductInputChange(e, index, "igstRate")}
                    className="border p-2 w-full"
                  />
                </td>
                <td className="border px-4 py-2">
                  <select
                    value={product.cessAdvolRate}
                    onChange={(e) => handleProductInputChange(e, index, "cessAdvolRate")}
                    className="border p-2 w-full bg-white"
                  >
                    {cessAdvolOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="border px-4 py-2">
                  <select
                    value={product.cessNonAdvolRate}
                    onChange={(e) => handleProductInputChange(e, index, "cessNonAdvolRate")}
                    className="border p-2 w-full bg-white"
                  >
                    {cessNonAdvolOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="border px-4 py-2">
                  <button className="bg-red-500 text-white px-4 py-1 rounded" onClick={() => removeItem(index)}>
                    <Trash2 />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="my-[4px] border-b-2 border-neutral-200"></div>
        {/* Table for Total*/}
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="border px-4 py-2">
                <TextWithDot text="Total Tax'ble Amount" textClasses="" dotColor="black" />
              </th>
              <th className="border px-4 py-2">
                <TextWithDot text="CGST Amount" textClasses="" dotColor="black" />
              </th>
              <th className="border px-4 py-2">
                <TextWithDot text="SGST Amount" textClasses="" dotColor="black" />
              </th>
              <th className="border px-4 py-2">
                <TextWithDot text="IGST Amount" textClasses="" dotColor="black" />
              </th>
              <th className="border px-4 py-2">
                <TextWithDot text="CESS Advol Amount" textClasses="" dotColor="black" />
              </th>
              <th className="border px-4 py-2">
                <TextWithDot text="CESS Non Advol Amount" textClasses="" dotColor="black" />
              </th>
              <th className="border px-4 py-2">Other Amount (+/-)</th>
              <th className="border px-4 py-2">
                <TextWithDot text="Total Inv. Amount" textClasses="" dotColor="black" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">
                <input
                  type="number"
                  value={totals.taxableAmount}
                  onChange={(e) => handleTotalsInputChange(e, "taxableAmount")}
                  className="border p-2 w-full"
                />
              </td>
              <td className="border px-4 py-2">
                <input
                  type="number"
                  value={totals.cgstAmount}
                  onChange={(e) => handleTotalsInputChange(e, "cgstAmount")}
                  className="border p-2 w-full"
                />
              </td>
              <td className="border px-4 py-2">
                <input
                  type="number"
                  value={totals.sgstAmount}
                  onChange={(e) => handleTotalsInputChange(e, "sgstAmount")}
                  className="border p-2 w-full"
                />
              </td>
              <td className="border px-4 py-2">
                <input
                  type="number"
                  value={totals.igstAmount}
                  onChange={(e) => handleTotalsInputChange(e, "igstAmount")}
                  className="border p-2 w-full"
                />
              </td>
              <td className="border px-4 py-2">
                <input
                  type="number"
                  value={totals.cessAdvolAmount}
                  onChange={(e) => handleTotalsInputChange(e, "cessAdvolAmount")}
                  className="border p-2 w-full"
                />
              </td>
              <td className="border px-4 py-2">
                <input
                  type="number"
                  value={totals.cessNonAdvolAmount}
                  onChange={(e) => handleTotalsInputChange(e, "cessNonAdvolAmount")}
                  className="border p-2 w-full"
                />
              </td>
              <td className="border px-4 py-2">
                <input
                  type="number"
                  value={totals.otherAmount}
                  onChange={(e) => handleTotalsInputChange(e, "otherAmount")}
                  className="border p-2 w-full"
                />
              </td>
              <td className="border px-4 py-2">
                <input
                  type="number"
                  value={totals.totalInvoiceAmount}
                  onChange={(e) => handleTotalsInputChange(e, "totalInvoiceAmount")}
                  className="border p-2 w-full"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </section>

      {/* transportation details*/}
      <section>
        <h3 className="py-[10px] px-[20px] text-xl bg-stone-900 text-white">Transportation Details</h3>
        <div className="flex flex-row items-center justify-between gap-4 px-[20px] py-[20px] border-b-2 border-neutral-200">
          {/* Transporter ID */}
          <div className="flex items-center">
            <label className="mr-2">Transporter ID:</label>
            <input
              type="text"
              name="transporterID"
              value={transporterDetails.transporterID}
              onChange={handleTransporterDetailsChange}
              className="border border-gray-300 p-2 rounded"
              placeholder="Enter Transporter ID"
            />
          </div>

          {/* Transporter Name */}
          <div className="flex items-center">
            <label className="mr-2">Transporter Name:</label>
            <input
              type="text"
              name="transporterName"
              value={transporterDetails.transporterName}
              onChange={handleTransporterDetailsChange}
              className="border border-gray-300 p-2 rounded"
              placeholder="Enter Transporter Name"
            />
          </div>

          {/* Auto Calculated PIN to PIN (in KM) */}
          {/* <div className="flex items-center"> */}
          {/* <label className="mr-2">Auto Calculated PIN to PIN (in KM):</label> */}
          {/* <span className="text-gray-600">30</span> Static Text */}
          {/* </div> */}

          {/* Approximate Distance (in KM) */}
          <div className="flex items-center">
            <label className="mr-2">
              <TextWithDot text="Approximate Distance (in KM):" textClasses="" dotColor="red" />
            </label>
            <input
              type="text"
              name="approxDistance"
              value={transporterDetails.approxDistance}
              onChange={handleTransporterDetailsChange}
              className="border border-gray-300 p-2 rounded"
              placeholder="Enter Approx. Distance"
            />
          </div>
        </div>
      </section>

      {/* Part-B  */}
      <section>
        <h3 className="py-[10px] px-[20px] text-xl bg-stone-900 text-white">PART-B</h3>
        <div className="border-b-2 border-neutral-200">
          <div className="flex justify-between">
            {/* Mode */}
            <div className="flex items-center border border-neutral-200 flex-1 py-[10px] px-[20px]">
              <label className="mr-2">Mode:</label>
              <div className="flex gap-4">
                {["Road", "Rail", "Air", "Ship or Ship Cum Road/Rail"].map((option) => (
                  <label key={option} className="flex gap-[10px] justify-center">
                    <input
                      type="radio"
                      name="mode"
                      value={option}
                      checked={mode === option}
                      onChange={handleModeChange}
                    />
                    {option}
                  </label>
                ))}
              </div>
            </div>

            {/* Vehicle Type */}
            <div className="flex items-center border border-neutral-200 flex-1 py-[10px] px-[20px]">
              <label className="mr-2">Vehicle Type:</label>
              <div className="flex gap-4">
                <label className="flex gap-[10px]">
                  <input
                    type="radio"
                    name="vehicleType"
                    value="Regular"
                    checked={vehicleType === "Regular"}
                    disabled={mode !== "Road"}
                    onChange={(e) => setVehicleType(e.target.value)}
                  />
                  Regular
                </label>
                <label className="flex gap-[10px]">
                  <input
                    type="radio"
                    name="vehicleType"
                    value="Over Dimensional Cargo"
                    checked={vehicleType === "Over Dimensional Cargo"}
                    disabled={mode !== "Road" && mode !== "Ship/Ship Cum Road/Rail"}
                    onChange={(e) => setVehicleType(e.target.value)}
                  />
                  Over Dimensional Cargo
                </label>
              </div>
            </div>
          </div>

          <div className="flex justify-between">
            {/* Vehicle No. */}
            <div className="flex items-center border border-neutral-200 flex-1 py-[10px] px-[20px]">
              <label className="mr-2">Vehicle No:</label>
              <input
                type="text"
                value={vehicleNo}
                onChange={(e) => setVehicleNo(e.target.value)}
                disabled={mode !== "Road" && mode !== "Ship/Ship Cum Road/Rail"}
                className="border border-gray-300 p-2 rounded"
                placeholder="Enter Vehicle No"
                minLength={7}
                maxLength={15}
              />
            </div>

            {/* Document No. and Date (dynamic based on mode) */}
            <div className="flex items-center border border-neutral-200  flex-1 py-[10px] px-[20px]">
              {mode === "Road" && (
                <>
                  <label className="mr-2">Transporter Doc. No. & Date</label>
                  <input
                    type="text"
                    value={docNumberPartB}
                    onChange={(e) => setDocNumberPartB(e.target.value)}
                    className="border border-gray-300 p-2 rounded"
                    placeholder="Enter Doc. No"
                    maxLength={15}
                  />
                  <input
                    type="date"
                    value={docDatePartB}
                    onChange={(e) => setDocDatePartB(e.target.value)}
                    className="border border-gray-300 p-2 rounded ml-2"
                  />
                </>
              )}
              {mode === "Rail" && (
                <>
                  <label className="mr-2">RR No & Date</label>
                  <input
                    type="text"
                    value={docNumberPartB}
                    onChange={(e) => setDocNumberPartB(e.target.value)}
                    className="border border-gray-300 p-2 rounded"
                    placeholder="Enter RR No"
                    maxLength={15}
                  />
                  <input
                    type="date"
                    value={docDatePartB}
                    onChange={(e) => setDocDatePartB(e.target.value)}
                    className="border border-gray-300 p-2 rounded ml-2"
                  />
                </>
              )}
              {mode === "Air" && (
                <>
                  <label className="mr-2">Airway Bill No & Date</label>
                  <input
                    type="text"
                    value={docNumberPartB}
                    onChange={(e) => setDocNumberPartB(e.target.value)}
                    className="border border-gray-300 p-2 rounded"
                    placeholder="Enter Airway Bill No"
                    maxLength={15}
                  />
                  <input
                    type="date"
                    value={docDatePartB}
                    onChange={(e) => setDocDatePartB(e.target.value)}
                    className="border border-gray-300 p-2 rounded ml-2"
                  />
                </>
              )}
              {mode === "Ship or Ship Cum Road/Rail" && (
                <>
                  <label className="mr-2">Bill of Lading No & Date</label>
                  <input
                    type="text"
                    value={docNumberPartB}
                    onChange={(e) => setDocNumberPartB(e.target.value)}
                    className="border border-gray-300 p-2 rounded"
                    placeholder="Enter Bill of Lading No"
                    maxLength={15}
                  />
                  <input
                    type="date"
                    value={docDatePartB}
                    onChange={(e) => setDocDatePartB(e.target.value)}
                    className="border border-gray-300 p-2 rounded ml-2"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </section>

      <div className="py-[40px] flex justify-center items-center gap-[10px] mr-[4vw]">
        <button className="px-[20px] py-[10px] bg-black text-white rounded" onClick={() => handleSubmitForm()}>
          Submit Form
        </button>
        <button className="px-[20px] py-[10px] bg-red-500 text-white rounded" onClick={() => handleExitForm()}>
          Exit
        </button>
      </div>

      <div className="flex justify-center pb-[20px]">
        <span className="block max-w-[60vw] text-center text-neutral-500">
          <span className="font-semibold text-black">Note:</span> Railway Receipt numbers are validated as per the given
          formats. If you have RR number other than these formats then, please raise a ticket by mentioning the RR no.
          Presently, the validation is optional, but in future, invalid formats will not be allowed.
        </span>
      </div>
    </>
  );
}

export default CreateEwayBill;
