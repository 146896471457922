//Section height - 82vh
import React, { useEffect, useLayoutEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchBatchesByProductID, fetchMoreBatchesProductID } from "../../../reducers/Slices/productSlice";

import IconWrapper from "../../MicroComponents/IconWrapper";

import { Search } from "react-feather";
import { debounce } from "@mui/material";
import { humanDateFormat } from "../../../utils/dateFormat";
import { prettifyDigits } from "../../../utils/prettifyDigits";
import { fetchExpiredProducts, fetchMoreExpiredProducts } from "../../../reducers/Slices/expiryBreakageSlice";
import { setShowWaitLoader } from "../../../reducers/Slices/appSlice";

function Expiry() {
  const dispatch = useDispatch();
  const products = useSelector((state) => state.expiryBreakage.expiredProducts.data || []);
  const productsNext = useSelector((state) => state.expiryBreakage.expiredProducts.next);

  const batches = useSelector((state) => state.product.batchesByProductId.data);
  const batchesNext = useSelector((state) => state.product.batchesByProductId.next);

  const [hasMoreProducts, setHasMoreProducts] = useState(false);
  const [hasMoreBatches, setHasMoreBatches] = useState(() => (batchesNext ? true : false));

  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productSearchTerm, setProductSearchTerm] = useState("");

  //Use effect to fetch more products based on value of next
  useEffect(() => {
    if (productsNext) {
      setHasMoreProducts(true);
    } else {
      setHasMoreProducts(false);
    }
  }, [productsNext]);

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (batchesNext) {
      setHasMoreBatches(true);
    } else {
      setHasMoreBatches(false);
    }
  }, [batchesNext]);

  //fetch more products on scroll if available
  const fetchNextProducts = () => {
    if (productsNext) {
      dispatch(fetchMoreExpiredProducts(productsNext));
    }
  };

  //Function to set handle show products
  const handleShowProduct = (product) => {
    setSelectedProduct(product);
  };

  const debouncedProductSearch = debounce((value) => setProductSearchTerm(value), 1000);

  useEffect(() => {
    if (productSearchTerm.length > 0) {
      dispatch(setShowWaitLoader(true));
      dispatch(fetchExpiredProducts(`?product_search=${productSearchTerm}`)).then(() =>
        dispatch(setShowWaitLoader(false))
      );
    } else {
      dispatch(setShowWaitLoader(true));
      dispatch(fetchExpiredProducts()).then(() => dispatch(setShowWaitLoader(false)));
    }
    //eslint-disable-next-line
  }, [productSearchTerm]);

  // first render, product list change, search list change
  useEffect(() => {
    if (productSearchTerm?.length > 0) {
      if (products?.length > 0) {
        setSelectedProduct(products[0]);
      }
    } else {
      if (products?.length > 0) {
        setSelectedProduct(products[0]);
      }
    }
    //eslint-disable-next-line
  }, [productSearchTerm]);

  //Side effect to fetch batch based on product ID
  useEffect(() => {
    if (!selectedProduct?.id) {
      return;
    }
    dispatch(fetchBatchesByProductID({ productId: selectedProduct?.id, filters: `?is_expiry=1` }));
    //eslint-disable-next-line
  }, [selectedProduct]);

  const handleLoadMoreBatches = () => {
    if (batchesNext) {
      dispatch(fetchMoreBatchesProductID(batchesNext));
    }
  };

  const businessDetails = useSelector((state) => state.user);

  const [isGstUser, setIsGstUser] = useState(false);

  useLayoutEffect(() => {
    setIsGstUser(() => businessDetails?.gst_number && businessDetails?.gst_number?.length > 0);
  }, [businessDetails]);

  return (
    <div className="h-full w-ful flex">
      {/* Product List and Search  */}
      <div className="flex-1 max-w-[25vw] p-[20px] border-r-2 border-neutral-200">
        {/* Product Search  */}
        <div className="pb-[10px] flex">
          <input
            type="text"
            className="h-[44px] w-full rounded-full border-2 border-neutral-200 text-neutral-500 px-[15px]"
            placeholder="Search Products"
            onChange={(e) => debouncedProductSearch(e.target.value)}
          />
          <button className="-ml-[40px]">
            <IconWrapper>
              <Search className="z-10 text-white"></Search>
            </IconWrapper>
          </button>
        </div>

        {/* Product List */}
        <div>
          <InfiniteScroll
            dataLength={products.length}
            next={fetchNextProducts}
            hasMore={hasMoreProducts}
            height={"71vh"}
            loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
            endMessage={
              <p style={{ textAlign: "center", paddingTop: "10px" }}>
                <b>Products List End</b>
              </p>
            }
          >
            {products.map((item, index) => (
              <div
                key={index}
                onClick={() => handleShowProduct(item)}
                className={`${
                  selectedProduct?.id === item?.id ? "border-black" : ""
                } border-2 hover:border-blue-500 focus:border-black p-2 rounded-lg bg-white cursor-pointer shadow mb-2`}
              >
                <div className="relative flex text-sm items-center justify-between gap-2 pb-2 pl-2 ">
                  <div>
                    <h3 className="text-base font-semibold text-black">{item.product_name}</h3>
                    <h3 className="text-gray-500">{item.brand}</h3>
                  </div>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>

      {/* Product Details & Expiry Batch Table  */}
      <div className="flex-1 max-w-[75vw]">
        <div className="p-[20px] border-b-2 border-neutral-200">
          <h3 className="text-3xl">{selectedProduct ? selectedProduct?.product_name : "Select Product"}</h3>
        </div>

        {/* Expired Batch Table */}
        {
          <div>
            <h3 className="py-[20px] px-[20px] text-neutral-500 text-center">Expired Product Batch List</h3>
            <div className="overflow-y-scroll h-[55vh] max-h-[55vh] w-full px-[20px]">
              <InfiniteScroll
                dataLength={batches?.length}
                next={handleLoadMoreBatches}
                hasMore={hasMoreBatches}
                height={"54vh"}
                loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
                endMessage={
                  <p style={{ textAlign: "center", paddingTop: "10px" }}>
                    <b>Batches List End</b>
                  </p>
                }
              >
                <table className="table-auto w-full border-collapse">
                  <thead className="bg-gray-50 w-full">
                    <tr>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200">
                        Batch Number
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200">
                        Barcode Number
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200">
                        Quantity
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200">
                        Deal / Free
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200">
                        MRP Price
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200">
                        Rate
                      </th>
                      {isGstUser && (
                        <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200">
                          Tax %
                        </th>
                      )}
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200">
                        Warehouse Name
                      </th>
                      <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200">
                        Expiry Date
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {batches?.map((batch) => (
                      <tr key={batch.id}>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {batch.batch_number}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {batch?.bar_code_number || "Nil"}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {batch.remaining_quantity - batch.deal_quantity}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {batch.deal_quantity ?? 0}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {prettifyDigits(batch.mrp_price)}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {prettifyDigits(batch?.sales_price)}
                        </td>
                        {isGstUser && (
                          <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                            {batch.tax}
                          </td>
                        )}
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {batch?.warehouse?.name || "Not selected"}
                        </td>
                        <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                          {humanDateFormat(batch.expiry_date)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default Expiry;
