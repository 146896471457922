import React, { useState, useEffect, useRef, useCallback } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Autocomplete, FormControl, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import {
  getBusinessPurchaseInvoiceDetailsById,
  updateBusinessPurchaseInvoice,
} from "../../../reducers/Slices/purchaseInvoiceSlice";
import moment from "moment";
import toast from "react-hot-toast";
import { fetchHSNData, fetchHSNDataByNumber, fetchProductByID } from "../../../reducers/Slices/productSlice";

import { debounce } from "lodash";
import { precisionDecimalDigit } from "../../../utils/prettifyDigits";

function UpdatePurchaseInvoiceOther({ invoice }) {
  const [showModal, setShowModal] = useState(false);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const businessPurchaseInvoiceDetails = useSelector((state) => state.purchaseInvoice.businessPurchaseInvoicesDetails);

  //HSN codes
  const HSN_data = useSelector((state) => state.product.HSNData.data);

  //Function to close custom modal and perform actions during closing of modal
  function handleClose() {
    setShowModal(false);
  }

  const [items, setItems] = useState([{}]);

  function calculatePurchaseRate(purchase_price, quantity, discount, total_quantity) {
    if (quantity === 0) {
      return 0;
    }

    const effective_discount = parseFloat(discount || 0);
    const effective_price_per_unit = parseFloat(purchase_price) * (total_quantity / quantity);
    const rate = effective_price_per_unit / (1 - effective_discount / 100);

    return rate;
  }

  //check if obj is empty
  function isEmpty(obj) {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }

  const getHSNDataByHSNCodeAsync = async (hsn_number) => {
    let data = HSN_data.find((HSNData) => HSNData?.hsn_code === hsn_number);

    try {
      if (!data) {
        const response = await dispatch(fetchHSNDataByNumber(`?hsn_code=${hsn_number}`));
        data = response.payload.data[0];
      }
      return data;
    } catch (err) {
      toast.error("some error occurred while fetching HSN data");
      return null;
    }
  };

  useEffect(() => {
    const updatedHsnTaxRates = async () => {
      if (isEmpty(businessPurchaseInvoiceDetails)) return;

      const fetchHSNdata = await Promise.all(
        businessPurchaseInvoiceDetails?.business_purchase_items?.map(async (item) => {
          const hsnTaxRate = await getHSNDataByHSNCodeAsync(item?.hsn_number);
          return hsnTaxRate;
        })
      );

      setItems(() => {
        if (!isEmpty(businessPurchaseInvoiceDetails)) {
          const updatedPurchaseRates = [];
          const updatedCalculatedAmountList = [];
          const updatedItems = businessPurchaseInvoiceDetails?.business_purchase_items.map((item, index) => {
            const { price, discount, quantity, deal_quantity, product_name, batch_number, mrp_discount, hsn_number } =
              item;
            const total_quantity = quantity;
            const purchase_rate = calculatePurchaseRate(price, quantity - deal_quantity, discount, total_quantity);

            updatedPurchaseRates.push(purchase_rate);

            let calculatedAmount = handleCalculateAmount(
              index,
              null,
              businessPurchaseInvoiceDetails?.business_purchase_items,
              purchase_rate,
              true
            );

            updatedCalculatedAmountList.push({
              calculated_amount: calculatedAmount ? parseFloat(calculatedAmount) : "",
            });

            let newObj = {
              remaining_quantity: item?.quantity - item?.deal_quantity,
              deal_quantity: item?.deal_quantity,
              total_quantity: item?.quantity,
              purchase_price: item?.price,
              amount: calculatedAmount,
              product_name: product_name,
              discount: discount,
              batch_number: batch_number,
              mrp_price: mrp_discount,
              hsn_number: hsn_number,
            };

            setPurchaseRates(updatedPurchaseRates);
            setCalculatedAmountValue(updatedCalculatedAmountList);
            return newObj;
          });

          return updatedItems;
        } else {
          return [{}];
        }
      });
    };

    updatedHsnTaxRates();
  }, [businessPurchaseInvoiceDetails]);

  //Optional Parameters for Purchase Invoice
  const [eBillValue, setEBillValue] = useState(null);
  const [invoiceCounterValue, setInvoiceCounterValue] = useState(null);
  const [billDate, setBillDate] = useState(null);

  //Amount value for display
  const [calculatedAmountValue, setCalculatedAmountValue] = useState([{}]);

  //Amount value for rate
  const [purchaseRates, setPurchaseRates] = useState([]);

  function handleUpdateInvoiceClick() {
    if (invoice?.id) {
      setFormData(() => {
        return {
          // vendor: parseInt(invoice?.vendor?.id),
          payment_option: "cash",
          payment_type: "paid",
          description: null,
        };
      });
      setInvoiceCounterValue(invoice?.invoice_counter);
      setEBillValue(invoice?.ewaybill_number ?? null);
      setBillDate(invoice?.bill_date ?? moment(new Date()).format("YYYY-MM-DD hh:mm:ss"));
      dispatch(getBusinessPurchaseInvoiceDetailsById({ id: invoice?.id }));
    }
    setShowModal(true);
  }

  const paymentOptions = [
    { label: "Cash", value: "cash" },
    // { label: "UPI", value: "upi" },
    { label: "Debit", value: "debit" },
    // { label: "Credit", value: "credit" },
  ];

  const handlePaymentOptionChange = (value) => {
    setFormData((prevState) => {
      const updatedState = {
        ...prevState,
        payment_option: value.value,
        payment_type: value.value === "debit" ? "pay_later" : "cash",
      };
      return updatedState;
    });
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  //userType with initializer function to calculate userType on render
  const [formData, setFormData] = useState(() => {
    return {
      vendor: parseInt(invoice?.vendor?.id),
      payment_option: "cash",
      payment_type: "paid",
      description: null,
    };
  });

  useEffect(() => {
    handleCalculate();
  }, [items]);

  useEffect(() => {
    setFormData((prevState) => {
      return {
        ...prevState,
        order_date_time: billDate,
        ewaybill_number: eBillValue,
        invoice_counter: invoiceCounterValue,
      };
    });
  }, [billDate, eBillValue, invoiceCounterValue]);

  const handleCalculate = () => {
    const { total, discount } = items.reduce(
      (accumulator, currentItem, index) => {
        if (purchaseRates[index]) {
          const quantity = parseInt(currentItem?.remaining_quantity ?? 0);
          let item_total = (purchaseRates[index] || 0) * quantity;
          let item_discount = item_total * (currentItem?.discount ? currentItem?.discount / 100 : 0);

          accumulator.total += item_total;
          accumulator.discount += item_discount;
        }
        return accumulator;
      },
      { total: 0, discount: 0 }
    );

    setFormData({
      ...formData,
      is_non_gst: true,
      sub_total: precisionDecimalDigit(total),
      discount: precisionDecimalDigit(discount),
      grand_total: precisionDecimalDigit(total - discount),
      paid_amount: precisionDecimalDigit(total - discount),
      remaining_total: 0.0,
      product_and_quantity: items,
    });
  };

  const handleRateChange = (value, index) => {
    const updatedPurchaseRates = purchaseRates;
    updatedPurchaseRates[index] = parseFloat(value);
    setPurchaseRates(updatedPurchaseRates);
    updateItemFields(index, items);
  };

  const handleFieldChange = (value, index, field) => {
    const updatedItems = items.map((item, i) => {
      if (i === index) {
        return { ...item, [field]: value };
      }
      return item;
    });

    setItems(updatedItems);
    updateItemFields(index, updatedItems);
  };

  const updateItemFields = (index, updatedItems) => {
    const rate = purchaseRates[index] ?? 0;

    const quantity = parseInt(
      updatedItems[index]?.remaining_quantity ? parseInt(updatedItems[index]?.remaining_quantity) : 0
    );

    const deal = parseInt(updatedItems[index]?.deal_quantity ?? 0);

    const discountPercent = parseFloat((updatedItems[index]?.discount ?? 0) / 100);
    const discount = rate * discountPercent;

    const total_quantity = deal + quantity;
    const purchase_price = ((rate - discount) * quantity) / total_quantity;

    setItems((prevItems) => {
      const nextItems = prevItems.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            amount: handleCalculateAmount(index, null, updatedItems),
            purchase_price,
            total_quantity,
          };
        }
        return item;
      });
      return nextItems;
    });
  };

  const handleCalculateAmount = (index, value, updatedItems, purchase_rate = null, purchase_data = false) => {
    if (value || value === 0 || value === "0" || value === "") {
      setCalculatedAmountValue((prevItems) => {
        const updatedCalculatedAmount = prevItems.map((item, i) => {
          if (i === index) {
            return { calculated_amount: value ? parseFloat(value) : "" };
          }
          return item;
        });
        return updatedCalculatedAmount;
      });

      setItems((prevItems) => {
        const nextItems = prevItems.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              amount: value ? parseFloat(value) : "",
            };
          }
          return item;
        });
        return nextItems;
      });
      return 0;
    }

    const rate = purchase_rate ? purchase_rate : parseFloat(purchaseRates[index] ?? 0);
    const quantity = purchase_data
      ? parseInt((updatedItems[index]?.quantity ?? 0) - (updatedItems[index]?.deal_quantity ?? 0))
      : parseInt(updatedItems[index]?.remaining_quantity ?? 0);

    const discountPercent = parseFloat((updatedItems[index]?.discount ?? 0) / 100);
    const discount = rate * discountPercent;

    const amount = (rate - discount) * quantity;

    //Going the extra mile because toFixed(2) is rounding numbers resulting in wrong value.
    const truncatedAmount = (Math.floor(amount * 1000) / 1000).toFixed(3).slice(0, -1);

    setCalculatedAmountValue((prevItems) => {
      const updatedCalculatedAmount = prevItems.map((item, i) => {
        if (i === index) {
          return { calculated_amount: truncatedAmount };
        }
        return item;
      });
      return updatedCalculatedAmount;
    });

    return amount;
  };

  const handleRemoveItem = (index) => {
    if (items?.length > 1) {
      setItems((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
    }
    if (items.length > 1) {
      setCalculatedAmountValue((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }
    if (items.length > 1) {
      setPurchaseRates((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems.splice(index, 1);
        return updatedItems;
      });
    }
  };

  function validateProductAndQuantity(data) {
    const requiredFields = ["product_name", "batch_number", "mrp_price"];
    console.log(data, "this is the data here");

    return data.product_and_quantity.every((product) => {
      const { purchase_price, amount, remaining_quantity } = product;

      if (purchase_price === null) {
        toast.error("purchase price cannot be zero!");
        return false;
      }

      if (remaining_quantity === null || parseInt(remaining_quantity) === 0) {
        toast.error("remaining quantity cannot be zero!");
        return false;
      }

      if (amount == null) {
        toast.error("fill amount value");
        return false;
      }

      // Check if each required field in product exists and is not empty
      return requiredFields.every((field) => product[field] != null && product[field] !== "");
    });
  }

  const handleSubmit = () => {
    let updatedFormData = formData;
    // if (formData.payment_type === "pay_later") {
    //   updatedFormData = {
    //     ...formData,
    //     remaining_total: formData.grand_total,
    //     paid_amount: "0",
    //   };
    // }

    if (!validateProductAndQuantity(updatedFormData)) {
      toast.error("fill all fields which are marked with *");
      return;
    }

    setDisableSubmitButton(false);

    dispatch(updateBusinessPurchaseInvoice({ id: invoice?.id, data: updatedFormData }))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          handleClose();
        } else {
          toast.error("sorry, Purchase Invoice Cannot be Updated");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const handleAddMoreProducts = () => {
    setItems((prevItems) => [...prevItems, {}]);
    setCalculatedAmountValue((prevItems) => [...prevItems, {}]);
    setPurchaseRates((prevItems) => [...prevItems, 0]);
  };

  //Move to next field on enter button
  const inputRefs = useRef([]);
  const submitButton = useRef(null);
  const addAnotherItemRef = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: items?.length }, () => Array(1));
  }, [items]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        if (focusedElement === submitButton.current) {
          submitButton.current.click();
          return;
        }
        if (focusedElement === addAnotherItemRef.current) {
          addAnotherItemRef.current.click();

          setTimeout(() => {
            const lastRowIndex = inputRefs.current.length - 1;
            // After clicking, shift focus to the first element of the last row
            if (inputRefs.current[lastRowIndex] && inputRefs.current[lastRowIndex][0]) {
              inputRefs.current[lastRowIndex][0].querySelector("input").focus();
            }
          }, 300); // Adding a 300ms delay before shifting focus
          return;
        }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement = inputRefs.current[i][j]?.querySelector("input");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row
              // if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
              //   submitButton.current.focus(); // Focus on the submit button
              // }
              if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
                //Note - for some reason this is not working like in other components without timout
                if (window.confirm("want to add another item")) {
                  setTimeout(() => {
                    addAnotherItemRef.current.focus(); // Focus on add another item button
                  }, 200);
                } else {
                  setTimeout(() => {
                    submitButton.current.focus(); //Focus on submit button
                  }, 200);
                }
              }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Cleanup on unmount
    };
  }, [items]);

  const handleHSNNumberChange = (value, index) => {
    handleFieldChange(value, index, "hsn_number");
  };

  const [hSNSearchTerm, sethSNSearchTerm] = useState("");

  useEffect(() => {
    if (hSNSearchTerm.length > 0) {
      dispatch(fetchHSNData(`?hsn_code=${hSNSearchTerm}`));
    }
  }, [hSNSearchTerm]);

  const setDebouncedHSNSearchTerm = debounce((value) => sethSNSearchTerm(value), 1000);

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center text-xl">Edit Business Purchase Invoice</div>
          {items?.map((item, index) => (
            <div
              key={item?.productId || index}
              className="flex flex-col gap-[20px] border-b-2 border-neutral-200 py-[20px]"
            >
              <div className="flex flex-col">
                <label htmlFor="products">
                  Product Name<span className="pl-1 text-red-500">*</span>
                </label>
                <TextField
                  id="product_name"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  value={items[index]?.product_name}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "product_name");
                  }}
                  // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                  type="text"
                  variant="outlined"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="batch-number">
                  Batch Number<span className="pl-1 text-red-500">*</span>
                </label>
                <TextField
                  id="batch-number"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  value={items[index]?.batch_number}
                  onChange={(e) => {
                    handleFieldChange(e.target.value, index, "batch_number");
                  }}
                  // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                  type="text"
                  variant="outlined"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="hsn-number">HSN number</label>
                <Autocomplete
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][2] = el; // Assign the ref if both checks pass
                    }
                  }}
                  disablePortal
                  id="hsn-number"
                  options={HSN_data || null}
                  style={{ borderColor: "black" }}
                  value={
                    HSN_data?.length && items[index]?.hsn_number
                      ? HSN_data.find((hsn) => hsn.hsn_code === items[index]?.hsn_number) || null
                      : null
                  }
                  getOptionLabel={(option) => option?.hsn_code}
                  onChange={(event, value) => {
                    handleHSNNumberChange(value?.hsn_code, index);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                      }}
                      onChange={(e) => setDebouncedHSNSearchTerm(e.target.value)}
                    />
                  )}
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="remaining-quantity">
                  Quantity<span className="pl-1 text-red-500">*</span>
                </label>
                <TextField
                  id="remaining-quantity"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][3] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(parseInt(e.target.value), index, "remaining_quantity");
                  }}
                  value={items[index]?.remaining_quantity}
                  // InputProps={{ inputProps: { min: 1, max: items[index]?.total_quantity } }}
                  type="number"
                  variant="outlined"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="deal_quantity">Deal Quantity</label>
                <TextField
                  id="deal_quantity"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][4] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(parseInt(e.target.value), index, "deal_quantity");
                  }}
                  value={items[index]?.deal_quantity}
                  defaultValue={0}
                  type="number"
                  variant="outlined"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="mrp_price">
                  MRP<span className="pl-1 text-red-500">*</span>
                </label>
                <TextField
                  id="mrp_price"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][5] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    handleFieldChange(precisionDecimalDigit(e.target.value), index, "mrp_price");
                  }}
                  value={items[index]?.mrp_price || ""}
                  type="number"
                  variant="outlined"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="rate">
                  Rate<span className="pl-1 text-red-500">*</span>
                </label>
                <TextField
                  id="rate"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][6] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    if (items[index]?.mrp_price === 0 || !items[index]?.mrp_price) {
                      toast.error("Please fill MRP first!");
                      return;
                    } else if (parseFloat(items[index]?.mrp_price) < parseFloat(e.target.value)) {
                      toast.error("Rate cannot be greater than MRP price!");
                      return;
                    }

                    handleRateChange(e.target.value, index);
                  }}
                  value={purchaseRates[index]}
                  type="number"
                  variant="outlined"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="discount_percent">Discount %</label>
                <TextField
                  id="discount_percent"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][8] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => {
                    if (e.target.value > 100.0) {
                      toast.error("Discount field cannnot be greater than 100%");
                      handleFieldChange(parseFloat(100 || 0), index, "discount");
                      return;
                    }
                    handleFieldChange(parseFloat(e.target.value || 0), index, "discount");
                  }}
                  value={items[index]?.discount || ""}
                  type="number"
                  variant="outlined"
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="amount">
                  Amount<span className="pl-1 text-red-500">*</span>
                </label>
                <TextField
                  id="amount"
                  ref={(el) => {
                    if (el && inputRefs.current[index]) {
                      inputRefs.current[index][10] = el; // Assign the ref if both checks pass
                    }
                  }}
                  inputProps={{
                    style: {
                      height: "20px",
                      paddingLeft: "10px",
                    },
                  }}
                  onChange={(e) => handleCalculateAmount(index, e.target.value)}
                  value={calculatedAmountValue[index]?.calculated_amount || 0}
                  type="text"
                  variant="outlined"
                />
              </div>
              <div className="py-[20px] w-full bg-green-20 flex justify-center">
                <button
                  className="px-[20px] py-[10px] bg-black rounded-full text-white"
                  onClick={() => handleRemoveItem(index)}
                >
                  Remove Product
                </button>
              </div>
            </div>
          ))}
          <div className="py-[20px] w-full bg-green-20 flex justify-center">
            <button
              ref={addAnotherItemRef}
              className="px-[20px] py-[10px] bg-black rounded-full text-white"
              onClick={() => handleAddMoreProducts()}
            >
              Add More Products
            </button>
          </div>
          <div className="flex flex-col">
            <label htmlFor="invoiceId">
              {" "}
              <Tooltip title="Optional Field: If you have purchase invoice from vendor and want same Invoice ID">
                <span className="border-b border-dotted border-black pb-[0.8px]">Invoice Id: </span>
              </Tooltip>
            </label>
            <TextField
              hiddenLabel
              id="invoiceId"
              variant="outlined"
              type="text"
              value={invoiceCounterValue || ""}
              onChange={(e) => setInvoiceCounterValue(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="eWayBill">
              {" "}
              <Tooltip title="Optional Field: E-bill ID">
                <span className="border-b border-dotted border-black pb-[0.8px]">E-Bill Id: </span>
              </Tooltip>
            </label>
            <TextField
              hiddenLabel
              id="eWayBill"
              type="text"
              variant="outlined"
              value={eBillValue}
              onChange={(e) => setEBillValue(e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="billDate">Bill Date</label>
            <TextField
              hiddenLabel
              id="billDate"
              variant="outlined"
              type="date"
              value={moment(billDate).format("YYYY-MM-DD")}
              onChange={(e) => setBillDate(moment(new Date(e.target.value)).format("YYYY-MM-DD hh:mm:ss"))}
            />
          </div>
          <div>
            <div className="py-[10px]"></div>
            <label htmlFor="payment-type" className="sr-only">
              Payment Type
            </label>
            <Autocomplete
              disablePortal
              id="payment-type"
              options={paymentOptions || []}
              sx={{ width: "100%" }}
              value={formData?.payment_option}
              onChange={(event, value) => handlePaymentOptionChange(value)}
              renderInput={(params) => <TextField {...params} label="Payment Option" />}
              required
            />
          </div>
          {formData.payment_type === "pay_later" && (
            <div className="flex flex-col py-[10px]">
              <h3 className="font-semibold text-gray-500">Amount Paid: </h3>
              <input
                type="number"
                className="py-[10px] px-[10px] border border-black rounded-sm"
                placeholder="Amount Received"
                value={parseFloat(formData.paid_amount)}
                onChange={(e) => {
                  if (e.target.value > formData?.grand_total) {
                    toast.error("Amount received cannot be more than grand total!");
                    return;
                  }

                  setFormData({
                    ...formData,
                    remaining_total: parseFloat(formData?.grand_total) - parseFloat(e.target.value),
                    paid_amount: parseFloat(e.target.value),
                  });
                }}
              />
            </div>
          )}
          <div className="flex flex-col">
            <label htmlFor="sub_total">Sub Total</label>
            <TextField hiddenLabel id="sub_total" variant="outlined" value={formData.sub_total || 0} disabled />
          </div>
          <div className="flex flex-col">
            <label htmlFor="discount">Discount</label>
            <TextField hiddenLabel id="discount" variant="outlined" value={formData.discount || 0} disabled />
          </div>

          <div className="flex flex-col">
            <label htmlFor="total">Total</label>
            <TextField hiddenLabel id="total" variant="outlined" value={formData.grand_total || 0} disabled />
          </div>
          <div className="flex flex-col">
            <label htmlFor="description">Description</label>
            <textarea
              className="w-full min-w-[300px] h-full min-h-[80px] p-2 border-2"
              placeholder=""
              id="description"
              onChange={(e) => setFormData({ ...formData, description: e.target.value })}
              value={formData.description}
            ></textarea>
          </div>

          <div className="flex gap-[10px] justify-center">
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full"
              onClick={handleSubmit}
              disabled={disableSubmitButton}
              ref={submitButton}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              Save
            </button>
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full"
              onClick={handleClose}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClose();
                }
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      <div>
        <button onClick={handleUpdateInvoiceClick}>Edit</button>
      </div>
    </>
  );
}

export default UpdatePurchaseInvoiceOther;
