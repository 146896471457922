import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadCustomerAndVendorMarg,
  // uploadBatchesMarg,
  uploadHSNAndSACMarg,
  // uploadLedgerMarg,
  uploadProductAndBatchesMarg,
  // uploadProductsMarg,
} from "../../../reducers/Slices/onboardingSlice";
import { http } from "../../../service/http";

const FileUploadOnboarding = ({ migrationSoftware, setDisableFinishStepButton }) => {
  const dispatch = useDispatch();

  // const [productFile, setProductFile] = useState(null);
  // const [batchesFile, setBatchesFile] = useState(null);
  // const [ledgerFile, setLedgerFile] = useState(null);
  // const [isUploadingProduct, setIsUploadingProduct] = useState(false);
  // const [isUploadingBatches, setIsUploadingBatches] = useState(false);
  // const [isUploadingLedger, setIsUploadingLedger] = useState(false);
  // const [isProductUploaded, setIsProductUploaded] = useState(false);

  const [customerVendorFile, setCustomerVendorFile] = useState(null);
  const [productBatchesFile, setProductBatchesFile] = useState(null);
  const [hsnSacFile, setHsnSacFile] = useState(null);

  const [isUploadingCustomerVendorFile, setIsUploadingCustomerVendorFile] = useState(false);
  const [isUploadingProductBatchesFile, setIsUploadingProductBatchesFile] = useState(false);
  const [isUploadingHsnSacFile, setIsUploadingHsnSacFile] = useState(false);

  const [isCustomerVendorUploaded, setIsCustomerVendorUploaded] = useState(false);
  const [isProductAndBatchesUploaded, setIsProductAndBatchesUploaded] = useState(false);

  const businessDetails = useSelector((state) => state.user);

  const handleFileChange = (e, fileType) => {
    const file = e.target.files[0];

    // Removing limitation
    // const maxSizeInMB = 15; // 10 MB
    // const maxSizeInBytes = maxSizeInMB * 1024 * 1024;

    // if (file && file?.size > maxSizeInBytes) {
    //   toast.error(`File size should be less than ${maxSizeInMB} MB`);
    //   return;
    // }

    // if (fileType === "product") setProductFile(file);
    // else if (fileType === "batches") setBatchesFile(file);
    // else if (fileType === "ledger") setLedgerFile(file);
    if (fileType === "customerVendor") setCustomerVendorFile(file);
    else if (fileType === "productBatches") setProductBatchesFile(file);
    else if (fileType === "hsnSac") setHsnSacFile(file);
  };

  //generate presigned url
  const generatePresignedURL = async (filename) => {
    if (filename) {
      try {
        const response = await http.post(
          `/storage/`,
          { file_name: `${businessDetails.id}/onboarding/${filename}` },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response?.data?.data?.url) {
          return response.data.data.url;
        } else {
          return null;
        }
      } catch (error) {
        toast.error("Some error occurred while generating url try again");
        return null;
      }
    }
  };

  //Upload to presignedURL
  async function uploadToPresignedURL(presignedURL, productFile) {
    try {
      const response = await axios.put(presignedURL, productFile, {
        headers: {
          "Content-Type": productFile.type,
        },
      });

      if (response.status === 200) {
        console.log("File uploaded successfully");
        return true;
      } else {
        console.error("Failed to upload file:", response.status, response.statusText);
        return false;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return false;
    }
  }

  const handleUploadCustomerAndVendor = async () => {
    if (!customerVendorFile) {
      toast.error("Please select a file.");
      return;
    }

    setIsUploadingCustomerVendorFile(true);

    const presignedURL = await generatePresignedURL(customerVendorFile.name);

    if (!presignedURL) {
      toast.error("Ran into some issues while uploading your file please re-select and upload file again");
      setIsUploadingCustomerVendorFile(false);
      return;
    }

    const fileUploadedToR2 = await uploadToPresignedURL(presignedURL, customerVendorFile);

    if (!fileUploadedToR2) {
      toast.error("Ran into some issues while uploading your file please re-select and upload file again");
      setIsUploadingCustomerVendorFile(false);
      return;
    }

    try {
      const response = await dispatch(
        uploadCustomerAndVendorMarg(`${businessDetails.id}/onboarding/${customerVendorFile?.name}`)
      );

      if (response?.payload) {
        toast.success("Customer and Vendor file uploaded successfully!");
      } else {
        toast.error("Something went wrong. File upload failed!");
      }
      setIsCustomerVendorUploaded(true);
      //Todo: move this to final step or something
      setDisableFinishStepButton(false);
    } catch (error) {
      console.error("Error uploading Customer and Vendor file:", error);
      toast.error("Failed to upload Customer and Vendor file. Please try again.");
    } finally {
      setIsUploadingCustomerVendorFile(false);
    }
  };

  const handleUploadProductAndBatches = async () => {
    if (!productBatchesFile) {
      toast.error("Please select a file.");
      return;
    }

    setIsUploadingProductBatchesFile(true);

    const presignedURL = await generatePresignedURL(productBatchesFile.name);

    if (!presignedURL) {
      toast.error("Ran into some issues while uploading your file please re-select and upload file again");
      setIsUploadingProductBatchesFile(false);
      return;
    }

    const fileUploadedToR2 = await uploadToPresignedURL(presignedURL, productBatchesFile);

    if (!fileUploadedToR2) {
      toast.error("Ran into some issues while uploading your file please re-select and upload file again");
      setIsUploadingProductBatchesFile(false);
      return;
    }

    try {
      const response = await dispatch(
        uploadProductAndBatchesMarg(`${businessDetails.id}/onboarding/${productBatchesFile?.name}`)
      );

      if (response?.payload) {
        toast.success("Product and batches file uploaded successfully!");
      } else {
        toast.error("Something went wrong. File upload failed!");
      }

      setIsProductAndBatchesUploaded(true);
      //Todo: move this to final step or something
      setDisableFinishStepButton(false);
    } catch (error) {
      console.error("Error uploading Product file:", error);
      toast.error("Failed to upload Product file. Please try again.");
    } finally {
      setIsUploadingProductBatchesFile(false);
    }
  };

  const handleUploadHsnAndSac = async () => {
    if (!hsnSacFile) {
      toast.error("Please select a file.");
      return;
    }

    setIsUploadingHsnSacFile(true);

    const presignedURL = await generatePresignedURL(hsnSacFile.name);

    if (!presignedURL) {
      toast.error("Ran into some issues while uploading your file please re-select and upload file again");
      setIsUploadingHsnSacFile(false);
      return;
    }

    const fileUploadedToR2 = await uploadToPresignedURL(presignedURL, hsnSacFile);

    if (!fileUploadedToR2) {
      toast.error("Ran into some issues while uploading your file please re-select and upload file again");
      setIsUploadingHsnSacFile(false);
      return;
    }

    try {
      const response = await dispatch(uploadHSNAndSACMarg(`${businessDetails.id}/onboarding/${hsnSacFile?.name}`));

      if (response?.payload) {
        toast.success("HSN/SAC file uploaded successfully!");
      } else {
        toast.error("Something went wrong. File upload failed!");
      }

      //Todo: move this to final step or something
      setDisableFinishStepButton(false);
    } catch (error) {
      console.error("Error uploading HSN/SAC file:", error);
      toast.error("Failed to upload HSN/SAC file. Please try again.");
    } finally {
      setIsUploadingHsnSacFile(false);
    }
  };

  // const handleUploadProduct = async () => {
  //   if (!productFile) {
  //     toast.error("Please select a Product file.");
  //     return;
  //   }

  //   setIsUploadingProduct(true);

  //   const presignedURL = await generatePresignedURL(productFile.name);

  //   if (!presignedURL) {
  //     toast.error("Ran into some issues while uploading your file please re-select and upload file again");
  //     setIsUploadingProduct(false);
  //     return;
  //   }

  //   const fileUploadedToR2 = await uploadToPresignedURL(presignedURL, productFile);

  //   if (!fileUploadedToR2) {
  //     toast.error("Ran into some issues while uploading your file please re-select and upload file again");
  //     setIsUploadingProduct(false);
  //     return;
  //   }

  //   try {
  //     await dispatch(uploadProductsMarg(`onboarding/${productFile?.name}`));
  //     toast.success("Product file uploaded successfully!");
  //     setIsProductUploaded(true);
  //     setDisableFinishStepButton(false);
  //   } catch (error) {
  //     console.error("Error uploading Product file:", error);
  //     toast.error("Failed to upload Product file. Please try again.");
  //   } finally {
  //     setIsUploadingProduct(false);
  //   }
  // };

  // const handleUploadBatches = async () => {
  //   if (!batchesFile) {
  //     toast.error("Please select a Batches file.");
  //     return;
  //   }

  //   setIsUploadingBatches(true);

  //   const presignedURL = await generatePresignedURL(batchesFile.name);

  //   if (!presignedURL) {
  //     toast.error("Ran into some issues while uploading your file please re-select and upload file again");
  //     setIsUploadingBatches(false);
  //     return;
  //   }

  //   const fileUploadedToR2 = await uploadToPresignedURL(presignedURL, batchesFile);

  //   if (!fileUploadedToR2) {
  //     toast.error("Ran into some issues while uploading your file please re-select and upload file again");
  //     setIsUploadingBatches(false);
  //     return;
  //   }

  //   try {
  //     await dispatch(uploadBatchesMarg(`onboarding/${batchesFile?.name}`));
  //     toast.success("Batches file uploaded successfully!");
  //     setDisableFinishStepButton(false);
  //   } catch (error) {
  //     console.error("Error uploading Batches file:", error);
  //     toast.error("Failed to upload Batches file. Please try again.");
  //   } finally {
  //     setIsUploadingBatches(false);
  //   }
  // };

  // const handleUploadLedger = async () => {
  //   if (!ledgerFile) {
  //     toast.error("Please select a Ledger file.");
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append("file", ledgerFile);

  //   try {
  //     setIsUploadingLedger(true);
  //     await axios.post(`/${migrationSoftware}/ledger`, formData, {
  //       headers: { "Content-Type": "multipart/form-data" },
  //     });
  //     await dispatch(uploadLedgerMarg(formData));
  //     toast.success("Ledger file uploaded successfully!");
  //     setDisableFinishStepButton(false);
  //   } catch (error) {
  //     console.error("Error uploading Ledger file:", error);
  //     toast.error("Failed to upload Ledger file. Please try again.");
  //   } finally {
  //     setIsUploadingLedger(false);
  //   }
  // };

  return (
    <div className="p-4">
      <h2 className="text-2xl pb-[50px] font-semibold max-w-[500px] text-center">
        Upload Excel Files From Previous Steps.
      </h2>

      {/* Product Excel Upload */}
      {/* <div className="flex justify-center items-center gap-[10px] mb-4">
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => handleFileChange(e, "product")}
          className="border border-gray-300 rounded px-[20px] py-[10px]"
        />
        <button
          onClick={handleUploadProduct}
          disabled={isUploadingProduct || !productFile}
          className={`px-[20px] py-[10px] font-semibold text-white rounded ${
            isUploadingProduct ? "bg-gray-400 cursor-not-allowed" : "bg-black hover:bg-stone-800"
          }`}
        >
          {isUploadingProduct ? "Uploading..." : "Upload Product File"}
        </button>
      </div> */}

      {/* Customer Vendor Upload */}
      <div className="flex justify-center items-center gap-[10px] mb-4">
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => handleFileChange(e, "customerVendor")}
          className="border border-gray-300 rounded px-[20px] py-[10px]"
        />
        <button
          onClick={handleUploadCustomerAndVendor}
          disabled={isUploadingCustomerVendorFile || !customerVendorFile}
          className={`px-[20px] py-[10px] font-semibold text-white rounded ${
            isUploadingCustomerVendorFile ? "bg-gray-400 cursor-not-allowed" : "bg-black hover:bg-stone-800"
          }`}
        >
          {isUploadingCustomerVendorFile ? "Uploading..." : "Upload Customer and Vendor File"}
        </button>
      </div>

      {/* Product and Batches Upload */}
      <div className="flex justify-center items-center gap-[10px] mb-4">
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => handleFileChange(e, "productBatches")}
          className="border border-gray-300 rounded px-[20px] py-[10px]"
        />
        <button
          onClick={handleUploadProductAndBatches}
          disabled={isUploadingProductBatchesFile || !productBatchesFile || !isCustomerVendorUploaded}
          className={`px-[20px] py-[10px] font-semibold text-white rounded ${
            isUploadingProductBatchesFile || !isCustomerVendorUploaded
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-black hover:bg-stone-800"
          }`}
        >
          {isUploadingProductBatchesFile ? "Uploading..." : "Upload Product and batches File"}
        </button>
      </div>

      {/* HSN/SAC file Upload */}
      <div className="flex justify-center items-center gap-[10px] mb-4">
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => handleFileChange(e, "hsnSac")}
          className="border border-gray-300 rounded px-[20px] py-[10px]"
        />
        <button
          onClick={handleUploadHsnAndSac}
          disabled={isUploadingHsnSacFile || !hsnSacFile || !isProductAndBatchesUploaded}
          className={`px-[20px] py-[10px] font-semibold text-white rounded ${
            isUploadingHsnSacFile || !isProductAndBatchesUploaded
              ? "bg-gray-400 cursor-not-allowed"
              : "bg-black hover:bg-stone-800"
          }`}
        >
          {isUploadingHsnSacFile ? "Uploading..." : "Upload HSN and SAC File"}
        </button>
      </div>

      {/* Batches Excel Upload */}
      {/* <div className="flex justify-center items-center gap-[10px] mb-4">
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => handleFileChange(e, "batches")}
          className="border border-gray-300 rounded px-[20px] py-[10px]"
          disabled={!isProductUploaded} // Disable input until product file is uploaded
        />
        <button
          onClick={handleUploadBatches}
          disabled={!isProductUploaded || isUploadingBatches || !batchesFile}
          className={`px-[20px] py-[10px] font-semibold text-white rounded ${
            !isProductUploaded || isUploadingBatches ? "bg-gray-400 cursor-not-allowed" : "bg-black hover:bg-stone-800"
          }`}
        >
          {isUploadingBatches ? "Uploading..." : "Upload Batches File"}
        </button>
      </div> */}

      {/* Ledger Excel Upload */}
      {/* <div className="flex justify-center items-center gap-[10px] mb-4">
        <input
          type="file"
          accept=".xls,.xlsx"
          onChange={(e) => handleFileChange(e, "ledger")}
          className="border border-gray-300 rounded px-[20px] py-[10px]"
        />
        <button
          onClick={handleUploadLedger}
          disabled={isUploadingLedger || !ledgerFile}
          className={`px-[20px] py-[10px] font-semibold text-white rounded ${
            isUploadingLedger ? "bg-gray-400 cursor-not-allowed" : "bg-black hover:bg-stone-800"
          }`}
        >
          {isUploadingLedger ? "Uploading..." : "Upload Ledger File"}
        </button>
      </div> */}
    </div>
  );
};

export default FileUploadOnboarding;
