import React, { useEffect, useLayoutEffect, useState } from "react";
// import "./Navbar.scss"; TODO - delete if nothing breaks
import { useDispatch, useSelector } from "react-redux";
import { json, Link, useFetcher } from "react-router-dom";
import { clearSession } from "../../../utils/session";
// import { Alert, Snackbar } from "@mui/material";
// import { reset, toggleSidebar } from "../../../reducers/Slices/appSlice";
import profileDefault from "../../../assets/images/profile-default.jpg";
import { localStorgeProfileCache } from "../../../reducers/Slices/userSlice";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../../config/firebase.js";
//pHaniver - Remove - Show menu state and function when reference 0

//UI components and imports
import IconWrapper from "../../MicroComponents/IconWrapper.jsx";
import { Bell, Search } from "react-feather";
import { toggleGlobalSearch, unsubscribeFirebaseNotification } from "../../../reducers/Slices/appSlice.js";
import toast from "react-hot-toast";
import moment from "moment";

const NavBar = () => {
  const data = useSelector((state) => state.user);
  // const alert = useSelector((state) => state.app.alert);
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(null);
  const [showNotificationBar, setShowNotificationBar] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState(Notification.permission);
  const [notifications, setNotifications] = useState([]); // State to hold incoming notifications

  useEffect(() => {
    dispatch(localStorgeProfileCache());

    // Function to save notification with a timestamp
    const saveNotification = (notification) => {
      const notifications = JSON.parse(localStorage.getItem("notifications")) || [];
      const currentDate = new Date().toISOString(); // Get the current date as an ISO string
      notification.receivedAt = currentDate; // Add the timestamp to the notification

      // Add the new notification to the list and update localStorage
      const updatedNotifications = [notification, ...notifications];
      localStorage.setItem("notifications", JSON.stringify(updatedNotifications));
    };

    // Function to retrieve notifications, filtering out those older than 3 days
    const getValidNotifications = () => {
      const now = new Date();
      const threeDaysAgo = now.setDate(now.getDate() - 3);
      const notifications = JSON.parse(localStorage.getItem("notifications")) || [];

      // Filter out expired notifications
      const validNotifications = notifications.filter((notification) => {
        const notificationDate = new Date(notification.receivedAt);
        return notificationDate.getTime() > threeDaysAgo;
      });

      // Update localStorage with valid notifications
      localStorage.setItem("notifications", JSON.stringify(validNotifications));

      return validNotifications;
    };

    // Check for existing valid notifications in localStorage and set state
    const cachedNotifications = getValidNotifications();
    setNotifications(cachedNotifications);

    if (messaging) {
      const unsubscribeOnMessage = onMessage(messaging, (payload) => {
        const notification = JSON.parse(payload.data.data);
        saveNotification(notification);

        // Update the notifications state with the new message
        setNotifications((prevNotifications) => {
          const newNotifications = [{ ...notification, receivedAt: new Date().toISOString() }, ...prevNotifications];
          return newNotifications;
        });
      });

      return () => {
        unsubscribeOnMessage();
      };
    }
  }, [dispatch]);

  const notificationTimingFormat = (receivedAt) => {
    const formattedDate = moment(receivedAt).format("MMMM Do, YYYY"); // e.g., "August 22nd, 2024"
    const formattedTime = moment(receivedAt).format("h:mm A"); // e.g., "4:35 PM

    return `${formattedDate} at ${formattedTime}`;
  };

  //Unsubscribe from notifications if notification permission is disabled.
  const unsubscribeTokenGeneratedByFirebase = (token) => {
    dispatch(unsubscribeFirebaseNotification({ device_token: token }))
      .then((action) => {
        if (action?.payload?.status === "success") {
          localStorage.removeItem("device_token", token); // Save the token in localStorage
          clearSession();
          window.location.reload();
        } else {
          clearSession();
          window.location.reload();
        }
      })
      .catch((err) => {
        toast.error(err);
        clearSession();
        window.location.reload();
      });
  };

  const handleLogout = () => {
    const device_token = localStorage.getItem("device_token");
    if (device_token) {
      unsubscribeTokenGeneratedByFirebase(device_token);
    } else {
      clearSession();
      window.location.reload();
    }
  };

  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  const [isGstUser, setIsGstUser] = useState(false);

  useLayoutEffect(() => {
    setIsGstUser(() => data?.gst_number && data?.gst_number?.length > 0);
  }, [data]);

  return (
    <>
      <div className="z-50">
        <div className="flex-col flex">
          <div className="relative border-b-2 border-gray-200 h-[80px] flex items-center">
            <div className="bg-white justify-between w-full items-center mx-auto px-4 flex">
              <div className="hidden lg:block">
                <div>
                  <p className="font-semibold text-lg">{data.business_name}</p>
                  {data?.gst_number && (
                    <p className="font-light text-xs">
                      GST: <span className="text-blue-600"> {data.gst_number.toUpperCase()} </span>
                    </p>
                  )}
                </div>
              </div>
              <div className=" justify-between items-center ml-auto flex space-x-3 ">
                <div className="md:block hidden">
                  <ul className="font-medium flex flex-row gap-6 justify-center">
                    <li>
                      <Link
                        to="/invoice/"
                        className="text-xs lg:text-sm text-nowrap whitespace-nowrap hover:text-orange-500"
                        aria-current="page"
                      >
                        + Invoice
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/purchase"
                        className="text-xs lg:text-sm text-nowrap whitespace-nowrap hover:text-orange-500"
                        aria-current="page"
                      >
                        + Purchase Invoice
                      </Link>
                    </li>
                    {isGstUser && (
                      <li>
                        <Link
                          to="/ewaybill"
                          className="text-xs lg:text-sm text-nowrap whitespace-nowrap hover:text-orange-500"
                          aria-current="page"
                        >
                          E-way
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to="/online-orders"
                        className="text-xs lg:text-sm text-nowrap whitespace-nowrap hover:text-orange-500"
                        aria-current="page"
                      >
                        Online Orders
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="flex flex-row gap-2 px-2 relative ">
                  <button onClick={() => dispatch(toggleGlobalSearch())}>
                    <IconWrapper>
                      <Search className="text-white z-10" />
                    </IconWrapper>
                  </button>

                  <button onClick={() => setShowNotificationBar(!showNotificationBar)}>
                    <IconWrapper notification={notifications.length > 0 ? true : false}>
                      <Bell className="text-white z-10" />
                    </IconWrapper>
                  </button>

                  {/* Show Notifcation Bar */}
                  {showNotificationBar && (
                    <div className="absolute border border-neutral-200 rounded-lg min-h-fit max-h-[50vh] overflow-y-scroll w-[20vw] mt-[60px] z-10 bg-white -translate-x-[30%]">
                      <div className="relative w-full h-full flex flex-col text-sm text-neutral-500 gap-[10px] p-[10px]">
                        {notificationPermission === "granted" ? (
                          notifications.length > 0 ? (
                            notifications.map((notification, index) => (
                              <div
                                key={index}
                                className="border border-neutral-200 w-full flex flex-col px-[20px] py-[20px] rounded-[5px]"
                              >
                                <h3 className="font-semibold text-lg">{notification.title}</h3>
                                <small className="mb-[5px]">{notificationTimingFormat(notification.receivedAt)}</small>
                                <p>{notification.body}</p>
                              </div>
                            ))
                          ) : (
                            <div className="h-full w-full flex items-center justify-center">No New Notification!</div>
                          )
                        ) : (
                          <div className="h-full w-full flex items-center justify-center">
                            Enable Notification Permission to see notifications
                          </div>
                        )}
                        {notifications.length > 0 && (
                          <button
                            className="sticky bottom-[10px] rounded-[5px] font-semibold text-white bg-black py-[10px]"
                            onClick={() => {
                              setNotifications([]);
                              localStorage.removeItem("notifications");
                              setShowNotificationBar(false);
                            }}
                          >
                            Clear Notifications
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div
                  className="justify-center items-center flex cursor-pointer"
                  onClick={() => handleShowMenu("settings")}
                >
                  <img
                    src={data?.profile_picture ? data?.profile_picture : profileDefault}
                    className="object-cover btn- h-[50px] w-[50px] rounded-full mr-2 bg-gray-300"
                    // alt={`profile of ${data.business_name}`}
                    alt={``}
                  />
                  <p className="font-bold text-xs lg:text-sm pr-2">{data.business_name}</p>
                  <div
                    id="dropdownInformation"
                    onMouseLeave={() => handleShowMenu("settings")}
                    className={`${
                      showMenu !== "settings" ? "hidden" : ""
                    } -ml-16 mt-40 absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
                  >
                    <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                      <li>
                        <Link to="/profile" className="block px-4 py-2 hover:bg-gray-100  ">
                          Profile
                        </Link>
                      </li>
                      <li>
                        <Link to="/current_plan" className="block px-4 py-2 hover:bg-gray-100">
                          Current Plan
                        </Link>
                      </li>
                      <li>
                        <Link to="/printing_options" className="block px-4 py-2 hover:bg-gray-100">
                          Printing Options
                        </Link>
                      </li>
                    </ul>
                    <div className="py-2">
                      <Link
                        to="#"
                        onClick={handleLogout}
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                      >
                        Sign out
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};

export default NavBar;
