import React, { useEffect, useLayoutEffect, useState } from "react";
import InvoiceDataTable from "./InvoiceDataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkCreateEInvoice,
  fetchCreditNotes,
  fetchInvoicesCustomer,
  // fetchInvoices,
  fetchMoreCreditNotes,
  fetchMoreInvoicesCustomer,
  // fetchMoreInvoices,
} from "../../../reducers/Slices/invoiceSlice";
// import { fetchPurchaseInvoices, fetchMorePurchaseInvoices } from "../../../reducers/Slices/purchaseInvoiceSlice";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import AddUser from "../../Invoices/AddUser";
import AddVendor from "../../Purchase/AddVendor";
import { MenuItem, Select } from "@mui/material";

import IconWrapper from "../../MicroComponents/IconWrapper";
import { Search } from "react-feather";
import { debounce } from "lodash";
import { useHotkeys } from "react-hotkeys-hook";
import {
  fetchBusinessPurchaseInvoices,
  fetchInvoicesVendor,
  fetchMoreInvoicesVendor,
  purchaseInvoiceSlice,
} from "../../../reducers/Slices/purchaseInvoiceSlice";
import Joyride from "react-joyride";
import moment from "moment";
import { subDays } from "date-fns";
import DateRangeSelector from "../../ProfitAndLoss/profitAndLossComponents/DateRangeSelector";
import { fetchHSNData } from "../../../reducers/Slices/productSlice";
import { http } from "../../../service/http";
import toast from "react-hot-toast";
import axios from "axios";
import WaitLoader from "../../SharedComponents/WaitLoader";
import { setShowWaitLoader } from "../../../reducers/Slices/appSlice";
// import moment from 'moment';

const InvoiceContainer = ({ infoType }) => {
  const [search, setSearch] = useState("");
  const [showAddUser, setShowAddUser] = useState(false);

  // const [showWaitLoader, setShowWaitLoader] = useState(false);
  const businessDetails = useSelector((state) => state.user);

  const [isGstUser, setIsGstUser] = useState(null);

  useLayoutEffect(() => {
    setIsGstUser(() => (businessDetails?.gst_number && businessDetails?.gst_number?.length > 0 ? true : false));
  }, [businessDetails]);

  //Hot key to add Customer / Vendor depending on context sales / purchase
  useHotkeys("Ctrl + Alt + C, Ctrl + Option + C", () => {
    if (infoType === "vendor") {
      return;
    }
    setShowAddUser(!showAddUser);
  });

  useHotkeys("Ctrl + Alt + V, Ctrl + Option + V", () => {
    if (infoType === "customer") {
      return;
    }
    setShowAddUser(!showAddUser);
  });

  //Invoices and purchase invoices
  const customer_invoices = useSelector((state) => state.invoice.customer_invoices) || [];
  const vendor_invoices = useSelector((state) => state.purchaseInvoice.vendor_invoices);
  const creditNotes = useSelector((state) => state.invoice.creditNotes || []);
  const businessPurchaseInvoices = useSelector((state) => state.purchaseInvoice.businessPurchaseInvoices);

  // const purchaseInvoices = useSelector((state) => state.purchaseInvoice.purchaseInvoices) || [];

  const dispatch = useDispatch();
  const [filter, setFilter] = useState({ month: "none", date_from: null, date_to: null, status: "" });
  // const [monthOptions, setMonthOptions] = useState([]);

  //Handle Date Change
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    setFilter((prevState) => {
      const dateFrom = moment(dateRange[0].startDate).format("YYYY-MM-DD");
      const dateTo = moment(dateRange[0].endDate).format("YYYY-MM-DD");

      const updatedState = { ...prevState, date_from: dateFrom, date_to: dateTo };
      return updatedState;
    });
    //eslint-disable-next-line
  }, [dateRange]);

  // const months = [
  //   { label: "January", id: "1" },
  //   { label: "February", id: "2" },
  //   { label: "March", id: "3" },
  //   { label: "April", id: "4" },
  //   { label: "May", id: "5" },
  //   { label: "June", id: "6" },
  //   { label: "July", id: "7" },
  //   { label: "August", id: "8" },
  //   { label: "September", id: "9" },
  //   { label: "October", id: "10" },
  //   { label: "November", id: "11" },
  //   { label: "December", id: "12" },
  // ];

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const currentMonth = currentDate.getMonth() + 1;
  //   const currentYear = currentDate.getFullYear();

  //   const getLast12Months = () => {
  //     let last12Months = [];

  //     for (let i = 0; i < 12; i++) {
  //       const monthIndex = (currentMonth - i - 1 + 12) % 12;
  //       const year = currentMonth - i - 1 < 0 ? currentYear - 1 : currentYear;
  //       last12Months.push({
  //         label: `${months[monthIndex].label} - ${year}`,
  //         id: months[monthIndex].id,
  //         year: year,
  //       });
  //     }

  //     return last12Months.reverse();
  //   };

  //   const thisMonthLabel = months.find((month) => parseInt(month.id, 10) === currentMonth).label;

  //   const last12Months = getLast12Months();

  //   // Add "This Month" as the first option
  //   const updatedMonthOptions = [
  //     { label: `This Month (${thisMonthLabel})`, id: "none", year: currentYear },
  //     ...last12Months,
  //   ];

  //   // Set the dynamically generated month options
  //   setMonthOptions(updatedMonthOptions);
  // }, []);

  // useEffect(() => {
  //   if (filter.month !== "none") {
  //     const selectedOption = monthOptions.find((month) => month.id === filter.month);

  //     if (selectedOption) {
  //       console.log(selectedOption, "this is the selected option");
  //       const selectedYear = selectedOption.year;
  //       console.log(selectedYear, "this is the selected yaer");
  //       const selectedMonth = parseInt(selectedOption.id, 10);

  //       if (selectedYear && selectedMonth) {
  //         const dateFrom = moment(`${selectedYear}-${selectedMonth}-01`, "YYYY-MM-DD");
  //         const dateTo = dateFrom.clone().endOf("month");

  //         console.log(dateFrom, dateTo, "this is the date from and to");

  //         if (dateFrom.isValid() && dateTo.isValid()) {
  //           // Format date to YYYY-MM-DD
  //           const formattedDateFrom = dateFrom.format("YYYY-MM-DD");
  //           const formattedDateTo = dateTo.format("YYYY-MM-DD");

  //           setFilter((prevFilter) => ({
  //             ...prevFilter,
  //             date_from: formattedDateFrom,
  //             date_to: formattedDateTo,
  //           }));
  //         } else {
  //           console.error("Invalid dates calculated:", dateFrom, dateTo);
  //         }
  //       }
  //     }
  //   } else {
  //     // Handle "This Month" case
  //     const dateFrom = moment().startOf("month");
  //     const dateTo = moment().endOf("month");

  //     // Format date to YYYY-MM-DD
  //     const formattedDateFrom = dateFrom.format("YYYY-MM-DD");
  //     const formattedDateTo = dateTo.format("YYYY-MM-DD");

  //     // Update the filter state
  //     setFilter((prevFilter) => ({
  //       ...prevFilter,
  //       date_from: formattedDateFrom,
  //       date_to: formattedDateTo,
  //     }));
  //   }
  // }, [filter.month, monthOptions]);

  const [salesInvoiceType, setSalesInvoiceType] = useState("normal");
  const [purchaseInvoiceType, setPurchaseInvoiceType] = useState("normal");
  const [nonGstInvoicesPurchase, setNonGstInvoicesPurchase] = useState(false);
  const [statusTypeFilter, setStatusTypeFilter] = useState("all");

  useEffect(() => {
    if (isGstUser === null) return;

    dispatch(setShowWaitLoader(true));

    if (infoType === "customer" && salesInvoiceType === "credit_note") {
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}&is_non_gst=${isGstUser ? 0 : 1}`;

      dispatch(fetchCreditNotes(params)).then(() => dispatch(setShowWaitLoader(false)));
    } else if (infoType === "customer" && salesInvoiceType === "e_invoice") {
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}&is_einvoice=1&is_non_gst=0`;

      dispatch(fetchInvoicesCustomer(params)).then(() => dispatch(setShowWaitLoader(false)));
    } else if (infoType === "customer" && salesInvoiceType === "cancelled") {
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}&is_cancelled=1&is_non_gst=${isGstUser ? 0 : 1}`;

      dispatch(fetchInvoicesCustomer(params)).then(() => dispatch(setShowWaitLoader(false)));
    } else if (infoType === "customer" && salesInvoiceType === "all") {
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}&is_non_gst=${isGstUser ? 0 : 1}`;

      dispatch(fetchInvoicesCustomer(params)).then(() => dispatch(setShowWaitLoader(false)));
    } else if (infoType === "customer") {
      //When infoType is customer run these string queries
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}&payment_type=${statusTypeFilter === "all" ? "" : statusTypeFilter}
        &is_non_gst=${isGstUser ? 0 : 1}&is_cancelled=0&is_einvoice=0
      `;

      dispatch(fetchInvoicesCustomer(params)).then(() => dispatch(setShowWaitLoader(false)));
    } else if (infoType === "vendor" && purchaseInvoiceType === "normal") {
      //When infoType is vendors run these string queries
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}&payment_type=${statusTypeFilter === "all" ? "" : statusTypeFilter}${
        nonGstInvoicesPurchase !== "All" && `&is_non_gst=${nonGstInvoicesPurchase ? "1" : "0"}&is_cancelled=0`
      }`;

      dispatch(fetchInvoicesVendor(params)).then(() => dispatch(setShowWaitLoader(false)));
    } else if (infoType === "vendor" && purchaseInvoiceType === "cancelled") {
      //When infoType is vendors run these string queries
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}&payment_type=${statusTypeFilter === "all" ? "" : statusTypeFilter}${
        nonGstInvoicesPurchase !== "All" && `&is_non_gst=${nonGstInvoicesPurchase ? "1" : "0"}&is_cancelled=1`
      }`;

      dispatch(fetchInvoicesVendor(params)).then(() => dispatch(setShowWaitLoader(false)));
    } else if (infoType === "vendor" && purchaseInvoiceType === "all") {
      //When infoType is vendors run these string queries
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from || ""
      }&date_to=${filter.date_to || ""}&payment_type=${statusTypeFilter === "all" ? "" : statusTypeFilter}${
        nonGstInvoicesPurchase !== "All" && `&is_non_gst=${nonGstInvoicesPurchase ? "1" : "0"}`
      }`;

      dispatch(fetchInvoicesVendor(params)).then(() => dispatch(setShowWaitLoader(false)));
    } else if (infoType === "vendor") {
      const params = `?search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
        filter.date_from ? filter.date_from + " 00:00:00" : ""
      }&date_to=${filter.date_to ? filter.date_to + " 23:59:59" : ""}&payment_type=${
        statusTypeFilter === "all" ? "" : statusTypeFilter
      }${nonGstInvoicesPurchase !== "All" && `&is_non_gst=${nonGstInvoicesPurchase ? "1" : "0"}`}`;
      dispatch(fetchBusinessPurchaseInvoices(params)).then(() => dispatch(setShowWaitLoader(false)));
    }
  }, [
    dispatch,
    search,
    filter,
    infoType,
    salesInvoiceType,
    purchaseInvoiceType,
    statusTypeFilter,
    isGstUser,
    nonGstInvoicesPurchase,
  ]);

  const handleDownloadExcel = async (type) => {
    if (!type) {
      return;
    }

    const params = `&search=${search}&month=${filter.month !== "none" ? filter.month : ""}&date_from=${
      filter.date_from || ""
    }&date_to=${filter.date_to || ""}&payment_type=${statusTypeFilter === "all" ? "" : statusTypeFilter}`;

    try {
      let response = null;
      if (type === "sales_invoice") {
        response = await http.get(`/invoice/api/export/sale_invoice/?export=excel${params}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        });
      } else if (type === "purchase_invoice") {
        response = await http.get(`/invoice/api/export/purchase_invoice/?export=excel${params}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        });
      }

      // Create a URL for the EXCEL blob
      const excelURL = URL.createObjectURL(response.data);

      // Create a temporary anchor element to trigger the download
      const link = document.createElement("a");
      link.href = excelURL;
      link.download = `${type}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error("EXCEL not found!");
    }
  };
  // let debounceTimer;

  // const handleSetDebouncedSearch = (search) => {
  //   clearTimeout(debounceTimer);
  //   debounceTimer = setTimeout(() => {
  //     setSearch(search);
  //   }, 500);
  // };

  const setDebouncedSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 1000);

  const handleLoadMore = () => {
    if (infoType === "customer" && salesInvoiceType === "credit_note") {
      dispatch(fetchMoreCreditNotes(creditNotes.next));
    } else if (infoType === "customer" && customer_invoices.next) {
      dispatch(fetchMoreInvoicesCustomer(customer_invoices.next));
    } else if (infoType === "vendor" && purchaseInvoiceType === "normal" && vendor_invoices.next) {
      dispatch(fetchMoreInvoicesVendor(vendor_invoices.next));
    } else if (infoType === "vendor" && vendor_invoices.next) {
      // dispatch(fetchMoreInvoicesVendor(vendor_invoices.next));
    }
  };

  // const handleFilter = (key, value) => {
  //   setFilter({ ...filter, [key]: value });
  // };

  //React Joyride - Guide user on how to use app
  const steps = [
    {
      target: "#joyride-step-1",
      content: "Search invoices by party or Invoice Number.",
    },
    {
      target: "#joyride-step-2",
      content: "Add new party directly from this section.",
    },
    {
      target: "#joyride-step-3",
      content: "Select a date range to filter invoices.",
    },
    {
      target: "#joyride-step-4",
      content: "Choose the type of invoices to view.",
    },
    {
      target: "#joyride-step-5",
      content: "Filter invoices by payment status: paid or remaining.",
    },
    {
      target: "#joyride-step-6",
      content: "View, edit, or download invoices from this table.",
    },
  ];

  const [localStorageTransactionStatus, setLocalStorageTransactionStatus] = useState(
    localStorage.getItem("showed_transaction_guide") || false
  );

  //handle till final steps
  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished" || status === "skipped") {
      localStorage.setItem("showed_transaction_guide", true);
      setLocalStorageTransactionStatus(false);
    }
  };

  const [selectedInvoices, setSelectedInvoices] = useState([]);

  const [authFormData, setAuthFormData] = useState({
    username: "",
    password: "",
    ip_address: "",
  });

  const handleOpenConvertWindow = () => {
    setShowAuthModal(true);
  };

  //handle convert to invoice
  const handleConvertToEInvoice = () => {
    // if (selectedInvoices.length === 1) {
    //   alert("Handle One E-Invoice Operation");
    // }
    // if (selectedInvoices.length > 1) {
    //   alert("handle multi invoice operation");
    // }

    if (authFormData.username.length === 0 && authFormData.password?.length === 0) {
      toast.error("Username and password cannot be empty!");
      return;
    }

    dispatch(setShowWaitLoader(true));

    dispatch(bulkCreateEInvoice({ invoices: selectedInvoices, authData: authFormData }))
      .then((action) => {
        dispatch(setShowWaitLoader(false));
        if (action.payload?.success && action.payload?.success?.length > 0) {
          toast.success(`${action.payload?.success?.length} converted to e-invoice`);
        } else {
          toast.error(`sorry, invoices cannot be Converted!`);
        }

        if (action.payload?.errors && action.payload?.erros?.length > 0) {
          toast.error(`${action.payload?.success?.length} failed to converted to e-invoice`);
        } else {
          toast.error(`sorry, invoices cannot be Converted!`);
        }
      })
      .catch((err) => {
        dispatch(setShowWaitLoader(false));
        toast.error("ran into some software issues while converting to e-invoice!");
      });
  };

  //convert all invoices to E-Invoice
  const handleConvertAllToEInvoice = () => {
    if (customer_invoices?.data?.length === 0) {
      return;
    }

    const updatedSelectedInvoices = [];

    customer_invoices?.data?.forEach((invoice) => {
      if (invoice?.irn) {
        return;
      }

      if (invoice?.is_deleted === true) {
        return;
      }

      if (invoice?.customer?.gst_number.length === 0) {
        return;
      }

      updatedSelectedInvoices.push(invoice?.id);
    });

    setSelectedInvoices(updatedSelectedInvoices);
  };

  //Fetch initial HSN data;
  useEffect(() => {
    dispatch(fetchHSNData());
  }, []);

  //auth form
  const [showAuthModal, setShowAuthModal] = useState(false);

  //Close E-WAy Auth Modal
  const handleCloseAuthModal = () => {
    setShowAuthModal(false);
  };

  //user ip address:
  const [ipAddress, setIpAdderess] = useState(null);

  ///side effect to set IP address in required forms;
  useEffect(() => {
    if (ipAddress) {
      //set ip address in auth from data.
      setAuthFormData((prevState) => {
        return { ...prevState, ip_address: ipAddress };
      });
    }
  }, [ipAddress]);

  // Fetch user's IP address
  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        if (response.data.ip) {
          setIpAdderess(response.data.ip);
        }
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };
    fetchIPAddress();
  }, []);

  // Handle form input change
  const handleAuthInputChange = (e) => {
    const { name, value } = e.target;
    setAuthFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmitAuth = (e) => {
    e.preventDefault();
    handleCloseAuthModal();
    handleConvertToEInvoice();
  };

  return (
    <>
      {!localStorageTransactionStatus && (
        <Joyride
          steps={steps}
          continuous={true}
          showSkipButton={true}
          disableBeacon={true}
          callback={handleJoyrideCallback}
          styles={{ options: { primaryColor: "#6366f1" } }}
        />
      )}
      <div className="lg:min-w-[60vw] h-[calc(100vh-170px)]">
        <CustomModal isOpen={showAuthModal} handleClose={handleCloseAuthModal}>
          <form onSubmit={handleSubmitAuth} className="flex flex-col items-center">
            <div className="mb-4 w-full">
              <label className="block text-gray-700 pb-[5px]">
                GST Portal Username<span className="pl-1 text-red-500">*</span>
              </label>
              <input
                type="text"
                name="username"
                value={authFormData.username}
                onChange={handleAuthInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <div className="mb-4 w-full pb-[5px]">
              <label className="block text-gray-700 pb-[5px]">
                GST Portal Password<span className="pl-1 text-red-500">*</span>
              </label>
              <input
                type="password"
                name="password"
                value={authFormData.password}
                onChange={handleAuthInputChange}
                className="w-full p-2 border rounded"
                required
              />
            </div>
            <button type="submit" className="px-[20px] py-[10px] bg-black text-white rounded-full max-w-fit">
              Submit
            </button>
            <span className="text-center pt-[10px] text-sm font-semibold text-neutral-500">
              Note: Please login on{" "}
              <a
                className="text-blue-500"
                href="https://docs.ewaybillgst.gov.in/"
                target="_blank"
                rel="noopener noreferrer"
              >
                GST Portal
              </a>{" "}
              and in Registration {">"} For GSP {">"} Select Tera Software Limited as GSP. Checkout{" "}
              <a className="text-blue-500" href="/documents/gsp_guide.pdf" target="_blank">
                Visual Guide.
              </a>
            </span>
            <span className="text-center pt-[10px] text-sm font-semibold text-neutral-500">
              Note: The GST number and email in the business profile must match the credentials in GST Portal.
            </span>
          </form>
        </CustomModal>
        <CustomModal isOpen={showAddUser} handleClose={() => setShowAddUser(false)}>
          {infoType === "customer" && <AddUser handleCloseModal={() => setShowAddUser(false)} currentCustomer={{}} />}
          {infoType === "vendor" && <AddVendor handleCloseModal={() => setShowAddUser(false)} currentVendor={{}} />}
        </CustomModal>
        <div className="font-semibold text-xl pb-[20px] pl-[10px]">Transactions</div>
        <div className="flex justify-between items-center">
          <div className="max-w-fit">
            <label for="default-search" className="sr-only">
              Search
            </label>
            <div className="flex items-center">
              <input
                type="search"
                onChange={(e) => setDebouncedSearch(e.target.value)}
                id="joyride-step-1"
                className="border-[1px] border-neutral-400 px-[20px] py-[8px] w-[350px] rounded-full focus:outline-none"
                placeholder="Search"
                required
              />
              <div className="-translate-x-[103%]">
                <IconWrapper>
                  <Search className="z-10 text-white" />
                </IconWrapper>
              </div>

              {/* <div className="absolute cursor-pointer z-5 inset-y-0 end-0 flex items-center px-3 ">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
                >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                  />
                  </svg>
            </div> */}
            </div>
          </div>
          <div className="flex gap-[10px]">
            <button
              className="flex gap-[5px] bg-black text-white px-[20px] py-[10px] rounded-full"
              onClick={() => handleDownloadExcel(infoType === "customer" ? "sales_invoice" : "purchase_invoice")}
            >
              <span className="capitalize">Export To Excel</span>
            </button>
            <button
              className="flex gap-[5px] bg-black text-white px-[20px] py-[10px] rounded-full"
              onClick={() => setShowAddUser(true)}
              id="joyride-step-2"
            >
              <span>+</span>
              <span className="capitalize">{`Add ${infoType}`}</span>
            </button>
          </div>
        </div>
        <div className="w-full flex justify-between my-[20px]">
          <div className="flex gap-[10px] items-center justify-center">
            {/* <div className="flex justify-center items-center">
              <Select
                id="joyride-step-3"
                value={filter.month}
                onChange={(e) => setFilter({ ...filter, month: e.target.value })}
                sx={{
                  width: "auto",
                  height: 40,
                  boxShadow: "none",
                  borderRadius: "999px",
                  padding: "10px",
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                    border: "1px solid gray",
                  },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "gray",
                    border: "1px solid gray",
                  },
                }}
              > */}
            {/* <MenuItem value={filter.month}>This Month</MenuItem> */}
            {/* {monthOptions.map((i) => (
                  <MenuItem value={i.id}>{i.label}</MenuItem>
                ))}
              </Select>
            </div> */}

            {infoType === "customer" && (
              <div>
                <Select
                  id="joyride-step-4"
                  value={salesInvoiceType}
                  onChange={(e) => setSalesInvoiceType(e.target.value)}
                  sx={{
                    width: "auto",
                    height: 40,
                    boxShadow: "none",
                    borderRadius: "999px",
                    padding: "10px",
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "gray",
                      border: "1px solid gray",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "gray",
                      border: "1px solid gray",
                    },
                  }}
                >
                  {/* <MenuItem value={filter.month}>This Month</MenuItem> */}
                  <MenuItem value={"all"}>All Sales Invoice</MenuItem>
                  <MenuItem value={"normal"}>Sales Invoice Only</MenuItem>
                  <MenuItem value={"cancelled"}>Cancelled Sales Invoice Only</MenuItem>
                  {isGstUser && <MenuItem value={"e_invoice"}>Sales E-Invoice Only</MenuItem>}
                  <MenuItem value={"credit_note"}>Credit Note</MenuItem>
                </Select>
              </div>
            )}

            {infoType === "vendor" && (
              <div>
                <Select
                  id="joyride-step-4"
                  value={purchaseInvoiceType}
                  onChange={(e) => setPurchaseInvoiceType(e.target.value)}
                  sx={{
                    width: "auto",
                    height: 40,
                    boxShadow: "none",
                    borderRadius: "999px",
                    padding: "10px",
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "gray",
                      border: "1px solid gray",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "gray",
                      border: "1px solid gray",
                    },
                  }}
                >
                  <MenuItem value={"all"}>All Stock Purchase Invoice</MenuItem>
                  <MenuItem value={"normal"}>Stock Purchase Invoice Only</MenuItem>
                  <MenuItem value={"cancelled"}>Cancelled Stock Purchase Invoice Only</MenuItem>
                  <MenuItem value={"other"}>Business Purchase Invoice</MenuItem>
                </Select>
              </div>
            )}

            {infoType === "vendor" && !isGstUser && (
              <div>
                <Select
                  id="joyride-step-4"
                  value={nonGstInvoicesPurchase}
                  onChange={(e) => setNonGstInvoicesPurchase(e.target.value)}
                  sx={{
                    width: "auto",
                    height: 40,
                    boxShadow: "none",
                    borderRadius: "999px",
                    padding: "10px",
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "gray",
                      border: "1px solid gray",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "gray",
                      border: "1px solid gray",
                    },
                  }}
                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={true}>Non-GST Invoices</MenuItem>
                  <MenuItem value={false}>GST Invoices</MenuItem>
                </Select>
              </div>
            )}

            {salesInvoiceType !== "credit_note" && (
              <div>
                <Select
                  id="joyride-step-5"
                  value={statusTypeFilter}
                  onChange={(e) => setStatusTypeFilter(e.target.value)}
                  sx={{
                    width: "auto",
                    minWidth: "150px",
                    height: 40,
                    boxShadow: "none",
                    borderRadius: "999px",
                    padding: "10px",
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "gray",
                      border: "1px solid gray",
                    },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "gray",
                      border: "1px solid gray",
                    },
                  }}
                >
                  {/* <MenuItem value={filter.month}>This Month</MenuItem> */}
                  <MenuItem value={"all"}>All</MenuItem>
                  <MenuItem value={"paid"}>Paid Invoices</MenuItem>
                  <MenuItem value={"remain_payment"}>Remaining Amount Invoices</MenuItem>
                </Select>
              </div>
            )}
            <div className="flex h-[40px]" id="joyride-step-3">
              <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
            </div>
          </div>
          {/* <div className="flex justify-center items-center">
            <div className="font-semibold mx-2">Between </div>
            <input
              type="date"
              className="border-2 py-[10px] px-[20px] rounded-full"
              value={filter.date_from}
              max={filter.date_to}
              onChange={(e) => handleFilter("date_from", e.target.value)}
            />
            <div className="font-semibold mx-2">to</div>
            <input
              type="date"
              className="border-2 py-[10px] px-[20px] rounded-full"
              value={filter.date_to}
              min={filter.date_from}
              onChange={(e) => handleFilter("date_to", e.target.value)}
            />
          </div> */}
          {selectedInvoices?.length !== 0 &&
            infoType === "customer" &&
            salesInvoiceType !== "e_invoice" &&
            isGstUser && (
              <div className="flex justify-center items-center">
                <button
                  onClick={() => handleOpenConvertWindow()}
                  className="px-[20px] py-[10px] bg-black text-white rounded-full"
                >
                  Convert {selectedInvoices.length} {selectedInvoices.length === 1 ? "Invoice" : "Invoices"} to
                  E-Invoice
                </button>
              </div>
            )}

          {selectedInvoices?.length === 0 &&
            infoType === "customer" &&
            salesInvoiceType !== "e_invoice" &&
            isGstUser && (
              <div className="flex justify-center items-center">
                <button
                  onClick={handleConvertAllToEInvoice}
                  className="px-[20px] py-[10px] bg-black text-white rounded-full"
                >
                  Convert All to E-Invoice
                </button>
              </div>
            )}
        </div>
        <div>
          <InvoiceDataTable
            infoType={infoType}
            next={
              infoType === "customer"
                ? salesInvoiceType === "credit_note"
                  ? creditNotes.next
                  : customer_invoices.next
                : purchaseInvoiceType === "normal" ||
                  purchaseInvoiceType === "all" ||
                  purchaseInvoiceType === "cancelled"
                ? vendor_invoices.next
                : businessPurchaseInvoices.next
            }
            invoices={
              infoType === "customer"
                ? salesInvoiceType === "credit_note"
                  ? creditNotes.data
                  : customer_invoices.data
                : purchaseInvoiceType === "normal" ||
                  purchaseInvoiceType === "all" ||
                  purchaseInvoiceType === "cancelled"
                ? vendor_invoices.data
                : businessPurchaseInvoices.data
            }
            salesInvoiceType={salesInvoiceType}
            purchaseInvoiceType={purchaseInvoiceType}
            creditNote={salesInvoiceType !== "credit_note" ? true : false}
            businessPurchaseInvoice={purchaseInvoiceType === "other" ? true : false}
            salesEInvoice={salesInvoiceType === "e_invoice" ? true : false}
            fetchMore={handleLoadMore}
            selectedInvoices={selectedInvoices}
            setSelectedInvoices={setSelectedInvoices}
            nonGstInvoicesPurchase={nonGstInvoicesPurchase}
          />
        </div>
      </div>
    </>
  );
};

export default InvoiceContainer;
