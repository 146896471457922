import React, { useEffect, useState } from "react";
import { http } from "../../service/http";
import { useDispatch } from "react-redux";
import { setBusinessDetails } from "../../reducers/Slices/userSlice";
import { useNavigate } from "react-router-dom";
// import GSTScreen from "../SharedComponents/GSTScreen";

import { searchZipcode } from "../../reducers/Slices/userSlice";
import toast from "react-hot-toast";
// import GSTScreenMandatory from "../SharedComponents/GSTScreenMandatory";
import GSTScreen from "../SharedComponents/GSTScreen";

export default function CreateBusinessModal() {
  const [formData, setFormData] = useState({ industry: 1, business_type: 1 });
  const [menus, setMenus] = useState({ industries: [], businesses: [] });
  const [showGstTab, setShowGstTab] = useState(true);

  //By default don't disable these fields
  const [disableFieldsOnGST, setDisableFieldsOnGST] = useState({
    zipcode: false,
    business_name: false,
    state: false,
    city: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      await Promise.all([getIndustriesMenu(), getBusinessTypeMenu()]);
    }
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async (url, key) => {
    try {
      const response = await http.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.data.status === "success") {
        setMenus((prevMenus) => ({ ...prevMenus, [key]: response.data.data }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getIndustriesMenu = () => {
    fetchData("/master_menu/api/industry/create", "industries");
  };

  const getBusinessTypeMenu = () => {
    fetchData("/master_menu/api/businesstype/list_create", "businesses");
  };

  const stateCodeMapper = (state) => {
    const stateCodes = {
      "ANDAMAN AND NICOBAR": "35",
      "ANDHRA PRADESH": "37",
      "ARUNACHAL PRADESH": "12",
      ASSAM: "18",
      BIHAR: "10",
      CHANDIGARH: "04",
      CHHATTISGARH: "22",
      "DADRA AND NAGAR HAVELI & DAMAN AND DIU": "26",
      DELHI: "07",
      GOA: "30",
      GUJARAT: "24",
      HARYANA: "06",
      "HIMACHAL PRADESH": "02",
      "JAMMU AND KASHMIR": "01",
      JHARKHAND: "20",
      KARNATAKA: "29",
      KERALA: "32",
      LADAKH: "38",
      LAKSHYADEEP: "31",
      "MADHYA PRADESH": "23",
      MAHARASHTRA: "27",
      MANIPUR: "14",
      MEGHALAYA: "17",
      MIZORAM: "15",
      NAGALAND: "13",
      ODISHA: "21",
      "OTHER COUNTRIES": "99",
      "Other Territory": "97",
      PUDUCHERRY: "34",
      PUNJAB: "03",
      RAJASTHAN: "08",
      SIKKIM: "11",
      "TAMIL NADU": "33",
      TELANGANA: "36",
      TRIPURA: "16",
      "UTTAR PRADESH": "09",
      UTTARAKHAND: "05",
      "WEST BENGAL": "19",
    };

    return parseInt(stateCodes[state]) || null;
  };

  const handleSearchZipcode = async () => {
    if (formData?.gst_number && disableFieldsOnGST?.zipcode) {
      toast.error("Can't change city and state on businesses with GST!");
      return;
    }

    dispatch(searchZipcode(formData.zipcode))
      .then((action) => {
        if (action.payload) {
          setFormData({
            ...formData,
            state: action.payload.state,
            city: action.payload.city,
            address: action.payload.address,
            state_code:
              `${action.payload.state_code}`.length > 1 ? action.payload.state_code : `0${action.payload.state_code}`,
            country_code: action.payload.country_code,
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  useEffect(() => {
    if (formData?.gst_number?.length > 0) {
      if (formData?.stateCode && formData?.stateCode?.length > 1) return;

      setFormData((prevState) => {
        return {
          ...prevState,
          state_code: formData?.gst_number?.slice(0, 2) || stateCodeMapper(formData?.state?.toUpperCase() || null),
        };
      });
    }
  }, [formData?.gst_number]);

  const handleChange = (e, data) => {
    e.preventDefault();
    setFormData((prevFormData) => ({ ...prevFormData, ...data }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let updatedFormData = { ...formData };

    //Check if gst number exists - if not then add empty gst number
    if (!formData.gst_number) {
      updatedFormData = { ...updatedFormData, gst_number: null };
    }

    try {
      const response = await http.post(`/user_profile/backend/business-profile/`, updatedFormData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.data.status === "success") {
        dispatch(setBusinessDetails(response.data.data));
        localStorage.setItem("business_profile", "true");

        //On new account creation navigate to plan selection page
        navigate("/plan_selection");
        // navigate("/");
      }
    } catch (error) {
      toast.error(error?.message);
    }
  };

  //Disable these fields on GST number based on response data of GST api - time complexity - O(n)
  const disableOnGST = (responseData) => {
    // List of objects we are disabling on field object present - Business Name, Zipcode, City and State.
    const propertiesToDisable = ["zipcode", "business_name", "state", "city"];

    // Map over each property and set disableFieldsOnGST on true if non-empty field and gst number present
    propertiesToDisable.forEach((fieldName) => {
      if (responseData[fieldName]?.length > 0) {
        setDisableFieldsOnGST((prevState) => {
          return { ...prevState, [fieldName]: true };
        });
      } else {
        setDisableFieldsOnGST((prevState) => {
          return { ...prevState, [fieldName]: false };
        });
      }
    });
  };

  return (
    <>
      {showGstTab ? (
        <GSTScreen
          setShowGstTab={setShowGstTab}
          formData={formData}
          setFormData={setFormData}
          disableOnGST={disableOnGST}
        />
      ) : (
        <div className="absolute z-20 h-[100dvh] bg-white flex  justify-center top-0 left-0 px-4 w-full max-h-full">
          <div className="relative max-w-md overflow-y-scroll">
            <div className="pt-[20px] md:pt-[40px] ">
              <h3 className="text-xl font-semibold text-gray-900 w-full text-center">Create Business Account</h3>
            </div>
            <form className="p-4 md:p-5" onSubmit={handleSubmit}>
              <div className="grid gap-4 mb-4 grid-cols-2">
                <div className="col-span-2">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Email id*
                  </label>
                  <input
                    type="email"
                    onChange={(e) => handleChange(e, { email: e.target.value })}
                    value={formData.email}
                    name="email"
                    id="email"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="name@profitai.com"
                    required
                  />
                </div>
                <div className="col-span-2">
                  <label htmlFor="business_name" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Business Name*
                  </label>
                  <input
                    type="text"
                    onChange={(e) => handleChange(e, { business_name: e.target.value })}
                    value={formData.business_name}
                    name="business_name"
                    id="business_name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    placeholder="Enter Business name"
                    disabled={disableFieldsOnGST?.business_name}
                    required
                  />
                </div>
                <div className="col-span-2 flex w-full">
                  <div className="relative z-0 w-full group">
                    <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900 ">
                      Zipcode*
                    </label>
                    <div className="flex gap-[10px] ">
                      <input
                        type="number"
                        min={0}
                        id="zipcode"
                        onChange={(e) => {
                          handleChange(e, { zipcode: e.target.value });
                        }}
                        value={formData.zipcode}
                        className="flex-1 shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                        placeholder="Enter zipcode"
                        disabled={disableFieldsOnGST?.zipcode}
                        required
                      />
                      <button
                        type="button"
                        onClick={handleSearchZipcode}
                        className="px-[20px] py-[10px] rounded-lg bg-black text-white"
                      >
                        Find
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-span-2  flex">
                  <div className="w-1/2 px-1">
                    {" "}
                    <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 ">
                      State
                    </label>
                    <input
                      type="state"
                      id="state"
                      value={formData.state}
                      onChange={(e) => handleChange(e, { state: e.target.value })}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="Enter state"
                      disabled={disableFieldsOnGST?.state}
                      required
                    />
                  </div>
                  <div className="w-1/2 px-1">
                    {" "}
                    <label htmlFor="city" className="ml-2 block mb-2 text-sm font-medium text-gray-900 ">
                      City
                    </label>
                    <input
                      type="city"
                      id="city"
                      value={formData.city}
                      onChange={(e) => handleChange(e, { city: e.target.value })}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="Enter city"
                      disabled={disableFieldsOnGST?.city}
                      required
                    />
                  </div>
                  <div className="w-1/3 p-1">
                    <label htmlFor="state_code" className="ml-2 block mb-2 text-sm font-medium text-gray-900 ">
                      State Code<span className="pl-1 text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      id="state_code"
                      value={formData.state_code}
                      onChange={(e) => setFormData({ ...formData, state_code: e.target.value })}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="i.e 17"
                      required
                    />
                  </div>
                </div>
                <form className="col-span-2 ">
                  <label htmlFor="address2" className="block mb-2 text-sm font-medium">
                    Address 1* (Max 250 Characters)
                  </label>
                  <textarea
                    id="address1"
                    value={formData.address1}
                    rows="2"
                    maxLength={250}
                    onChange={(e) => handleChange(e, { address1: e.target.value })}
                    className="block p-2.5 w-full text-sm   rounded-lg border border-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Address 1..."
                  ></textarea>
                </form>
                <form className="col-span-2">
                  <label htmlFor="address2" className="block mb-2 text-sm font-medium">
                    Address 2 (Max 250 Characters)
                  </label>
                  <textarea
                    id="address2"
                    rows="2"
                    maxLength={250}
                    value={formData.address2}
                    onChange={(e) => handleChange(e, { address2: e.target.value })}
                    className="block p-2.5 w-full text-sm  rounded-lg border border-gray-700 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Address 2..."
                  ></textarea>
                </form>
                <div className="col-span-2">
                  <label htmlFor="industry" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Industry Type*
                  </label>
                  <select
                    id="industry"
                    value={formData.industry}
                    onChange={(e) => handleChange(e, { industry: parseInt(e.target.value) })}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                  >
                    {menus.industries.map((item) => (
                      <option key={item.id + item.industry_name} value={`${item.id}`}>
                        {item.industry_name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-span-2">
                  <label htmlFor="business_type" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Business Type*
                  </label>

                  <select
                    id="business_type"
                    value={formData.business_type}
                    onChange={(e) => handleChange(e, { business_type: parseInt(e.target.value) })}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:border-gray-500 dark:placeholder-gray-400  dark:focus:ring-primary-500 dark:focus:border-primary-500"
                    required
                  >
                    {menus.businesses.map((item) => (
                      <option key={item.id + item.business_type} value={`${item.id}`}>
                        {item.business_type}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="w-full flex justify-center py-[10px]">
                <button
                  type="submit"
                  // onClick={handleSubmit}
                  className="px-[20px] py-[10px] bg-black text-white rounded-full"
                >
                  Create Account
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
