import { useDispatch, useSelector } from "react-redux";
import { generateBarcodeImage } from "../../../reducers/Slices/productSlice";
import formatDuration from "../../../utils/formatDuration";
import React, { useLayoutEffect, useState } from "react";
import toast from "react-hot-toast";
import { http } from "../../../service/http";

function ServiceDetailsTable({ product }) {
  const dispatch = useDispatch();

  const handlePrintBarcode = async (product) => {
    if (!product) {
      return;
    }

    const barcode = product?.service?.bar_code_number;
    if (barcode) {
      try {
        const response = await http.get(`/inventory/api/barcode/generate/${barcode}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        });
        // Create a URL for the PNG blob
        const imageURL = URL.createObjectURL(response.data);

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = `${barcode}_barcode.png`; // Set the file name as needed
        document.body.appendChild(link);
        link.click(); // Programmatically click the link to trigger the download
        document.body.removeChild(link); // Clean up by removing the link after the download
      } catch (error) {
        toast.error("Some error occurred while printing barcode", error);
      }
    }
  };

  const businessDetails = useSelector((state) => state.user);

  const [isGstUser, setIsGstUser] = useState(false);

  useLayoutEffect(() => {
    setIsGstUser(() => businessDetails?.gst_number && businessDetails?.gst_number?.length > 0);
  }, [businessDetails]);

  return (
    <div className="overflow-y-scroll h-[55vh] max-h-[55vh] border-[0.5px] border-[rgb(0,0,0,.25)]">
      <table border="1" className="table-auto w-full">
        <thead className="bg-gray-50">
          <tr>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              MRP Price
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Rate*
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Barcode Number*
            </th>
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Service Cost to Business / Expense*
            </th>
            {/* <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Duration Time
            </th> */}
            {isGstUser && (
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
                scope="col"
              >
                Tax %
              </th>
            )}
            <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
              scope="col"
            >
              Print Barcode
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product?.service?.mrp || "N/A"}
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product?.service?.rate || "N/A"}
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product?.service?.bar_code_number || "Nil"}
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product?.service?.cost || "N/A"}
            </td>
            {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              {product?.service?.duration_time ? formatDuration(product?.duration_time) : "0 Minutes" || "N/A"}
            </td> */}
            {isGstUser && (
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                {product.service?.tax || "N/A"}
              </td>
            )}
            {product?.service?.bar_code_number ? (
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                <button onClick={() => handlePrintBarcode(product)} className="font-bold">
                  Print Barcode{" "}
                </button>
              </td>
            ) : (
              <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                N/A
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default ServiceDetailsTable;
