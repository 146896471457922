import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../../../reducers/Slices/customerSlice";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, selectClasses, TextField } from "@mui/material";
import { fetchBrandList, fetchProducts, searchProducts } from "../../../../reducers/Slices/productSlice";
import { subDays } from "date-fns";

//Custom Components
import DateRangeSelector from "../../../ProfitAndLoss/profitAndLossComponents/DateRangeSelector";
import StatementsTable from "./StatementsTable";

import { XCircle } from "react-feather";
import toast from "react-hot-toast";
import { fetchMoreStockWiseReportsDaily, fetchStockReportsDaily } from "../../../../reducers/Slices/reportSlice";
import { changeDateFormat } from "../../../../utils/dateFormat";
import { http } from "../../../../service/http";
import { setShowWaitLoader } from "../../../../reducers/Slices/appSlice";

function StockWise() {
  const dispatch = useDispatch();

  // const customers = useSelector((state) => state.customer.customers.data) || [];
  const products = useSelector((state) => state.product.products.data) || [];
  const Companies = useSelector((state) => state.product.brands.data) || [];

  //stock wise reports and data
  const stockReports = useSelector((state) => state.report.stockReports.data);
  const stockReportsNext = useSelector((state) => state.report.stockReports.next);

  // const Companies = [
  //   "Tech Innovations Inc.",
  //   "Global Solutions Ltd.",
  //   "EcoSmart Technologies",
  //   "NextGen Systems LLC",
  //   "FutureWave Enterprises",
  //   "Skyline Dynamics",
  //   "Quantum Leap Industries",
  //   "Visionary Creations Co.",
  //   "Pinnacle Consulting Group",
  //   "Blue Horizon Ventures",
  // ];

  //Handle Date Range Operations
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //Customer Selection Operations
  // const [selectedCustomer, setSelectedCustomer] = useState(null);
  // const [customerSearchTerm, setCustomerSearchTerm] = useState("");
  // const setDebouncedCustomerSearchTerm = debounce((value) => setCustomerSearchTerm(value));

  // useEffect(() => {
  //   if (customerSearchTerm.length > 0) {
  //     dispatch(fetchCustomers(`?search=${customerSearchTerm}`));
  //   } else {
  //     dispatch(fetchCustomers());
  //   }
  //   //eslint-disable-next-line
  // }, [customerSearchTerm]);

  //Product Selection Operations - todo - if company is selected then only show products of that company only.
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const [productAutoCompleteLoading, setProductAutoCompleteLoading] = useState(false);
  const setDebouncedProductSearchTerm = debounce((value) => setProductSearchTerm(value), 1000);

  useEffect(() => {
    if (productSearchTerm.length > 0) {
      setProductAutoCompleteLoading(true);
      dispatch(searchProducts(`?product_search=${productSearchTerm}`))
        .then((action) => {
          setProductAutoCompleteLoading(false);
        })
        .catch((err) => {
          setProductAutoCompleteLoading(false);
        });
    } else {
      dispatch(fetchProducts());
    }
    //eslint-disable-next-line
  }, [productSearchTerm]);

  //Company Selection Operation
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companySearchTerm, setCompanySearchTerm] = useState("");
  const [companyAutoCompleteLoading, setCompanyAutoCompleteLoading] = useState(false);
  const setDebouncedCompanySearchTerm = debounce((value) => setCompanySearchTerm(value), 1000);

  useEffect(() => {
    if (companySearchTerm.length > 0) {
      setCompanyAutoCompleteLoading(true);
      dispatch(fetchBrandList(`?search=${companySearchTerm}`))
        .then((action) => {
          setCompanyAutoCompleteLoading(false);
        })
        .catch((err) => {
          setCompanyAutoCompleteLoading(false);
        });
    } else {
      dispatch(fetchBrandList());
    }
    //eslint-disable-next-line
  }, [companySearchTerm]);

  const [selectedStockList, setSelectedStockList] = useState([]);

  //get initial reports
  useEffect(() => {
    //Based on date Range change generate this base string
    const start_date = changeDateFormat(dateRange[0].startDate);
    const end_date = changeDateFormat(dateRange[0].endDate);

    let baseString = `?start_date=${start_date} 00:00:00&end_date=${end_date} 23:59:59`;

    if (selectedStockList?.length > 0) {
      baseString += `&product_ids=${selectedStockList.map((stock) => stock.id).join(",")}`;
    }

    if (selectedCompany) {
      baseString += `&brand=${selectedCompany}`;
    }

    dispatch(setShowWaitLoader(true));

    dispatch(fetchStockReportsDaily(baseString))
      .then((action) => {
        dispatch(setShowWaitLoader(false));
        if (action.payload) {
        } else {
          toast.error("There was some error fetching stock-wise reports");
        }
      })
      .catch((err) => {
        dispatch(setShowWaitLoader(false));
        toast.error("There was some error fetching stock-wise reports");
      });
    //eslint-disable-next-line
  }, [selectedStockList, selectedCompany, dateRange.startDate, dateRange.endDate]);

  // Fetch more statements on scroll if available
  const fetchNextStatements = () => {
    if (stockReportsNext) {
      dispatch(fetchMoreStockWiseReportsDaily(stockReportsNext));
    }
  };

  //Download operations
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState("xlsx");

  const handleDownloadExcel = async (e) => {
    e.preventDefault();

    const start_date = changeDateFormat(dateRange[0].startDate);
    const end_date = changeDateFormat(dateRange[0].endDate);

    let baseString = `&start_date=${start_date} 00:00:00&end_date=${end_date} 23:59:59`;

    if (selectedStockList?.length > 0) {
      baseString += `&product_ids=${selectedStockList.map((stock) => stock.id).join(",")}`;
    }

    dispatch(setShowWaitLoader(true));
    try {
      const response = await http.get(`/report/stock/daily/?export=1${baseString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      });

      // Create a URL for the EXCEL blob
      const excelURL = URL.createObjectURL(response.data);

      // Create a temporary anchor element to trigger the download
      const link = document.createElement("a");
      link.href = excelURL;
      link.download = `Stock Wise Report`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(setShowWaitLoader(false));
    } catch (error) {
      toast.error("EXCEL not found!");
      dispatch(setShowWaitLoader(false));
    }
  };

  //Statement Table Operations

  const columns = [
    ["Date", "Date"],
    // ["Customer", "customer_name"],
    ["Product", "Product Name"],
    // ["Invoice ID", "invoice_counter"],
    ["Opening Stock", "Opening Stock"],
    ["Qty Cr", "Qty Cr"],
    ["Qty Dr", "Qty Dr"],
    ["Closing Stock", "Closing Stock"],
    // ["Sales Qty", "Sales Qty"],
    // ["Tax", "Tax"],
    // ["Amount", "amount"],
    ["Total Sales", "Total Sales"],
    ["Total Purchase", "Total Purchase"],
    // ["Profit margin", "Profit Margin"],
  ];

  // const dummyProducts = [
  //   { product_name: "Wireless Mouse", brand: "Logitech" },
  //   { product_name: "Bluetooth Headphones", brand: "Sony" },
  //   { product_name: "Mechanical Keyboard", brand: "Razer" },
  //   { product_name: "Gaming Monitor", brand: "ASUS" },
  //   { product_name: "4K Ultra HD TV", brand: "Samsung" },
  //   { product_name: "Smartphone", brand: "Apple" },
  //   { product_name: "Smartwatch", brand: "Garmin" },
  //   { product_name: "External SSD", brand: "Samsung" },
  //   { product_name: "Webcam", brand: "Logitech" },
  //   { product_name: "Wireless Charger", brand: "Anker" },
  // ];

  // const dummyCustomers = [
  //   { customer_name: "Alice Johnson", email: "alice.johnson@example.com" },
  //   { customer_name: "Bob Smith", email: "bob.smith@example.com" },
  //   { customer_name: "Charlie Brown", email: "charlie.brown@example.com" },
  //   { customer_name: "Diana Prince", email: "diana.prince@example.com" },
  //   { customer_name: "Ethan Hunt", email: "ethan.hunt@example.com" },
  //   { customer_name: "Fiona Apple", email: "fiona.apple@example.com" },
  //   { customer_name: "George Lucas", email: "george.lucas@example.com" },
  //   { customer_name: "Hannah Montana", email: "hannah.montana@example.com" },
  //   { customer_name: "Ivy League", email: "ivy.league@example.com" },
  //   { customer_name: "Jack Sparrow", email: "jack.sparrow@example.com" },
  // ];

  // const dummyData = [];

  // for (let i = 0; i < 10; i++) {
  //   const date = new Date(Date.now() - Math.floor(Math.random() * 1000 * 60 * 60 * 24 * 30)).toLocaleDateString();

  //   // Randomly select a customer and a product
  //   const randomCustomer = dummyCustomers[Math.floor(Math.random() * dummyCustomers.length)];
  //   const randomProduct = dummyProducts[Math.floor(Math.random() * dummyProducts.length)];

  //   const invoiceID = `INV${1000 + i}`; // Generate Invoice ID
  //   const qty = Math.floor(Math.random() * 100) + 1; // Random quantity between 1 and 100
  //   const amount = (qty * 20).toFixed(2); // Total amount before tax
  //   const tax = (amount * 0.1).toFixed(2); // Tax amount (10% of the amount)
  //   const opening_stock = 100;
  //   const qty_cr = 10;
  //   const qty_dr = 20;
  //   const closing_stock = 90;
  //   const totalAmountWithTax = (parseFloat(amount) + parseFloat(tax)).toFixed(2); // Total amount with tax
  //   const debit = totalAmountWithTax; // Total unpaid amount
  //   const credit = (Math.random() * parseFloat(amount)).toFixed(2); // Random paid amount
  //   const profitMargin = (parseFloat(debit) - parseFloat(credit)).toFixed(2); // Profit margin calculation

  //   dummyData.push({
  //     date,
  //     // customer_name: randomCustomer.customer_name,
  //     product_name: randomProduct.product_name,
  //     opening_stock: opening_stock,
  //     closing_stock: closing_stock,
  //     qty_cr: qty_cr,
  //     qty_dr: qty_dr,
  //     // invoice_counter: invoiceID,
  //     volume_of_invoice: qty,
  //     tax_absolute: tax,
  //     amount: totalAmountWithTax,
  //     total_unpaid: debit,
  //     total_paid: credit,
  //     profit_margin_absolute: profitMargin,
  //   });
  // }

  // Product Listing Operations

  const handleProductListing = (product, fromSelect = false) => {
    const productAlreadyInStock = selectedStockList.some((stock) => stock.id === product.id);

    if (productAlreadyInStock) {
      if (fromSelect) {
        toast.error("Product is already selected.");
      } else {
        setSelectedStockList((prevState) => {
          const filteredProducts = prevState.filter((stock) => stock.id !== product.id);
          return [...filteredProducts];
        });
      }
      return;
    }

    setSelectedStockList((prevState) => [...prevState, product]);
  };

  const autocompleteRef = useRef(null);

  return (
    <div>
      {/* Customer Selector, FY, Date, Product selector, Company selector */}
      <div className="border-b-2 border-netural-200">
        <form className="flex px-[20px] gap-[10px]">
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <Autocomplete
              disablePortal
              options={products || []}
              noOptionsText={productAutoCompleteLoading ? "Loading..." : "No data found!"}
              value={selectedProduct}
              getOptionLabel={(option) => option.product_name}
              onChange={(event, value) => {
                handleProductListing(value, true);
                setSelectedProduct(value);
                if (autocompleteRef.current) {
                  setSelectedProduct(null);
                  autocompleteRef.current.blur();
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Products"
                  inputRef={autocompleteRef}
                  onChange={(e) => setDebouncedProductSearchTerm(e.target.value)}
                />
              )}
            />
          </div>

          <div className="pt-[20px] pb-[20px] w-full max-w-[200px] flex">
            <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
          </div>
          {/* <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <Autocomplete
              disablePortal
              options={customers || []}
              value={selectedCustomer}
              getOptionLabel={(option) => option.customer_name}
              onChange={(event, value) => {
                setSelectedCustomer(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Customer"
                  onChange={(e) => setDebouncedCustomerSearchTerm(e.target.value)}
                />
              )}
            />
          </div> */}
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <Autocomplete
              disablePortal
              options={Companies || []}
              noOptionsText={companyAutoCompleteLoading ? "Loading..." : "No data found!"}
              value={selectedCompany}
              getOptionLabel={(option) => option}
              onChange={(event, value) => {
                setSelectedCompany(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company"
                  onChange={(e) => setDebouncedCompanySearchTerm(e.target.value)}
                />
              )}
            />
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <FormControl fullWidth>
              <InputLabel id="Download">Format</InputLabel>
              <Select
                labelId="Download"
                id="Download_format"
                value={selectedDownloadFormat}
                label="Download"
                onChange={(e) => setSelectedDownloadFormat(e.target.value)}
                style={{ backgroundColor: "white" }}
              >
                <MenuItem value={"xlsx"}>Excel</MenuItem>
                {/* <MenuItem value={"pdf"}>PDF</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[25%]">
            <button
              id="joyride-step-6"
              onClick={(e) => handleDownloadExcel(e)}
              className="text-white bg-black hover:bg-[rgba(0,0,0,0.8)] h-full w-full rounded-[4px]"
            >
              Download
            </button>
          </div>
        </form>

        {/* Showing Selected Products with remove operations*/}
        {selectedStockList?.length > 0 && (
          <div className="px-[20px] pb-[10px] overflow-x-scroll flex items-center gap-[10px]">
            <h3 className="text-nowrap">Selected Products: </h3>
            <div className="flex">
              {selectedStockList.map((stock) => (
                <div key={stock?.id} className="mr-[10px]">
                  <button
                    className="flex gap-[5px] items-center bg-stone-200 px-[10px] py-[5px] rounded-md"
                    onClick={() => handleProductListing(stock)}
                  >
                    <span className="text-neutral-600 text-nowrap">{stock?.product_name}</span>
                    <span>
                      <XCircle className="h-[20px] text-red-500" />
                    </span>
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="p-[20px]">
        <span className="text-center text-neutral-500 block w-full pb-[20px]">Stock-Wise Statement Table</span>
        {/* Statements data Table */}
        <div>
          <StatementsTable
            columns={columns}
            data={stockReports}
            next={stockReportsNext}
            fetchNextStatements={fetchNextStatements}
          />
        </div>
        {/* statements total */}
        {/* <div className="border border-neutral-200 h-[45px] flex">
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Total Sales: </span>
            <span>₹1000</span>
          </span>
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Profit %: </span>
            <span>25%</span>
          </span>
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Profit Margin: </span>
            <span>₹1000</span>
          </span>
        </div> */}
      </div>
    </div>
  );
}

export default StockWise;
