import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import InventoryList from "../Inventory/InventoryComponents/InventoryList";
import LineCharForInventory from "../Inventory/InventoryComponents/LineCharForInventory";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";
// import ActiveInventoryData from "../Inventory/InventoryComponents/ActiveInventoryData";
// import { Checkbox } from "@mui/material";
// import gstIcon from "../../assets/images/gst.jpg";

//Functional Imports
import EditCustomer from "./LedgerBookComponents/EditCustomer";
import DeleteCustomer from "./LedgerBookComponents/DeleteCustomer";
import EditVendor from "./LedgerBookComponents/EditVendor";
import DeleteVendor from "./LedgerBookComponents/DeleteVendor";

import {
  fetchCashLedger,
  // fetchCustomerAndVendor,
  // fetchMoreCustomerAndVendor,
  fetchLedgerEntries,
  fetchMoreLedgerEntries,
} from "../../reducers/Slices/ledgerSlice";
import { fetchInvoicesCustomer, fetchSalesCompareAnalytics } from "../../reducers/Slices/invoiceSlice";
import { fetchInvoicesVendor, purchaseMonthlyAnalyticsData } from "../../reducers/Slices/purchaseInvoiceSlice";

//UI components
import { Filter, Search } from "react-feather";
import IconWrapper from "../MicroComponents/IconWrapper";
import LogPayment from "./LedgerBookComponents/LogPayment";
import Joyride from "react-joyride";
import LogPaymentVendor from "./LedgerBookComponents/LogPaymentVendor";

import PaymentList from "./LedgerBookComponents/PaymentList";
import WaitLoader from "../SharedComponents/WaitLoader";
import { setShowWaitLoader } from "../../reducers/Slices/appSlice";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const LedgerBook = () => {
  //Check is there is query
  const query = useQuery();
  const navigate = useNavigate();
  const user_name = query.get("user_name");

  const dispatch = useDispatch();

  // const users = useSelector((state) => state.ledger.users.data);
  // const usersNext = useSelector((state) => state.ledger.users.next);

  const ledgerEntries = useSelector((state) => state.ledger.ledgerEntries.data);
  const ledgerEntriesNext = useSelector((state) => state.ledger.ledgerEntries.next);

  // const [hasMoreUsers, setHasMoreUsers] = useState(() => (usersNext ? true : false));

  const [hasMoreLedgerEntries, setHasMoreLedgerEntries] = useState(() => (ledgerEntriesNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  // useEffect(() => {
  //   if (usersNext) {
  //     setHasMoreUsers(true);
  //   } else {
  //     setHasMoreUsers(false);
  //   }
  // }, [usersNext]);

  useEffect(() => {
    if (ledgerEntriesNext) {
      setHasMoreLedgerEntries(true);
    } else {
      setHasMoreLedgerEntries(false);
    }
  }, [ledgerEntriesNext]);

  //fetch more expenses on scroll if available
  // const fetchNextUsers = () => {
  //   if (usersNext) {
  //     dispatch(fetchMoreCustomerAndVendor(usersNext));
  //   }
  // };

  const fetchNextLedgerEntriesFunc = () => {
    if (ledgerEntriesNext) {
      dispatch(fetchMoreLedgerEntries(ledgerEntriesNext));
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("");

  useEffect(() => {
    if (user_name) {
      setSearchTerm(user_name);
      navigate("/ledger-book", { replace: true });
    }
  }, [user_name]);

  //Base string for fetchCustomerAndVendor Reducer API
  const [stringQuery, setStringQuery] = useState("/");

  //Select Intitial User
  // useEffect(() => {
  //   setSelectedUser(users[0]);
  // }, [users]);

  //Select Intitial User
  useEffect(() => {
    setSelectedLedgerEntry(ledgerEntries[0]);
  }, [ledgerEntries]);

  useEffect(() => {
    setStringQuery(() => {
      let initialString = "/";

      //Guard Clause
      if (searchTerm.length === 0 && filter.length === 0) {
        return "/";
      }

      //Setting search term
      if (searchTerm.length > 0) {
        initialString += `?search=${searchTerm}`;
      }

      //Setting filter string
      if (searchTerm.length === 0 && filter.length > 0) {
        initialString += `?${filter}`;
      } else if (filter.length > 0) {
        initialString += `&${filter}`;
      }

      return initialString;
    });
  }, [filter, searchTerm]);

  //Debounced SearchTerm
  const debouncedSetSearchTerm = debounce((term) => {
    setSearchTerm(term);
  }, 1000);

  const sales_analytics = useSelector((state) => state.invoice.sales_analytics);
  const purchase_analytics = useSelector((state) => state.purchaseInvoice.purchase_analytics);
  const customer_invoices = useSelector((state) => state.invoice.customer_invoices);
  const vendor_invoices = useSelector((state) => state.purchaseInvoice.vendor_invoices);
  const ledger = useSelector((state) => state.ledger.cashLedger);

  // const [selectedUser, setSelectedUser] = useState(users[0]);

  const [selectedLedgerEntry, setSelectedLedgerEntry] = useState(ledgerEntries[0]);

  const [showMenu, setShowMenu] = useState(false);

  //Fetch Customers and Vendors
  // useEffect(() => {
  //   dispatch(fetchCustomerAndVendor(stringQuery));
  // }, [dispatch, stringQuery]);

  useEffect(() => {
    dispatch(setShowWaitLoader(true));
    dispatch(fetchLedgerEntries(stringQuery)).then(() => dispatch(setShowWaitLoader(false)));
  }, [dispatch, stringQuery]);

  // useEffect(() => {
  //   if (selectedUser?.id) {
  //     if (selectedUser?.type === "vendor") {
  //       dispatch(fetchInvoicesVendor(`?vendor_id=${selectedUser.id}`));
  //       dispatch(fetchCashLedger(`?vendorId=${selectedUser.id}`));
  //       dispatch(purchaseMonthlyAnalyticsData(`?vendorId=${selectedUser.id}`));
  //     } else if (selectedUser?.type === "customer") {
  //       dispatch(fetchInvoicesCustomer(`?customer_id=${selectedUser.id}`));
  //       dispatch(fetchCashLedger(`?customerId=${selectedUser.id}`));
  //       dispatch(fetchSalesCompareAnalytics(selectedUser.id));
  //     }
  //   }
  // }, [selectedUser, dispatch]);

  useEffect(() => {
    if (selectedLedgerEntry?.id) {
      if (ledgerType(selectedLedgerEntry) === "vendor") {
        dispatch(fetchInvoicesVendor(`?vendor_id=${selectedLedgerEntry?.vendor?.id}&is_cancelled=0`));
        dispatch(fetchCashLedger(`?vendorId=${selectedLedgerEntry?.vendor?.id}`));
        dispatch(purchaseMonthlyAnalyticsData(`?vendorId=${selectedLedgerEntry?.vendor?.id}`));
      } else if (ledgerType(selectedLedgerEntry) === "customer") {
        dispatch(fetchInvoicesCustomer(`?customer_id=${selectedLedgerEntry?.customer?.id}&is_cancelled=0`));
        dispatch(fetchCashLedger(`?customerId=${selectedLedgerEntry?.customer?.id}`));
        dispatch(fetchSalesCompareAnalytics(selectedLedgerEntry?.customer?.id));
      }
    }
  }, [selectedLedgerEntry, dispatch]);

  //ledger entry type function
  const ledgerType = (ledgerEntry) => {
    if (!ledgerEntry?.id) return null;

    if (ledgerEntry?.vendor === null) {
      return "customer";
    } else {
      return "vendor";
    }
  };

  //handle Filter button Menu
  const handleShowMenu = (value) => {
    showMenu !== value ? setShowMenu(value) : setShowMenu(null);
  };

  // const handleSelectUser = (user) => {
  //   setSelectedUser(user);
  // };

  const handleSelectLedger = (ledgerEntry) => {
    setSelectedLedgerEntry(ledgerEntry);
  };

  const steps = [
    {
      target: "#joyride-step-1",
      content: "Search for a specific customer or vendor here.",
    },
    {
      target: "#joyride-step-2",
      content: "Use these filters for the ledger list: Only Vendor, Only Customer, or Name: Descending.",
    },
    {
      target: "#joyride-step-3",
      content: "This is your ledger list. You can edit or delete a customer or vendor from here.",
    },
    {
      target: "#joyride-step-4",
      content: "View specific details about the selected customer or vendor here.",
    },
    {
      target: "#joyride-step-5",
      content: "Use this button to log payments and offset customer debts.",
    },
    {
      target: "#joyride-step-6",
      content: "This graph shows the performance of the selected customer or vendor.",
    },
    {
      target: "#joyride-step-7",
      content: "Here is the recent invoices list for the selected customer or vendor.",
    },
  ];

  const [localStorageLedgerStatus, setLocalStorageLedgerStatus] = useState(
    localStorage.getItem("showed_ledgerBook_guide") || false
  );

  //handle till final steps
  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished" || status === "skipped") {
      localStorage.setItem("showed_ledgerBook_guide", true);
      setLocalStorageLedgerStatus(false);
    }
  };

  //Payment and invoices tab switcher
  const [selectedUserTab, setSelectedUserTab] = useState("payment");

  //function to determine and return credit / debit;
  const decideCreditDebit = (ledgerEntry) => {
    console.log(ledgerEntry, "this is the ledger entry here");
    // handle for customer
    if (ledgerEntry?.customer) {
      const total_sales = parseFloat(ledgerEntry?.sales_amount);
      const total_received = parseFloat(ledgerEntry?.total_recieved);

      const credit_amount = parseFloat(ledgerEntry?.credit_amount);
      const debit_amount = parseFloat(ledgerEntry?.debit_amount);

      const amount = total_sales - total_received + debit_amount - credit_amount;

      if (amount === 0) {
        return { label: "Debit Amount: ", value: 0 };
      }

      if (amount < 0) {
        return { label: "Credit Amount: ", value: Math.abs(amount)?.toFixed(2) };
      }

      if (amount >= 0) {
        return { label: "Debit Amount: ", value: Math.abs(amount)?.toFixed(2) };
      }
    }

    // handle for vendor
    if (ledgerEntry?.vendor) {
      const total_purchase = parseFloat(ledgerEntry?.purchase_amount);
      const total_paid = parseFloat(ledgerEntry?.total_paid);

      const credit_amount = parseFloat(ledgerEntry?.credit_amount);
      const debit_amount = parseFloat(ledgerEntry?.debit_amount);

      const amount = total_purchase - total_paid - debit_amount + credit_amount;

      if (amount === 0) {
        return { label: "Credit Amount: ", value: 0 };
      }

      if (amount < 0) {
        return { label: "Debit Amount: ", value: Math.abs(amount)?.toFixed(2) };
      }

      if (amount >= 0) {
        return { label: "Credit Amount: ", value: Math.abs(amount)?.toFixed(2) };
      }
    }
  };

  return (
    <>
      {!localStorageLedgerStatus && (
        <Joyride
          steps={steps}
          continuous={true}
          showSkipButton={true}
          disableBeacon={true}
          callback={handleJoyrideCallback}
          styles={{ options: { primaryColor: "#6366f1" } }}
        />
      )}
      <div className="flex h-full">
        {/* Ledger Users */}
        <div className="w-[30%] h-full border-r-2 border-neutral-200">
          <div className="border-b-2 border-neutral-200 flex p-[15px] gap-[5px]">
            {/* Search Bar and Search Button */}
            <div className="w-full flex" id="joyride-step-1">
              <input
                type="text"
                className="border-2 rounded-full h-[44px] w-full p-[10px]"
                placeholder="Search Vendor or Customer..."
                onChange={(e) => debouncedSetSearchTerm(e.target.value)}
              />
              <button className="h-[44px] -ml-[42px]">
                <IconWrapper>
                  <Search className="text-white z-10" />
                </IconWrapper>
              </button>
            </div>

            {/* Filter Button and Functionality */}
            <div className="relative">
              <button className="h-[44px]" onClick={() => handleShowMenu("settings")} id="joyride-step-2">
                <IconWrapper>
                  <Filter className="text-white z-10" />
                </IconWrapper>
              </button>
              <div
                id="dropdownInformation"
                onMouseLeave={() => handleShowMenu("settings")}
                className={`${
                  showMenu !== "settings" ? "hidden" : ""
                } translate-x-[-50%] left-[50%] mt-[10px] absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44`}
              >
                <ul className="py-2 text-sm" aria-labelledby="dropdownInformationButton">
                  <li>
                    <button onClick={() => setFilter("")} className="text-lg py-2 w-full border-b-2 border-neutral-200">
                      All
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setFilter("vendor_only=true")}
                      className="text-lg py-2 w-full border-b-2 border-neutral-200"
                    >
                      Only Vendor
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setFilter("customer_only=true")}
                      className="text-lg pt-2 border-b-2 w-full border-neutral-200"
                    >
                      Only Customer
                    </button>
                  </li>
                  <li>
                    <button
                      onClick={() => setFilter("name=descending")}
                      className="text-lg pt-2 w-full border-neutral-200"
                    >
                      Name: Descending
                    </button>
                  </li>
                  {/* <li>
                    <button onClick={() => setFilter("favorite=1")} className="text-lg pt-2 w-full border-neutral-200">
                      Favorite only
                    </button>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>

          <div className="p-[15px] bg-white" id="joyride-step-3">
            <InfiniteScroll
              dataLength={ledgerEntries.length}
              next={fetchNextLedgerEntriesFunc}
              hasMore={hasMoreLedgerEntries}
              height={"76vh"}
              loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
              endMessage={
                <p style={{ textAlign: "center", paddingTop: "10px" }}>
                  <b>Customer/Vendor List End</b>
                </p>
              }
            >
              {ledgerEntries?.map((item, index) => (
                <div
                  key={`${index}`}
                  onClick={() => handleSelectLedger(item)}
                  className={`${
                    selectedLedgerEntry?.id === item?.id ? "border-black" : ""
                  } border-2 hover:border-black focus:border-black mb-[10px] rounded-[20px] p-[15px] bg-white cursor-pointer`}
                >
                  <div className=" relative flex pl-2 pb-2 gap-2">
                    <div className="text-xs xl:text-sm flex justify-between">
                      <h2 className="text-black text-md font-semibold flex flex-col">
                        <span className="text-lg truncate text-ellipses max-w-[9.5vw]">
                          {ledgerType(item) === "customer" ? item?.customer?.customer_name : item?.vendor?.vendor_name}
                        </span>
                        <span className="text-neutral-500">
                          GST number:{" "}
                          {ledgerType(item) === "customer"
                            ? item?.customer?.gst_number?.length > 0
                              ? item?.customer?.gst_number?.toUpperCase()
                              : "Not Provided"
                            : item?.vendor?.gst_number?.length > 0
                            ? item?.vendor?.gst_number?.toUpperCase()
                            : "Not Provided"}
                        </span>
                        <span className={`${ledgerType(item) === "vendor" ? "text-blue-500" : "text-orange-500"}`}>
                          {ledgerType(item) === "vendor" ? "(Vendor)" : "(Customer)"}
                        </span>
                      </h2>
                    </div>
                    <div className="absolute right-1 text-xs xl:text-sm flex justify-between">
                      <h2
                        className={`font-bold flex justify-center items-center ${
                          (ledgerType(item) === "customer" ? item?.customer?.gst_number : item?.vendor?.gst_number) &&
                          "-mt-2"
                        }`}
                      >
                        {/* Edit Customer / Vendor  */}
                        {ledgerType(item) === "vendor" ? <EditVendor ledger={item} /> : <EditCustomer ledger={item} />}

                        {/* Delete Customer / Vendor  */}
                        {ledgerType(item) === "vendor" ? (
                          <DeleteVendor ledger={item} />
                        ) : (
                          <DeleteCustomer ledger={item} />
                        )}
                      </h2>
                    </div>
                  </div>
                  <div className="relative flex px-2 pt-2  text-nowrap whitespace-nowrap">
                    <div className="text-xs xl:text-sm flex justify-between">
                      <h2 className="font-semibold">
                        <span className="text-neutral-500">
                          {ledgerType(item) === "customer" ? item?.customer?.phone_number : item?.vendor?.phone_number}
                        </span>
                      </h2>
                    </div>
                    <div className="absolute right-2 text-xs xl:text-sm flex justify-between">
                      <h2 className="font-semibold">
                        {/* Total Amount  */}
                        {ledgerType(item) === "customer" ? "Total Sales: " : "Total Purchase: "}{" "}
                        <span className="text-neutral-500">
                          {/* {item.all_grand_total || 0.0} */}₹
                          {ledgerType(item) === "customer" ? item?.sales_amount || 0 : item?.purchase_amount || 0}
                        </span>
                      </h2>
                    </div>
                  </div>
                  <div className="mt-0.5 relative flex p-2 text-nowrap whitespace-nowrap">
                    {/* {(ledgerType(item) === "customer" ? item?.customer?.gst_number : item?.vendor?.gst_number) && (
                      <div className="text-xs xl:text-sm flex justify-between">
                        <h2 className="font-semibold">
                          GST :{" "}
                          <span className="text-neutral-500">
                            {ledgerType(item) === "customer" ? item?.customer?.gst_number : item?.vendor?.gst_number}
                          </span>
                        </h2>
                      </div>
                    )} */}
                    <div className={`absolute right-2 top-0 text-xs xl:text-sm flex justify-between`}>
                      {/* Credit / Debit functionality */}
                      <h2 className="font-semibold">
                        {decideCreditDebit(item)?.label}
                        <span className="text-neutral-500">₹{decideCreditDebit(item)?.value || 0.0}</span>
                      </h2>

                      {/* {ledgerType(item) === "customer" && (
                        <h2 className="font-semibold">
                          Debit Amount: <span className="text-neutral-500">₹{item?.all_remaining || 0.0}</span>
                        </h2>
                      )} */}
                    </div>
                  </div>
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
        {/* Selected User Data */}
        <div className="w-[45%] border-r-2">
          <div>
            <div className="border-b-2 px-[20px] py-[20px] bg-white" id="joyride-step-4">
              <div className="pb-[20px] flex justify-between items-center">
                <h2 className="text-3xl max-w-[35vw]">
                  {ledgerType(selectedLedgerEntry) === "customer"
                    ? selectedLedgerEntry?.customer?.customer_name
                    : selectedLedgerEntry?.vendor?.vendor_name}
                </h2>
                {ledgerType(selectedLedgerEntry) === "customer" ? (
                  <LogPayment customer={selectedLedgerEntry?.customer} />
                ) : (
                  <LogPaymentVendor vendor={selectedLedgerEntry?.vendor} />
                )}
              </div>
              {ledgerType(selectedLedgerEntry) === "customer" && (
                <div>
                  <div className="font-semibold">
                    Total Sale:{" "}
                    <span className="text-gray-500 font-regular">₹{selectedLedgerEntry?.sales_amount || 0}</span>
                  </div>
                  <div className="font-semibold">
                    Total Amount Received:{" "}
                    <span className="text-gray-500 font-regular">₹{selectedLedgerEntry?.total_recieved || 0}</span>
                  </div>
                  {/* <div className="font-semibold">
                    Total Credit:{" "}
                    <span className="text-gray-500 font-regular">₹{selectedLedgerEntry?.credit_amount || 0}</span>
                  </div> */}
                  {/* <div className="font-semibold">
                    Outstanding Balance:{" "}
                    <span className="text-red-500 font-regular">₹{sales_analytics?.total_outstanding || 0}</span>
                  </div> */}
                  {/* <div className="font-semibold">
                    Total Debit:{" "}
                    <span className="text-gray-500 font-regular">₹{selectedLedgerEntry?.debit_amount || 0}</span>
                  </div> */}
                  <div className="font-semibold">
                    {decideCreditDebit(selectedLedgerEntry)?.label}
                    <span className="text-gray-500 font-regular">
                      ₹{decideCreditDebit(selectedLedgerEntry)?.value || 0}
                    </span>
                  </div>
                </div>
              )}
              {ledgerType(selectedLedgerEntry) === "vendor" && (
                <div>
                  <div className="font-semibold">
                    Total Purchase:
                    <span className="text-gray-500 font-regular">₹{selectedLedgerEntry?.purchase_amount || 0}</span>
                  </div>
                  <div className="font-semibold">
                    Total Amount Paid:{" "}
                    <span className="text-gray-500 font-regular">₹{selectedLedgerEntry?.total_paid || 0}</span>
                  </div>
                  {/* <div className="font-semibold">
                    Total Credit:{" "}
                    <span className="text-gray-500 font-regular">₹{selectedLedgerEntry?.credit_amount || 0}</span>
                  </div> */}
                  {/* <div className="font-semibold">
                    Outstanding Balance:{" "}
                    <span className="text-gray-500 font-regular">₹{purchase_analytics?.total_outstanding || 0}</span>
                  </div> */}
                  {/* <div className="font-semibold">
                    Total Debit:{" "}
                    <span className="text-gray-500 font-regular">₹{selectedLedgerEntry?.debit_amount || 0}</span>
                  </div> */}
                  <div className="font-semibold">
                    {decideCreditDebit(selectedLedgerEntry)?.label}
                    <span className="text-gray-500 font-regular">
                      ₹{decideCreditDebit(selectedLedgerEntry)?.value || 0}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {/* Graph Container */}
            <div className="py-[20px] bg-white" id="joyride-step-6">
              <div className="text-center w-full py-[10px] text-lg text-neutral-500">
                {`Comparison of ${ledgerType(selectedLedgerEntry) === "vendor" ? "purchase" : "sale"} of ${
                  ledgerType(selectedLedgerEntry) === "customer"
                    ? selectedLedgerEntry?.customer?.customer_name
                    : selectedLedgerEntry?.vendor?.vendor_name
                } to the last month`}
              </div>
              <LineCharForInventory
                data={ledgerType(selectedLedgerEntry) === "customer" ? sales_analytics : purchase_analytics}
                type={ledgerType(selectedLedgerEntry)}
              />
            </div>
          </div>
        </div>

        {/* inventory list container */}
        <div className="w-[25%] bg-white" id="joyride-step-7">
          <div className="flex justify-between py-[10px] border-b-2 border-neutral-200">
            <button
              className={`flex-1 ${selectedUserTab === "payment" ? "text-black" : "text-neutral-500"}`}
              onClick={() => setSelectedUserTab("payment")}
            >
              Payment Tab
            </button>
            <span className="text-neutral-500 px-[10px]">|</span>
            <button
              className={`flex-1 ${selectedUserTab === "invoice" ? "text-black" : "text-neutral-500"}`}
              onClick={() => setSelectedUserTab("invoice")}
            >
              Invoices Tab
            </button>
          </div>
          {selectedUserTab === "payment" && (
            <div>
              <PaymentList
                ledger={ledger}
                title={
                  ledgerType(selectedLedgerEntry) === "customer"
                    ? selectedLedgerEntry?.customer?.customer_name
                    : selectedLedgerEntry?.vendor?.vendor_name
                }
                type={ledgerType(selectedLedgerEntry)}
              />
            </div>
          )}
          {selectedUserTab === "invoice" && (
            <InventoryList
              type={ledgerType(selectedLedgerEntry)}
              setSelectedItem={
                ledgerType(selectedLedgerEntry) === "customer"
                  ? selectedLedgerEntry?.customer
                  : selectedLedgerEntry?.vendor
              }
              activeItem={
                ledgerType(selectedLedgerEntry) === "customer"
                  ? selectedLedgerEntry?.customer
                  : selectedLedgerEntry?.vendor
              }
              invoices={ledgerType(selectedLedgerEntry) === "vendor" ? vendor_invoices : customer_invoices}
              title={
                ledgerType(selectedLedgerEntry) === "customer"
                  ? selectedLedgerEntry?.customer?.customer_name
                  : selectedLedgerEntry?.vendor?.vendor_name
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default LedgerBook;
