import React, { useEffect, useState } from "react";
import AddProduct from "./InventoryComponents/AddProduct.jsx";
import EditProduct from "./InventoryComponents/EditProduct.jsx";
import RemoveProduct from "./InventoryComponents/RemoveProduct.jsx";
import ShowProducts from "./InventoryComponents/ShowProducts.jsx";
import AddBatch from "./InventoryComponents/AddBatch.jsx";
import BatchTable from "./InventoryComponents/BatchTable.jsx";
import InventoryList from "./InventoryComponents/InventoryList";
import LineCharForInventory from "./InventoryComponents/LineCharForInventory";
import { useDispatch, useSelector } from "react-redux";
import { fetchHSNData, fetchProducts, salesAnalyticsByProductId } from "../../reducers/Slices/productSlice";
import { fetchInvoiceByProductId } from "../../reducers/Slices/invoiceSlice";
import ServiceDetailsTable from "./InventoryComponents/ServiceDetailsTable.jsx";
import Joyride from "react-joyride";
import WaitLoader from "../SharedComponents/WaitLoader.jsx";
import { setShowWaitLoader } from "../../reducers/Slices/appSlice.js";

const Inventory = () => {
  //Products data and next funtion to fetch more
  const products = useSelector((state) => state.product.products.data);
  const productsNext = useSelector((state) => state.product.products.next);

  const product_analytics = useSelector((state) => state.product.product_analytics);

  //Searched products and next function to fetch more
  const searchedProducts = useSelector((state) => state.product.searchedProducts.data);
  const searchedProductsNext = useSelector((state) => state.product.searchedProducts.next);

  const [product, setProduct] = useState(null);

  useEffect(() => {
    if (searchedProducts && searchedProducts?.length > 0) {
      setProduct(searchedProducts[0]);
    }
  }, [searchedProducts]);

  //Toggle Between batches and Graph and service details
  const [showGraph, setShowGraph] = useState(false);
  const [showBatches, setShowBatches] = useState(true);
  const [showServiceDetails, setShowServiceDetails] = useState(false);

  const dispatch = useDispatch();

  //Fetching all products on first render
  useEffect(() => {
    dispatch(setShowWaitLoader(true));
    dispatch(fetchProducts()).then(() => dispatch(setShowWaitLoader(false)));
  }, [dispatch]);

  // Set first view to first product
  useEffect(() => {
    if (product?.id) {
      dispatch(fetchInvoiceByProductId(products[0].id));
      dispatch(salesAnalyticsByProductId(products[0].id));
    }
  }, [dispatch, product]);

  useEffect(() => {
    if (product?.id) {
      return;
    } else {
      setProduct(products[0]);
    }
  }, [products]);

  //Side effect - based on product type i.e goods or service switch context i.e batches or service details
  useEffect(() => {
    if (!product) {
      return;
    }

    if (showGraph) {
      return;
    }

    if (product?.is_service === "Y") {
      setShowBatches(false);
      setShowServiceDetails(true);
    } else {
      setShowBatches(true);
      setShowServiceDetails(false);
    }
  }, [product, showGraph]);

  //Get invoices based on product id and set product view
  const invoices = useSelector((state) => state.invoice.product_invoices);

  //React Joyride - Guide user on how to use app
  const steps = [
    {
      target: "#joyride-step-1",
      content: "Click this toggle to open the search bar and search for products.",
    },
    {
      target: "#joyride-step-2",
      content: "Use the 'Add Product' button to add goods or services to your inventory.",
    },
    {
      target: "#joyride-step-3",
      content: "This is your product list with the brand name and status.",
    },
    {
      target: "#joyride-step-4",
      content: "View detailed information about a selected product here.",
    },
    {
      target: "#joyride-step-5",
      content: "Edit or delete product details for the selected item here.",
    },
    {
      target: "#joyride-step-6",
      content: "Toggle between batch/service details and performance graphs based on sales.",
    },
    {
      target: "#joyride-step-7",
      content: "This table shows batch or service details, depending on the product type.",
    },
    {
      target: "#joyride-step-8",
      content: "Add a new batch from here.",
    },
    {
      target: "#joyride-step-9",
      content: "View recent invoices for the selected product here.",
    },
  ];

  const [localStorageInventoryStatus, setLocalStorageInventoryStatus] = useState(
    localStorage.getItem("showed_inventory_guide") || false
  );

  //handle till final steps
  const handleJoyrideCallback = (data) => {
    const { status } = data;

    if (status === "finished" || status === "skipped") {
      localStorage.setItem("showed_inventory_guide", true);
      setLocalStorageInventoryStatus(false);
    }
  };

  //Fetch initial HSN data;
  useEffect(() => {
    dispatch(fetchHSNData());
  }, []);

  return (
    <>
      {!localStorageInventoryStatus && (
        <Joyride
          steps={steps}
          continuous={true}
          showSkipButton={true}
          disableBeacon={true}
          callback={handleJoyrideCallback}
          styles={{ options: { primaryColor: "#6366f1" } }}
        />
      )}
      <div className="flex flex-col lg:flex-row h-[calc(100vh-80px)] max-w-[96vw]">
        {/* main products container  */}
        <div className="lg:w-3/12 2xl:w-[20%] border-b-2 border-neutral-200 lg:border-b-0 p-[20px]">
          <AddProduct />
          <div id="joyride-step-3" className="bg-white">
            {searchedProducts && searchedProducts?.length > 0 ? (
              <ShowProducts
                product={product}
                setProduct={setProduct}
                products={searchedProducts}
                next={searchedProductsNext}
                searched={true}
              />
            ) : (
              products.length > 0 && (
                <ShowProducts
                  product={product}
                  setProduct={setProduct}
                  products={products}
                  next={productsNext}
                  searched={false}
                />
              )
            )}
          </div>
        </div>

        {/* main product inventory container */}

        <div className="relative lg:w-6/12 2xl:w-[55%] border-b-2 border-neutral-200 lg:border-b-0 border-x-0 lg:border-x-2">
          <div className="bg-white pb-[20px]" id="joyride-step-4">
            <div className="flex justify-between items-center pt-[10px] xl:pt-[20px] px-[20px] xl:px-[20px]">
              <h3 className="text-3xl font-semibold">{product?.product_name}</h3>

              <div className="flex gap-2" id="joyride-step-5">
                <EditProduct product={product} />
                <RemoveProduct product={product} />
              </div>
            </div>

            <div className="flex gap-[20px] pt-[20px] px-[20px]">
              <h3 className="text-base">
                <span className="text-gray-500">{product?.is_service === "Y" ? "Company" : "Brand"} Name: </span>{" "}
                {product?.brand}
              </h3>

              <span className="text-base">
                <span className="text-gray-500">{product?.is_service === "Y" ? "SAC code:" : "HSN number:"} </span>{" "}
                {product?.hsn_number}
              </span>

              <span className="text-base">
                <span className="text-gray-500">{product?.is_service === "Y" ? "Service Type" : "Packaging"}: </span>{" "}
                {product?.package}
              </span>

              {/* Status Code */}
              {!showServiceDetails && !showGraph && (
                <h3 className="text-base flex">
                  <span className="text-gray-500 px-2">Status: </span>
                  {product?.remaining_quantity > 0 ? (
                    <span className="text-green-500">Available</span>
                  ) : (
                    <span className="text-red-500">Not Available</span>
                  )}
                </h3>
              )}
            </div>
          </div>

          {/* Graph and Batches toggler */}
          <div
            className="border-t-2 border-b-2 border-neutral-200 w-full flex justify-between py-[10px] bg-white"
            id="joyride-step-6"
          >
            <div className="w-full flex justify-center">
              <button
                style={{ color: showGraph ? "rgb(107, 114, 128)" : "rgb(0, 0, 0)" }}
                onClick={() => {
                  showServiceDetails ? setShowServiceDetails(true) : setShowBatches(true);
                  setShowGraph(false);
                }}
              >
                {product?.is_service === "Y" ? "Service" : "Batches"} Tab
              </button>
            </div>
            <div className="border-l-2 border-neutral-200"></div>
            <div className="w-full flex justify-center">
              <button
                style={{ color: showBatches || showServiceDetails ? "rgb(107, 114, 128)" : "rgb(0, 0, 0)" }}
                onClick={() => {
                  showServiceDetails ? setShowServiceDetails(false) : setShowBatches(false);
                  setShowGraph(true);
                }}
              >
                Graph Tab
              </button>
            </div>
          </div>

          {/* Graph and Batches, service Container  */}
          <div id="joyride-step-7" className="bg-white">
            {showGraph && (
              <div id="graph-component" className="px-[20px]">
                <p className="py-[20px] text-center text-lg text-neutral-500">
                  {`Comparison of sale of ${product?.product_name} to the last month.`}{" "}
                </p>

                <LineCharForInventory data={product_analytics} />
              </div>
            )}

            {/* Show Batches for Commodities and goods  */}
            {showBatches && (
              <div id="batches-component" className="px-[20px]">
                <p className="py-[20px] text-center text-lg text-neutral-500">{`Batches for ${product?.product_name}`}</p>
                {product && <BatchTable product={product} />}
                <div className="absolute bottom-[35px] right-[20px]">{product && <AddBatch product={product} />}</div>
              </div>
            )}

            {/* Show Service details for service based products */}
            {showServiceDetails && (
              <div id="service-component" className="px-[20px]">
                <p className="py-[20px] text-center text-lg text-neutral-500">{`Service Details for ${product?.product_name}`}</p>
                {product && <ServiceDetailsTable product={product} />}
              </div>
            )}
          </div>
        </div>

        {/* main inventory list container  */}
        <div className="lg:w-3/12 2xl:w-[25%] border-b-2 border-neutral-200 md:border-b-0 bg-white" id="joyride-step-9">
          <InventoryList
            setSelectedItem={setProduct}
            type={"product"}
            invoices={invoices}
            activeItem={product}
            title={product?.product_name}
          />
        </div>
      </div>
    </>
  );
};

export default Inventory;
