import React, { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

const StatementsTable = ({ data, columns, next, fetchNextStatements }) => {
  const [hasMoreStatements, setHasMoreStatements] = useState(() => (next ? true : false));

  // Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    setHasMoreStatements(next);
  }, [next]);

  return (
    <div className="w-full bg-white" id="joyride-step-8">
      <InfiniteScroll
        dataLength={data.length}
        next={fetchNextStatements}
        hasMore={hasMoreStatements}
        height={"52vh"}
        loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
        endMessage={
          <p style={{ textAlign: "center", paddingTop: "10px", paddingBottom: "10px" }}>
            <span>Statements List End</span>
          </p>
        }
      >
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              {columns?.map((column, index) => (
                <th
                  key={index}
                  scope="col"
                  className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-neutral-200 text-nowrap"
                >
                  {column[0]}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-white">
            {data?.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
                  >
                    {/* Display "N/A" if the value is null, otherwise format as needed */}
                    {row[column[1]] === null
                      ? "N/A"
                      : typeof row[column[1]] === "number"
                      ? row[column[1]].toFixed(2)
                      : row[column[1]]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
};

export default StatementsTable;
