import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField } from "@mui/material";
import { getInvoiceDetailsById, updateNonGstInvoice } from "../../../reducers/Slices/invoiceSlice";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { updateInvoice } from "../../../reducers/Slices/invoiceSlice";
import toast from "react-hot-toast";
import {
  fetchBatchesByProductID,
  fetchProductByBarcode,
  fetchProductByID,
  searchProducts,
} from "../../../reducers/Slices/productSlice";
import BarcodeReader from "../../Invoices/InvoiceComponents/BarcodeReader";
import { debounce } from "lodash";
import { http } from "../../../service/http";
import { precisionDecimalDigit } from "../../../utils/prettifyDigits";

function UpdateInvoiceNonGst({ invoice, products }) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const [invoiceId, setInvoiceId] = useState(null);
  const [invoiceUserID, setInvoiceUserId] = useState(0);
  const [invoiceInfo, setInvoiceInfo] = useState([]);
  const [selectedProductList, setSelectedProductList] = useState([]);
  const [selectedBatchesList, setSelectedBatchesList] = useState([]);
  const [selectedProductQuantityList, setSelectedProductQuantityList] = useState([]);
  const [selectedDealQuantityList, setSelectedDealQuantityList] = useState([]);
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [customerGst, setCustomerGst] = useState(null);

  const [dispatchStateCode, setDispatchStateCode] = useState(null);
  const [shipmentStateCode, setShipmentStateCode] = useState(null);

  //Discount on MRP and calculated Amount Value
  const [discountOnMRPValue, setDiscountOnMRPValue] = useState([{}]);
  const [calculatedAmountValue, setCalculatedAmountValue] = useState([{}]);

  //Invoice Dimensions
  const [invoiceDimension, setInvoiceDimension] = useState({ total: 0, discount: 0, profit: 0, sub_total: 0 });

  //Invoice Descriptiom
  const [invoiceDescription, setInvoiceDescription] = useState("");

  const invoiceDetails = useSelector((state) => state.invoice.invoiceDetails);

  const [updateInvoiceFormData, setUpdateInvoiceFormData] = useState({});

  //Side effect to set invoice info state
  useEffect(() => {
    //Check if invoice exists
    if (!invoiceId) {
      return;
    }

    //Check if invoice detail exist
    if (!invoiceDetails) {
      return;
    }

    //Only run if invoiceId is similar to invoice id in invoice details
    if (!(invoiceId === invoiceDetails?.id)) {
      return;
    }

    // Get the number of new objects needed to fill discount on mrp arr and calculated amount arr
    const numberOfNewObjects = Math.max(0, invoiceDetails?.sales_invoice_items?.length - 1);

    // Create a new array with the existing object plus new empty objects
    setDiscountOnMRPValue([{}, ...Array(numberOfNewObjects).fill({})]);
    setCalculatedAmountValue([{}, ...Array(numberOfNewObjects).fill({})]);

    setInvoiceInfo(invoiceDetails);
    setInvoiceDescription(invoiceDetails?.description);

    setDispatchStateCode(invoiceDetails?.dispatch_state_code);
    setShipmentStateCode(invoiceDetails?.shipment_state_code);

    setCustomerGst(invoiceDetails?.customer_gst);
  }, [invoiceDetails, invoiceId]);

  //Side effect for operations based on invoiceInfo
  useEffect(() => {
    //Only run if there is something in invoiceInfo
    if (!invoiceInfo?.sales_invoice_items?.length > 0) {
      return;
    }

    const nextProductList = [];
    const nextBatchList = [];
    const nextQuantityList = [];
    const nextDealQuantityList = [];

    invoiceInfo?.sales_invoice_items?.map(async (info, index) => {
      // if (products.length > 0) {
      // let product = products?.find((product) => product.id === info.product.id);
      let product = info.product;

      // if (!product) {
      //   const response = await dispatch(fetchProductByID(info.product.id));
      //   if (response?.payload) {
      //     product = response.payload;
      //   }
      // }

      // ~~ changes here
      if (product?.is_service === "N") {
        // let batch = product?.batches?.find((batch) => batch.id === info.batch);
        let batch = info.batch;

        // if (!batch) {
        //   const response = await dispatch(
        //     fetchBatchesByProductID({ productId: product?.id, filters: `?batch_id=${info.batch}` })
        //   );
        //   batch = response.payload.batchData.data[0];
        // }

        const quantity = info.quantity;
        const deal_quantity = info.deal_quantity;

        handleDiscountOnMRPChange(info?.mrp_discount, index, true, {});

        nextProductList.push(product);
        if (batch) {
          nextBatchList.push({
            ...batch,
            sales_price: calculateNetRate(batch?.mrp_price, info?.mrp_discount),
          });
        } else {
          nextBatchList.push({});
        }
        nextQuantityList.push(quantity);
        nextDealQuantityList.push(deal_quantity);
      } else if (product?.is_service === "Y") {
        const quantity = 1;
        const deal_quantity = 0;

        handleDiscountOnMRPChange(info?.mrp_discount, index, true, {});
        nextProductList.push({ ...product, ...product.service, hsn_number: product.hsn_number });
        nextBatchList.push({});
        nextQuantityList.push(quantity);
        nextDealQuantityList.push(deal_quantity);
      }
      // }

      return info;
    });

    setSelectedProductList(nextProductList);
    setSelectedBatchesList(nextBatchList);
    handleCalculateAmount(nextBatchList);
    setSelectedProductQuantityList(nextQuantityList);
    setSelectedDealQuantityList(nextDealQuantityList);
  }, [invoiceInfo]);

  //Side effect to reverse calculate rate based on discountOnMRP change
  useEffect(() => {
    if (selectedProductList?.length <= 0) {
      return;
    }

    const updatedBatches = selectedBatchesList.map((item, index) => {
      if (selectedProductList[index]?.is_service === "Y") {
        return {};
      }

      //if object is empty then return empty object
      if (item !== null && item !== undefined && Object.keys(item).length === 0) {
        return {};
      }

      return {
        ...item,
        sales_price: calculateNetRate(item?.mrp_price, discountOnMRPValue[index]?.discountOnMRP),
      };
    });
    setSelectedBatchesList(updatedBatches);

    const updatedProducts = selectedProductList.map((item, index) => {
      if (selectedProductList[index]?.is_service === "Y") {
        return { ...item, rate: calculateNetRate(item?.mrp, discountOnMRPValue[index]?.discountOnMRP) };
      } else {
        return item;
      }
    });

    setSelectedProductList(updatedProducts);
  }, [discountOnMRPValue]);

  //Handle Product change - update in selectedProductsList
  const handleProductChange = (value, index) => {
    setSelectedProductList((prevState) => {
      const newState = [...prevState];
      if (value.is_service === "Y") {
        newState[index] = { ...value, ...value.service, hsn_number: value.hsn_number };
      } else {
        newState[index] = value;
      }
      return newState;
    });

    setSelectedBatchesList((prevState) => {
      const newState = [...prevState];

      if (value?.is_service === "Y") {
        newState[index] = {};
      } else {
        newState[index] = value?.batches[0];
      }
      return newState;
    });

    if (value?.is_service === "Y") {
      handleDiscountOnMRPChange(null, index, false, {
        mrp: value?.service?.mrp,
        sales_price: value?.service?.rate,
      });
    } else {
      handleDiscountOnMRPChange(null, index, false, {
        mrp: value?.batches[0]?.mrp_price,
        sales_price: value?.batches[0]?.sales_price,
      });
    }

    setCalculatedAmountValue((prevState) => {
      const updatedAmount = [...prevState];
      //Don't update amount if it is a service product
      if (value?.is_service === "N") {
        updatedAmount[index] = { calculated_amount: 0, checkedByUser: false };
      }
      return updatedAmount;
    });

    //Change amount value based on product change
    setSelectedProductQuantityList((prevState) => {
      const newState = [...prevState];
      if (value?.is_service === "Y") {
        newState[index] = 1;
      } else {
        newState[index] = 0;
      }
      return newState;
    });

    setSelectedDealQuantityList((prevState) => {
      const newState = [...prevState];
      if (value?.is_service === "Y") {
        newState[index] = 0;
      } else {
        newState[index] = value?.deal_quantity;
      }
      return newState;
    });
  };

  //Function to reverse calculate netRate based on new discountOnMRPercent
  const calculateNetRate = (mrp, discountOnMRPPercent) => {
    //calculate the rate after discount
    const discountedRate = parseFloat(mrp) - parseFloat(mrp) * ((parseFloat(discountOnMRPPercent) || 0) / 100);
    const netRate = discountedRate;

    return netRate.toFixed(3).slice(0, -1);
  };

  //Handle Batch Change - update in selectedBatchesList
  const handleBatchChange = (value, index) => {
    setSelectedBatchesList((prevState) => {
      const newState = [...prevState];
      if (value) {
        newState[index] = value;
      } else {
        newState[index] = {};
      }
      return newState;
    });

    handleDiscountOnMRPChange(null, index, false, {
      mrp: value?.mrp_price,
      sales_price: value?.sales_price,
    });
  };

  //Handle Quantity Change - update in selectedProductsQuantityList
  const handleQuantityChange = (value, index) => {
    if (value < 0) {
      toast.error("Quantity can't be a negative number!");
      return;
    }

    if (value > selectedBatchesList[index]?.remaining_quantity) {
      toast.error("Quantity can't exceed batch quantity!");
      return;
    }

    setSelectedProductQuantityList((prevState) => {
      const newState = [...prevState];
      newState[index] = parseInt(value) || 0;
      return newState;
    });
  };

  //Handle Deal Quantity Change - update in selectedDealQuantityList
  const handleDealQuantityChange = (value, index) => {
    if (value < 0) {
      toast.error("Deal can't be free!");
      return;
    }

    setSelectedDealQuantityList((prevState) => {
      const newState = [...prevState];
      newState[index] = parseInt(value) || 0;
      return newState;
    });
  };

  const handleAddMoreProducts = () => {
    setSelectedProductList((prevState) => [...prevState, { product_name: "", quantity: 0 }]);
    setSelectedBatchesList((prev) => [...prev, {}]);
    setSelectedProductQuantityList((prev) => [...prev, {}]);
    setSelectedDealQuantityList((prev) => [...prev, {}]);
    setDiscountOnMRPValue((prev) => [...prev, {}]);
    setCalculatedAmountValue((prev) => [...prev, {}]);
  };

  const paymentOptions = [
    { label: "Cash", value: "cash" },
    { label: "Credit", value: "credit" },
  ];

  const [selectedPaymentOption, setSelectedPaymentOption] = useState(() => {
    return paymentOptions.find((option) => option.value === invoice?.payment_option && invoice?.id === invoiceId);
  });

  const handlePaymentOptionChange = (value) => {
    setSelectedPaymentOption(value);
  };

  //Side effect to calculate total, discount, tax, profit
  useEffect(() => {
    const nextInvoiceDimension = selectedProductList.reduce(
      (accumulator, currentItem, index) => {
        if (currentItem?.is_service === "N") {
          if (selectedBatchesList[index]?.sales_price || selectedBatchesList[index]?.sales_price == 0) {
            let quantity = parseInt(selectedProductQuantityList[index] || 0);
            let deal_quantity = parseInt(selectedDealQuantityList[index] || 0);
            let item_total = parseFloat(selectedBatchesList[index]?.sales_price || 0) * quantity;
            let item_mrp_total = parseFloat(selectedBatchesList[index]?.mrp_price || 0) * quantity;
            let item_discount = parseFloat((discountOnMRPValue[index]?.discountOnMRP || 0) / 100) * item_mrp_total;

            let item_profit =
              (calculateNetRate(selectedBatchesList[index]?.mrp_price, discountOnMRPValue[index]?.discountOnMRP) -
                (selectedBatchesList[index]?.purchase_price ?? 0)) *
              (quantity - deal_quantity);

            accumulator.total += item_total + item_discount;
            accumulator.discount += item_discount;
            accumulator.profit += item_profit;
          }
        }

        if (currentItem?.is_service === "Y") {
          if (selectedProductList[index]?.rate || selectedProductList[index]?.rate == 0) {
            let quantity = 1;
            let item_total = parseFloat(selectedProductList[index]?.rate || 0) * quantity;
            let item_mrp_total = parseFloat(selectedProductList[index]?.mrp || 0) * quantity;
            let item_discount = parseFloat((discountOnMRPValue[index]?.discountOnMRP || 0) / 100) * item_mrp_total;
            let item_profit =
              (calculateNetRate(selectedProductList[index]?.mrp, discountOnMRPValue[index]?.discountOnMRP) -
                (selectedProductList[index]?.cost ?? 0)) *
              quantity;

            accumulator.total += item_total + item_discount;
            accumulator.discount += item_discount;
            accumulator.profit += item_profit;
          }
        }
        return accumulator;
      },
      { total: 0, discount: 0, profit: 0 }
    );

    setInvoiceDimension(nextInvoiceDimension);
  }, [
    selectedProductList,
    selectedBatchesList,
    selectedProductQuantityList,
    discountOnMRPValue,
    calculatedAmountValue,
    selectedDealQuantityList,
  ]);

  useEffect(() => {
    handleCalculateAmount(selectedBatchesList);
  }, [selectedBatchesList, discountOnMRPValue, selectedProductQuantityList, selectedProductList]);

  //side effect to set form data
  useEffect(() => {
    const productAndQuantity = [];
    const serviceAndQuantity = [];

    selectedProductList?.forEach((product, index) => {
      if (product?.is_service === "N") {
        productAndQuantity.push({
          productId: selectedProductList[index]?.id,
          batchId: selectedBatchesList[index]?.id,
          quantity: selectedProductQuantityList[index],
          deal_quantity: selectedDealQuantityList[index] || 0,
          total_quantity:
            parseInt(selectedProductQuantityList[index] || 0) + parseInt(selectedDealQuantityList[index] || 0),
          amount: precisionDecimalDigit(calculatedAmountValue[index]?.calculated_amount),
          mrp_discount: precisionDecimalDigit(discountOnMRPValue[index]?.discountOnMRP || 0),
          discount: precisionDecimalDigit(
            parseFloat(discountOnMRPValue[index]?.discountOnMRP / 100) *
              parseFloat(selectedBatchesList[index]?.mrp_price) *
              parseInt(selectedProductQuantityList[index])
          ),
          assessable_amount: precisionDecimalDigit(
            parseFloat(selectedBatchesList[index]?.sales_price) * parseInt(selectedProductQuantityList[index])
          ),
        });
      }

      if (product?.is_service === "Y") {
        serviceAndQuantity.push({
          productId: selectedProductList[index]?.id,
          quantity: 1,
          amount: precisionDecimalDigit(calculatedAmountValue[index]?.calculated_amount),
          mrp_discount: precisionDecimalDigit(discountOnMRPValue[index]?.discountOnMRP || 0),
          discount: precisionDecimalDigit(
            parseFloat(discountOnMRPValue[index]?.discountOnMRP / 100) * parseFloat(selectedProductList[index].mrp)
          ),
          assessable_amount: precisionDecimalDigit(parseFloat(selectedProductList[index]?.rate)),
        });
      }
    });

    setUpdateInvoiceFormData(() => {
      let updatedRemainingTotal = invoice?.remaining_total;
      let updatedPaidAmount = invoice?.paid_amount;

      if (selectedPaymentOption?.value === "cash" || selectedPaymentOption?.value === "upi") {
        updatedRemainingTotal = 0;
        updatedPaidAmount = calculatedAmountValue?.reduce((accumulator, currentObject) => {
          return accumulator + parseFloat(currentObject?.calculated_amount);
        }, 0);
      }

      return {
        invoice_id: invoiceId,
        customer: invoiceUserID,
        payment_type: selectedPaymentOption?.value === "credit" ? "remain_payment" : "paid",
        payment_option: selectedPaymentOption?.value || "cash",
        paid_amount: precisionDecimalDigit(updatedPaidAmount),

        discount: precisionDecimalDigit(invoiceDimension.discount),
        grand_total: precisionDecimalDigit(
          calculatedAmountValue?.reduce((accumulator, currentObject) => {
            return accumulator + parseFloat(currentObject?.calculated_amount);
          }, 0)
        ),
        remaining_total: precisionDecimalDigit(updatedRemainingTotal),
        sub_total: precisionDecimalDigit(invoiceDimension.total),
        product_and_quantity: productAndQuantity,
        service_and_quantity: serviceAndQuantity,
      };
    });
  }, [
    invoiceDimension,
    selectedBatchesList,
    selectedPaymentOption,
    selectedProductList,
    selectedProductQuantityList,
    selectedDealQuantityList,
    invoiceId,
    invoiceUserID,
    calculatedAmountValue,
    discountOnMRPValue,
    invoice,
  ]);

  //Calculate discount on MRP
  const handleDiscountOnMRPChange = (value, index, isDiscount, data) => {
    if (!value) {
      setDiscountOnMRPValue((prevItems) => {
        return prevItems.map((item, i) => {
          if (i === index) {
            return { discountOnMRP: nextDiscountOnMRP }; // Update the specified field of the item
          }
          return item;
        });
      });
    }

    let nextDiscountOnMRP;
    //Calculate Discount On MRP if DiscountMRP is not directly given
    if (!isDiscount) {
      const nextValue = calculateDiscountOnMRP(data);
      nextDiscountOnMRP = nextValue ? nextValue : null;
    } else {
      nextDiscountOnMRP = value;
    }

    //Guard to check if nextDiscountOnMRP is not null
    if (!nextDiscountOnMRP) {
      return 0;
    }

    setDiscountOnMRPValue((prevItems) => {
      const updatedVal = prevItems.map((item, i) => {
        if (i === index) {
          return { discountOnMRP: nextDiscountOnMRP }; // Update the specified field of the item
        }
        return item;
      });
      return updatedVal;
    });
  };

  //Function to calculate discount on mrp based on mrp and tax percent
  const calculateDiscountOnMRP = (data) => {
    if (!data?.mrp || !data?.sales_price) {
      return null;
    }
    // Convert tax percent to decimal
    // const taxDecimal = parseFloat(data?.tax_percent ?? 0) / 100;

    // const taxedRate = parseFloat(data?.sales_price ?? 0) + parseFloat(data?.sales_price ?? 0) * taxDecimal;

    // Calculate the discount on MRP
    const discountOnMRPPercent =
      (parseFloat(data?.mrp ?? 0) - parseFloat(data?.sales_price ?? 0)) / parseFloat(data?.mrp ?? 0);

    const discountOnMRP = parseFloat(discountOnMRPPercent * 100);
    return discountOnMRP;
  };

  const handleCalculateAmount = (items) => {
    if (items?.length <= 0) {
      return;
    }

    setCalculatedAmountValue((prevValue) => {
      const updatedCalculatedAmountValue = prevValue?.map((_, index) => {
        if (_?.checkedByUser === true) {
          return _;
        }

        const quantity = selectedProductQuantityList[index];

        if (!items[index]) {
          return _;
        }
        // ~~ changes here
        let mrp_price, sales_price;

        // ~~ changes here
        //Assign values based on nature of product i.e service or goods
        if (selectedProductList[index]?.is_service === "Y") {
          mrp_price = selectedProductList[index]?.mrp;
          sales_price = selectedProductList[index]?.rate;
        } else {
          mrp_price = items[index]?.mrp_price;
          sales_price = items[index]?.sales_price;
        }

        if (!quantity || !mrp_price || !sales_price) {
          return _;
        }

        if (!sales_price || !quantity) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        const parsedQuantity = parseInt(quantity);
        // const parsedTax = parseFloat(tax) / 100;

        if (sales_price === 0 || parsedQuantity === 0) {
          return { calculated_amount: 0, checkedByUser: false };
        }

        // let amount = (parseFloat(mrp_price) - parseFloat(mrp_price) * (discountOnMRPPresent / 100)) * parsedQuantity;
        let amount = parseFloat(sales_price) * parsedQuantity;

        //Going the extra mile because toFixed(2) is rounding numbers resulting in wrong value.
        const truncatedAmount = (Math.floor(amount * 1000) / 1000).toFixed(3).slice(0, -1);

        return { calculated_amount: truncatedAmount, checkedByUser: false };
      });
      return updatedCalculatedAmountValue;
    });
  };

  //Change amount value when user manually changes it based on index
  const handleAmountValueChange = (value, index) => {
    setCalculatedAmountValue((prevItems) => {
      const updatedCalculatedAmount = prevItems.map((item, i) => {
        if (index === i) {
          return { calculated_amount: value, checkedByUser: true };
        }
        return item;
      });
      return updatedCalculatedAmount;
    });
  };

  //Submit function
  function handleSubmit() {
    setDisableSubmitButton(true);

    let updatedFormData = { ...updateInvoiceFormData, description: invoiceDescription, is_einvoice: false };

    //Check to see both quantity and deal quantity is not zero per product
    const hasInvalidProduct = updateInvoiceFormData.product_and_quantity.some(
      (item) => item.quantity === 0 && item.deal_quantity === 0
    );

    if (customerGst) {
      updatedFormData = { ...updatedFormData, customer_gst: customerGst };
    } else {
      updatedFormData = { ...updatedFormData, customer_gst: null };
    }

    if (hasInvalidProduct) {
      toast.error("both quantity or deal quantity cannot be 0 in product");
      setDisableSubmitButton(false);
      return;
    }

    // console.log(updateInvoiceFormData, "this is the updated form data");
    // setDisableSubmitButton(false);

    dispatch(updateNonGstInvoice({ id: invoiceDetails?.id, data: updatedFormData }))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          setShowModal(false);
          // resetInitialValue
        } else {
          toast.error("Sorry, invoice cannot be updated");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  }

  //Function to close custom modal and perform actions during closing of modal
  function handleClose() {
    setShowModal(false);
  }

  function handleUpdateInvoiceClick() {
    if (invoice?.id) {
      setInvoiceId(invoice.id);
      setInvoiceUserId(invoice.customer.id);
      setSelectedPaymentOption(() => paymentOptions.find((option) => option.value === invoice?.payment_option));
      dispatch(getInvoiceDetailsById({ id: invoice?.id }));
    }
    setShowModal(true);
  }

  const handleRemoveItem = (index) => {
    if (selectedProductList?.length > 1) {
      setSelectedProductList((prevItems) => {
        const updatedProductList = [...prevItems]; // Create a copy of the array
        updatedProductList.splice(index, 1); // Remove the item at the specified index
        return updatedProductList; // Set the updated array as the new state
      });
    }

    if (selectedBatchesList?.length > 1) {
      setSelectedBatchesList((prevItems) => {
        const updatedBatchesList = [...prevItems];
        updatedBatchesList.splice(index, 1);
        return updatedBatchesList;
      });
    }

    if (selectedProductQuantityList?.length > 1) {
      setSelectedProductQuantityList((prevItems) => {
        const updatedProductQuantityList = [...prevItems];
        updatedProductQuantityList.splice(index, 1);
        return updatedProductQuantityList;
      });
    }

    if (selectedDealQuantityList?.length > 1) {
      setSelectedDealQuantityList((prevItems) => {
        const updatedDealQuantityList = [...prevItems];
        updatedDealQuantityList.splice(index, 1);
        return updatedDealQuantityList;
      });
    }

    if (discountOnMRPValue?.length > 1) {
      setDiscountOnMRPValue((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
    }

    if (calculatedAmountValue?.length > 1) {
      setCalculatedAmountValue((prevItems) => {
        const updatedItems = [...prevItems]; // Create a copy of the array
        updatedItems.splice(index, 1); // Remove the item at the specified index
        return updatedItems; // Set the updated array as the new state
      });
    }
  };

  //Move to next field on enter button
  const inputRefs = useRef([]);
  const submitButton = useRef(null);
  const addAnotherItemRef = useRef(null);

  useEffect(() => {
    inputRefs.current = Array.from({ length: selectedProductList.length }, () => Array(1));
  }, [selectedProductList]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        const focusedElement = document.activeElement;

        // Check if the submit button is focused ana if it is click it
        if (focusedElement === submitButton.current) {
          submitButton.current.click();
          return;
        }

        if (focusedElement === addAnotherItemRef.current) {
          addAnotherItemRef.current.click();

          setTimeout(() => {
            const lastRowIndex = inputRefs.current.length - 1;
            // After clicking, shift focus to the first element of the last row
            if (inputRefs.current[lastRowIndex] && inputRefs.current[lastRowIndex][0]) {
              inputRefs.current[lastRowIndex][0].querySelector("input").focus();
            }
          }, 300); // Adding a 300ms delay before shifting focus
          return;
        }

        // Check which input is currently focused and get its index
        for (let i = 0; i < inputRefs.current.length; i++) {
          for (let j = 0; j < inputRefs.current[i].length; j++) {
            // Check if the focused element is the current input
            const inputElement = inputRefs.current[i][j]?.querySelector("input");
            if (inputElement && inputElement === focusedElement) {
              // Check if it is the last element of the last row

              if (i === inputRefs.current.length - 1 && j === inputRefs.current[i].length - 1) {
                //Note - for some reason this is not working like in other components without timout
                if (window.confirm("want to add another item")) {
                  setTimeout(() => {
                    addAnotherItemRef.current.focus(); // Focus on add another item button
                  }, 200);
                } else {
                  setTimeout(() => {
                    submitButton.current.focus(); //Focus on submit button
                  }, 200);
                }
              }
              // Move focus to the next input in the same row
              if (inputRefs.current[i][j + 1]) {
                inputRefs.current[i][j + 1].querySelector("input").focus();
              }
              // If there is no next input, move to the first input of the next row
              else if (inputRefs.current[i + 1] && inputRefs.current[i + 1][0]) {
                inputRefs.current[i + 1][0].querySelector("input").focus();
              }
              return; // Exit the function once focus is moved
            }
          }
        }
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress); // Cleanup on unmount
    };
  }, [selectedProductList]);

  //Barcode related operations

  const [scannedBarcode, setScannedBarcode] = useState("");
  const [capturedProduct, setCapturedProduct] = useState({});

  /*Just here to workaround react's nature of not re-updating state if similar data causing this api to not fetch data if 
    some barcode is already present. */
  const [scanTimestamp, setScanTimestamp] = useState(Date.now());

  useEffect(() => {
    if (scannedBarcode?.length > 0) {
      dispatch(fetchProductByBarcode(scannedBarcode))
        .then((action) => {
          if (action) {
            setCapturedProduct(action.payload);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong! Product dosen't exist or there are two products with same barcode!");
        });
    }
    //eslint-disable-next-line
  }, [scannedBarcode, scanTimestamp]);

  // Side effect to rearrange data of captured product;
  useEffect(() => {
    //If product dosen't exist then return
    if (!capturedProduct?.id) {
      return;
    }

    //If batch data exists then extract id because id field is already taken in product
    const batchData = capturedProduct?.batch;

    if (batchData) {
      delete capturedProduct?.batch;
    }

    //check if product already exists or not, if it does simply update quantity only.
    const checkProductExists = selectedProductList.findIndex((product) => product?.id === capturedProduct?.id);
    const checkBatchExists = selectedBatchesList.findIndex((batch) => batch?.id === batchData?.id);

    if (checkProductExists >= 0) {
      //based on product nature i.e service or quantity proceed, if it is service then add one more or if it is product then update quantity or add new product if different batch
      if (batchData) {
        if (checkBatchExists >= 0) {
          const previousQuantity = parseInt(selectedProductQuantityList[checkBatchExists]);
          handleQuantityChange(previousQuantity + 1, checkBatchExists);
          //end execution here i.e batch found
          return;
        }
      }
    }

    // based on batch data add product, if there is no batch data then that means product is service
    if (batchData) {
      setSelectedProductList((prevState) => {
        return [...prevState, capturedProduct];
      });

      setSelectedBatchesList((prevState) => {
        return [...prevState, batchData];
      });
    } else {
      setSelectedProductList((prevState) => {
        return [...prevState, { ...capturedProduct, ...capturedProduct.service }];
      });

      setSelectedBatchesList((prevState) => {
        return [...prevState, {}];
      });
    }

    const productIndex = selectedProductList?.length;

    setSelectedProductQuantityList((prev) => [...prev, 1]);
    setSelectedDealQuantityList((prev) => [...prev, 0]);
    setDiscountOnMRPValue((prev) => [...prev, {}]);
    setCalculatedAmountValue((prev) => [...prev, {}]);

    if (batchData) {
      handleDiscountOnMRPChange(null, productIndex, false, {
        mrp: batchData?.mrp_price,
        sales_price: batchData?.sales_price,
      });
    } else {
      handleDiscountOnMRPChange(null, productIndex, false, {
        mrp: capturedProduct?.mrp,
        sales_price: capturedProduct?.rate,
      });
    }

    //eslint-disable-next-line
  }, [capturedProduct]);

  //Handle Product Search in Autocomplete

  //Searched products
  // const searchedProducts = useSelector((state) => state.product.searchedProducts.data);

  //Debounced search term state to call searched product API
  const [productSearchTerm, setProductSearchTerm] = useState("");

  const setDebouncedProductSearchTerm = debounce((value) => setProductSearchTerm(value), 1000);

  useEffect(() => {
    if (productSearchTerm.length > 0) {
      dispatch(searchProducts(`?product_search=${productSearchTerm}`));
    }
  }, [productSearchTerm]);

  //Debounced batch search term state to call batches search API
  const [batchSearchTerm, setBatchSearchTerm] = useState("");
  const [productIdForSearchedBatch, setProductIdForSearchedBatch] = useState(null);

  const handleBatchSearch = (productId, value) => {
    setDebouncedBatchSearchTerm(value);
    setProductIdForSearchedBatch(productId);
  };

  const setDebouncedBatchSearchTerm = debounce((value) => setBatchSearchTerm(value), 1000);

  useEffect(() => {
    if (!productIdForSearchedBatch) {
      return;
    }

    if (!batchSearchTerm) {
      return;
    }

    dispatch(
      fetchBatchesByProductID({ productId: productIdForSearchedBatch, filters: `?batch_search=${batchSearchTerm}` })
    );
  }, [batchSearchTerm]);

  const getBatchesByProductId = (id) => {
    const product = products?.find((product) => product?.id === id);
    if (product) {
      return product?.batches;
    }
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <BarcodeReader barcode={scannedBarcode} setBarcode={setScannedBarcode} setScanTimestamp={setScanTimestamp} />
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center text-xl">Edit Invoice</div>
          {selectedProductList?.length > 0 &&
            selectedProductList.map((product, index) => (
              <div
                key={product?.id || index}
                className="flex flex-col gap-[20px] border-b-2 border-neutral-200 py-[20px]"
              >
                <div>
                  <div className="py-[10px]"></div>
                  <label htmlFor="products" className="sr-only">
                    products<span className="pl-1 text-red-500">*</span>
                  </label>
                  <Autocomplete
                    ref={(el) => {
                      if (el && inputRefs.current[index]) {
                        inputRefs.current[index][0] = el; // Assign the ref if both checks pass
                      }
                    }}
                    disablePortal
                    id="products"
                    options={products || []}
                    getOptionLabel={(option) =>
                      option.total_remaining_quantity
                        ? `${option.product_name} - Qty: ${option.total_remaining_quantity}`
                        : `${option.product_name}`
                    }
                    sx={{ width: "100%" }}
                    value={selectedProductList[index]}
                    onChange={(event, value) => {
                      handleProductChange(value, index);

                      // ~~ Change here
                      if (value?.is_service === "Y") {
                        handleDiscountOnMRPChange(null, index, false, {
                          mrp: value?.service?.mrp,
                          sales_price: value?.service?.rate,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Products"
                        onChange={(e) => setDebouncedProductSearchTerm(e.target.value)}
                      />
                    )}
                    required
                  />
                </div>
                {product?.is_service === "N" && (
                  <div>
                    <div className="py-[10px]"></div>
                    <label htmlFor="batch-number" className="sr-only">
                      Batch Number<span className="pl-1 text-red-500">*</span>
                    </label>
                    <Autocomplete
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][1] = el; // Assign the ref if both checks pass
                        }
                      }}
                      disablePortal
                      id="batch-number"
                      options={getBatchesByProductId(selectedProductList[index]?.id) || []}
                      getOptionLabel={(option) =>
                        option.batch_number
                          ? `${option.batch_number} - Qty: ${option.remaining_quantity}`
                          : `${option.batch_number}`
                      }
                      sx={{ width: "100%" }}
                      value={
                        selectedBatchesList[index] && Object.keys(selectedBatchesList[index]).length > 0
                          ? selectedBatchesList[index]
                          : null
                        // selectedBatchesList[index] != null && Object.keys(selectedBatchesList[index]).length > 0
                        //   ? selectedBatchesList[index]
                        //   : null
                      }
                      onChange={(event, value) => handleBatchChange(value, index)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Batch Number"
                          onChange={(e) => handleBatchSearch(selectedProductList[index]?.id, e.target.value)}
                        />
                      )}
                      required
                    />
                  </div>
                )}
                {/* {product?.is_service === "N" ? (
                  <div className="flex flex-col">
                    <label htmlFor="hsn-number">
                      HSN number<span className="pl-1 text-red-500">*</span>
                    </label>
                    <TextField
                      hiddenLabel
                      id="hsn-number"
                      variant="outlined"
                      type="text"
                      value={product?.hsn_number || ""}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <label htmlFor="hsn-number">HSN number</label>
                    <TextField
                      hiddenLabel
                      id="hsn-number"
                      variant="outlined"
                      type="text"
                      value={selectedProductList[index]?.hsn_number || ""}
                      disabled
                    />
                  </div>
                )} */}
                {product?.is_service === "N" && (
                  <div className="flex flex-col">
                    <label htmlFor="quantity">
                      Quantity<span className="pl-1 text-red-500">*</span>
                    </label>
                    <TextField
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][2] = el; // Assign the ref if both checks pass
                        }
                      }}
                      hiddenLabel
                      id="quantity"
                      variant="outlined"
                      defaultValue={selectedProductQuantityList[index]}
                      value={selectedProductQuantityList[index]}
                      onChange={(event) => handleQuantityChange(event.target.value, index)}
                      required
                    />
                  </div>
                )}
                {product?.is_service === "N" && (
                  <div className="flex flex-col">
                    <label htmlFor="deal_quantity">Deal / Free</label>
                    <TextField
                      ref={(el) => {
                        if (el && inputRefs.current[index]) {
                          inputRefs.current[index][3] = el; // Assign the ref if both checks pass
                        }
                      }}
                      hiddenLabel
                      id="deal_quantity"
                      variant="outlined"
                      defaultValue={selectedDealQuantityList[index]}
                      value={selectedDealQuantityList[index]}
                      onChange={(event) => handleDealQuantityChange(event.target.value, index)}
                    />
                  </div>
                )}
                {product?.is_service === "N" ? (
                  <div className="flex flex-col">
                    <label htmlFor="retail_without_tax">
                      Retail<span className="pl-1 text-red-500">*</span>
                    </label>
                    <TextField
                      hiddenLabel
                      id="retail_without_tax"
                      variant="outlined"
                      value={selectedBatchesList[index]?.sales_price}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <label htmlFor="retail_without_tax">
                      Retail<span className="pl-1 text-red-500">*</span>
                    </label>
                    <TextField
                      hiddenLabel
                      id="retail_without_tax"
                      variant="outlined"
                      value={selectedProductList[index]?.rate}
                      disabled
                    />
                  </div>
                )}
                {/* {product?.is_service === "N" ? (
                  <div className="flex flex-col">
                    <label htmlFor="retail_without_tax">
                      Tax %<span className="pl-1 text-red-500">*</span>
                    </label>
                    <TextField
                      hiddenLabel
                      id="retail_without_tax"
                      variant="outlined"
                      value={selectedBatchesList[index]?.tax}
                      disabled
                    />
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <label htmlFor="retail_without_tax">
                      Tax %<span className="pl-1 text-red-500">*</span>
                    </label>
                    <TextField
                      hiddenLabel
                      id="retail_without_tax"
                      variant="outlined"
                      value={selectedProductList[index]?.tax}
                      disabled
                    />
                  </div>
                )} */}
                <div className="flex flex-col">
                  <label htmlFor="discount_on_mrp">Discount on MRP %</label>
                  <TextField
                    ref={(el) => {
                      if (el && inputRefs.current[index]) {
                        inputRefs.current[index][4] = el; // Assign the ref if both checks pass
                      }
                    }}
                    id="discount_on_mrp"
                    //value - to directly give discount, index, isDiscount, data, checkedByUser
                    onChange={(e) => {
                      if (e.target.value > 100.0) {
                        toast.error("discount On MRP field cannnot be greater than 100%");
                        handleDiscountOnMRPChange("100.00", index, true, {});
                        return;
                      }
                      handleDiscountOnMRPChange(e.target.value, index, true, {});
                    }}
                    type="number"
                    value={
                      discountOnMRPValue[index]?.discountOnMRP
                        ? precisionDecimalDigit(discountOnMRPValue[index]?.discountOnMRP)
                        : ""
                    }
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="retail_without_tax">
                    Amount<span className="pl-1 text-red-500">*</span>
                  </label>
                  <TextField
                    ref={(el) => {
                      if (el && inputRefs.current[index]) {
                        inputRefs.current[index][5] = el; // Assign the ref if both checks pass
                      }
                    }}
                    id="amount"
                    onChange={(e) => handleAmountValueChange(e.target.value, index)}
                    value={calculatedAmountValue[index]?.calculated_amount || 0}
                    type="text"
                    variant="outlined"
                    // disabled
                  />
                </div>
                <div className="py-[20px] w-full bg-green-20 flex justify-center">
                  <button
                    className="px-[20px] py-[10px] bg-black rounded-full text-white"
                    onClick={() => handleRemoveItem(index)}
                  >
                    Remove Product
                  </button>
                </div>
              </div>
            ))}
          <div className="py-[20px] w-full bg-green-20 flex justify-center">
            <button
              ref={addAnotherItemRef}
              className="px-[20px] py-[10px] bg-black rounded-full text-white"
              onClick={handleAddMoreProducts}
            >
              Add More Products
            </button>
          </div>
          <div>
            <div className="py-[10px]"></div>
            <label htmlFor="payment-option" className="sr-only">
              Payment Option
            </label>
            <Autocomplete
              disablePortal
              id="payment-option"
              options={paymentOptions || []}
              sx={{ width: "100%" }}
              value={selectedPaymentOption}
              onChange={(event, value) => {
                handlePaymentOptionChange(value);
              }}
              renderInput={(params) => <TextField {...params} label="Payment Option" />}
              required
            />
          </div>
          {selectedPaymentOption?.value === "credit" && (
            <div className="flex flex-col">
              <label htmlFor="remaining_amount">Amount Received</label>
              <TextField
                hiddenLabel
                id="remaining_amount"
                variant="outlined"
                type="number"
                value={parseFloat(updateInvoiceFormData?.paid_amount)}
                onChange={(e) => {
                  if (e.target.value > updateInvoiceFormData?.grand_total) {
                    toast.error("Amount received cannot be more than grand total!");
                    return;
                  }

                  setUpdateInvoiceFormData((prevData) => {
                    return {
                      ...prevData,
                      remaining_total: parseFloat(prevData?.grand_total) - parseFloat(e.target.value),
                      paid_amount: parseFloat(e.target.value),
                    };
                  });
                }}
              />
            </div>
          )}
          <div className="flex flex-col">
            <label htmlFor="sub_total">Sub-Total</label>
            <TextField hiddenLabel id="sgst" variant="outlined" value={invoiceDimension.total || 0} disabled />
          </div>
          <div className="flex flex-col">
            <label htmlFor="discount-per">Discount</label>
            <TextField hiddenLabel id="discount-per" variant="outlined" value={invoiceDimension.discount} disabled />
          </div>
          {/* {dispatchStateCode === shipmentStateCode && (
            <>
              <div className="flex flex-col">
                <label htmlFor="sgst">SGST</label>
                <TextField
                  hiddenLabel
                  id="sgst"
                  variant="outlined"
                  value={invoiceDimension.tax.toFixed(3).slice(0, -1) / 2 || 0}
                  disabled
                />
              </div>
              <div className="flex flex-col">
                <label htmlFor="cgst">CGST</label>
                <TextField
                  hiddenLabel
                  id="cgst"
                  variant="outlined"
                  value={invoiceDimension.tax.toFixed(3).slice(0, -1) / 2 || 0}
                  disabled
                />
              </div>
            </>
          )}
          {dispatchStateCode !== shipmentStateCode && (
            <div className="flex flex-col">
              <label htmlFor="cgst">IGST</label>
              <TextField
                hiddenLabel
                id="cgst"
                variant="outlined"
                value={invoiceDimension.tax.toFixed(3).slice(0, -1) || 0}
                disabled
              />
            </div>
          )} */}
          <div className="flex flex-col">
            <label htmlFor="total">Total</label>
            <TextField
              hiddenLabel
              id="total"
              variant="outlined"
              value={updateInvoiceFormData?.grand_total || 0}
              disabled
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="profit">Profit Margin</label>
            <TextField
              hiddenLabel
              id="profit"
              variant="outlined"
              value={invoiceDimension.profit.toFixed(3).slice(0, -1) || 0}
              disabled
            />
          </div>{" "}
          <div className="flex flex-col justify-between ">
            <h3 className=" text-black">Description</h3>

            <textarea
              className="w-full min-w-[300px] h-full min-h-[80px] p-2 border-2"
              placeholder=""
              onChange={(e) => setInvoiceDescription(e.target.value)}
              value={invoiceDescription}
            ></textarea>
          </div>
          <div className="flex gap-[10px] justify-center">
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full"
              onClick={handleSubmit}
              ref={submitButton}
              disabled={disableSubmitButton}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
            >
              Save
            </button>
            <button
              className="px-[20px] py-[10px] bg-black text-white rounded-full"
              onClick={handleClose}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClose();
                }
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      <div>
        <button onClick={handleUpdateInvoiceClick}>Edit</button>
      </div>
    </>
  );
}

export default UpdateInvoiceNonGst;
