import React, { useState } from "react";

//Statement Sections
import CustomerWise from "./statementsComponents/CustomerWise";
import StockWise from "./statementsComponents/StockWise";
import CompanyWise from "./statementsComponents/CompanyWise";
import InternalAudit from "./statementsComponents/InternalAudit";
import BalanceSheet from "./statementsComponents/BalanceSheet";
import CurrentAccount from "./statementsComponents/CurrentAccount";

function Statements() {
  //Statement Sections - Customer Wise, Stock Wise, Company Wise, Internal Audit, Balance Sheet, Current Account
  const [selectedStatement, setSelectedStatement] = useState("");

  //Render component based on Selected Statement
  const renderStatementComponent = () => {
    switch (selectedStatement) {
      case "CustomerWise":
        return <CustomerWise />;
      case "StockWise":
        return <StockWise />;
      case "CompanyWise":
        return <CompanyWise />;
      case "InternalAudit":
        return <InternalAudit />;
      case "BalanceSheet":
        return <BalanceSheet />;
      case "CurrentAccount":
        return <CurrentAccount />;
      default:
        return <CustomerWise />;
    }
  };
  return (
    <div className="flex">
      <aside className="p-[15px] border-r-2 border-neutral-200 min-w-[300px] w-[15vw] h-[calc(100vh-175px)]">
        <ul className="flex flex-col gap-[10px]">
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedStatement("CustomerWise")}
          >
            Customer Wise
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedStatement("StockWise")}
          >
            Stock Wise
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedStatement("CompanyWise")}
          >
            Company Wise
          </li>
          {/* <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedStatement("InternalAudit")}
          >
            Internal Audit
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedStatement("BalanceSheet")}
          >
            Balance Sheet
          </li>
          <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedStatement("CurrentAccount")}
          >
            Current Account
          </li> */}
        </ul>
      </aside>

      {/* statement container */}
      <div className="w-[80vw] max-w-[80vw] overflow-hidden">{renderStatementComponent()}</div>
    </div>
  );
}

export default Statements;
