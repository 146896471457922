import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateCustomer } from "../../../reducers/Slices/ledgerSlice";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { searchZipcode } from "../../../reducers/Slices/userSlice";

//UI component Import
import { Edit2 } from "react-feather";
import toast from "react-hot-toast";
import { fetchCustomers } from "../../../reducers/Slices/customerSlice";

function EditCustomer({ ledger }) {
  console.log(ledger, "this is the customer");
  const dispatch = useDispatch();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const [formData, setFormData] = useState(() => {
    return {
      id: ledger?.customer?.id,
      customer_name: ledger?.customer.customer_name || "",
      address: ledger?.customer.address || "",
      phone_number: ledger?.customer.phone_number || "",
      zipcode: ledger?.customer.zipcode || "",
      gst_number: ledger?.customer.gst_number,
      state: ledger?.customer.state || "",
      city: ledger?.customer.city || "",
      state_code: ledger?.customer.state_code || "",
      email: ledger?.customer.email || "",
      license_number: ledger?.customer?.license_number || "",
      favorite: ledger?.customer.favorite || false,
    };
  });
  const [showModal, setShowModal] = useState(false);

  const handleSearchZipcode = () => {
    dispatch(searchZipcode(formData.zipcode))
      .then((action) => {
        if (action.payload) {
          setFormData({
            ...formData,
            state: action.payload.state,
            city: action.payload.city,
            address: action.payload.address,
            state_code:
              `${action.payload.state_code}`.length > 1 ? action.payload.state_code : `0${action.payload.state_code}`,
            country_code: action.payload.country_code,
          });
        }
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleUpdateCustomer();
    // setShowModal(!showModal);
  };

  const handleClose = () => {
    setShowModal(!showModal);
  };

  const handleUpdateCustomer = () => {
    setDisableSubmitButton(true);
    dispatch(updateCustomer({ ledger: ledger, formData: formData }))
      .then((action) => {
        if (action.payload) {
          setShowModal(!showModal);
          setDisableSubmitButton(false);
          dispatch(fetchCustomers());
        } else {
          toast.error("Sorry, Customer cannot be Edited!");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4  w-full" onDoubleClick={(event) => event.stopPropagation()}>
          <div className="flex justify-center">
            <form className="w-full mx-auto" onSubmit={handleSubmit}>
              <div className="w-full">
                <div className="mb-5">
                  <label htmlFor="customer_name" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Full Customer Name<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="customer_name"
                    onChange={(e) => setFormData({ ...formData, customer_name: e.target.value })}
                    value={formData.customer_name}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    placeholder="name@profitai.com"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="phone_number" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Mobile Number<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    id="phone_number"
                    value={formData.phone_number}
                    onChange={(e) => setFormData({ ...formData, phone_number: e.target.value.slice(0, 10) })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    min={0}
                    max={9999999999}
                    placeholder="Enter mobile number"
                    required
                  />
                </div>
                <div>
                  <label htmlFor="zipcode" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Zipcode<span className="pl-1 text-red-500">*</span>
                  </label>
                  <div className="relative z-0 flex gap-[10px] mb-5 group">
                    <input
                      type="zipcode"
                      onChange={(e) => setFormData({ ...formData, zipcode: e.target.value })}
                      id="zipcode"
                      value={formData.zipcode}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="Enter zipcode"
                      required
                    />
                    <button
                      type="button"
                      onClick={handleSearchZipcode}
                      className="bg-black text-white px-[20px] py-[10px] rounded-lg"
                    >
                      Find
                    </button>
                  </div>
                </div>
                <div className="flex mb-5">
                  <div className="w-1/3 p-1">
                    {" "}
                    <label htmlFor="state" className="block mb-2 text-sm font-medium text-gray-900 ">
                      State
                    </label>
                    <input
                      type="text"
                      id="state"
                      value={formData.state}
                      onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="Enter state"
                      required
                    />
                  </div>
                  <div className="w-1/3 p-1">
                    {" "}
                    <label htmlFor="city" className="ml-2 block mb-2 text-sm font-medium text-gray-900 ">
                      City
                    </label>
                    <input
                      type="text"
                      id="city"
                      value={formData.city}
                      onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="Enter city"
                      required
                    />
                  </div>
                  <div className="w-1/3 p-1">
                    {" "}
                    <label htmlFor="state_code" className="ml-2 block mb-2 text-sm font-medium text-gray-900 ">
                      State Code
                    </label>
                    <input
                      type="text"
                      id="state_code"
                      value={formData.state_code}
                      onChange={(e) => setFormData({ ...formData, state_code: e.target.value })}
                      className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                      placeholder="i.e 17"
                      required
                    />
                  </div>
                </div>
                <form className="mb-5 mx-auto">
                  <label htmlFor="address" className="block mb-2 text-sm font-medium">
                    Address<span className="pl-1 text-red-500">*</span>
                  </label>
                  <textarea
                    id="address"
                    rows="2"
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    className="block p-2.5 w-full text-sm   rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    value={formData.address}
                    placeholder="Enter address"
                  ></textarea>
                </form>
                <div className="mb-5">
                  <label htmlFor="license_number" className="block mb-2 text-sm font-medium text-gray-900 ">
                    License Number
                  </label>
                  <input
                    type="text"
                    id="license_number"
                    onChange={(e) => setFormData({ ...formData, license_number: e.target.value })}
                    value={formData.license_number}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    placeholder=""
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 ">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    value={formData.email}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:border-gray-600 dark:placeholder-gray-400 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:shadow-sm-light"
                    placeholder="name@profitai.com"
                  />
                </div>
                <div className="w-full flex items-center justify-center py-[20px]">
                  <button
                    type="submit"
                    disabled={disableSubmitButton}
                    // onClick={handleSubmit}
                    className="bg-black text-white rounded-full px-[20px] py-[10px]"
                  >
                    Click to Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <button
        type="button"
        className="flex items-center font-medium gap-[6px] border-[1px] border-black text-md px-2 py-[2px] rounded-full ml-2"
        onClick={() => setShowModal(!showModal)}
      >
        Edit
        <Edit2 className="h-[15px] w-[15px]" />
      </button>
    </>
  );
}

export default EditCustomer;
