import React, { useState, useEffect, useLayoutEffect } from "react";
import { Select, InputLabel, FormControl, MenuItem, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { subDays } from "date-fns";
import { debounce } from "lodash";

//Reducer imports
import { fetchStatements, GenerateOfflineFile, removeOfflineFile } from "../../../reducers/Slices/profitAndLossSlice";

//Utilities
import { humanDateFormat, weekFormat, monthFormat, quarterFormat, yearFormat } from "../../../utils/dateFormat";

import { changeDateFormat } from "../../../utils/dateFormat";

//Custom Components
import DateRangeSelector from "./DateRangeSelector";
import Dashboard from "./Dashboard";
import PLTable from "./PLTable";
import get_url_extension from "../../../utils/urlExtension";
import { setShowWaitLoader } from "../../../reducers/Slices/appSlice";

function PL() {
  const dispatch = useDispatch();

  const statements = useSelector((state) => state.profitAndLoss.statements.data);
  const statementsNext = useSelector((state) => state.profitAndLoss.statements.next);
  const statementsDownloadLink = useSelector((state) => state.profitAndLoss.offlineFileUrl);
  const overallStats = useSelector((state) => state.profitAndLoss.overall_stats);

  // Convert date from machine readable to human readable date format
  const [processedStatements, setProcessedStatements] = useState([]);

  //States of Controls
  const [selectedSegment, setSelectedSegment] = useState("day");

  useEffect(() => {
    //Important return do not remove - for when initial data is customer, customer_zipcode or product_brand; - todo fix in backend
    if (statements[0]?.customer || statements[0]?.customer_zipcode || statements[0]?.product_brand) {
      return;
    }
    const processStatements = () => {
      return statements.map((statement) => {
        let formattedDate;
        switch (selectedSegment) {
          case "day":
            formattedDate = humanDateFormat(statement?.date);
            break;
          case "week":
            formattedDate = weekFormat(statement?.date);
            break;
          case "month":
            formattedDate = monthFormat(statement?.date);
            break;
          case "quarter":
            formattedDate = quarterFormat(statement?.date);
            break;
          case "year":
            formattedDate = yearFormat(statement?.date);
            break;
          default:
            formattedDate = statement?.date; // Default case if no segment is matched
        }
        return {
          ...statement,
          date: formattedDate,
        };
      });
    };

    setProcessedStatements(processStatements());
  }, [statements, selectedSegment]);

  //Handle Segment Change

  const handleSegmentChange = (e) => {
    setSelectedSegment(e.target.value);
  };

  //Handle Date Change
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //Handle Filter Change
  const [searchFilter, setSearchFilter] = useState("");

  const debouncedSetSearchFilter = debounce((value) => setSearchFilter(value), 1000);

  //Handle Download Format
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState("xlsx");

  const handleDownload = () => {
    if (!statementsDownloadLink) {
      return;
    }

    if (get_url_extension(statementsDownloadLink) !== selectedDownloadFormat) {
      return;
    }

    const fileUrl = statementsDownloadLink;

    const link = document.createElement("a");
    link.href = fileUrl;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  const businessDetails = useSelector((state) => state.user);

  const [isGstUser, setIsGstUser] = useState(false);

  useLayoutEffect(() => {
    setIsGstUser(() => businessDetails?.gst_number && businessDetails?.gst_number?.length > 0);
  }, [businessDetails]);

  //Table heading for PL table
  const columns = isGstUser
    ? [
        ["date", "date"],
        ["volume of invoice", "volume_of_invoice"],
        ["volume of goods", "volume_of_goods"],
        ["gross sales", "gross_sales"],
        ["gross profit", "gross_profit"],
        ["gross profit %", "gross_profit_percentage"],
        ["tax", "tax"],
        // ["discount", "discount"],
        ["net Profit", "net_profit"],
        ["net Profit %", "net_profit_percentage"],
      ]
    : [
        ["date", "date"],
        ["volume of invoice", "volume_of_invoice"],
        ["volume of goods", "volume_of_goods"],
        ["gross sales", "gross_sales"],
        ["gross profit", "gross_profit"],
        ["gross profit %", "gross_profit_percentage"],
        // ["tax", "tax"],
        // ["discount", "discount"],
        ["net Profit", "net_profit"],
        ["net Profit %", "net_profit_percentage"],
      ];

  //Testing Data
  // const data = [
  //   {
  //     date: humanDateFormat("2024-06-10"),
  //     "volume of invoice": 100,
  //     "volume of goods": 200,
  //     "gross sales": 5000,
  //     "gross profit": 1500,
  //     "gross profit %": "30%",
  //     Tax: 300,
  //     "net Profit": 1200,
  //     "net Profit %": "24%",
  //   },
  //   {
  //     date: humanDateFormat("2024-06-15"),
  //     "volume of invoice": 110,
  //     "volume of goods": 240,
  //     "gross sales": 8000,
  //     "gross profit": 2000,
  //     "gross profit %": "20%",
  //     Tax: 400,
  //     "net Profit": 1500,
  //     "net Profit %": "18%",
  //   },
  //   {
  //     date: humanDateFormat("2024-06-18"),
  //     "volume of invoice": 210,
  //     "volume of goods": 500,
  //     "gross sales": 10000,
  //     "gross profit": 3000,
  //     "gross profit %": "30%",
  //     Tax: 1000,
  //     "net Profit": 2500,
  //     "net Profit %": "25%",
  //   },
  // ];

  //Based on segment, date range, filter change fetch data
  useEffect(() => {
    let localString = "/";

    //Based on segment change generate this base string
    localString += `?group_by=${selectedSegment}`;

    //Based on date Range change generate this base string
    const start_date = changeDateFormat(dateRange[0].startDate);
    const end_date = changeDateFormat(dateRange[0].endDate);

    localString += `&start_date=${start_date} 00:00:00&end_date=${end_date} 23:59:59`;

    dispatch(setShowWaitLoader(true));
    dispatch(fetchStatements(localString)).then(() => dispatch(setShowWaitLoader(false)));
    dispatch(removeOfflineFile());
    dispatch(GenerateOfflineFile(`${localString}&file_type=${selectedDownloadFormat}`));
  }, [selectedSegment, dateRange, dispatch, selectedDownloadFormat]);

  //Based on search/filter input
  useEffect(() => {
    if (searchFilter) {
      let localString = `/?search=${searchFilter}`;
      dispatch(fetchStatements(localString));
    } else {
      const start_date = changeDateFormat(dateRange[0].startDate);
      const end_date = changeDateFormat(dateRange[0].endDate);
      let localString = `/?group_by=${selectedSegment}&start_date=${start_date} 00:00:00&end_date=${end_date} 23:59:59`;
      dispatch(fetchStatements(localString));
    }
    //eslint-disable-next-line
  }, [searchFilter, dispatch]);

  return (
    <div className="h-full w-full">
      {/* Controls */}
      <div className="p-[20px] flex gap-[20px] border-b-2 border-neutral-200 flex-wrap">
        <div className="flex gap-[20px] flex-1">
          {/* Segment Selector */}
          <FormControl fullWidth sx={{ minWidth: "200px" }}>
            <InputLabel id="Segment">Segment</InputLabel>
            <Select
              labelId="Segment"
              id="Segment_input"
              value={selectedSegment}
              label="Segment"
              onChange={handleSegmentChange}
              style={{ backgroundColor: "white" }}
            >
              <MenuItem value={"day"}>Day Wise</MenuItem>
              <MenuItem value={"week"}>Week Wise</MenuItem>
              <MenuItem value={"month"}>Month Wise</MenuItem>
              <MenuItem value={"quarter"}>Quarter Wise</MenuItem>
              <MenuItem value={"year"}>Year Wise</MenuItem>
            </Select>
          </FormControl>
          {/* Date Range Selector */}
          <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
          {/* Filter Input */}
          <TextField
            id="filter_input"
            sx={{ minWidth: "20vw" }}
            label="Filter"
            variant="outlined"
            onChange={(e) => debouncedSetSearchFilter(e.target.value)}
          />
        </div>

        <div className="flex gap-[20px] flex-1">
          {/* Download Format Selector - excel, csv */}
          <FormControl fullWidth sx={{ minWidth: "200px" }}>
            <InputLabel id="Download">Format</InputLabel>
            <Select
              labelId="Download"
              id="Download_format"
              value={selectedDownloadFormat}
              label="Download"
              onChange={(e) => setSelectedDownloadFormat(e.target.value)}
              style={{ backgroundColor: "white" }}
            >
              <MenuItem value={"xlsx"}>Excel</MenuItem>
              <MenuItem value={"csv"}>CSV</MenuItem>
            </Select>
          </FormControl>
          {/* Download Button */}
          <button
            id="joyride-step-6"
            onClick={handleDownload}
            className="text-white bg-black hover:bg-[rgba(0,0,0,0.8)] px-[50px] rounded-[4px]"
          >
            Download
          </button>
        </div>
      </div>

      {/* Dashboard Analytics*/}
      <Dashboard data={overallStats} />

      {/* PL data Table */}
      <div className="border-t-2 border-neutral-200 p-[20px] rounded-[4px]">
        <PLTable columns={columns} data={processedStatements} next={statementsNext} />
      </div>
    </div>
  );
}

export default PL;
