import "./App.css";
import "./tailwindcss.css";
import Approuter from "./AppRouter";
import { store } from "./reducers/Store";
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import MainLoadingScreen from "./components/SharedComponents/MainLoadingScreen";

function App() {
  //Disable mouse wheel behaviour in input tag globally
  useEffect(() => {
    const handleWheel = (event) => {
      if (event.target.type === "number") {
        event.preventDefault();
      }
    };

    document.addEventListener("wheel", handleWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <Provider store={store}>
      <div className="">
        <Approuter />
        <Toaster position="bottom-right" />
      </div>
    </Provider>
  );
}

export default App;
