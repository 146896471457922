import React from "react";
import { useDispatch } from "react-redux";
import { deleteCustomer } from "../../../reducers/Slices/ledgerSlice";
import { X as Cross } from "react-feather";
import toast from "react-hot-toast";

function DeleteCustomer({ ledger }) {
  const dispatch = useDispatch();
  function handleDeleteCustomer() {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      dispatch(deleteCustomer({ ledger: ledger, customer_id: ledger?.customer?.id }))
        .then((action) => {
          if (action.payload) {
          } else {
            toast.error("Sorry, delete not successful!");
          }
        })
        .catch(() => {
          toast.error("some error occured!");
        });
    }
  }

  return (
    <button
      type="button"
      className="flex items-center font-medium gap-[6px] border-[1px] border-red-500 text-red-500 text-md px-2 py-[2px] rounded-full ml-2"
      onClick={() => handleDeleteCustomer()}
    >
      Delete
      <Cross className="h-[15px] w-[15px]" />
    </button>
  );
}

export default DeleteCustomer;
