import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
import axios from "axios";

//Get all both vendor and customer
export const fetchCustomerAndVendor = createAsyncThunk("ledger/fetchCustomerAndVendor", async (data, extra) => {
  const res = await http.get(`/user_profile/backend/customer_vendor${data}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch more customers and vendors - react infinity scroll
export const fetchMoreCustomerAndVendor = createAsyncThunk("ledger/fetchMoreCustomerAndVendor", async (url, extra) => {
  const res = await axios.get(url, {
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

// API to delete vendor - redundant for ledgerbook
export const deleteVendor = createAsyncThunk("ledger/deleteVendor", async (data, extra) => {
  console.log(data, "this is the delete vendor data here");
  const res = await http.delete("/user_profile/backend/vendor/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: { vendor_id: data?.vendor_id },
  });
  if (res.data.status === "success") {
    return data?.ledger;
  }
});

// API to delete customer - redundant for Ledgerbook
export const deleteCustomer = createAsyncThunk("ledger/deleteCustomer", async (data, extra) => {
  const res = await http.delete("/user_profile/backend/customer/", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    data: { customer_id: data?.customer_id },
  });
  if (res.data.status === "success") {
    return data?.ledger;
  }
});

// API to update Vendor - redundant for ledgerbook
export const updateVendor = createAsyncThunk("ledger/updateVendor", async (data, extra) => {
  const res = await http.put("/user_profile/backend/vendor/", data?.formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return { ...data?.ledger, vendor: res.data.data };
  }
});

// API to update customer - redundant for ledgerbook
export const updateCustomer = createAsyncThunk("ledger/updateCustomer", async (data, extra) => {
  const res = await http.put("/user_profile/backend/customer/", data?.formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return { ...data.ledger, customer: res.data.data };
  }
});

// API to create cash ledger - To account of outstanding balance of user - dosen't have addcase builder
export const createCashLedger = createAsyncThunk("ledger/createCashLedger", async (data, extra) => {
  const res = await http.post("/cash_ledger/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//API to fetch cash ledger data
export const fetchCashLedger = createAsyncThunk("ledger/fetchCashLedger", async (data, extra) => {
  const res = await http.get(`/cash_ledger/${data ?? ""}`, {
    header: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.results.status === "success") {
    return res.data.results;
  }
});

//API to fetch more cash ledger data
export const fetchMoreCashLedger = createAsyncThunk("ledger/fetchMoreCashLedger", async (url, extra) => {
  const res = await http.get(url, {
    header: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.results;
  }
});

//ledger entries new api
export const fetchLedgerEntries = createAsyncThunk("ledger/fetchLedgerEntries", async (data, extra) => {
  const res = await http.get(`/ledger${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//export const fetch more ledger entries
export const fetchMoreLedgerEntries = createAsyncThunk("ledger/fetchMoreLedgerEntries", async (url, extra) => {
  const res = await http.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//APIs for future purposes if needed
//API to update Cash Ledger - PUT - /cash_ledger/<id here>/
//API to get all Cash Ledger - GET - /cash_ledger/
//API to get Cash Ledger by id - GET - /cash_ledger/<id here>/
//API to delete Cash Ledger by id - DELETE - /cash_ledger/<id here>/

export const ledgerSlice = createSlice({
  name: "ledger",
  initialState: {
    users: { data: [], next: "" },
    cashLedger: { data: [], next: "" },
    ledgerEntries: { data: [], next: "" },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerAndVendor.fulfilled, (state, action) => {
      return { ...state, users: { data: action.payload.data, next: action.payload.next } };
    });
    builder.addCase(fetchMoreCustomerAndVendor.fulfilled, (state, action) => {
      return { ...state, users: { data: [...state.users.data, ...action.payload.data], next: action.payload.next } };
    });
    builder.addCase(deleteCustomer.fulfilled, (state, action) => {
      return {
        ...state,
        ledgerEntries: {
          ...state.ledgerEntries,
          data: state.ledgerEntries.data.filter((ledgerEntry) => ledgerEntry.id !== action.payload.id),
        },
      };
    });
    builder.addCase(deleteVendor.fulfilled, (state, action) => {
      return {
        ...state,
        ledgerEntries: {
          ...state.ledgerEntries,
          data: state.ledgerEntries.data.filter((ledgerEntry) => ledgerEntry.id !== action.payload.id),
        },
      };
    });
    builder.addCase(updateVendor.fulfilled, (state, action) => {
      return {
        ...state,
        ledgerEntries: {
          ...state.ledgerEntries,
          data: state.ledgerEntries.data.map((ledgerEntry) => {
            if (ledgerEntry.id === action.payload.id) {
              return action.payload;
            } else {
              return ledgerEntry;
            }
          }),
        },
      };
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      return {
        ...state,
        ledgerEntries: {
          ...state.ledgerEntries,
          data: state.ledgerEntries.data.map((ledgerEntry) => {
            if (ledgerEntry.id === action.payload.id) {
              return action.payload;
            } else {
              return ledgerEntry;
            }
          }),
        },
      };
    });
    builder.addCase(fetchCashLedger.fulfilled, (state, action) => {
      return {
        ...state,
        cashLedger: {
          data: action.payload.data,
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchMoreCashLedger.fulfilled, (state, action) => {
      return {
        ...state,
        cashLedger: {
          data: [...state.cashLedger.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchLedgerEntries.fulfilled, (state, action) => {
      return {
        ...state,
        ledgerEntries: {
          data: action.payload.data,
          next: action.payload.next,
        },
      };
    });
    builder.addCase(fetchMoreLedgerEntries.fulfilled, (state, action) => {
      return {
        ...state,
        ledgerEntries: {
          data: [...state.ledgerEntries.data, ...action.payload.data],
          next: action.payload.next,
        },
      };
    });
  },
});

export default ledgerSlice.reducer;
