import React, { useState, useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchHSNData, updateBatch } from "../../../reducers/Slices/productSlice";
import { changeDateFormat } from "../../../utils/dateFormat";

// pHaniver - TODO - move CustomModel to Macro Component
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import toast from "react-hot-toast";
import BarcodeInput from "./BarcodeInput";
import { fetchWarehouses } from "../../../reducers/Slices/warehouseSlice";
import { Autocomplete, debounce, TextField } from "@mui/material";
import { fetchVendors } from "../../../reducers/Slices/vendorSlice";

function EditBatch({ batch, product }) {
  //Modal toggler
  const [showModal, setShowModal] = useState(false);

  const rawVendors = useSelector((state) => state.vendor.vendors.data);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    setVendors(() => {
      if (!batch?.vendor?.id) {
        return [...rawVendors];
      }

      if (rawVendors?.length === 0) {
        return [batch?.vendor];
      }

      if (rawVendors.find((vendor) => vendor?.id === batch?.vendor?.id)) {
        return [...rawVendors];
      } else {
        return [...rawVendors, batch?.vendor];
      }
    });
  }, [rawVendors, batch?.vendor]);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  //State to hold batch form data
  const [batchFormData, setBatchFormData] = useState({
    product_id: product?.id,
    batch_id: batch.id,
    batch: {
      ...batch,
      remaining_quantity: batch?.remaining_quantity - batch?.deal_quantity,
      vendor: batch?.vendor?.id || null,
      size: "Deprecated",
      warehouse: batch?.warehouse?.id,
    },
  });

  function handleEditBatchClick() {
    setShowModal(true);
    getHSNTaxRates();
  }

  function handleClose() {
    setShowModal(false);
  }

  // useEffect(() => {
  //   setBatchFormData((prevState) => {
  //     return {
  //       ...prevState,
  //       batch: { ...batch, total_quantity: parseInt(batch?.remaining_quantity ?? 0) + parseInt(batch?.deal ?? 0) },
  //     };
  //   });
  //   //eslint-disable-next-line
  // }, [batchFormData?.batch?.remaining_quantity, batchFormData?.batch?.deal]);

  useEffect(() => {
    if (batchFormData) {
      setBatchFormData((prevState) => {
        const updatedBatch = {
          ...prevState.batch,
          total_quantity:
            parseInt(prevState.batch.remaining_quantity ?? 0) + parseInt(prevState?.batch?.deal_quantity ?? 0),
        };
        return { ...prevState, batch: updatedBatch };
      });
    }

    //eslint-disable-next-line
  }, [batchFormData?.batch?.remaining_quantity, batchFormData?.batch?.deal_quantity]);

  const dispatch = useDispatch();

  //Update Product when parent product changes
  useEffect(() => {
    if (product) {
      const { id } = product;
      const batchFormDataWithProductId = { ...batchFormData, product_id: id };
      setBatchFormData(batchFormDataWithProductId);
    }
    //eslint-disable-next-line
  }, [product]);

  const [isGstUser, setIsGstUser] = useState(false);

  function validateRequiredFields() {
    const { sales_price, mrp_price, purchase_price, tax, batch_number, expiry_date } = batchFormData.batch;
    if (
      batch_number?.length === 0 ||
      mrp_price?.length === 0 ||
      sales_price?.length === 0 ||
      purchase_price?.length === 0 ||
      (tax?.length === 0 && isGstUser) ||
      batch_number?.length === 0 ||
      expiry_date?.length === 0
    ) {
      toast.error("Please fill in all required fields marked with *.");
      return false;
    }
    return true;
  }

  function handleSubmit() {
    if (!validateRequiredFields()) return;

    setDisableSubmitButton(true);
    //eslint-disable-next-line
    dispatch(updateBatch(batchFormData))
      .then((action) => {
        if (action.payload) {
          setDisableSubmitButton(false);
          setShowModal(false);
        } else {
          toast.error("Sorry, batch cannot be updated");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error("Error occurred while updating batch");
        setDisableSubmitButton(false);
      });
  }

  function handleDateChange(e) {
    setBatchFormData({
      ...batchFormData,
      batch: { ...batchFormData.batch, expiry_date: e.target.value },
    });
  }

  //Barcode functionality
  const [barcodeNumber, setBarcodeNumber] = useState(null);

  const warehouses = useSelector((state) => state.warehouse.warehouses.data);

  const findWarehouseByIdAsyncAPI = async (id) => {
    if (!id) {
      return;
    }

    let warehouse = warehouses?.find((product) => product.id === id);

    // if (!warehouse) {
    //   const response = await dispatch(fetchWarehouseByID(id));
    //   if (response?.payload?.data) {
    //     warehouse = response.payload.data;
    //   }
    // }

    return warehouse;
  };

  //side effect to set barcode and get get warehouse if not available if available in batches
  useEffect(() => {
    if (batch?.bar_code_number) {
      setBarcodeNumber(batch?.bar_code_number);
    }

    if (batch?.warehouse) {
      findWarehouseByIdAsyncAPI(batch?.warehouse);
    }
  }, [batch]);

  //side effect for barcode.
  useEffect(() => {
    setBatchFormData((prevState) => {
      return { ...prevState, batch: { ...prevState?.batch, bar_code_number: barcodeNumber } };
    });
  }, [barcodeNumber]);

  //Debounced search term state to call fetch warehouse API
  const [warehouseSearchTerm, setWarehouseSearchTerm] = useState("");
  const [warehouseAutoCompleteLoading, setWarehouseAutoCompleteLoading] = useState(false);
  const setDebouncedWarehouseSearchTerm = debounce((value) => setWarehouseSearchTerm(value), 1000);

  useEffect(() => {
    if (warehouseSearchTerm?.length > 0) {
      setWarehouseAutoCompleteLoading(true);
      dispatch(fetchWarehouses(`?search=${warehouseSearchTerm}`))
        .then((action) => {
          setWarehouseAutoCompleteLoading(false);
        })
        .catch((err) => {
          setWarehouseAutoCompleteLoading(false);
        });
    }
    // dispatch(searchProducts(productSearchTerm));
  }, [warehouseSearchTerm]);

  const findWarehouseById = (id) => {
    if (!id) {
      return;
    }
    return warehouses.find((warehouse) => warehouse.id === id);
  };

  // //Dummy HSN tax rates
  // const hsnTaxRates = {
  //   1205: [5],
  //   1902: [12, 18],
  //   2106: [18],
  //   2402: [28],
  //   3304: [18, 28],
  //   3824: [5],
  //   4202: [12],
  //   4901: [5, 12],
  //   6802: [28],
  //   7306: [18, 28],
  // };

  const [hSNTaxRates, sethSNTaxRates] = useState([]);

  //HSN codes
  const HSN_data = useSelector((state) => state.product.HSNData.data);

  const getHSNDataByHSNCodeAsync = async (hsn_number) => {
    let data = HSN_data.filter((HSNData) => HSNData?.hsn_code === hsn_number);

    try {
      if (data?.length === 0) {
        const response = await dispatch(fetchHSNData(`?hsn_code=${hsn_number}`));
        data = response.payload.data.filter((HSNData) => HSNData?.hsn_code === hsn_number);
      }
      return data;
    } catch (err) {
      toast.error("some error occurred while fetching HSN data");
      return null;
    }
  };

  const getHSNTaxRates = async () => {
    if (product?.hsn_number) {
      const hsnData = await getHSNDataByHSNCodeAsync(product?.hsn_number);

      if (!hsnData) {
        return null;
      }

      const duplicatePercentages =
        hsnData?.length > 1
          ? hsnData.map((data) => parseFloat(data?.cgst_rate) + parseFloat(data?.sgst_rate))
          : [parseFloat(hsnData[0]?.cgst_rate) + parseFloat(hsnData[0]?.sgst_rate)];

      const taxPercent = [...new Set(duplicatePercentages)];

      // const taxPercent = parseFloat(data?.cgst_rate) + parseFloat(data?.sgst_rate);
      const nextHSNTaxRateArr = taxPercent || [];
      sethSNTaxRates(nextHSNTaxRateArr);
    }
  };

  //vendor operations
  const [vendorSearchTerm, setVendorSearchTerm] = useState("");
  const [vendorAutoCompleteLoading, setVendorAutoCompleteLoading] = useState(false);

  const setDebouncedVendorSearchTerm = debounce((value) => setVendorSearchTerm(value), 1000);

  const findVendorById = (id) => {
    if (!id) {
      return;
    }

    return vendors.find((vendor) => vendor.id === id);
  };

  useEffect(() => {
    if (vendorSearchTerm.length > 0) {
      setVendorAutoCompleteLoading(true);
      dispatch(fetchVendors(`?search=${vendorSearchTerm}`))
        .then((action) => {
          setVendorAutoCompleteLoading(false);
        })
        .catch((err) => {
          setVendorAutoCompleteLoading(false);
        });
    }
    //eslint-disable-next-line
  }, [vendorSearchTerm]);

  // useEffect(() => {
  //   dispatch(fetchVendors());
  // }, []);

  const businessDetails = useSelector((state) => state.user);

  useLayoutEffect(() => {
    setIsGstUser(() => businessDetails?.gst_number && businessDetails?.gst_number?.length > 0);
  }, [businessDetails]);

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="item-container flex flex-col gap-[20px]">
          <div className="item-description text-center">Batch Description</div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="batch-number" className="text-neutral-500">
              Batch Number<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="batch-number"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.batch_number}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, batch_number: e.target.value },
                })
              }
              required
            />
          </div>
          {/* <div>
            <label htmlFor="size">Size</label>
            <input
              type="text"
              id="size"
              value={batchFormData.batch.size}
              onChange={(e) =>
                setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, size: e.target.value } })
              }
            />
          </div> */}
          {/* <div>
            <label htmlFor="total-quantity">Total Quantity</label>
            <input
              type="number"
              id="total-quantity"
              value={batchFormData.batch.total_quantity}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, total_quantity: e.target.value },
                })
              }
            />
          </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="remaining-quantity" className="text-neutral-500">
              Quantity<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="number"
              id="remaining-quantity"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batch?.remaining_quantity}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, remaining_quantity: e.target.value },
                })
              }
              required
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="rate" className="text-neutral-500">
              Deal/Free
            </label>
            <input
              type="text"
              id="rate"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData?.batch?.deal}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, deal: e.target.value },
                })
              }
              required
            />
          </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="mrp-price" className="text-neutral-500">
              MRP Price<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="mrp-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.mrp_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, mrp_price: e.target.value },
                })
              }
              required
            />
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="sales-price" className="text-neutral-500">
              Sales Price*
            </label>
            <input
              type="text"
              id="sales-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.sales_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, sales_price: e.target.value },
                })
              }
              required
            />
          </div> */}
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="rate" className="text-neutral-500">
              Rate A / Retail<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="rate"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.sales_price}
              onChange={(e) => {
                if (batchFormData?.batch?.mrp_price === 0 || !batchFormData?.batch?.mrp_price) {
                  toast.error("Please fill MRP first!");
                  return;
                } else if (parseFloat(batchFormData?.batch?.mrp_price) < parseFloat(e.target.value)) {
                  toast.error("Rate cannot be greater than MRP price!");
                  return;
                }

                setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, sales_price: e.target.value } });
              }}
              required
            />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="purchase-price" className="text-neutral-500">
              Purchase Price<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              type="text"
              id="purchase-price"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.purchase_price}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, purchase_price: e.target.value },
                })
              }
              required
            />
          </div>
          {isGstUser && (
            <div className="flex flex-col gap-[10px]">
              <label htmlFor="tax" className="text-neutral-500">
                Tax %<span className="pl-1 text-red-500">*</span>
              </label>
              {hSNTaxRates?.length === 1 && (
                <input
                  type="number"
                  id="tax-percentage"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
                  value={batchFormData.batch.tax}
                  onChange={(e) => {
                    setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, tax: e.target.value } });
                  }}
                  disabled
                  required
                />
              )}
              {hSNTaxRates?.length > 1 && (
                <select
                  id="tax-percentage"
                  className="border-[1px] border-neutral-500 px-[10px] py-[10px] bg-white"
                  value={parseFloat(batchFormData.batch.tax)}
                  onChange={(e) =>
                    setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, tax: e.target.value } })
                  }
                  required
                >
                  <option value="" disabled>
                    Select Tax Percentage
                  </option>
                  {hSNTaxRates.map((rate) => (
                    <option key={rate} value={rate}>
                      {rate}%
                    </option>
                  ))}
                </select>
              )}
            </div>
          )}
          <div>
            <div className="flex flex-col gap-[10px]">
              <label htmlFor="vendor" className="text-neutral-500">
                vendor
              </label>
              <Autocomplete
                disablePortal
                id="vendor"
                options={vendors}
                noOptionsText={vendorAutoCompleteLoading ? "Loading..." : "No data found!"}
                value={findVendorById(batchFormData?.batch?.vendor)}
                getOptionLabel={(option) => option.vendor_name}
                onChange={(event, value) =>
                  setBatchFormData({
                    ...batchFormData,
                    batch: { ...batchFormData?.batch, vendor: value?.id },
                  })
                }
                renderInput={(params) => (
                  <TextField {...params} required onChange={(e) => setDebouncedVendorSearchTerm(e.target.value)} />
                )}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-col gap-[10px]">
              <label htmlFor="warehouse" className="text-neutral-500">
                Warehouse
              </label>
              <Autocomplete
                disablePortal
                id="warehouse"
                options={warehouses || []}
                noOptionsText={warehouseAutoCompleteLoading ? "Loading..." : "No data found!"}
                value={findWarehouseById(batchFormData?.batch?.warehouse)}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => {
                  setBatchFormData({
                    ...batchFormData,
                    batch: { ...batchFormData?.batch, warehouse: value?.id },
                  });
                }}
                renderInput={(params) => (
                  <TextField {...params} onChange={(e) => setDebouncedWarehouseSearchTerm(e.target.value)} />
                )}
              />
            </div>
          </div>
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="discount" className="text-neutral-500">
              Discount %
            </label>
            <input
              type="text"
              id="discount"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.discount}
              onChange={(e) =>
                setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, discount: e.target.value } })
              }
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="add-margin" className="text-neutral-500">
              Add Margin %
            </label>
            <input
              type="text"
              id="add-margin"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.add_margin}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, add_margin: e.target.value },
                })
              }
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="bar-code-number" className="text-neutral-500">
              Bar Code Number*
            </label>
            <input
              type="text"
              id="bar-code-number"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.bar_code_number}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, bar_code_number: e.target.value },
                })
              }
              required
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="hsn-number" className="text-neutral-500">
              HSN Number*
            </label>
            <input
              type="text"
              id="hsn-number"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.hsn_number}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, hsn_number: e.target.value },
                })
              }
              required
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="package" className="text-neutral-500">
              Package*
            </label>
            <input
              type="text"
              id="package"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.package}
              onChange={(e) =>
                setBatchFormData({
                  ...batchFormData,
                  batch: { ...batchFormData.batch, package: e.target.value },
                })
              }
              required
            />
          </div> */}
          {/* <div className="flex flex-col gap-[10px]">
            <label htmlFor="status" className="text-neutral-500">
              Status
            </label>
            <input
              type="text"
              id="status"
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              value={batchFormData.batch.status}
              onChange={(e) =>
                setBatchFormData({ ...batchFormData, batch: { ...batchFormData.batch, status: e.target.value } })
              }
            />
          </div> */}
          {/* For Barcode  */}
          <div className="relative">
            <BarcodeInput barcodeNumber={barcodeNumber} setBarcodeNumber={setBarcodeNumber} />
          </div>
          <div className="flex flex-col gap-[10px]">
            <label htmlFor="expiry-date" className="text-neutral-500">
              Expiry Date<span className="pl-1 text-red-500">*</span>
            </label>
            <input
              className="px-[20px] py-[10px] border-[1px] border-neutral-500"
              type="date"
              id="expiry-date"
              value={batchFormData?.batch?.expiry_date ? changeDateFormat(batchFormData?.batch?.expiry_date) : null}
              onChange={handleDateChange}
              required
            />
          </div>
          <div className="flex gap-[20px] justify-center">
            <button
              className="save px-[20px] py-[10px] bg-black text-white rounded-full"
              onClick={handleSubmit}
              disabled={disableSubmitButton}
            >
              Save
            </button>
            <button className="cancel" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </div>
      </CustomModal>
      <div>
        <button className="font-bold" onClick={() => handleEditBatchClick(true)}>
          Edit
        </button>
      </div>
    </>
  );
}

export default EditBatch;
