import React, { useEffect, useLayoutEffect, useState } from "react";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { createProduct, fetchHSNData } from "../../../reducers/Slices/productSlice";
import { Autocomplete, TextField } from "@mui/material";
import { debounce } from "lodash";
import toast from "react-hot-toast";

const AddProduct = ({ showModal, setShowModal }) => {
  const [addProductFormData, setAddProductFormData] = useState({
    product_name: "",
    brand: "",
    hsn_number: "",
    package: "",
    batches: [],
  });

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  const dispatch = useDispatch();

  const [isGstUser, setIsGstUser] = useState(false);

  const handleSubmit = () => {
    setDisableSubmitButton(true);

    if (
      !addProductFormData?.product_name ||
      !addProductFormData?.brand ||
      (!addProductFormData?.hsn_number && isGstUser) ||
      !addProductFormData?.package
    ) {
      toast.error("please fill all field values in form!");
      setDisableSubmitButton(false);
      return;
    }

    //eslint-disable-next-line
    dispatch(createProduct(addProductFormData))
      .then((action) => {
        if (action.payload) {
          setAddProductFormData({});
          setDisableSubmitButton(false);
          setShowModal(false);
        } else {
          setDisableSubmitButton(false);
          toast.error("Sorry, product cannot be added");
        }
      })
      .catch((err) => {
        toast.error("Error Occured", err);
      });
  };

  const handleClose = () => {
    setAddProductFormData({});
    setShowModal(false);
  };

  //HSN codes
  const HSN_data = useSelector((state) => state.product.HSNData.data);

  const handleHSNNumberChange = (value) => {
    const nextAddProductFormData = { ...addProductFormData, hsn_number: value };
    setAddProductFormData(nextAddProductFormData);
  };

  const [hSNSearchTerm, sethSNSearchTerm] = useState("");
  const [hSNAutoCompleteLoading, setHSNAutoCompleteLoading] = useState(false);

  useEffect(() => {
    if (hSNSearchTerm.length > 0) {
      setHSNAutoCompleteLoading(true);
      dispatch(fetchHSNData(`?hsn_code=${hSNSearchTerm}`))
        .then((action) => {
          setHSNAutoCompleteLoading(false);
        })
        .catch((err) => {
          setHSNAutoCompleteLoading(false);
        });
    }
  }, [hSNSearchTerm]);

  const setDebouncedHSNSearchTerm = debounce((value) => sethSNSearchTerm(value), 1000);

  const getHSNDataByHSNCode = (hsn_number) => {
    return HSN_data.find((HSNData) => HSNData?.hsn_code === hsn_number);
  };

  const businessDetails = useSelector((state) => state.user);

  useLayoutEffect(() => {
    setIsGstUser(() => businessDetails?.gst_number && businessDetails?.gst_number?.length > 0);
  }, [businessDetails]);

  const unitsOfMeasure = [
    { code: "BAG", name: "BAGS" },
    { code: "BAL", name: "BALE" },
    { code: "BDL", name: "BUNDLES" },
    { code: "BKL", name: "BUCKLES" },
    { code: "BOU", name: "BILLION OF UNITS" },
    { code: "BOX", name: "BOX" },
    { code: "BTL", name: "BOTTLES" },
    { code: "BUN", name: "BUNCHES" },
    { code: "CAN", name: "CANS" },
    { code: "CBM", name: "CUBIC METERS" },
    { code: "CCM", name: "CUBIC CENTIMETERS" },
    { code: "CMS", name: "CENTI METERS" },
    { code: "CTN", name: "CARTONS" },
    { code: "DOZ", name: "DOZENS" },
    { code: "DRM", name: "DRUMS" },
    { code: "GGK", name: "GREAT GROSS" },
    { code: "GMS", name: "GRAMMES" },
    { code: "GRS", name: "GROSS" },
    { code: "GYD", name: "GROSS YARDS" },
    { code: "KGS", name: "KILOGRAMS" },
    { code: "KLR", name: "KILOLITRE" },
    { code: "KME", name: "KILOMETRE" },
    { code: "LTR", name: "LITRES" },
    { code: "MTR", name: "METERS" },
    { code: "MLT", name: "MILILITRE" },
    { code: "MTS", name: "METRIC TON" },
    { code: "NOS", name: "NUMBERS" },
    { code: "OTH", name: "OTHERS" },
    { code: "PAC", name: "PACKS" },
    { code: "PCS", name: "PIECES" },
    { code: "PRS", name: "PAIRS" },
    { code: "QTL", name: "QUINTAL" },
    { code: "ROL", name: "ROLLS" },
    { code: "SET", name: "SETS" },
    { code: "SQF", name: "SQUARE FEET" },
    { code: "SQM", name: "SQUARE METERS" },
    { code: "SQY", name: "SQUARE YARDS" },
    { code: "TBS", name: "TABLETS" },
    { code: "TGM", name: "TEN GROSS" },
    { code: "THD", name: "THOUSANDS" },
    { code: "TON", name: "TONNES" },
    { code: "TUB", name: "TUBES" },
    { code: "UGS", name: "US GALLONS" },
    { code: "UNT", name: "UNITS" },
    { code: "YDS", name: "YARDS" },
  ];

  return (
    <CustomModal isOpen={showModal} handleClose={handleClose}>
      <div className="item-container flex flex-col gap-[20px]">
        <div className="item-description text-center">Product description</div>
        <div className="flex flex-col gap-[10px]">
          <label htmlFor="Product-name" className="text-neutral-500">
            Product Name*
          </label>
          <input
            type="text"
            id="Product-name"
            className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
            value={addProductFormData.product_name}
            onChange={(e) => {
              const nextAddProductFormData = { ...addProductFormData, product_name: e.target.value };
              setAddProductFormData(nextAddProductFormData);
            }}
            required
          />
        </div>
        <div className="flex flex-col gap-[10px]">
          <label htmlFor="brand-name" className="text-neutral-500">
            Brand Name*
          </label>
          <input
            type="text"
            id="brand-name"
            className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
            value={addProductFormData.brand}
            onChange={(e) => {
              const nextAddProductFormData = { ...addProductFormData, brand: e.target.value };
              setAddProductFormData(nextAddProductFormData);
            }}
            required
          />
        </div>
        <div className="flex flex-col gap-[10px]">
          <label htmlFor="hsn-number" className="text-neutral-500">
            HSN Number<span className="pl-1 text-red-500">*</span>
          </label>
          <Autocomplete
            disablePortal
            id="hsn-number"
            options={HSN_data || null}
            noOptionsText={hSNAutoCompleteLoading ? "Loading..." : "No data found!"}
            style={{ borderColor: "black" }}
            value={getHSNDataByHSNCode(addProductFormData?.hsn_number)}
            getOptionLabel={(option) => option?.hsn_code}
            onChange={(event, value) => {
              handleHSNNumberChange(value?.hsn_code);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    border: "0.5px solid #737373",
                    borderRadius: "0px",
                  },
                }}
                onChange={(e) => setDebouncedHSNSearchTerm(e.target.value)}
              />
            )}
          />
        </div>

        <div className="flex flex-col gap-[10px]">
          <label htmlFor="product-package" className="text-neutral-500">
            Product Packaging*
          </label>
          {/* <input
            type="text"
            id="product-package"
            className="border-[1px] border-neutral-500 px-[10px] py-[10px]"
            value={addProductFormData?.package}
            onChange={(e) => {
              const nextAddProductFormData = { ...addProductFormData, package: e.target.value };
              setAddProductFormData(nextAddProductFormData);
            }}
            required
          /> */}
          <Autocomplete
            disablePortal
            id="product Package"
            options={unitsOfMeasure || null}
            style={{ borderColor: "black" }}
            // value={getHSNDataByHSNCode(addProductFormData?.hsn_number)}
            getOptionLabel={(option) => `${option?.name} (${option?.code})`}
            onChange={(event, value) => {
              const nextAddProductFormData = { ...addProductFormData, package: value?.code };
              setAddProductFormData(nextAddProductFormData);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  sx: {
                    border: "0.5px solid #737373", // Black border
                    borderRadius: "0px", // No border radius
                  },
                }}
              />
            )}
          />
        </div>

        <div className="flex justify-center gap-[20px]">
          <button
            className="save bg-black px-[20px] py-[10px] text-white rounded-full"
            onClick={handleSubmit}
            disabled={disableSubmitButton}
          >
            Save
          </button>
          <button className="cancel" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </CustomModal>
  );
};
export default AddProduct;
