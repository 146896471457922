import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { createCashRegistery, getCashRegisterAnalytics } from "../../../reducers/Slices/cashRegisterSlice";
import { fetchVendors } from "../../../reducers/Slices/vendorSlice";
import { fetchCustomers } from "../../../reducers/Slices/customerSlice";

// UI Imports
import toast from "react-hot-toast";
import ButtonWrapper from "../../MicroComponents/ButtonWrapper";
import CustomModal from "../../SharedComponents/CustomModals/CustomModal";
import { Autocomplete, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material";

function AddCashRegistery() {
  const dispatch = useDispatch();

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  // Get today's date for default value of date
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const [formData, setFormData] = useState({
    date: getTodayDate(),
    name: "",
    customer: null,
    vendor: null,
    amount: 0,
    note: "",
    type: "receipt", // Default type set to "payment"
  });
  const [showModal, setShowModal] = useState(false);

  //switch between customer and vendor
  const [selectedType, setSelectedType] = useState("normal");

  //make other one null when it is changed
  const handleTypeChange = (event) => {
    const type = event.target.value;
    let updatedFormData = formData;
    setSelectedType(type);

    //based on user type - change type of payment (receipt | payment)
    if (type === "normal" || type === "customer") {
      updatedFormData = { ...updatedFormData, type: "receipt" };
    } else {
      updatedFormData = { ...updatedFormData, type: "payment" };
    }

    setFormData({
      ...updatedFormData,
      customer: type !== "normal" ? (type === "customer" ? formData.customer : null) : null,
      vendor: type !== "normal" ? (type === "vendor" ? formData.vendor : null) : null,
      name: type !== "normal" ? "" : formData.name,
    });
  };

  const handleSubmit = () => {
    handleAddCashRegistery();
  };

  const handleClose = () => {
    resetInitialValue();
    setShowModal(!showModal);
  };

  function validatePayload(payload) {
    // Check if both customer and vendor are null, and name is empty
    if (payload.customer === null && payload.vendor === null && !payload.name) {
      toast.error("If either customer and vendor are not selected then name cannot be empty.");
      return false;
    }

    // Check if name is empty, and customer or vendor is not an integer ID
    if (!payload.name) {
      if (
        (payload.customer === null || !Number.isInteger(payload.customer)) &&
        (payload.vendor === null || !Number.isInteger(payload.vendor))
      ) {
        toast.error("If name is empty, either customer or vendor must be selected.");
        return false;
      }
    }

    // Check if amount is 0 or negative
    if (payload.amount <= 0) {
      toast.error("Amount cannot be 0 or negative.");
      return false;
    }

    // If all validations pass
    return true;
  }

  const handleAddCashRegistery = () => {
    setDisableSubmitButton(true);

    if (!validatePayload(formData)) {
      setDisableSubmitButton(false);
      return;
    }

    dispatch(createCashRegistery(formData))
      .then((action) => {
        if (action.payload) {
          dispatch(getCashRegisterAnalytics());
          setShowModal(!showModal);
          setDisableSubmitButton(false);
        } else {
          toast.error("Cash registery entry creation failed");
          setDisableSubmitButton(false);
        }
      })
      .catch((err) => {
        toast.error(err);
        setDisableSubmitButton(false);
      });
  };

  const resetInitialValue = () => {
    const initialValues = {
      date: getTodayDate(), // Reset to today's date
      // name: "",
      customer: null,
      vendor: null,
      amount: 0,
      note: "",
      type: "receipt", // Reset type to default
    };
    setFormData(initialValues);
  };

  // Reset Value on new form creation
  useEffect(() => {
    resetInitialValue();
  }, [showModal]);

  const handleAddCashRegisteryClick = () => {
    dispatch(fetchVendors());
    dispatch(fetchCustomers());
    setShowModal(!showModal);
  };

  //vendor operations
  const vendors = useSelector((state) => state.vendor.vendors.data);

  const [vendorSearchTerm, setVendorSearchTerm] = useState("");
  const [vendorAutoCompleteLoading, setVendorAutoCompleteLoading] = useState(false);
  const setDebouncedVendorSearchTerm = debounce((value) => setVendorSearchTerm(value), 500);

  useEffect(() => {
    if (vendorSearchTerm.length > 0) {
      setVendorAutoCompleteLoading(true);
      dispatch(fetchVendors(`?search=${vendorSearchTerm}`))
        .then((action) => {
          setVendorAutoCompleteLoading(false);
        })
        .catch((err) => {
          setVendorAutoCompleteLoading(false);
        });
    }

    //eslint-disable-next-line
  }, [vendorSearchTerm]);

  const getVendorById = (id) => {
    return vendors.find((vendor) => vendor.id === id);
  };

  //customer operations
  const customers = useSelector((state) => state.customer.customers.data);
  const [customerAutoCompleteLoading, setCustomerAutoCompleteLoading] = useState(false);
  const [customerSearchTerm, setCustomerSearchTerm] = useState("");

  const setDebouncedCustomerSearchTerm = debounce((value) => setCustomerSearchTerm(value), 500);

  useEffect(() => {
    if (customerSearchTerm.length > 0) {
      setCustomerAutoCompleteLoading(true);
      dispatch(fetchCustomers(`?search=${customerSearchTerm}`))
        .then((action) => {
          setCustomerAutoCompleteLoading(false);
        })
        .catch((err) => {
          setCustomerAutoCompleteLoading(false);
        });
    }

    //eslint-disable-next-line
  }, [customerSearchTerm]);

  //get customer by id
  const getCustomerById = (id) => {
    return customers.find((customer) => customer.id === id);
  };

  return (
    <>
      <CustomModal isOpen={showModal} handleClose={handleClose}>
        <div className="p-4 w-full">
          <div className="flex justify-center">
            <form className="w-full mx-auto">
              <div className="w-full">
                <div className="mb-5">
                  {/* Radio Button for Customer or Vendor */}
                  <div className="mb-5">
                    <label className="block mb-2 text-lg text-neutral-500 text-center">Select Type of Party</label>
                    <div className="flex justify-center ">
                      <RadioGroup row value={selectedType} onChange={handleTypeChange}>
                        <FormControlLabel value="customer" control={<Radio />} label="Customer" />
                        <FormControlLabel value="vendor" control={<Radio />} label="Vendor" />
                        <FormControlLabel value="normal" control={<Radio />} label="Normal" />
                      </RadioGroup>
                    </div>
                  </div>
                  {/* No Type Selected  */}
                  {selectedType === "normal" && (
                    <div className="mb-5">
                      <label htmlFor="name" className="block mb-2 text-sm font-medium text-gray-900">
                        Name<span className="pl-1 text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                        value={formData.name}
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                        placeholder="Enter name"
                        required
                      />
                    </div>
                  )}

                  {/* Vendor Autocomplete */}
                  {selectedType === "vendor" && (
                    <div className="mb-5">
                      <label htmlFor="vendor" className="block mb-2 text-sm font-medium text-gray-900">
                        Vendor<span className="pl-1 text-red-500">*</span>
                      </label>
                      <Autocomplete
                        disablePortal
                        id="vendor"
                        options={vendors}
                        noOptionsText={vendorAutoCompleteLoading ? "Loading..." : "No data found!"}
                        value={getVendorById(formData?.vendor) || null}
                        getOptionLabel={(option) => option.vendor_name}
                        onChange={(event, value) => setFormData({ ...formData, vendor: value ? value.id : null })}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            onChange={(e) => setDebouncedVendorSearchTerm(e.target.value)}
                          />
                        )}
                      />
                    </div>
                  )}

                  {/* Customer Autocomplete */}
                  {selectedType === "customer" && (
                    <div className="mb-5">
                      <label htmlFor="customer" className="block mb-2 text-sm font-medium text-gray-900">
                        Customer<span className="pl-1 text-red-500">*</span>
                      </label>
                      <Autocomplete
                        disablePortal
                        id="customer"
                        options={customers}
                        noOptionsText={customerAutoCompleteLoading ? "Loading..." : "No data found!"}
                        value={getCustomerById(formData?.customer) || null}
                        getOptionLabel={(option) => option.customer_name}
                        onChange={(event, value) => setFormData({ ...formData, customer: value ? value.id : null })}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            onChange={(e) => setDebouncedCustomerSearchTerm(e.target.value)}
                          />
                        )}
                      />
                    </div>
                  )}

                  <label htmlFor="date" className="block mb-2 text-sm font-medium text-gray-900">
                    Date<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="date"
                    id="date"
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                    value={formData.date}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="amount" className="block mb-2 text-sm font-medium text-gray-900">
                    Amount<span className="pl-1 text-red-500">*</span>
                  </label>
                  <input
                    type="number"
                    id="amount"
                    value={formData.amount}
                    onChange={(e) => setFormData({ ...formData, amount: parseFloat(e.target.value) })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    placeholder="Enter amount"
                    required
                  />
                </div>
                <div className="mb-5">
                  <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900">
                    Type<span className="pl-1 text-red-500">*</span>
                  </label>
                  <select
                    id="type"
                    value={formData.type}
                    onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                    required
                  >
                    <option value="payment">Payment</option>
                    <option value="receipt">Receipt</option>
                  </select>
                </div>
                <div className="mb-5">
                  <label htmlFor="note" className="block mb-2 text-sm font-medium text-gray-900">
                    Note
                  </label>
                  <textarea
                    id="note"
                    rows="4"
                    onChange={(e) => setFormData({ ...formData, note: e.target.value })}
                    className="block p-2.5 w-full text-sm rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    value={formData.note}
                    placeholder="Enter note"
                  ></textarea>
                </div>
                <div className="w-full flex items-center justify-center">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={disableSubmitButton}
                    className="text-white bg-black hover:bg-black focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-xs px-5 py-2.5 text-center"
                  >
                    Submit Cash Entry
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomModal>
      <ButtonWrapper eventCallback={handleAddCashRegisteryClick}>Add Cash Entry</ButtonWrapper>
    </>
  );
}

export default AddCashRegistery;
