import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomers } from "../../../../reducers/Slices/customerSlice";
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { fetchBrandList, fetchProducts, searchProducts } from "../../../../reducers/Slices/productSlice";
import { subDays } from "date-fns";

//Custom Components
import DateRangeSelector from "../../../ProfitAndLoss/profitAndLossComponents/DateRangeSelector";
import StatementsTable from "./StatementsTable";
import { http } from "../../../../service/http";
import { changeDateFormat } from "../../../../utils/dateFormat";
import toast from "react-hot-toast";
import {
  fetchCustomerWiseReportsDaily,
  fetchMoreCustomerWiseReportsDaily,
} from "../../../../reducers/Slices/reportSlice";
import { setShowWaitLoader } from "../../../../reducers/Slices/appSlice";

function CustomerWise() {
  const dispatch = useDispatch();

  const customers = useSelector((state) => state.customer.customers.data);
  const products = useSelector((state) => state.product.products.data);
  const Companies = useSelector((state) => state.product.brands.data);

  const customerReports = useSelector((state) => state.report.customerReports.data);
  const customerReportsNext = useSelector((state) => state.report.customerReports.next);

  // const Companies = [
  //   "Tech Innovations Inc.",
  //   "Global Solutions Ltd.",
  //   "EcoSmart Technologies",
  //   "NextGen Systems LLC",
  //   "FutureWave Enterprises",
  //   "Skyline Dynamics",
  //   "Quantum Leap Industries",
  //   "Visionary Creations Co.",
  //   "Pinnacle Consulting Group",
  //   "Blue Horizon Ventures",
  // ];

  //Handle Date Range Operations
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  //Customer Selection Operations
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerSearchTerm, setCustomerSearchTerm] = useState("");
  const [customerAutoCompleteLoading, setCustomerAutoCompleteLoading] = useState(false);
  const setDebouncedCustomerSearchTerm = debounce((value) => setCustomerSearchTerm(value));

  useEffect(() => {
    if (customerSearchTerm.length > 0) {
      setCustomerAutoCompleteLoading(true);
      dispatch(fetchCustomers(`?search=${customerSearchTerm}`))
        .then((action) => {
          setCustomerAutoCompleteLoading(false);
        })
        .catch((err) => {
          setCustomerAutoCompleteLoading(false);
        });
    } else {
      dispatch(fetchCustomers());
    }
    //eslint-disable-next-line
  }, [customerSearchTerm]);

  useEffect(() => {
    if (customers?.length > 0) {
      setSelectedCustomer(customers[0]);
    }
  }, [customers]);

  //Product Selection Operations - todo - if company is selected then only show products of that company only.
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const [productAutoCompleteLoading, setProductAutoCompleteLoading] = useState(false);
  const setDebouncedProductSearchTerm = debounce((value) => setProductSearchTerm(value), 1000);

  useEffect(() => {
    if (productSearchTerm.length > 0) {
      setProductAutoCompleteLoading(true);
      dispatch(searchProducts(`?product_search=${productSearchTerm}`))
        .then((action) => {
          setProductAutoCompleteLoading(false);
        })
        .catch((err) => {
          setProductAutoCompleteLoading(false);
        });
    } else {
      dispatch(fetchProducts());
    }
    //eslint-disable-next-line
  }, [productSearchTerm]);

  //Company Selection Operation
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companySearchTerm, setCompanySearchTerm] = useState("");
  const [companyAutoCompleteLoading, setCompanyAutoCompleteLoading] = useState(false);
  const setDebouncedCompanySearchTerm = debounce((value) => setCompanySearchTerm(value), 1000);

  useEffect(() => {
    if (companySearchTerm.length > 0) {
      setCompanyAutoCompleteLoading(true);
      dispatch(fetchBrandList(`?search=${companySearchTerm}`))
        .then((action) => {
          setCompanyAutoCompleteLoading(false);
        })
        .catch((err) => {
          setCompanyAutoCompleteLoading(false);
        });
    } else {
      dispatch(fetchBrandList());
    }
    //eslint-disable-next-line
  }, [companySearchTerm]);

  //get initial reports
  useEffect(() => {
    if (!selectedCustomer) {
      return;
    }

    //Based on date Range change generate this base string
    const start_date = changeDateFormat(dateRange[0].startDate);
    const end_date = changeDateFormat(dateRange[0].endDate);

    let baseString = `?start_date=${start_date}&end_date=${end_date}`;

    if (selectedProduct) {
      baseString += `&product_ids=${selectedProduct?.id}`;
    }

    if (selectedCompany) {
      baseString += `&brand=${selectedCompany}`;
    }

    baseString += `&customer_ids=${selectedCustomer?.id}`;

    dispatch(setShowWaitLoader(true));
    dispatch(fetchCustomerWiseReportsDaily(baseString))
      .then((action) => {
        dispatch(setShowWaitLoader(false));
        if (action.payload) {
        } else {
          toast.error("There was some error fetching customer-wise reports");
        }
      })
      .catch((err) => {
        dispatch(setShowWaitLoader(false));
        toast.error("There was some error fetching customer-wise reports");
      });
    //eslint-disable-next-line
  }, [selectedCustomer, selectedProduct, selectedCompany, dateRange.startDate, dateRange.endDate]);

  // Fetch more statements on scroll if available
  const fetchNextStatements = () => {
    if (customerReportsNext) {
      dispatch(fetchMoreCustomerWiseReportsDaily(customerReportsNext));
    }
  };

  //Download operations
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState("xlsx");

  const handleDownloadExcel = async (e) => {
    e.preventDefault();

    if (!selectedCustomer) {
      toast.error("first selected a customer");
      return;
    }

    const start_date = changeDateFormat(dateRange[0].startDate);
    const end_date = changeDateFormat(dateRange[0].endDate);

    let baseString = `&start_date=${start_date}&end_date=${end_date}`;

    if (selectedProduct) {
      baseString += `&product_ids=${selectedProduct?.id}`;
    }

    if (selectedCompany) {
      baseString = `&brand=${selectedCompany}`;
    }

    baseString = `&customer_ids=${selectedCustomer?.id}`;

    dispatch(setShowWaitLoader(true));
    try {
      const response = await http.get(`/report/customer/daily/?export=1${baseString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        responseType: "blob",
      });

      // Create a URL for the EXCEL blob
      const excelURL = URL.createObjectURL(response.data);

      // Create a temporary anchor element to trigger the download
      const link = document.createElement("a");
      link.href = excelURL;
      link.download = `Customer Wise Report`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      dispatch(setShowWaitLoader(false));
    } catch (error) {
      toast.error("EXCEL not found!");
      dispatch(setShowWaitLoader(false));
    }
  };

  //Statement Table Operations

  // "Date": "2024-11-27",
  // "Total Volume of Invoices": 3,
  // "Total Tax": 81.77,
  // "Total Amount": 763.17,
  // "Total Discount": -3.18,
  // "Total Paid Amount": 763.17,
  // "Avg Invoice Value": 254.39,
  // "Max Invoice Value": 538.19,
  // "Min Invoice Value": 74.99,
  // "Pay Letter Invoices": 0,
  // "Remaining Payment Invoices": 0,
  // "Paid Invoices": 3

  const columns = [
    ["Date", "Date"],
    ["Tax", "Total Tax"],
    ["Discount", "Total Discount"],
    ["Total Amount", "Total Amount"],
    ["Total Received", "Total Paid Amount"],
    ["Min Invoice Value", "Min Invoice Value"],
    ["Avg Invoice Value", "Avg Invoice Value"],
    ["Max Invoice Value", "Max Invoice Value"],
    ["Unpaid Invoices", "Pay Letter Invoices"],
    ["Partially Paid Invoices", "Remaining Payment Invoices"],
    ["Fully Paid Invoices", "Paid Invoices"],
    ["Total Invoices", "Total Volume of Invoices"],
  ];

  // const dummyData = [];

  // for (let i = 0; i < 10; i++) {
  //   const date = new Date(Date.now() - Math.floor(Math.random() * 1000 * 60 * 60 * 24 * 30)).toLocaleDateString();

  //   // Randomly decide if these values should be null (20% chance)
  //   const shouldBeNull = Math.random() < 0.2; // 20% chance for null values

  //   const invoiceID = shouldBeNull ? null : `INV${1000 + i}`; // Set to null or generate ID
  //   const qty = shouldBeNull ? null : Math.floor(Math.random() * 100) + 1; // Set to null or random qty
  //   const amount = qty !== null ? (qty * 20).toFixed(2) : 200; // Calculate amount if qty is not null
  //   const tax = !shouldBeNull ? (amount * 0.1).toFixed(2) : null; // Calculate tax if amount is not null
  //   const totalAmountWithTax = !shouldBeNull
  //     ? (parseFloat(amount) + parseFloat(tax)).toFixed(2)
  //     : (parseFloat(amount) + parseFloat(0)).toFixed(2); // Total amount with tax
  //   const debit = totalAmountWithTax; // Total unpaid amount
  //   const credit = (Math.random() * (amount ? parseFloat(amount) : 0)).toFixed(2); // Random paid amount based on amount

  //   const profitMargin = debit !== null ? (parseFloat(debit) - parseFloat(credit)).toFixed(2) : null; // Profit margin calculation

  //   dummyData.push({
  //     date,
  //     invoice_counter: invoiceID,
  //     volume_of_invoice: qty,
  //     tax_absolute: tax,
  //     amount: totalAmountWithTax,
  //     total_unpaid: debit,
  //     total_paid: credit,
  //     profit_margin_absolute: profitMargin,
  //   });
  // }

  return (
    <div>
      {/* Customer Selector, FY, Date, Product selector, Company selector */}
      <div className="border-b-2 border-netural-200">
        <form className="flex px-[20px] gap-[10px]">
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <Autocomplete
              disablePortal
              options={customers || []}
              value={selectedCustomer}
              getOptionLabel={(option) => option.customer_name}
              noOptionsText={customerAutoCompleteLoading ? "Loading..." : "No data found!"}
              onChange={(event, value) => {
                setSelectedCustomer(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Customer Name"
                  onChange={(e) => setDebouncedCustomerSearchTerm(e.target.value)}
                />
              )}
            />
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[200px] flex">
            <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <Autocomplete
              disablePortal
              options={products || []}
              noOptionsText={productAutoCompleteLoading ? "Loading..." : "No data found!"}
              value={selectedProduct}
              getOptionLabel={(option) => option.product_name}
              onChange={(event, value) => {
                setSelectedProduct(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Product"
                  onChange={(e) => setDebouncedProductSearchTerm(e.target.value)}
                />
              )}
            />
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <Autocomplete
              disablePortal
              options={Companies || []}
              noOptionsText={companyAutoCompleteLoading ? "Loading..." : "No data found!"}
              value={selectedCompany}
              getOptionLabel={(option) => option}
              onChange={(event, value) => {
                setSelectedCompany(value);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Company"
                  onChange={(e) => setDebouncedCompanySearchTerm(e.target.value)}
                />
              )}
            />
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <FormControl fullWidth>
              <InputLabel id="Download">Format</InputLabel>
              <Select
                labelId="Download"
                id="Download_format"
                value={selectedDownloadFormat}
                label="Download"
                onChange={(e) => setSelectedDownloadFormat(e.target.value)}
                style={{ backgroundColor: "white" }}
              >
                <MenuItem value={"xlsx"}>Excel</MenuItem>
                {/* <MenuItem value={"pdf"}>PDF</MenuItem> */}
              </Select>
            </FormControl>
          </div>
          <div className="pt-[20px] pb-[20px] w-full max-w-[20%]">
            <button
              id="joyride-step-6"
              onClick={(e) => handleDownloadExcel(e)}
              className="text-white bg-black hover:bg-[rgba(0,0,0,0.8)] h-full w-full rounded-[4px]"
            >
              Download
            </button>
          </div>
        </form>
      </div>
      <div className="p-[20px]">
        <span className="text-center text-neutral-500 block w-full pb-[20px]">Customer-Wise Statement Table</span>
        {/* Statements data Table */}
        <div>
          <StatementsTable
            columns={columns}
            data={customerReports}
            next={customerReportsNext}
            fetchNextStatements={fetchNextStatements}
          />
        </div>
        {/* statements total */}
        {/* <div className="border border-neutral-200 h-[45px] flex">
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Total Sales: </span>
            <span>₹1000</span>
          </span>
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Total Credit: </span>
            <span>₹1000</span>
          </span>
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Total Payment: </span>
            <span>₹1000</span>
          </span>
          <span className="h-full border-r border-neutral-200 flex-1 flex items-center justify-center w-full">
            <span className="text-neutral-500 pr-[5px]">Outstanding: </span>
            <span>₹1000</span>
          </span>
        </div> */}
      </div>
    </div>
  );
}

export default CustomerWise;
