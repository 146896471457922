import React, { useState } from "react";

import ImportEInvoice from "./ImportAndExportComponent/ImportEInvoice";
import ExportEInvoice from "./ImportAndExportComponent/ExportEInvoice";
import ImportFromOtherSoftware from "./ImportAndExportComponent/ImportFromOtherSoftware";

function ImportExport() {
  const [selectedIE, setSelectedIE] = useState("ExportEInvoice");

  //Render component based on Selection
  const renderIEComponent = () => {
    switch (selectedIE) {
      case "ImportEInvoice":
        return <ImportEInvoice />;
      case "ExportEInvoice":
        return <ExportEInvoice />;
      default:
        return <ImportFromOtherSoftware />;
    }
  };

  return (
    // Import and Export section
    <div className="flex max-w-[96vw]">
      <aside className="p-[15px] border-r-2 border-neutral-200 min-w-[300px] w-[15vw] h-[calc(100vh-80px)]">
        <ul className="flex flex-col gap-[10px]">
          {/* <li
            className={`border-[2px] ${
              selectedIE === "ImportInventory" || selectedIE === "default" ? "border-black" : "border-neutral-200"
            } hover:border-black rounded-[20px] px-[5px] py-[15px] text-center`}
            onClick={() => setSelectedIE("ImportInventory")}
          >
            Import ERP Data
          </li> */}
          {/* <li
            className="border-[2px] border-neutral-200 hover:border-neutral-400 rounded-[20px] px-[5px] py-[15px] text-center"
            onClick={() => setSelectedIE("ImportEInvoice")}
          >
            Import E-Invoice
          </li> */}
          <li
            className={`border-[2px] ${
              selectedIE === "ExportEInvoice" ? "border-black" : "border-neutral-200"
            } hover:border-black rounded-[20px] px-[5px] py-[15px] text-center`}
            onClick={() => setSelectedIE("ExportEInvoice")}
          >
            Export E-Invoice
          </li>
        </ul>
      </aside>

      {/* P&L container */}
      <div className="w-[85vw] max-w-[85vw] overflow-hidden">{renderIEComponent()}</div>
    </div>
  );
}

export default ImportExport;
