import React, { useEffect, useLayoutEffect, useState } from "react";
import EditBatch from "./EditBatch";
import RemoveBatch from "./RemoveBatch";
import { humanDateFormat } from "../../../utils/dateFormat";
// import { generateBarcodeImage } from "../../../reducers/Slices/productSlice";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { http } from "../../../service/http";
import { fetchBatchesByProductID, fetchMoreBatchesProductID } from "../../../reducers/Slices/productSlice";
import InfiniteScroll from "react-infinite-scroll-component";
import { prettifyDigits } from "../../../utils/prettifyDigits";
import moment from "moment";
import { fetchVendors } from "../../../reducers/Slices/vendorSlice";

function BatchTable({ product }) {
  const dispatch = useDispatch();

  const batches = useSelector((state) => state.product.batchesByProductId.data);
  const batchesNext = useSelector((state) => state.product.batchesByProductId.next);

  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };

  const getTodayDateEpoch = () => {
    return moment().startOf("day").valueOf(); // Start of today's date in epoch
  };

  const isDateExpired = (expiryDate) => {
    if (!expiryDate) {
      return false;
    }
    const expiryEpoch = moment(expiryDate).valueOf(); // Convert expiry date to epoch
    const todayEpoch = getTodayDateEpoch();
    console.log(expiryEpoch < todayEpoch, "expirt compary ");
    return expiryEpoch < todayEpoch;
  };

  const [hasMoreBatches, setHasMoreBatches] = useState(() => (batchesNext ? true : false));

  //Use effect to fetch more expenses based on value of expensesNext
  useEffect(() => {
    if (batchesNext) {
      setHasMoreBatches(true);
    } else {
      setHasMoreBatches(false);
    }
  }, [batchesNext]);

  //Side effect to fetch batch based on product ID
  useEffect(() => {
    if (!product?.id) {
      return;
    }
    dispatch(fetchBatchesByProductID({ productId: product?.id }));
    //eslint-disable-next-line
  }, [product]);

  const handleLoadMoreBatches = () => {
    if (batchesNext) {
      dispatch(fetchMoreBatchesProductID(batchesNext));
    }
  };

  const handlePrintBarcode = async (batch) => {
    if (!batch) {
      return;
    }

    const barcode = batch?.bar_code_number;
    if (barcode) {
      try {
        const response = await http.get(`/inventory/api/barcode/generate/${barcode}/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          responseType: "blob",
        });
        // Create a URL for the PNG blob
        const imageURL = URL.createObjectURL(response.data);

        // Create a temporary anchor element to trigger the download
        const link = document.createElement("a");
        link.href = imageURL;
        link.download = `${barcode}_barcode.png`; // Set the file name as needed
        document.body.appendChild(link);
        link.click(); // Programmatically click the link to trigger the download
        document.body.removeChild(link); // Clean up by removing the link after the download
      } catch (error) {
        toast.error("Some error occurred while printing barcode", error);
      }
    }
  };

  useEffect(() => {
    dispatch(fetchVendors());
  }, []);

  const businessDetails = useSelector((state) => state.user);
  const [isGstUser, setIsGstUser] = useState(false);

  useLayoutEffect(() => {
    setIsGstUser(() => businessDetails?.gst_number && businessDetails?.gst_number?.length > 0);
  }, [businessDetails]);

  return (
    <div className="overflow-y-scroll h-[55vh] max-h-[55vh] border-[0.5px] border-[rgb(0,0,0,.25)]">
      <InfiniteScroll
        dataLength={batches?.length}
        next={handleLoadMoreBatches}
        hasMore={hasMoreBatches}
        height={"54vh"}
        loader={<h4 style={{ textAlign: "center", paddingTop: "10px" }}>Loading More...</h4>}
        endMessage={
          <p style={{ textAlign: "center", paddingTop: "10px" }}>
            <b>Batches List End</b>
          </p>
        }
      >
        <table border="1" className="table-auto">
          <thead className="bg-gray-50">
            <tr>
              {/*<th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
            Batch ID
            </th> */}
              {/*<th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
            Size
            </th> */}
              {/* <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
            Total Quantity
            </th> */}
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Batch Number
              </th>
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Barcode Number
              </th>
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Quantity
              </th>
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Deal / Free
              </th>
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                MRP Price
              </th>
              {/* <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
              Sales Price
            </th> */}
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Rate
              </th>
              {/* <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
              Purchase Price
            </th> */}
              {isGstUser && (
                <th
                  className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                  scope="col"
                >
                  Tax %
                </th>
              )}
              {/* <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
              Discount %
            </th> */}
              {/* <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
              Margin %
            </th> */}
              {/* <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
              Barcode Number
            </th> */}
              {/* <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
              HSN Number
            </th> */}
              {/* <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
              Package
            </th> */}
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Expiry Date
              </th>
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Vendor
              </th>
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Warehouse Name
              </th>

              {/* <th
              className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
              scope="col"
            >
              Status
            </th> */}
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Print Barcode
              </th>
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Edit Batch
              </th>
              <th
                className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                scope="col"
              >
                Delete Batch
              </th>
            </tr>
          </thead>
          <tbody>
            {batches?.map((batch) => (
              <tr key={batch.id}>
                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">{batch.id}</td> */}
                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">{batch.size}</td> */}
                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">{batch.total_quantity}</td> */}
                <td
                  className={`px-6 py-4 text-center whitespace-nowrap text-sm ${
                    isDateExpired(batch.expiry_date) ? "text-red-500" : "text-gray-500"
                  } bg-white border-[1px] border-neutral-200 `}
                >
                  {batch?.batch_number}
                </td>
                <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                  {batch?.bar_code_number || "Nil"}
                </td>
                <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                  {batch?.remaining_quantity - batch?.deal_quantity ?? 0}
                </td>
                <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                  {batch?.deal_quantity ?? 0}
                </td>
                <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                  {prettifyDigits(batch?.mrp_price)}
                </td>
                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                {batch.sales_price}
              </td> */}
                <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                  {prettifyDigits(batch?.sales_price)}
                </td>
                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                {batch.purchase_price}
              </td> */}
                {isGstUser && (
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                    {batch?.tax ?? 0}
                  </td>
                )}
                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                {batch.discount}
              </td> */}
                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                {batch.add_margin}
              </td> */}
                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                {batch.bar_code_number}
              </td> */}
                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                {batch.hsn_number}
              </td> */}
                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                {batch.package}
              </td> */}
                <td
                  className={`px-6 py-4 text-center whitespace-nowrap text-sm ${
                    isDateExpired(batch.expiry_date) ? "text-red-500" : "text-gray-500"
                  } bg-white border-[1px] border-neutral-200 `}
                >
                  {humanDateFormat(batch.expiry_date)}
                </td>
                {batch?.vendor ? (
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                    {batch?.vendor?.vendor_name}
                  </td>
                ) : (
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                    Not Selected
                  </td>
                )}
                <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                  {batch?.warehouse?.name || "Not selected"}
                </td>

                {/* <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                {batch.status}
              </td> */}
                {batch?.bar_code_number ? (
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                    <button onClick={() => handlePrintBarcode(batch)} className="font-bold">
                      Print Barcode
                    </button>
                  </td>
                ) : (
                  <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                    N/A
                  </td>
                )}
                <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                  <EditBatch batch={batch} product={product} />
                </td>
                <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
                  <RemoveBatch batch={batch} product={product} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </div>
  );
}

export default BatchTable;
