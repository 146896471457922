export const prettifyDigits = (value) => {
  if (value) {
    return parseFloat(value).toFixed(2);
  } else {
    return 0;
  }
};

export const precisionDecimalDigit = (value, string = false) => {
  if (value) {
    const formattedValue = parseFloat(value).toFixed(3).slice(0, -1);

    if (string) {
      return formattedValue;
    }

    return parseFloat(formattedValue);
  } else {
    return 0.0;
  }
};
