import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "@mui/material";
import { Box, Fade, Backdrop } from "@mui/material";

const CustomModal = ({ children, isOpen, styles, handleClose }) => {
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const childrenContainerRef = useRef(null);

  useEffect(() => {
    if (showExitConfirmation && childrenContainerRef.current) {
      childrenContainerRef.current.scrollTop = 0;
      childrenContainerRef.current.style.overflow = "hidden";
    } else if (childrenContainerRef.current) {
      childrenContainerRef.current.style.overflow = "auto";
    }
  }, [showExitConfirmation]);

  const styled = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    maxHeight: "96vh !important",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "none",
    outline: "none",
    borderRadius: "10px",
    boxShadow: 24,
    ...styles,
  };

  const handleBeforeClose = () => {
    setShowExitConfirmation(true); // Show the custom exit confirmation dialog
  };

  const handleConfirmExit = () => {
    setShowExitConfirmation(false);
    handleClose(); // Call the parent component's close function to actually close the modal
  };

  const handleCancelExit = () => {
    setShowExitConfirmation(false); // Hide the exit confirmation dialog
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleBeforeClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isOpen}>
          <Box sx={styled}>
            <div
              ref={childrenContainerRef}
              style={{ overflowY: "auto", maxHeight: "80vh", padding: "30px" }}
              onDoubleClick={(event) => event.stopPropagation()}
            >
              {children}
            </div>

            {showExitConfirmation && (
              <div className="absolute top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] bg-white h-[100%] w-[100%] flex flex-col items-center justify-center z-10">
                <p className="text-2xl max-w-[20vw] text-center">
                  Unsaved changes will be lost. <br /> Are you sure you want to exit?
                </p>
                <div className="flex gap-[10px] pt-[20px]">
                  <button className="px-[20px] py-[10px] bg-black text-white rounded-full" onClick={handleConfirmExit}>
                    Yes
                  </button>
                  <button className="px-[20px] py-[10px] bg-black text-white rounded-full" onClick={handleCancelExit}>
                    No
                  </button>
                </div>
              </div>
            )}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default CustomModal;
