import React, { useState } from "react";

function PrintingOptions() {
  const [selectedPrinter, setSelectedPrinter] = useState("");
  const [extraFields, setExtraFields] = useState({});

  const printerTypes = [
    { id: "small", name: "Small Print Printer" },
    { id: "large", name: "Large Print Printer" },
  ];

  const handlePrinterTypeChange = (event) => {
    setSelectedPrinter(event.target.value);
  };

  const extraFieldOptions = [
    { id: "Batch Number", label: "Batch Number" },
    { id: "Expiry Date", label: "Expiry Date" },
  ];

  const handleCheckboxChange = (id) => {
    setExtraFields((prevFields) => ({
      ...prevFields,
      [id]: !prevFields[id],
    }));
  };

  return (
    <div className="px-[20px] py-[20px]">
      <h1 className="text-2xl font-semibold">Printing Options</h1>

      {/* Printer Type */}
      <div className="py-[20px]">
        <label htmlFor="printerType" className="block font-semibold mb-2">
          Select Thermal Printer Type:
        </label>
        <select
          id="printerType"
          value={selectedPrinter}
          onChange={handlePrinterTypeChange}
          className="block px-4 py-2 border bg-white border-neutral-500 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-indigo-200"
        >
          <option value="" disabled>
            -- Choose Printer Type --
          </option>
          {printerTypes.map((printer) => (
            <option key={printer.id} value={printer.id}>
              {printer.name}
            </option>
          ))}
        </select>
        {/* 
        {selectedPrinter && (
          <div className="mt-4">
            You selected: {printerTypes.find((printer) => printer.id === selectedPrinter)?.name}
          </div>
        )} */}
      </div>

      {/* Optional Fields */}

      <div className="border-y-2 border-neutral-200 py-[10px]">
        <h3 className="font-semibold">Extra Fields To Show In Invoices PDF:</h3>

        <div className="py-[10px]">
          {extraFieldOptions.map((field) => (
            <div key={field.id} className="flex items-center mb-2">
              <input
                type="checkbox"
                id={field.id}
                checked={!!extraFields[field.id]}
                onChange={() => handleCheckboxChange(field.id)}
                className="mr-2"
              />
              <label htmlFor={field.id} className="text-gray-700">
                {field.label}
              </label>
            </div>
          ))}
        </div>

        {/* <div className="mt-4">
          <h4 className="text-indigo-600 font-bold">Checklist:</h4>
          <ul className="list-disc ml-6">
            {Object.entries(extraFields)
              .filter(([_, isChecked]) => isChecked)
              .map(([id]) => (
                <li key={id} className="text-gray-800">
                  {extraFieldOptions.find((field) => field.id === id)?.label}
                </li>
              ))}
          </ul>
        </div> */}
      </div>

      <div className="my-[20px] flex items-center justify-center">
        <button
          className="bg-black text-white px-[20px] py-[10px] rounded-full font-semibold"
          onClick={() => alert("Save Print Settings button was clicked!")}
        >
          Save Print Settings
        </button>
      </div>
    </div>
  );
}

export default PrintingOptions;
