import React, { useEffect, useState } from "react";
import get_url_extension from "../../../utils/urlExtension";
import { useSelector } from "react-redux";

function GSTR2B({ financialYear, quarter, month }) {
  //Download Operations
  //Handle Download Format
  const [selectedDownloadFormat, setSelectedDownloadFormat] = useState("pdf");

  const handleDownload = () => {
    //operation to get download link
    let statementsDownloadLink = null;

    if (!statementsDownloadLink) {
      alert("download button was clicked here");
      return;
    }

    if (get_url_extension(statementsDownloadLink) !== selectedDownloadFormat) {
      return;
    }

    const fileUrl = statementsDownloadLink;

    const link = document.createElement("a");
    link.href = fileUrl;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  //business details
  const businessDetails = useSelector((state) => state.user);

  //summary and all tables tab switcher
  const [selectedMainTab, setSelectedMainTab] = useState("summary");

  //Summary Section Operations
  const [selectedSummarySecondaryTab, setSelectedSummarySecondaryTab] = useState("itc_available");

  //summary table rendered
  const tableDataRowRenderer = (selectedSummaryTab) => {
    //ITC Available
    if (selectedSummaryTab === "itc_available") {
      return (
        <>
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm font-semibold text-black  bg-white border-[1px] border-neutral-200">
              Part-A
            </td>
            <td
              colSpan={6}
              className="px-6 py-4 text-center whitespace-nowrap text-sm font-semibold text-black  bg-white border-[1px] border-neutral-200"
            >
              ITC Available - Credit may be claimed in relevant headings in GSTR-3B
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              I
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              All other ITC - Supplies from registered persons
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(5)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              150.36
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              16,093.57
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              16,093.57
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Invoices */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Invoices</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              150.36
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              16,093.57
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              16,093.57
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Debit notes */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Debit notes</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - ECO - Documents */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">ECO - Documents</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Invoices (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Invoices (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Debit notes (Amendments) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Debit notes (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - ECO - Documents (Amendments) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">ECO - Documents (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              II
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              Inward Supplies from ISD
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(4)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* II - ISD - Invoices */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">ISD - Invoices</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* II - ISD - Invoices (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">ISD - Invoices (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              III
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              Inward Supplies liable for reverse charge
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              3.1(d) 4(A)(3)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* III - B2B - Invoices */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Invoices</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* III - B2B - Debit notes */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Debit notes</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* III - B2B - Invoices (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Invoices (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* III - B2B - Debit notes (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Debit notes (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              IV
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              Import of Goods
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(1)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* IV - IMPG - Import of goods from overseas */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">IMPG - Import of goods from overseas</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* IV - IMPG (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">IMPG (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* IV - IMPGSEZ - Import of goods from SEZ */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">IMPGSEZ - Import of goods from SEZ</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* IV - IMPGSEZ - (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">IMPGSEZ (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm font-semibold text-black bg-white border-[1px] border-neutral-200">
              Part-B
            </td>
            <td
              colSpan={6}
              className="px-6 py-4 text-center whitespace-nowrap text-sm font-semibold text-black bg-white border-[1px] border-neutral-200"
            >
              ITC Available - Credit notes should be net off against relevant ITC available headings in GSTR-3B
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              I
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              Others
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Credit Notes */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">B2B - Credit notes</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(5)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Credit notes (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">B2B - Credit notes (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(5)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Credit notes (Reverse Charge) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">B2B - Credit notes (Reverse charge)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              3.1(d) 4(A)(3)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Credit notes (Reverse charge) (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">B2B - Credit Notes (Reverse charge) (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              3.1(d) 4(A)(3)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - ISD - Credit notes */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">ISD - Credit notes</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(4)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - ISD - Credit notes (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">ISD - Credit notes (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(4)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>
        </>
      );
    } else if (selectedSummaryTab === "itc_not_available") {
      return (
        <>
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm font-semibold text-black bg-white border-[1px] border-neutral-200">
              Part-A
            </td>
            <td
              colSpan={6}
              className="px-6 py-4 text-center whitespace-nowrap text-sm font-semibold text-black bg-white border-[1px] border-neutral-200"
            >
              ITC Not Available
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              I
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              All other ITC - Supplies from registered persons
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(D)(2)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Invoices */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Invoices</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Debit notes */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Debit notes</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - ECO - Documents */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">ECO - Documents</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Invoices (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Invoices (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Debit notes (Amendments) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Debit notes (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - ECO - Documents (Amendments) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">ECO - Documents (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              II
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              Inward Supplies from ISD
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(D)(2)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* II - ISD - Invoices */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">ISD - Invoices</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* II - ISD - Invoices (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">ISD - Invoices (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              III
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              Inward Supplies liable for reverse charge
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              3.1(d) 4(D)(2)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* III - B2B - Invoices */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Invoices</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* III - B2B - Debit notes */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Debit notes</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* III - B2B - Invoices (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Invoices (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* III - B2B - Debit notes (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Debit notes (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm font-semibold text-black bg-white border-[1px] border-neutral-200">
              Part-B
            </td>
            <td
              colSpan={6}
              className="px-6 py-4 text-center whitespace-nowrap text-sm font-semibold text-black bg-white border-[1px] border-neutral-200"
            >
              ITC Available - Credit notes should be net off against relevant ITC available headings in GSTR-3B
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              I
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              Others
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Credit Notes */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">B2B - Credit notes</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(5)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Credit notes (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">B2B - Credit notes (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(5)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Credit notes (Reverse Charge) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">B2B - Credit notes (Reverse charge)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(3)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Credit notes (Reverse charge) (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">B2B - Credit Notes (Reverse charge) (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(3)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - ISD - Credit notes */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">ISD - Credit notes</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(4)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - ISD - Credit notes (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              <button className="text-blue-600">ISD - Credit notes (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(A)(4)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>
        </>
      );
    } else if (selectedSummaryTab === "itc_reversal") {
      return (
        <>
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm font-semibold text-black bg-white border-[1px] border-neutral-200">
              Part-A
            </td>
            <td
              colSpan={6}
              className="px-6 py-4 text-center whitespace-nowrap text-sm font-semibold text-black bg-white border-[1px] border-neutral-200"
            >
              ITC Reversed - Others
            </td>
          </tr>

          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              I
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              ITC Reversal on account of Rule 37A
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              4(B)(2)
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Invoices */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Invoices</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Debit notes */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Debit notes</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Invoices (Amendment) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Invoices (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>

          {/* I - B2B - Debit notes (Amendments) */}
          <tr>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"></td>
            <td
              colSpan={2}
              className="px-6 py-4  whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200"
            >
              <button className="text-blue-600">B2B - Debit notes (Amendment)</button>
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
            <td className="px-6 py-4 text-center whitespace-nowrap text-sm text-gray-500 bg-white border-[1px] border-neutral-200">
              0.00
            </td>
          </tr>
        </>
      );
    }
  };

  // All Table Operations
  const allTableDropdownOptions = [
    { index: 1, title: "Taxable inward supplies received from registered person - B2B" },
    { index: 2, title: "Amendments to previously filed invoices by supplier - B2BA" },
    { index: 3, title: "Debit/Credit notes (Original) - B2B CDNR" },
    { index: 4, title: "Amendments to previously filed Credit/Debit notes by supplier - B2B CDNRA" },
    { index: 5, title: "ISD Credits" },
    { index: 6, title: "Amendments ISD Credits received - ISDA" },
    { index: 7, title: "Import of goods from overseas on bill of entry - IMPG" },
    { index: 8, title: "Import of goods from SEZ units/developers on bill of entry - IMPGSEZ" },
    { index: 9, title: "Documents reported by ECO on which ECO is liable to pay tax u/s 9(5) - ECO" },
    { index: 10, title: "Amendments to documents reported by ECO on which ECO is liable to pay tax u/s 9(5) - ECOA" },
    { index: 11, title: "ITC Reversed - B2B" },
    { index: 12, title: "Amendments to previously filed invoices by supplier - B2BA (ITC reversal)" },
    { index: 13, title: "Debit notes (Original) - B2B DNR" },
    { index: 14, title: "Amendments to previously filed Debit notes by supplier - B2B DNRA" },
  ];

  const [selectedAllTable, setSelectedAllTable] = useState("1");

  //supplier wise details / document details
  const [selectedSubCategoryAllTable, setSelectedSubCategoryAllTable] = useState("supplier_wise");

  //supplier wise details:
  const [searchSupplierAllTable, setSearchSupplierAllTable] = useState();

  return (
    <div className="overflow-y-scroll">
      {/* Nested Bar - Period Selection */}
      <div className="flex justify-between border-b-2 border-neutral-200">
        <div className="flex gap-[10px] h-[75px] items-center pl-[10px]">
          <span>
            <strong>GSTIN: </strong>
          </span>
          <span>{businessDetails?.gst_number ? businessDetails?.gst_number?.toUpperCase() : ""}</span>
        </div>
        <div className="h-[75px] py-[15px] pr-[10px] flex gap-[10px]">
          {/* Date Range Selector */}
          {/* <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} /> */}
          {/* Download Format Selector - excel, csv, json*/}
          <select
            value={selectedDownloadFormat}
            onChange={(e) => setSelectedDownloadFormat(e.target.value)}
            className="bg-white px-[10px] border border-neutral-300 rounded-sm"
          >
            <option value={"pdf"}>Pdf</option>
            <option value={"xlsx"}>Excel</option>
          </select>
          {/* Download Button */}
          <button
            id="joyride-step-6"
            onClick={handleDownload}
            className="text-white bg-black hover:bg-[rgba(0,0,0,0.8)] px-[50px] rounded-[4px]"
          >
            Download
          </button>
          {/* Download Button */}
          <button
            id="joyride-step-6"
            onClick={() => alert("view advisory button was clicked")}
            className="text-white bg-black hover:bg-[rgba(0,0,0,0.8)] px-[50px] rounded-[4px]"
          >
            View Advisory
          </button>
        </div>
      </div>

      {/* summary and all table switcher */}
      <div className="flex py-[10px] border-b-2">
        <button
          className={`flex-1 text-center ${
            selectedMainTab === "summary" ? "text-black font-semibold " : "text-neutral-500"
          }`}
          onClick={() => setSelectedMainTab("summary")}
        >
          Summary
        </button>
        <span className="text-neutral-300">|</span>
        <button
          className={`flex-1 text-center ${
            selectedMainTab === "allTables" ? "text-black font-semibold " : "text-neutral-500"
          }`}
          onClick={() => setSelectedMainTab("allTables")}
        >
          All Tables
        </button>
      </div>

      {/* Summary Section */}
      {selectedMainTab === "summary" && (
        <div>
          {/* ITC Available, ITC not Available, ITC Reversal - switcher */}
          <div className="flex py-[10px] border-b-2">
            <button
              className={`flex-1 text-center ${
                selectedSummarySecondaryTab === "itc_available" ? "text-black font-semibold " : "text-neutral-500"
              }`}
              onClick={() => setSelectedSummarySecondaryTab("itc_available")}
            >
              ITC Available
            </button>
            <span className="text-neutral-300">|</span>
            <button
              className={`flex-1 text-center ${
                selectedSummarySecondaryTab === "itc_not_available" ? "text-black font-semibold " : "text-neutral-500"
              }`}
              onClick={() => setSelectedSummarySecondaryTab("itc_not_available")}
            >
              ITC Not Available
            </button>
            <span className="text-neutral-300">|</span>
            <button
              className={`flex-1 text-center ${
                selectedSummarySecondaryTab === "itc_reversal" ? "text-black font-semibold " : "text-neutral-500"
              }`}
              onClick={() => setSelectedSummarySecondaryTab("itc_reversal")}
            >
              ITC Reversal
            </button>
          </div>

          {/* Table to display ITC information */}
          <table className="w-full max-w-[60vw] mx-auto mt-[20px] mb-[50px]">
            <thead className={`${selectedSummarySecondaryTab === "itc_available" ? "bg-green-100" : "bg-red-100"}`}>
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                >
                  S.NO.
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                >
                  Heading {`[Expand All Button] | [Collapse All Button]`}
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                >
                  GSTR-3B table
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                >
                  Integrated Tax (₹)
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                >
                  Central Tax (₹)
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                >
                  State/UT Tax (₹)
                </th>

                <th
                  scope="col"
                  className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border-[1px] border-netural-200 text-nowrap"
                >
                  Cess (₹)
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">{tableDataRowRenderer(selectedSummarySecondaryTab)}</tbody>
          </table>
        </div>
      )}

      {/* All Tables Section */}
      {selectedMainTab === "allTables" && (
        <div className="flex py-[10px] px-[10px] items-center border-b-2 ">
          {/* Selection drop down and title  */}
          <div>
            <select
              className="px-[20px] py-[10px] bg-white border border-neutral-300"
              value={"0"}
              onChange={(e) => setSelectedAllTable(e.target.value)}
            >
              <option value="0">Select table to view details</option>
              {allTableDropdownOptions.map((item) => (
                <option key={item.index} value={item.index}>
                  {item.title}
                </option>
              ))}
            </select>
          </div>

          {/* heading based on selected table */}
          <div className="px-[20px] py-[8.5px] ml-[10px] w-full border border-neutral-300 ">
            <h3>
              <span className="text-neutral-500">Selected Table:</span>{" "}
              {allTableDropdownOptions[selectedAllTable - 1].title}
            </h3>
          </div>
        </div>
      )}

      {/* allTableSwitcher(Supplier wise details, Document Details) - filters available, search option, download excel button */}
      <div>
        {/* Supplier wise and Document details switcher */}
        <div className="flex py-[10px] border-b-2">
          <button
            className={`flex-1 text-center ${
              selectedSubCategoryAllTable === "supplier_wise" ? "text-black font-semibold " : "text-neutral-500"
            }`}
            onClick={() => setSelectedSubCategoryAllTable("supplier_wise")}
          >
            Supplier Wise Details
          </button>
          <span className="text-neutral-300">|</span>
          <button
            className={`flex-1 text-center ${
              selectedSubCategoryAllTable === "document_wise" ? "text-black font-semibold " : "text-neutral-500"
            }`}
            onClick={() => setSelectedSubCategoryAllTable("document_wise")}
          >
            Document Details
          </button>
        </div>
      </div>
    </div>
  );
}

export default GSTR2B;
