import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";

// //Get stock wise reports - filters: start_date, end_date, product_id and brand
// export const fetchStockReports = createAsyncThunk("report/fetchStockReports", async (data, extra) => {
//   const res = await http.get(`/report/stock/${data ?? ""}`, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data;
//   }
// });

// //get more stock wise reports
// export const fetchMoreStockWiseReports = createAsyncThunk("report/fetchMoreStockWiseReports", async (url, extra) => {
//   const res = await http.get(url, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data;
//   }
// });

//Get stock wise reports - filters: start_date, end_date, product_id and brand
export const fetchStockReportsDaily = createAsyncThunk("report/fetchStockReportsDaily", async (data, extra) => {
  const res = await http.get(`/report/stock/daily/${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//get more stock wise reports
export const fetchMoreStockWiseReportsDaily = createAsyncThunk(
  "report/fetchMoreStockWiseReportsDaily",
  async (url, extra) => {
    const res = await http.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//Get company wise reports - filters: start_date, end_date, product_id and brand
export const fetchCompanyWiseReportsDaily = createAsyncThunk(
  "report/fetchCompanyWiseReportsDaily",
  async (data, extra) => {
    const res = await http.get(`/report/brand/daily/${data ?? ""}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//get more company wise reports
export const fetchMoreCompanyWiseReportsDaily = createAsyncThunk(
  "report/fetchMoreCompanyWiseReportsDaily",
  async (url, extra) => {
    const res = await http.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//Get customer wise reports - filters: start_date, end_date, product_id and brand
export const fetchCustomerWiseReportsDaily = createAsyncThunk(
  "report/fetchCustomerWiseReportsDaily",
  async (data, extra) => {
    const res = await http.get(`/report/customer/daily/${data ?? ""}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//get more customer wise reports
export const fetchMoreCustomerWiseReportsDaily = createAsyncThunk(
  "report/fetchMoreCustomerWiseReportsDaily",
  async (url, extra) => {
    const res = await http.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

export const reportSlice = createSlice({
  name: "report",
  initialState: {
    stockReports: { data: [], next: null },
    companyReports: { data: [], next: null },
    customerReports: { data: [], next: null },
  },
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(fetchStockReports.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     stockReports: { data: action.payload.data, next: action.payload.next },
    //   };
    // });
    // builder.addCase(fetchMoreStockWiseReports.fulfilled, (state, action) => {
    //   return {
    //     ...state,
    //     stockReports: { data: [...state.stockReports.data, ...action.payload.data], next: action.payload.next },
    //   };
    // });
    builder.addCase(fetchStockReportsDaily.fulfilled, (state, action) => {
      return {
        ...state,
        stockReports: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreStockWiseReportsDaily.fulfilled, (state, action) => {
      return {
        ...state,
        stockReports: { data: [...state.stockReports.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(fetchCompanyWiseReportsDaily.fulfilled, (state, action) => {
      return {
        ...state,
        companyReports: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreCompanyWiseReportsDaily.fulfilled, (state, action) => {
      return {
        ...state,
        companyReports: { data: [...state.stockReports.data, ...action.payload.data], next: action.payload.next },
      };
    });
    builder.addCase(fetchCustomerWiseReportsDaily.fulfilled, (state, action) => {
      return {
        ...state,
        customerReports: { data: action.payload.data, next: action.payload.next },
      };
    });
    builder.addCase(fetchMoreCustomerWiseReportsDaily.fulfilled, (state, action) => {
      return {
        ...state,
        customerReports: { data: [...state.stockReports.data, ...action.payload.data], next: action.payload.next },
      };
    });
  },
});

export default reportSlice.reducer;
// export const {} = reportSlice.actions;
