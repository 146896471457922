import React, { useEffect } from "react";
import { CircularProgress } from "@mui/material";

function WaitLoader() {
  return (
    <div className="absolute flex bottom-0 right-0 items-end justify-end z-[999]">
      <div className="-ml-[80px] mt-[120px] mb-[50px] mr-[35px] flex justify-center gap-[10px] text-lg text-slate-700 font-semibold px-[20px] py-[10px] bg-slate-100 rounded-full border-slate-200 border-2">
        Fetching Data... <CircularProgress size={30} />
      </div>
    </div>
  );
}

export default WaitLoader;
