import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { http } from "../../service/http";
// import axios from "axios";

//fetch purchase invoices
export const fetchInvoicesVendor = createAsyncThunk("purchaseInvoice/fetchInvoicesVendor", async (data, extra) => {
  const res = await http.get(`invoice/api/invoice/vendor/list${data ?? ""}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Fetch more purchase invoices for react infinity scroll
export const fetchMoreInvoicesVendor = createAsyncThunk(
  "purchaseInvoice/fetchMoreInvoicesVendor",
  async (url, extra) => {
    const res = await http.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//Get Purchase Invoice Details by ID
export const getPurchaseInvoiceDetailsById = createAsyncThunk(
  "purchaseInvoice/getPurchaseInvoiceDetailsById",
  async (data, extra) => {
    const res = await http.get(`/invoice/api/invoice/purchase-details/${data.id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//Create purchase demand
export const createPurchaseDemand = createAsyncThunk("purchaseInvoice/createPurchaseDemand", async (data, extra) => {
  const res = await http.post("request_invoices/api/invoice/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data;
  }
});

//Create purchase invoice
export const createPurchaseInvoice = createAsyncThunk("purchaseInvoice/createPurchaseInvoice", async (data, extra) => {
  const res = await http.post("invoice/api/purchase/create/", data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.invoice;
  }
});

//create purchase invoice non-gst
export const createPurchaseInvoiceNonGst = createAsyncThunk(
  "purchaseInvoice/createPurchaseInvoiceNonGst",
  async (data, extra) => {
    const res = await http.post("/non_gst/api/purchase/create/", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data.invoice;
    }
  }
);

//Cancel purchase invoice
export const deletePurchaseInvoice = createAsyncThunk("purchaseInvoice/deletePurchaseInvoice", async (data, extra) => {
  const res = await http.delete(`invoice/api/purchase/create/${data.id}/`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.status === 200 || res.status === 204) {
    return data;
  }
});

//Cancel purchase invoice non gst
export const deletePurchaseInvoiceNonGst = createAsyncThunk(
  "purchaseInvoice/deletePurchaseInvoiceNonGst",
  async (data, extra) => {
    const res = await http.delete(`/non_gst/api/purchase/create/${data.id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200 || res.status === 204) {
      return data;
    }
  }
);

//Update purchase invoice
export const updatePurchaseInvoice = createAsyncThunk("purchaseInvoice/updatePurchaseInvoice", async (data, extra) => {
  const res = await http.put(`invoice/api/purchase/create/${data.id}/`, data.data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
  if (res.data.status === "success") {
    return res.data.invoice;
  }
});

//Update purchase invoice - non gst
export const updatePurchaseInvoiceNonGst = createAsyncThunk(
  "purchaseInvoice/updatePurchaseInvoiceNonGst",
  async (data, extra) => {
    const res = await http.put(`/non_gst/api/purchase/create/${data.id}/`, data.data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data.invoice;
    }
  }
);

//Purchase monthly analytics data
export const purchaseMonthlyAnalyticsData = createAsyncThunk(
  "purchaseInvoice/purchaseMonthlyAnalyticsData",
  async (data, extra) => {
    const res = await http.get(`/invoice/api/invoice/monthly-purchases-analytics${data}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

//get vendor invoices
// export const getVendorInvoices = createAsyncThunk("purchaseInvoice/getVendorInvoices", async (data, extra) => {
//   const res = await http.get(`invoice/api/invoice/vendor/list${data ?? ""}`, {
//     headers: {
//       Authorization: `Bearer ${localStorage.getItem("token")}`,
//     },
//   });
//   if (res.data.status === "success") {
//     return res.data;
//   }
// });

//business purchase api integration:

//invoice creation - create invoice
export const createBusinessPurchaseInvoice = createAsyncThunk(
  "purchaseInvoice/createBusinessPurchaseInvoice",
  async (data, extra) => {
    const res = await http.post("business_invoice/purchase-invoices/", data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

//invoice updation - update invoice
export const updateBusinessPurchaseInvoice = createAsyncThunk(
  "purchaseInvoice/updateBusinessPurchaseInvoice",
  async (data, extra) => {
    const res = await http.put(`business_invoice/purchase-invoices/${data.id}/`, data.data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data.data;
    }
  }
);

//Cancel business purchase invoices
export const deleteBusinessPurchaseInvoice = createAsyncThunk(
  "purchaseInvoice/deleteBusinessPurchaseInvoice",
  async (data, extra) => {
    const res = await http.delete(`/business_invoice/purchase-invoices/${data.id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.status === 200 || res.status === 204) {
      return data;
    }
  }
);

//Get Purchase Invoice Details by ID
export const getBusinessPurchaseInvoiceDetailsById = createAsyncThunk(
  "purchaseInvoice/getBusinessPurchaseInvoiceDetailsById",
  async (data, extra) => {
    const res = await http.get(`business_invoice/purchase-invoices/${data.id}/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.status === "success") {
      return res.data;
    }
  }
);

//fetch business purchase invoices
export const fetchBusinessPurchaseInvoices = createAsyncThunk(
  "purchaseInvoice/fetchBusinessPurchaseInvoices",
  async (data, extra) => {
    const res = await http.get(`/business_invoice/purchase-invoices/${data ?? ""}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.results.status === "success") {
      return res.data.results;
    }
  }
);

//Fetch more business purchase invoices for react infinity scroll
export const fetchMoreBusinessPurchaseInvoices = createAsyncThunk(
  "purchaseInvoice/fetchMoreBusinessPurchaseInvoices",
  async (url, extra) => {
    const res = await http.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    if (res.data.results.status === "success") {
      return res.data.results;
    }
  }
);

export const purchaseInvoiceSlice = createSlice({
  name: "purchaseInvoice",
  initialState: {
    // customer_invoices: [],
    vendor_invoices: {
      data: [],
      next: null,
    },
    purchaseInvoiceDetails: [],
    businessPurchaseInvoices: { data: [], next: null },
    businessPurchaseInvoicesDetails: {},
    puchaseInvoiceVendor: {},
    purchase_analytics: { current_month_data: [], previous_month_data: [], x_labels: [] },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvoicesVendor.fulfilled, (state, action) => {
      return { ...state, vendor_invoices: { data: action.payload?.data, next: action.payload?.next } };
    });
    builder.addCase(fetchMoreInvoicesVendor.fulfilled, (state, action) => {
      return {
        ...state,
        vendor_invoices: {
          data: [...state.vendor_invoices.data, ...action.payload?.data],
          next: action.payload?.next,
        },
      };
    });
    builder.addCase(getPurchaseInvoiceDetailsById.fulfilled, (state, action) => {
      return { ...state, purchaseInvoiceDetails: action.payload.data, purchaseInvoiceVendor: action.payload.vendor };
    });
    builder.addCase(createPurchaseInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        vendor_invoices: { ...state.vendor_invoices, data: [...state.vendor_invoices.data, action.payload] },
      };
    });
    builder.addCase(createPurchaseInvoiceNonGst.fulfilled, (state, action) => {
      return {
        ...state,
        vendor_invoices: { ...state.vendor_invoices, data: [...state.vendor_invoices.data, action.payload] },
      };
    });
    builder.addCase(purchaseMonthlyAnalyticsData.fulfilled, (state, action) => {
      return {
        ...state,
        purchase_analytics: action.payload,
      };
    });
    builder.addCase(updatePurchaseInvoice.fulfilled, (state, action) => {
      if (!action.payload?.id) {
        return;
      }

      const updatedPurchaseInvoice = action.payload;

      const updatedData = state.vendor_invoices.data.map((invoice) => {
        if (invoice.id === updatedPurchaseInvoice.id) {
          return updatedPurchaseInvoice;
        }
        return invoice;
      });
      return { ...state, vendor_invoices: { ...state.vendor_invoices, data: updatedData } };
    });
    builder.addCase(updatePurchaseInvoiceNonGst.fulfilled, (state, action) => {
      if (!action.payload?.id) {
        return;
      }

      const updatedPurchaseInvoice = action.payload;

      const updatedData = state.vendor_invoices.data.map((invoice) => {
        if (invoice.id === updatedPurchaseInvoice.id) {
          return updatedPurchaseInvoice;
        }
        return invoice;
      });
      return { ...state, vendor_invoices: { ...state.vendor_invoices, data: updatedData } };
    });
    // builder.addCase(getVendorInvoices.fulfilled, (state, action) => {
    //   return { ...state, vendor_invoices: { data: action.payload?.data, next: action.payload?.next } };
    // });
    //   // builder.addCase(fetchInvoiceByCustomerId.fulfilled, (state, action) => {
    //   //   return { ...state, customer_invoices: action.payload };
    //   // });
    //   // builder.addCase(fetchInvoiceByVendorId.fulfilled, (state, action) => {
    //   //   return { ...state, vendor_invoices: action.payload };
    //   // });
    //   builder.addCase(fetchInvoiceByProductId.fulfilled, (state, action) => {
    //     return { ...state, product_invoices: { data: action.payload.data, next: action.payload.next } };
    //   });
    //   builder.addCase(fetchMoreInvoiceByProductId.fulfilled, (state, action) => {
    //     return {
    //       ...state,
    //       product_invoices: { data: [...state.product_invoices.data, ...action.payload.data], next: action.payload.next },
    //     };
    //   });
    //   builder.addCase(fetchSalesCompareAnalytics.fulfilled, (state, action) => {
    //     return { ...state, sales_analytics: action.payload };
    //   });
    //   builder.addCase(getInvoiceDetailsById.fulfilled, (state, action) => {
    //     return { ...state, invoiceDetails: action.payload };
    //   });
    //   builder.addCase(getVendorInvoices.fulfilled, (state, action) => {
    //     return { ...state, invoices: { data: action.payload.data, next: action.payload.next } };
    //   });
    //   builder.addCase(getCustomerInvoices.fulfilled, (state, action) => {
    //     return { ...state, invoices: { data: action.payload.data, next: action.payload.next } };
    //   });
    builder.addCase(deletePurchaseInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        vendor_invoices: {
          ...state.vendor_invoices,
          data: state.vendor_invoices.data.filter((invoice) => invoice.id !== action.payload?.id),
        },
      };
    });
    builder.addCase(deletePurchaseInvoiceNonGst.fulfilled, (state, action) => {
      return {
        ...state,
        vendor_invoices: {
          ...state.vendor_invoices,
          data: state.vendor_invoices.data.filter((invoice) => invoice.id !== action.payload?.id),
        },
      };
    });
    builder.addCase(createBusinessPurchaseInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        businessPurchaseInvoices: {
          ...state.businessPurchaseInvoices,
          data: [...state.businessPurchaseInvoices.data, action.payload],
        },
      };
    });
    builder.addCase(getBusinessPurchaseInvoiceDetailsById.fulfilled, (state, action) => {
      return { ...state, businessPurchaseInvoicesDetails: action.payload.data };
    });
    builder.addCase(updateBusinessPurchaseInvoice.fulfilled, (state, action) => {
      if (!action.payload?.id) {
        return;
      }

      const updatedBusinessPurchaseInvoice = action.payload;

      const updatedData = state.businessPurchaseInvoices.data.map((invoice) => {
        if (invoice.id === updatedBusinessPurchaseInvoice.id) {
          return updatedBusinessPurchaseInvoice;
        }
        return invoice;
      });

      return { ...state, businessPurchaseInvoices: { ...state.businessPurchaseInvoices, data: updatedData } };
    });
    builder.addCase(deleteBusinessPurchaseInvoice.fulfilled, (state, action) => {
      return {
        ...state,
        businessPurchaseInvoices: {
          ...state.businessPurchaseInvoices,
          data: state.businessPurchaseInvoices.data.filter((invoice) => invoice.id !== action.payload?.id),
        },
      };
    });
    builder.addCase(fetchBusinessPurchaseInvoices.fulfilled, (state, action) => {
      return { ...state, businessPurchaseInvoices: { data: action.payload?.data, next: action.payload?.next } };
    });
    builder.addCase(fetchMoreBusinessPurchaseInvoices.fulfilled, (state, action) => {
      return {
        ...state,
        businessPurchaseInvoices: {
          data: [...state.businessPurchaseInvoices.data, ...action.payload?.data],
          next: action.payload?.next,
        },
      };
    });
  },
});

export default purchaseInvoiceSlice.reducer;
// export const { getInvoiceById, addInvoice } = invoiceSlice.actions;
